import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {  audience_black, your_posts_black, content_black, mute_black, dm_black, spaces_black, discoverability_black, right_arrow,right_arrow_black, back_btn, back_btn_black,  audience_and_tagging, content_you_see, direct_msg, discoverability, mute_and_block, spaces, your_post} from "../../settings2/src/assets";
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}
export interface PrivacySettings {
  protect_posts: boolean;
  photo_tagging: string;
  post_sensitive_content: boolean;
  view_sensitive_content: boolean;
  show_content_in_this_location: boolean;
  hide_sensitive_content_in_search: boolean;
  exclude_blocked_muted_in_search: boolean;
  message_request_from: string;
  allow_audio_calls_from: "anyone" | "people_you_follow" | "people_who_follow_you";
  always_relay_calls: boolean;
  send_read_receipts: boolean;
  enable_audio_calling: boolean;
}
interface UserData {
  id: string;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  categories: { id: number; whiteLogo: string; blackLogo: string; title: string; description: string;}[];
  photoTagging: string;
  currentView: string,
  isDarkMode: boolean;
  checkboxes: { checkbox1: boolean; checkbox2: boolean; checkbox3: boolean;};
  photoTag:{ btn1: boolean, btn3: boolean, btn2: boolean };
  showPhotoTagModal: boolean;
  isLoading: boolean;
  showRemoveContactsConfirmation: boolean;
  listOfPrivacySettings: PrivacySettings;
  blockedListOfAccounts: any;
  showUnmuteModal: boolean;
  selectedUser: UserData
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrivacySettingsId:string ="";
  submitPrivacySettingsId: string = "";
  blockedAccountsApiId: string = "";
  mutedAccountsApiId: string = "";
  unmuteApiId: string = "";
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.ReciveUserCredentials),
        getName(MessageEnum.CountryCodeMessage)
        // Customizable Area End
        ];

        this.state = {
        // Customizable Area Start
        photoTagging: "Off",
        showRemoveContactsConfirmation: false,
        isLoading: true,
        categories: [
            {id:1, whiteLogo:audience_and_tagging, blackLogo:audience_black, title: "Audience and tagging", description: "Manage what information you allow other people on Arrows to see."},
            {id:2, whiteLogo:your_post, blackLogo:your_posts_black, title: "Your posts", description: "Manage the information associated with your posts."},
            {id:3, whiteLogo:content_you_see, blackLogo:content_black, title: "Content you see", description: "Decide what you see on Arrows based on your preferences."},
            {id:4, whiteLogo:mute_and_block, blackLogo:mute_black, title: "Mute and block", description: "Manage the accounts, words and notifications that you've muted or blocked."},
            {id:5, whiteLogo:direct_msg, blackLogo:dm_black, title: "Direct messages", description: "Manage who can message you directly."},
            {id:6, whiteLogo:spaces, blackLogo:spaces_black, title: "Spaces", description: "Manage your Spaces activity"},
            {id:7, whiteLogo:discoverability, blackLogo:discoverability_black, title: "Discoverability and contacts", description: "Control your discoverability settings and manage contacts you've imported."},
        ],
        currentView: 'main',
        isDarkMode: false,
        checkboxes: { checkbox1: true, checkbox2: true, checkbox3: true, },
        photoTag: { btn1: false, btn2: false, btn3: false, },
        showPhotoTagModal: false,
        listOfPrivacySettings: {} as PrivacySettings,
        blockedListOfAccounts: [],
        showUnmuteModal: false,
        selectedUser: {} as UserData
        // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
          return ""
        }

        // Customizable Area Start
        this.getPrivacySettingsResponse(message)
        this.submitPrivacySettingsResponse(message)        
        this.blockedAccountsResponse(message)  
        this.mutedAccountsResponse(message)  
        this.UnmuteAccountsResponse(message)    
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
      this.getPrivacySettings();
    }

    getImageUrl= (isDarkMode:boolean, imageType: 'back' | 'right') => {
      const imageMap = { 
        back: { dark: back_btn, light: back_btn_black },
        right: { dark: right_arrow, light: right_arrow_black}
      };
      return isDarkMode ?imageMap[imageType].dark : imageMap[imageType].light;
    }

    changeMsgRequestFrom = (val: string) => {
      this.setState({listOfPrivacySettings: { ...this.state.listOfPrivacySettings, message_request_from: val }}, () => this.submitPrivacySettings("photo_tagging"));
    };

    handlePhotoTagging=(val: string) => {
      const data = { ...this.state.listOfPrivacySettings, photo_tagging: val };
      this.setState({listOfPrivacySettings: data});
    }

    onClickBackButton = async () => {
      let backToType = "";
      switch (this.state.currentView) {
        case 'Audienceandtagging':
          backToType = "main";
          break;
        case 'Yourposts':
          backToType = "main";
          break;
        case 'Contentyousee':
          backToType = "main";
          break;
        case 'Exploresettings':
          backToType = "Contentyousee";
          break;
        case 'Searchsettings':
          backToType = "Contentyousee";
          break;
        case 'Muteandblock':
          backToType = "main";
          break;
        case 'Directmessages':
          backToType = "main";
          break;
        case 'Discoverabilityandcontacts':
          backToType = "main";
          break;
        case 'Blockedaccounts':
          backToType = "Muteandblock";
          break;  
        case 'MutedAccount':
            backToType = "Muteandblock";
            break;           
        default:
          backToType = "main";
          break;
      }
      this.setState({
        currentView: backToType
      })
    }

    privacyApiCall = (data: ApiData) => {
      const { contentType, method, endPoint, body } = data;
      const token =  localStorage.getItem("authToken") || "";
      const header = { "Content-Type": contentType, token: token };
      const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
      body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };

    getPrivacySettings = ()  => {
      this.getPrivacySettingsId = this.privacyApiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.validationApiGETMethodType,
        endPoint: "bx_block_privacy_setting/privacy_settings",
      });
    }

    getPrivacySettingsResponse = (message:Message) => {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getPrivacySettingsId === apiRequestCallId && responseJson && responseJson.data){ 
        const attributes = responseJson.data.attributes;
        this.setState({
          listOfPrivacySettings: {
            protect_posts: attributes.audience_and_tagging.protect_posts,
            photo_tagging: attributes.audience_and_tagging.photo_tagging,
            post_sensitive_content: attributes.your_posts.post_sensitive_content,
            view_sensitive_content: attributes.content_you_see.view_sensitive_content,
            show_content_in_this_location: attributes.explore_settings.show_content_in_this_location,
            hide_sensitive_content_in_search: attributes.search_settings.hide_sensitive_content_in_search,
            exclude_blocked_muted_in_search: attributes.search_settings.exclude_blocked_muted_in_search,
            message_request_from: attributes.direct_messages.message_request_from,
            allow_audio_calls_from: attributes.direct_messages.allow_audio_calls_from,
            always_relay_calls: attributes.direct_messages.always_relay_calls,
            send_read_receipts: attributes.direct_messages.send_read_receipts,
            enable_audio_calling: false // Default value as per the requirement
          },
          isLoading: false
        })
      } 
    }

    submitPrivacySettingsResponse = (message:Message) => {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson, "responseJson")
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.submitPrivacySettingsId === apiRequestCallId && responseJson && responseJson.messages){
        this.setState({
          isLoading: false,
        })
      }
    }

    submitPrivacySettings = (val? : keyof PrivacySettings) => {
      let data = { ...this.state.listOfPrivacySettings };
      this.setState({
        isLoading: true,
        showPhotoTagModal: false
      })
      let reqBody = {}
      if(val){
        const currentValue = data[val];
        if (typeof currentValue === "boolean" ) {
          reqBody = { ...this.state.listOfPrivacySettings, [val]: !currentValue }
          this.setState({
            listOfPrivacySettings: { ...this.state.listOfPrivacySettings, [val]: !currentValue }
          })
        } 

        if(val === "photo_tagging"){
          reqBody = {...this.state.listOfPrivacySettings}
        }

        this.submitPrivacySettingsId = this.privacyApiCall({
          contentType: "application/json",
          method: "PATCH",
          endPoint: "bx_block_privacy_setting/privacy_settings",
          body: reqBody
        });
      }
    }

    showBlockedAccounts = () => {
      this.setState({ isLoading: true });
      this.blockedAccountsApiId = this.privacyApiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.validationApiGETMethodType,
        endPoint: "bx_block_followers/blocked_accounts",
      });
    }

    blockedAccountsResponse= (message:Message) => {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson, "responseJson")
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.blockedAccountsApiId === apiRequestCallId && responseJson && responseJson.data){
        this.setState({
          isLoading: false,
          currentView: "Blockedaccounts",
          blockedListOfAccounts: responseJson.data
        })
      }
    }

    showMutedAccounts = () => {
      this.setState({ isLoading: true });
      this.mutedAccountsApiId = this.privacyApiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.validationApiGETMethodType,
        endPoint: "bx_block_followers/muted_accounts",
      });
    }

    mutedAccountsResponse= (message:Message) => {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      console.log(responseJson, "responseJson")
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.mutedAccountsApiId === apiRequestCallId && responseJson && responseJson.data){
        this.setState({
          isLoading: false,
          currentView: "MutedAccounts",
          blockedListOfAccounts: responseJson.data
        })
      }
    }

    showUnmuteModal = (userData: any) => {
      this.setState({
        showUnmuteModal: true,
        selectedUser: {id: userData.attributes.id, name: userData.attributes.user_name}
      })
    }

    closeUnmuteModal = () => {
      this.setState({
        showUnmuteModal: false,
      })
    }

    onConfirmUnmute = () => {
      this.setState({
        showUnmuteModal: false,
        isLoading: true
      })
      const isBlocked = this.state.currentView === "Blockedaccounts";
      this.unmuteApiId = this.privacyApiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: isBlocked ? "bx_block_followers/unblock_account":"bx_block_followers/unmute_account",
        body: {account_id: this.state.selectedUser.id }
      });
    }

    UnmuteAccountsResponse= (message:Message) => {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.unmuteApiId === apiRequestCallId && responseJson && responseJson.data){
        const listOfUsers = this.state.blockedListOfAccounts
        const newList = listOfUsers.filter((each: any) => each.attributes.id != this.state.selectedUser.id);
        this.setState({
          isLoading: false,
          blockedListOfAccounts: newList
        })
      }
    }
  
    // Customizable Area End
}
