import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData, getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  checked?: boolean
  classes?: { [key: string]: string }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  subscriptionDetails:{id:number,type:string,attributes:{id:number,name:string,price:string,description:string,subscribed:boolean,valid_up_to:string,features:string[]}}[]
  activeId:number;
  apiError:string;
  userToken:string;
  submittedDetails:{id:number,type:string,attributes:{id:number,name:string,price:string,description:string,subscribed:boolean,valid_up_to:string,features:string[]}}[],
  isLoading:boolean
  updateSubscription:boolean
  userSubscriptionId:number|null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getSubsId:string='';
  submitSubscriptionsId:string=''
  apiGetDataCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      subscriptionDetails:[],
      activeId:0,
      apiError:'',
      submittedDetails:[],
      userToken:'',
      isLoading:true,
      updateSubscription:false,
      userSubscriptionId:null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    this.getSubscriptionsApi(message)

    this.submitSubscriptionsApi(message)

    this.getUserDataResponse(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getSubData()
    this.getUserData()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  getSubData=async()=>{
    const token = await getStorageData("authToken");
    this.setState({userToken:token})
    const header = {
      token:token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSubscriptionsApi=(message:Message)=>{

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({isLoading:false})

        if (apiRequestCallId === this.getSubsId) {
          if (apiRequestCallId && !responseJson.errors) {
          if(responseJson){
            const alreadySubs  =  responseJson.data.some((item:{
              id:string,
              type:string,
              attributes:{
                  subscribed:boolean,
                  status:string,
                  name:string,
                  id:number
              }
             })=>item.attributes.subscribed===true)

            this.setState({
              subscriptionDetails: responseJson.data,
              updateSubscription:alreadySubs

            },this.getActiveId);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }else{
          this.setState({apiError:responseJson.errors[0]})
        }
      }

    }

  }


  getUserDataResponse=(message:Message)=>{

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        if (apiRequestCallId === this.apiGetDataCallId) {
          if (apiRequestCallId && !responseJson.errors) {
          if(responseJson){
            this.setState({
             userSubscriptionId:responseJson.data.attributes.user_subscription_id
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

    }

  }

  getUserData = async () => {
    let token = await getStorageData("authToken");
    
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  submitSubscriptionsApi=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId === this.submitSubscriptionsId) {
          if (!responseJson.errors) {
          const accountUser='plans'  
          setStorageData("userAccount",accountUser) 
          if(responseJson){
            this.setState({
              submittedDetails: responseJson.data
            });
            this.navigateToBecomeOwner()

          }
          this.parseApiCatchErrorResponse(errorReponse);
         }
         else{
          this.setState({apiError:responseJson.errors[0]})
         }
      }
    }
  }

  handleSelectSubscription=(activeId:number)=>{
    const result = this.state.subscriptionDetails.map((each) => {
      if (each.id === activeId) {
        return { ...each, attributes: { ...each.attributes, subscribed: !each.attributes.subscribed } }
      }
      return { ...each, attributes: { ...each.attributes, subscribed: false } }
    })
    this.setState({ subscriptionDetails: result }, () => {
      this.getActiveId()
    })
  }

  getActiveId=()=>{
    const activeIdArray=this.state.subscriptionDetails.filter((each)=>each.attributes.subscribed===true)
    this.setState({activeId:activeIdArray.length>0?activeIdArray[0].id:0})
  }
 
  submitSubscription=()=>{
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token:this.state.userToken
    };

    const sendData={
        "subscription_id":this.state.activeId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSubscriptionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitSubscriptionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(sendData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submitSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  handleSubsctiption=()=>{
    if(this.state.updateSubscription){
      this.updateSubscription()
    }else{
    this.submitSubscription()
    }
  }

  updateSubscription=()=>{
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token:this.state.userToken
    };

    const sendData={
      "subscription_id":this.state.activeId
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitSubscriptionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateSubscriptionApiEndPoint}/${this.state.userSubscriptionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(sendData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchTypeApi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToCategories=()=>{
     const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Categories"
    );
    this.send(message);

  }

  navigateToBecomeOwner=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BecomeOwner"
    );
    this.send(message);
  }

  // Customizable Area End
}
