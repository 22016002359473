// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import _ from 'lodash'
import { toast } from 'react-toastify'
import { convertMentionHashtagText, downloadGifFromUrl, sendAPIRequest } from "../../../components/src/utils";
import { EmojiData } from 'emoji-mart'
import React from "react";

export interface Media {
  id: number
  url: string
  fileName: string
  contentType: string
}

export interface MentionPerson {
  id: number
  profilePhoto: string | null
  userName: string
  fullName: string
}

interface SuggestMentionPeople extends MentionPerson {
  isSelected: boolean
}

export interface PostData {
  body: string
  media: Array<Media>
  mediaMentions: Array<MentionPerson>
  uploadingMediaIds: string[]
}

const emptyPost: PostData = {
  body: '',
  media: [],
  mediaMentions: [],
  uploadingMediaIds: []
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  checked?: boolean
  onGetNewFeed: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string
  anchorEl: null | HTMLElement
  selectedAudience: number
  postData: Array<PostData>
  selectedPostIndex: number
  showMultiPostsModal: boolean
  mentionPeople: Array<SuggestMentionPeople>
  temporaryMentionPeopleList: Array<MentionPerson>
  showMentionPeopleMedia: boolean
  searchMention: string
  emojiAnchorEl: null | HTMLElement
  postCreationWidth: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadAttachmentIds: Array<{id: string, postIndex: number}> = []
  submitPostsId: string = ''
  getMentionPeopleListId: string = ''
  uploadGifFileId: {id: string, postIndex: number} = {id: '', postIndex: 0}

  postCreationRef = React.createRef<HTMLDivElement>()

  private debouncedFetchPeople: (query: string) => void
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      anchorEl: null,
      selectedAudience: 0,
      postData: [{ ...(_.cloneDeep(emptyPost)) }],
      selectedPostIndex: 0,
      showMultiPostsModal: false,
      mentionPeople: [],
      temporaryMentionPeopleList: [],
      showMentionPeopleMedia: false,
      searchMention: '',
      emojiAnchorEl: null,
      postCreationWidth: 0,
    };
    this.debouncedFetchPeople = _.debounce(this.callAPIGetMentionPeopleList, 300)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = localStorage.getItem('authToken') || ''
    this.setState({ token })
    setTimeout(this.updatePostCreationWidth, 0)
    window.addEventListener('resize', this.updatePostCreationWidth);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updatePostCreationWidth);
  }

  updatePostCreationWidth = () => {
    if (this.postCreationRef.current) {
      const width = this.postCreationRef.current.offsetWidth;
      this.setState({ postCreationWidth: width });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const uploadAttachmentId = this.uploadAttachmentIds.find(item => item.id === apiRequestCallId)
      
      if (uploadAttachmentId) {
        const attachment = responseJson?.data?.attributes
        const postIndex = uploadAttachmentId.postIndex

        this.handleUploadAttachmentResponse(attachment, postIndex, apiRequestCallId)
        return
      }

      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.submitPostsId]: this.handleSubmitPostsResopnse,
      [this.getMentionPeopleListId]: this.handleGetMentionPeopleListResponse,
      [this.uploadGifFileId.id]: this.handleUploadGifResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleUploadGifResponse = (responseJson: any) => {
    const attachment = responseJson?.data?.attributes
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      postDataList[this.uploadGifFileId.postIndex].uploadingMediaIds = [...postDataList[this.uploadGifFileId.postIndex].uploadingMediaIds]?.filter(id => id !== this.uploadGifFileId.id)
      return { ...prev, postData: postDataList }
    })

    if (attachment) {
      const media: Media = {
        id: attachment.id,
        url: attachment.url,
        fileName: attachment.filename,
        contentType: attachment.content_type,
      }
      this.setState((prev) => {
        const postDataList = _.cloneDeep(prev.postData)
        postDataList[prev.selectedPostIndex].media.unshift(media)
        return { ...prev, postData: postDataList }
      })
    }
  }

  handleGetMentionPeopleListResponse = (responseJson: any) => {
    if (!responseJson?.errors) {
      const mentionList: SuggestMentionPeople[] = responseJson?.data?.map((person: any) => {
        const isSelected = this.state.temporaryMentionPeopleList.findIndex(item => item.id === person?.attributes?.id) === -1 ? false : true
        return {
          id: person?.attributes?.id,
          profilePhoto: person?.attributes?.profile_photo,
          userName: person?.attributes?.user_name,
          fullName: person?.attributes?.full_name,
          isSelected
        }
      })
      this.setState({ mentionPeople: mentionList })
    }
  }

  handleSubmitPostsResopnse = (responseJson: any) => {
    if (responseJson?.errors) {
      toast.error('Failed posting')
    } else {
      toast.success('Posted successfully')
      this.props.onGetNewFeed()
    }
  }

  handleUploadAttachmentResponse = (attachment: any, postIndex: number, apiRequestCallId: string) => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      postDataList[postIndex].uploadingMediaIds = [...postDataList[postIndex].uploadingMediaIds]?.filter(id => id !== apiRequestCallId)
      return { ...prev, postData: postDataList }
    })
    if (attachment) {
      const media: Media = {
        id: attachment.id,
        url: attachment.url,
        fileName: attachment.filename,
        contentType: attachment.content_type,
      }
      this.setState((prev) => {
        const postDataList = _.cloneDeep(prev.postData)
        postDataList[postIndex].media.unshift(media)
        return { ...prev, postData: postDataList }
      })
    } else {
      toast.error('Failed uploading')
    }
  }

  handleAudienceClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      anchorEl: null,
      selectedAudience: parseInt(e.target.value)
    })
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  chooseAudienceText = () => {
    if (Boolean(this.state.anchorEl)) {
      return 'Choose your audience'
    } else {
      switch (this.state.selectedAudience) {
        case 1:
          return 'Only followers can see the post'
        case 2:
          return 'Only paid subscribers can see the post'
        default:
          return 'Everyone can see the post'
      }
    }
  }

  onChangeFile = (event: React.ChangeEvent<HTMLInputElement>, postIndex: number) => {
    const files = event.target.files
    const numberOfRemainFile = 4 - this.state.postData[postIndex].media.length - this.state.postData[postIndex].uploadingMediaIds.length

    if (files && files.length > 0 && files.length <= numberOfRemainFile) {
      for (const file of files) {
        this.uploadAttachment(file, postIndex)
      }
    } else if (files && files.length > numberOfRemainFile) {
      toast.info('Please choose up to 4 photos, videos, or GIFs.')
    }
  }

  uploadAttachment = (file: File, postIndex: number) => {
    const formData = new FormData()
    formData.append('media', file)

    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadAttachmentIds.push({id: requestMessage.messageId, postIndex: postIndex})
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      postDataList[postIndex].uploadingMediaIds.push(requestMessage.messageId)
      return { ...prev, postData: postDataList }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading/attachments"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRemoveMediaFile = (postIndex: number, mediaIndex: number) => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      postDataList[postIndex].media = postDataList[postIndex].media.filter((file) => file.id !== mediaIndex)

      return { ...prev, postData: postDataList }
    })
  }

  checkPostHasData = (objectPostData: PostData) => {
    if (objectPostData.body.trim().length > 0 || objectPostData.media.length > 0) {
      return true
    }
    return false
  }

  get checkMultiPostHasData() {
    return this.state.postData.every(post => {
      return this.checkPostHasData(post)
    })
  }

  handleChangeValue = (value: string, postIndex: number) => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData);
      postDataList[postIndex].body = value;
      return { ...prev, postData: postDataList };
    });
  };

  handleClosePostBtn = (postIndex: number) => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData);
      const newSelectPostIndex = postIndex === this.state.postData.length - 1 ? postIndex - 1 : postIndex;
      const newPostDataList = postDataList.filter((post, i) => i !== postIndex);
      return { ...prev, postData: newPostDataList, selectedPostIndex: newSelectPostIndex };
    });
  };

  handAddPostBtn = (index: number) => this.setState((prev) => {
    const postDataList = _.cloneDeep(prev.postData)
    const selectingPostIndex = postDataList.findIndex((item, i) => i === index)
    if (selectingPostIndex !== -1) {
      postDataList.splice(index + 1, 0, { ...(_.cloneDeep(emptyPost)) })
    }
    return { ...prev, postData: postDataList, selectedPostIndex: index + 1, showMultiPostsModal: true }
  })

  handleClosePostModal = () => this.setState({
    postData: [{ ...(_.cloneDeep(emptyPost)) }],
    showMultiPostsModal: false,
  })

  get dataForSinglePost() {
    if (this.state.postData.length === 1 && !this.state.showMultiPostsModal) {
      return this.state.postData[0]
    } else {
      return { ...(_.cloneDeep(emptyPost)) }
    }
  }

  submitPosts = () => {
    const customPostData = this.state.postData.map(post => {
      const mediaIds = post.media.map(item => item.id)
      const mentionMediaIds = post.mediaMentions.map(item => item.id)
      return {
        body: convertMentionHashtagText(post.body),
        media: mediaIds,
        mediaMentions: mentionMediaIds,
      }
    })
    const formData = new FormData()

    customPostData.reverse().forEach((post, index) => {
      formData.append(`posts[${index}]body`, post.body);
      formData.append(`posts[${index}]audience`, this.state.selectedAudience.toString());
      formData.append(`posts[${index}]attachment_ids[]`, post.media.join(','))
      formData.append(`posts[${index}]media_mention_ids[]`, post.mediaMentions.join(','))
    })

    this.submitPostsId = sendAPIRequest(
      'bx_block_posts/create_multiple_posts',
      {
        method: 'POST',
        headers: {
          token: this.state.token
        },
        body: formData
      }
    )
    this.setState({
      postData: [{ ...(_.cloneDeep(emptyPost)) }],
      showMultiPostsModal: false,
    })
  }

  handleChangeSearchPeople = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value
    this.setState({ searchMention: search })
    if (!!search.trim()) {
      this.debouncedFetchPeople(search)
    }
  }

  callAPIGetMentionPeopleList = (search: string) => {
    this.getMentionPeopleListId = sendAPIRequest(
      `account_block/mentions?query=${search}`,
      {
        method: 'GET',
        headers: {
          token: this.state.token,
        }
      }
    )
  }

  generateText(users: MentionPerson[]): string {
    const uniqueUsers = Array.from(new Map(users.map(user => [user.id, user])).values());

    if (uniqueUsers.length === 1) {
      return uniqueUsers[0].fullName;
    } else if (uniqueUsers.length === 2) {
      return `${uniqueUsers[0].fullName} and ${uniqueUsers[1].fullName}`;
    } else if (uniqueUsers.length > 2) {
      return `${uniqueUsers[0].fullName} and ${uniqueUsers.length - 1} others`;
    } else {
      return '';
    }
  }

  handleClickShowMentionPeople = () => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      const temporaryList = _.cloneDeep(postDataList[this.state.selectedPostIndex].mediaMentions)
      return { ...prev, showMentionPeopleMedia: true, temporaryMentionPeopleList: temporaryList }
    })
  }

  handleClickCloseMentionModal = () => this.setState({ showMentionPeopleMedia: false, searchMention: '', mentionPeople: [] })

  comparePeopleMentioning = (arr1: MentionPerson[], arr2: MentionPerson[]) => {
    const sortedArr1 = _.sortBy(arr1, ['id']);
    const sortedArr2 = _.sortBy(arr2, ['id']);
  
    return _.isEqual(sortedArr1, sortedArr2);
  }

  handleCompleteSelectMentionPeople = () => this.setState((prev) => {
    const postDataList = _.cloneDeep(prev.postData)
    const cloneMentionedList = _.cloneDeep(prev.temporaryMentionPeopleList)
    postDataList[prev.selectedPostIndex].mediaMentions = cloneMentionedList
    return {
      ...prev,
      postData: postDataList,
      showMentionPeopleMedia: false,
      temporaryMentionPeopleList: [],
      searchMention: '',
      mentionPeople: [],
    }
  })

  handleRemoveSelectedMention = (item: MentionPerson) => this.setState((prev) => {
    const cloneTemporaryList = _.cloneDeep(prev.temporaryMentionPeopleList)
    const newTemporaryMentionPeopleList = cloneTemporaryList.filter(i => i.id !== item.id)
    return { ...prev, temporaryMentionPeopleList: newTemporaryMentionPeopleList }
  })

  handleClickSuggestMention = (person: SuggestMentionPeople) => this.setState((prev) => {
    const cloneTemporaryList = _.cloneDeep(prev.temporaryMentionPeopleList)
    let newTemporaryMentionPeopleList: MentionPerson[]
    const selectedSuggestPersonId = cloneTemporaryList.findIndex(i => i.id === person.id)
    if (selectedSuggestPersonId === -1) {
      newTemporaryMentionPeopleList = [...cloneTemporaryList, { ...person }]
    } else {
      newTemporaryMentionPeopleList = cloneTemporaryList.filter(i => i.id !== person.id)
    }
    return { ...prev, searchMention: '', mentionPeople: [], temporaryMentionPeopleList: newTemporaryMentionPeopleList }
  })

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelect = (emoji: EmojiData) => {
    this.setState((prev) => {
      const postDataList = _.cloneDeep(prev.postData)
      postDataList[prev.selectedPostIndex].body = postDataList[prev.selectedPostIndex].body + (emoji as any).native
      return { ...prev, postData: postDataList }
    });
  };

  uploadGifFile = async (gifUrl: string, gifName: string, postIndex: number) => {
    const file = await downloadGifFromUrl(gifUrl, gifName)
    const numberOfRemainFile = 4 - this.state.postData[postIndex].media.length - this.state.postData[postIndex].uploadingMediaIds.length
    
    if (file && numberOfRemainFile > 0) {
      const formData = new FormData();
      formData.append('media', file);

      const apiId = sendAPIRequest(
        `bx_block_bulk_uploading/attachments`,
        {
          method: 'POST',
          headers: {
            token: this.state.token,
          },
          body: formData
        }
      )
      this.uploadGifFileId = {id: apiId, postIndex}
      this.setState((prev) => {
        const postDataList = _.cloneDeep(prev.postData)
        postDataList[postIndex].uploadingMediaIds.push(apiId)
        return { ...prev, postData: postDataList }
      })
    } else if (file && numberOfRemainFile === 0) {
      toast.info('Please choose up to 4 photos, videos, or GIFs.')
    }
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End