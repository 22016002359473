import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";


export interface FollowersData {
  name:string,
  username:string,
  userProfile:string
 }

 export interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}

export interface User {
  id: number;
  full_name: string;
  user_name: string;
  email: string | null;
  full_phone_number: string;
  country_code: string;
  phone_number: number;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string | null;
  admin_verification: boolean;
  profile_photo: string | null;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: [number, number];
}

export interface HashTag {
  hash_tag: string;
  indices: [number, number];
}

export interface MentionedData {
  id: string;
  type: string;
  attributes: {
    id: number;
  connected_post_id: number | null;
  is_repost: boolean;
  body: string;
  body_mentions: BodyMention[];
  hash_tags: HashTag[];
  media: Media[];
  media_mentions:string[];
  location: string | null;
  created_at: string;
  updated_at: string;
  is_saved: boolean;
  is_pinned: boolean;
  is_liked: boolean;
  total_likes: number;
  total_reposts: number;
  total_comments: number;
  impressions: number;
  model_name: string;
  post_by: User;
  mentioned_accounts: string[];
  parent_post: null;
  }
}

interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{[key:string]:string}
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  followers:FollowersData[],
  filter:string,
  activeIndex:number,
  filterModel:boolean,
  filterOption:string[],
  selectedItems:number,
  mentionData:MentionedData[],
  page:number,
  pagination:PaginationDetails
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      followers:[{name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""}
      ],
      filter:"",
      activeIndex:0,
      filterModel:false,
      selectedItems:2,
      filterOption:[
        "Accounts you follow",
        "Accounts you don't follow",
        "New users on the platform"
      ],
      mentionData:[{
        "id": "398",
        "type": "post",
        "attributes": {
            "id": 398,
            "connected_post_id": null,
            "is_repost": false,
            "body": "A beautiful post #hash_tag  @dev48439",
            "body_mentions": [
                {
                    "id": 548,
                    "full_name": "dev",
                    "user_name": "@dev48439",
                    "indices": [
                        28,
                        37
                    ]
                },
           ],
            "hash_tags": [
              {
                "hash_tag": "#hash_tag",
                "indices": [
                    17,
                    26
                ]
            }],
            "media": [
                {
                    "id": 763,
                    "url": "https://rb.gy/uvdkst",
                    "thumbnail_url": null,
                    "filename": "video-4c847ab7-b3b3-41be-aa55-df9bbebf1b9a7834694582626350395.mp4",
                    "content_type": "video/mp4",
                    "file_size": 549700
                },
                {
                  "id": 776,
                  "url": "https://pixabay.com/sound-effects/dark-engine-logo-141942/",
                  "thumbnail_url": null,
                  "filename": "07-08-24-03-39-297535803008370662136.m4a",
                  "content_type": "audio/mp4",
                  "file_size": 51157
              },
            ],
            "media_mentions": [],
            "location": null,
            "created_at": "about 3 hours ago",
            "updated_at": "2024-08-07T09:00:39.398Z",
            "is_saved": false,
            "is_pinned": false,
            "is_liked": false,
            "total_likes": 0,
            "total_reposts": 0,
            "total_comments": 0,
            "impressions": 8,
            "model_name": "post",
            "post_by": {
                "id": 492,
                "full_name": "Tran Duc Y",
                "user_name": "@tran_duc_y51733",
                "email": "ducyk41cntt@gmail.com",
                "full_phone_number": "",
                "country_code": "",
                "phone_number": 898877,
                "bio": null,
                "location": null,
                "website": null,
                "occupation": null,
                "created_at": "2024-07-09T09:16:07.015Z",
                "is_early_adopter": true,
                "date_of_birth": "1999-06-23",
                "admin_verification": true,
                "profile_photo": null,
                "cover_photo": null,
                "is_following": false,
                "is_follower": false,
                "is_muted": false,
                "is_blocked": false,
                "following_count": 3,
                "followers_count": 2,
                "user_subscription": "I am an individual",
                "user_ownership": false,
                "is_tailored": true,
                "stories_count": 0,
                "viewed_stories": 0
            },
            "mentioned_accounts": [],
            "parent_post": null
        }
      },
      {
        "id": "397",
        "type": "post",
        "attributes": {
            "id": 397,
            "connected_post_id": null,
            "is_repost": false,
            "body": "",
            "body_mentions": [],
            "hash_tags": [],
            "media": [
                {
                    "id": 760,
                    "url": "https://rb.gy/47l5mf",
                    "thumbnail_url": null,
                    "filename": "2023-03-15 (2).png",
                    "content_type": "image/png",
                    "file_size": 306407
                }
            ],
            "media_mentions": [],
            "location": null,
            "created_at": "about 4 hours ago",
            "updated_at": "2024-08-07T08:28:40.654Z",
            "is_saved": false,
            "is_pinned": false,
            "is_liked": false,
            "total_likes": 0,
            "total_reposts": 0,
            "total_comments": 0,
            "impressions": 9,
            "model_name": "post",
            "post_by": {
                "id": 665,
                "full_name": "swapna",
                "user_name": "@swapna46367",
                "email": null,
                "full_phone_number": "919284983498",
                "country_code": "91",
                "phone_number": 9284983498,
                "bio": null,
                "location": null,
                "website": null,
                "occupation": null,
                "created_at": "2024-08-06T11:59:55.642Z",
                "is_early_adopter": true,
                "date_of_birth": "2024-08-01",
                "admin_verification": true,
                "profile_photo": null,
                "cover_photo": null,
                "is_following": true,
                "is_follower": false,
                "is_muted": false,
                "is_blocked": false,
                "following_count": 0,
                "followers_count": 2,
                "user_subscription": "I am a business",
                "user_ownership": false,
                "is_tailored": false,
                "stories_count": 0,
                "viewed_stories": 0
            },
            "mentioned_accounts": [],
            "parent_post": null
        }
      }],
      page:1,
      pagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0 
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: [...this.state.data,...apiResponse?.notifications?.data],
          pagination:apiResponse?.pagination_details,
          page:this.state.page+1
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications =async()=> {
    const token = await getStorageData("authToken");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint+`?page=${this.state.page}&per_page=10`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  setFilter=((event:React.ChangeEvent<HTMLInputElement>)=> {    
    this.setState({ filter: event.target.value })
  })

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {    
    this.setState({ activeIndex: newValue})
   };

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  handleClose =()=>{
    this.setState({filterModel:false})
  }

  handleOpenModel =()=>{
    this.setState({filterModel:true})
  }

  handleItemClick = (index:number) => {
    this.setState({ selectedItems: index });
  };
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }
  // Customizable Area End
}
