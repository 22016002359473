import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    IconButton,
    Paper,
    Box,
    Radio,
} from "@material-ui/core";
import { ThemeProvider, styled } from "@material-ui/core/styles";
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikInputField from "../../../components/src/FormikInputField.web";
import { darkView, dashboardBackground, lightBackground, view, RightIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";

const forgotPasswordSchema = Yup.object().shape({
email: Yup.string()
    .required("This field is required."),
});

const Images=styled(Grid)(({ theme })=>({
    [theme.breakpoints.down("xs")]: {
        backgroundImage:'unset !important',
     }
  }))
  
  const CustomPaper=styled(Paper)(({ theme })=>({
  [theme.breakpoints.down("xs")]: {
       height :'100vh !important',
     }
  }))

  const CustomButton = styled(Button)({
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:"0.64 !important"
    }    
  })
  
  const StyledIcon = styled(IconButton)({
    padding:0,
    marginLeft:-4,
    "&.MuiIconButton-root:hover":{
     background:'unset'
    }
  })

  const webStyle={
   gridContainerLightTheme: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
    backgroundImage: `url(${lightBackground})`,     
    },
    gridContainerDarkTheme:{
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100vh",
      backgroundImage: `url(${dashboardBackground})`,      
    },
    gridItem:{
      position: "fixed" as 'fixed',     
      zIndex: 99,
      top: 0,
      right: 0,
    },
    switchGridContainer:{
      paddingRight: "1.5rem"
    },
    
    welcomeText:{
      textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
      fontFamily: 'Manrope',
      lineHeight: '150%',
      letterSpacing: '-0.414px',
      marginBottom: "0.5rem",
      fontSize: "20.72px",
      fontStyle: 'normal',
      fontWeight: 400,
      
    },
    arrowText:{     
      fontSize: "31px",
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '113%',
      letterSpacing: '-0.414px',
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontFamily: 'Manrope',
    },
    subHeadText:{
        fontWeight: 600,
        lineHeight: '120%',
        letterSpacing: '-0.414px',
        marginBottom: "40px",
        fontFamily: 'Manrope',
        fontSize: "16px",
        fontStyle: 'normal',
        marginTop:"10px"        
      },
    loginText:{
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '150%',
      letterSpacing: '-0.56px',
      marginBottom: "1.5rem",
      fontFamily: 'Manrope',
      fontSize: "1.75rem",    
    },
    errorContainer:{
      display: 'flex',     
      fontFamily: 'Manrope',
      fontSize: "10px",
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '120%',
      marginBottom: "1.2rem",
      padding: '0.9rem 1rem',
      alignItems: 'center',
      borderRadius: "0.625rem",
      borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
      background: 'linear-gradient(92deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0.00) 28.72%), rgba(33, 33, 33, 0.29)',
      backdropFilter: 'blur(24.5px)',
      color: '#DC2626',      
    },
    forgotPasswordText:{     
      marginBottom: "3rem",
      textAlign: "right" as 'right',
      cursor: "pointer",
      marginTop: "7px",
      textDecoration:'underline',
      color: '#8833ff',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      fontFamily: 'Manrope',
      fontSize: "14px",
      fontStyle: 'normal' as 'normal',
      fontWeight: 500,
    },
    buttonText:{
      fontSize:'12px'
    },
    accountText:{
      fontFamily: 'Manrope',
      fontSize: "14px",
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%',
      letterSpacing: '-0.28px',
      textAlign: "center" as 'center',
      marginTop: "3rem"
    },
    labelStyle:{
      fontWeight: 500
    },
   
    iconButton:{
      color: "#6B6B6B",
    },
    lightColor:{     
      height:'100vh',
      backgroundColor:'#fff',
    },
    darkColor:{      
      height:'100vh',
      backgroundColor:'#070707', 
    },
    errorText:{
      fontFamily: 'Manrope',
      fontSize: "10px",
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#DC2626',      
  },
   
  buttonStyle: {        
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '8px',
    padding:"16px", 
    boxShadow:"none !important" ,
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    fontFamily: "Manrope",  
     
   },
   buttonStyleDArk: {  
    padding:"16px", 
     boxShadow:"none !important",
     background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
     backgroundClip: "padding-box, border-box",
     backgroundOrigin: "padding-box, border-box",
     border :"1px solid transparent",
     borderRadius: "10px",
     textTransform: "capitalize" as "capitalize",
     color: "black",      
     fontFamily: "Manrope",
     fontSize: "14px",
     fontStyle: "normal" as 'normal',
     fontWeight: 700,
     marginTop: '8px',
             
   }, 

   selectBtn:{
    padding:"15px 10px", 
  display:"flex",
  justifyContent :"space-between",
  alignItems:"center",
  background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
  backgroundClip: "padding-box, border-box",
  backgroundOrigin: "padding-box, border-box",
  border :"2px solid transparent",
  borderRadius: "6px",
  textTransform: "capitalize" as "capitalize",
  color: "black",
  whiteSpace: 'nowrap', 
 overflow: 'hidden', 
 textOverflow: 'ellipsis',
   fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as 'normal',
  fontWeight: 700,
  marginTop: '8px', 
  
   },
   selectBtnDark:{   
    padding:"15px 10px", 
    boxShadow:"none !important",
    display:"flex",
     justifyContent :"space-between",
     whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis',
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"2px solid transparent",
    borderRadius: "6px",
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '8px',
   }   
  } 

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";
import { styles } from "@material-ui/pickers/views/Clock/Clock";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  conditionShort = (condition:boolean,trueStatement:any,falseStatement:any) =>{
    return condition ?  trueStatement :falseStatement
   }


   BoxContainer=styled(Box)({
    "& .TextContainer > .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
     height:24
    },
    "& .TextContainer > .MuiTextField-root > .MuiOutlinedInput-root":{
      borderWidth:"1px"
    },

    "& .TextContainer2 > .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
      height:24
     },
     "& .TextContainer2 > .MuiTextField-root > .MuiOutlinedInput-root":{
       borderWidth:"1px",
       borderColor:'#8833FF'
     },

     "& .TextError> .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
      height:24
     },
     "& .TextError > .MuiTextField-root > .MuiOutlinedInput-root":{
       borderWidth:"1px",
       borderColor:'#f44336'
     },
 })

 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
        <Formik
          initialValues={{
                        email: "",
                    }}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={(values) => {
                       this.handleFindAccound(values)
                    }}
                    data-test-id="forgot-password-fromik-form"
        >
        {(formikProps) => {
          const { values,handleChange} = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Loader loading={this.state.forgotPasswordPageLoading}/>
              <CustomPaper style={this.conditionShort(this.props.checked,webStyle.lightColor,webStyle.darkColor)}>
              <Images container 
                   style={this.conditionShort(this.props.checked, webStyle.gridContainerLightTheme,webStyle.gridContainerDarkTheme)}>
                <Grid item xs={12} style={webStyle.gridItem}>
                </Grid>
                <Grid item xs={1} sm={6} md={7}container justifyContent="center" alignItems="center" direction="column">
                 </Grid>
                <Grid item xs={10} sm={6} md={5} lg={5} container justifyContent="center" alignItems="center" alignContent="center">
                  <Grid item xs={8} >
                  <Box style={{color: this.conditionShort(this.props.checked,"black","#fff"),
                          height: "40px",
                          width: "40px",
                          cursor:'pointer',
                          borderRadius: "8px",
                          backgroundColor: this.conditionShort(this.props.checked,"var(--Neutrals-Cool-gray-50, #E9E9E9)","var(--Basic-Black, #222)"),
                          marginBottom: "40px"}}>
                  <IconButton style={{
                       height:"100%",
                       width:'100%',
                       borderRadius:'unset'
                      }}  data-test-id="backBtn" onClick={()=>{
                        this.state.isFindAccount?this.props.navigation.goBack():this.setState({isFindAccount:true})
                        }}>
                      <img src={this.conditionShort(this.props.checked,darkView,view)} />
                  </IconButton>
                  </Box>
                    <Typography style={webStyle.arrowText}>
                     {this.conditionShort(this.state.isFindAccount,"Find your Arrows account","Reset Password")}                       
                    </Typography>
                   
                    <Typography data-test-id='loginText' style={webStyle.subHeadText}>
                    {this.conditionShort(this.state.isFindAccount,"Enter your email, phone, or username.","No problem, You can use the information associated with your account.")} 
                    </Typography>  

                    {this.state.apiResError && (
                      <Box style={webStyle.errorContainer}>
                       <Typography style={webStyle.errorText} data-test-id='apiError'>{this.state.apiResError}</Typography>
                      </Box>
                    )} 

                    {this.state.validationError && (
                      <Box style={webStyle.errorContainer}>
                       <Typography style={webStyle.errorText} data-test-id='apiError'>{this.state.validationError}</Typography>
                      </Box>
                    )}                     

                  </Grid>

                 {this.state.isFindAccount ? 
                 <Grid item xs={8} style={{display:"flex",flexDirection:"column",rowGap:"40px"}}>
                  <this.BoxContainer>
                   <div className={this.conditionShort((this.state.apiResError!=='' ||this.state.validationError!==""),"TextError",(values.email.length>0?"TextContainer2":"TextContainer"))}>
                   <Field
                      component={FormikInputField}
                      name="email"
                      onChange={(e:React.ChangeEvent<HTMLInputElement>)=>this.handleUserField(e,handleChange)}
                      value={values.email}
                      label="Phone, Email or @username"
                      placeholder="Enter Phone, Email or @username"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style:{                          
                            height: "45px"
                        }
                      }}
                      id="emailInput"
                      error={this.state.validationError|| this.state.apiResError}
                    />
                    </div>
                  </this.BoxContainer>
                    <CustomButton disabled={(values.email==="")}  type="submit" fullWidth variant="contained" color="primary"  style={{padding:"15px 30px",height:48, fontSize:"14px",color:"#fff",background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" }} 
                        className={this.conditionShort(this.props.checked,webStyle.buttonStyleDArk ,webStyle.buttonStyle)} data-test-id="searchBtn" 
                         endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}
                         
                        > 
                       Search
                    </CustomButton>

                  </Grid> : 
                  <Grid item xs={8} style={{display:"flex",flexDirection:"column",rowGap:"40px"}}>                  
                   
                    <Box style={{display:"flex",flexDirection:"column",rowGap:"10px"}}> 
                    { this.state.selectOptions.map((value:string,index:number)=> <CustomButton  key={index} fullWidth onClick={()=>this.handleCheck(value,index)} data-test-id="emailBtn" 
                     style={{ ...(this.conditionShort(this.props.checked,webStyle.selectBtn ,webStyle.selectBtnDark)),
                      ...(this.conditionShort(this.state.selectedButton.activeIndex !== index , { border: '2px solid #6B6B6B' } , {}))}}                       
                      >
                           <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>                             
                             {value}
                            </span>     
                            <Radio style={{width:"10%"}} 
                             checked={this.state.selectedButton.activeIndex == index} data-test-id="emailInnerBtn" onChange={()=>this.handleCheck(value,index)} color="primary"/>
                        </CustomButton>
                     )}
                    </Box>

                    <CustomButton   fullWidth variant="contained" color="primary"  style={{fontSize:"14px",padding:"15px 30px" ,color:"#fff",background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",height:48 }} 
                        className={this.conditionShort(this.props.checked,webStyle.buttonStyleDArk ,webStyle.buttonStyle)} data-test-id="navigateBtn" 
                         endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}
                         onClick={this.handleSendOtp}
                         disabled={!this.state.selectedButton?.type}
                        > 
                       Next
                    </CustomButton>

                    <Typography style={webStyle.accountText}>
                                         Back to <span data-test-id="backtolodinBtn" onClick={this.handleNavigation} style={{
                                             background: 'linear-gradient(323deg, #3858E3 -17.41%, #BD02F7 80.89%)',
                                             backgroundClip: 'text',
                                             WebkitBackgroundClip: 'text',
                                             WebkitTextFillColor: 'transparent',
                                             textShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
                                             fontFamily: 'Lato',
                                             fontSize: 14,
                                             fontStyle: 'normal',
                                             fontWeight: 500,
                                             lineHeight: '113%',
                                             letterSpacing: '-0.28px',
                                             marginLeft: "0.25rem",
                                             cursor: "pointer",
                                             textDecoration:"underline",
                                             textDecorationColor:"rgba(142, 56, 227)"
                                         }}>Log In</span>
                    </Typography>                              

                   </Grid>}
                  </Grid>                 
              </Images>
              </CustomPaper>
            </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
