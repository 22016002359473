import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Modal,
  Typography,
  Radio,
  Button,
  Popover,
  CircularProgress
} from "@material-ui/core";
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import Loader from "../../../components/src/Loader.web";

import {AvatarImage, VerifiedImage, arrowLogo,checkbox,darkBackground,darkCheckBox,darkMoreIcon,darkView,lightBackground,lightCheckbox,moreIcon, view} from "./assets";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info:{
      main:"#222222",
      light:'#fff'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",   
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      },
      info:{
        main:"#FFFFFF",
        light:'#0B0B0B'
      }
     }
    });
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  morIconList=()=>{
    const theme = this.props.checked?lightTheme:darkTheme
    const popper = Boolean(this.state.anchorEl)
    const id = popper ? 'simple-popper' : undefined;
    return(
      <ThemeProvider theme={theme}>
       <CustomPopover
        id={id} 
        open={popper} 
        anchorEl={this.state.anchorEl} 
        onClose={this.handleMenuClose}
        style={{background: "rgba(0, 0, 0, 0.4)bottom",zIndex:99999}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        >
        <Box>  
         {this.state.morIconList.map((item)=>{
            return(
                <MenuBoxContainer data-testid="menuItem" onClick={()=>this.handleNavigation(item.path)} key={item.id}>
                  <Box>
                    <img src = {this.props.checked?item.darkIcon:item.icon} alt="icon_image" />
                  </Box>
                  <Box>
                    <Typography className="item_list_name">{item.name}</Typography>
                  </Box>
                </MenuBoxContainer>
            )
         })}
         </Box>
       </CustomPopover>  
       </ThemeProvider> 
    )
  }

  list = () => (
    <div
      className={"tabsContainer"}>
      <List className={"list"}>
        {this.state.tabItems.map((each) =>
        (<ListItem alignItems="center"
          className={"listItem"}
          button
          data-test-id={`listItem${each.id}`}
          key={each.id} onClick={()=>this.handleNavigation(each.path)}>
          <ListItemIcon className={"drawerItemIcon"}>
            <img src={this.props.checked?each.darkIcon:each.icon} className={"iconImage"} />
          </ListItemIcon>
          <ListItemText primary={each.name} className={each.name!==this.props.tabItem?"itemText":"activeItemText"}/>
        </ListItem>))}
        <ListItem alignItems="center"
          className={"listItem"}
          onClick={this.handleMenuClick}
          data-testid="moreIcon"
          button>
          <ListItemIcon className={"drawerItemIcon"}>
            <img src={this.props.checked?darkMoreIcon:moreIcon} className={"iconImage"} />
          </ListItemIcon>
          <ListItemText primary='More' className={"itemText"}/>
        </ListItem>
      </List>
    </div>
  );

  renderAccountModal=()=>{
    const theme =this.props.checked?lightTheme:darkTheme
    return(
      <ThemeProvider theme={theme}>
       <Modal 
        onClose={this.handleClose}
        data-testid="Modal"
        open={this.state.AccountModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <BoxContainer>
        <Box className={this.props.checked?"darkModal_container":"Modal_Container"}>
             <Box className="Header">
              <Box className="backImage" >
                <img data-testid="backImage" onClick={this.handleClose} src={this.props.checked?darkView:view}/>
              </Box>
              <Box className="HeadContainer">
               <Typography className="account-text" style={{color:theme.palette.info.main}}>Accounts</Typography>
               </Box>
             </Box>
        
             <Box className={"userList_Container"}>
             {this.state.multiUserLoading? <CircularProgress size="5rem" color="primary" />:
              this.state.userList.map((item)=>{
                return(
                  <Box key={item.user_id} className="main-container">
              <Box className="AccountUser"> 
                <Box className="UserDetail">
                <img src={item.profile_photo} className="avatarImage"/>
                </Box>
                <Box className="Account_info">
                <Box className="Account_userVerified">
                <Typography style={{color:theme.palette.info.main}} className="Account_name">{item.fullName}</Typography>
                 <img src={VerifiedImage} height="13.33" width="13.33"/>
                </Box>
                <Box>
                  <Typography className="Account_username">{item.username}</Typography>
                </Box>
                </Box>
                </Box>  

                <Box className="checkBox-container">
                   <Radio 
                    checked ={this.state.selectedAccount===item.user_id}
                    name="radio-button"
                    data-testid="RadioOptions"
                    value = {item.user_id}
                    onChange={()=>this.handleCheckboxChange(item)}
                    icon={<img src={this.props.checked?lightCheckbox:darkCheckBox} alt="Avatar" />}
                    checkedIcon={<img src={checkbox} alt="Avatar" />}
                    />
                </Box>
             </Box>
                )
             })
             }
             </Box>
             <Box className="action-btn">
                <Box>
                 <Button variant="outlined" className="buttonStyle">
                 <Typography style={{background:theme.palette.info.light}} onClick={this.handleCreateAccountNavigation} className="innerButtonStyle"> Create a new  account </Typography>
                  </Button>
                </Box>
                <Box>
                <Button variant="outlined" className="buttonStyle">
                 <Typography style={{background:theme.palette.info.light}} onClick={this.handleAddUserNavigation} className="innerButtonStyle"> Add an existing account </Typography>
                  </Button>
                </Box>
             </Box>
        </Box>
        </BoxContainer>
       </Modal>
       </ThemeProvider>  
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.checked?lightTheme:darkTheme}>
         <StyleContainer key={this.state.selectedAccount}>
          <Loader  loading={this.state.RefreshedTokenLoading} />
        <Paper className={this.props.checked?"paperLightTheme":"paper"}>
        {this.renderAccountModal()}
        <Box className={"container"}>
          <img src={arrowLogo} data-test-id='logoImage' className={"logoImage"}/>
          {this.list()}
          <Box className="userInfo">
           <Box className="userContainer">
             <Box className="userInfoGroup">
              <img src={ this.state.selectedUser?.profile_photo??AvatarImage} className="avatarImage"/>
               <Box className="user_details_info">
                <Box className="userverifiedImage">
                <Typography className="user-name-text">{this.state.selectedUser?.fullName}</Typography>
                 <img src={VerifiedImage} height="13.33" width="13.33"/>
                </Box>
                <Box>
                  <Typography className="userNameStyle">{this.state.selectedUser?.username}</Typography>
                </Box>
               </Box>
             </Box>
             <Box>
             <img 
                data-testid="openModal"
                onClick={this.handleAccountModal} 
                src={this.props.checked?darkMoreIcon:moreIcon}
                className={"iconImage-Account"}
              />
             </Box>
           </Box>
          </Box>
        </Box>
        <Box className="child_css">
          {this.props.children}
        </Box>
        </Paper>
        {this.morIconList()}
        </StyleContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const CustomPopover=styled(Popover)({
  "& .MuiPaper-elevation8-53":{
     boxShadow:'none'
  },
})

const MenuBoxContainer=styled(Box)({
 width: "256px",
 height:"40px",
 display:'flex',
 margin:"8px",
 alignItems:'center',
 cursor:'pointer',
 gap:"10px",

 "& .item_list_name":{
  fontSize:"14px",
  fontWeight:500,
  fontFamily:'Manrope'
 }
 
})

const BoxContainer=styled(Box)(({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  "& .Modal_Container":{
    padding: "20px 24px",
    background:'#0B0B0B',
    gap:"24px",
    borderRadius:"10px",
    height:"381px",
    width:'632px',
    border:`1px solid #35383F`
  },

  "& .darkModal_container":{
    padding: "20px 24px",
    background:'#fff',
    gap:"24px",
    borderRadius:"10px",
    height:"381px",
    width:'632px',
    border:`1px solid #35383F`
  },
  "& .account-text":{
    fontFamily:'Manrope',
    fontWeight:800,
    fontSize:18,
    textAlign:'center'
  },

  "& .HeadContainer":{
   width:"96%"
  },

  "& .Header":{
    height:48,
    width:"100%",
    display:'flex',
    flexDirection:'row'
  },
  "& .main-container":{
    padding:'10px 60px',
    justifyContent:'space-between',
    alignItems:'center',
    display:'flex',
    flexDirection:'row'
   },
   "& .AccountUser":{
      height:'32px',
      display:'flex',
      flexDirection:'row',
      gap:'5px',
      alignItems:"center",
      justifyConten:'center'
   },
   
   "& .avatarImage":{
    height:"35px",
    width:"35px",
    borderRadius:"100px",
    objectFit:'cover'
    },

   "& .Account_name":{
    fontFamily:'Manrope',
    fontWeight:800,
    fontSize:18,
   },

   "& .Account_userVerified":{
    display:'flex',
    gap:'2px'
   },

   "& .Account_info":{
    display:"flex",
    flexDirection:'column',
    gap:'4px'
   },

  "& .action-btn":{
    display:"flex",
    gap:'15px',
    padding:"28px"
  },

  "& .backImage":{
    marginLeft:20
  },

   "& .Account_username":{
    color:'#E0E0E0',
    fontFamily:'Manrope',
    fontSize:'10px',
    fontWeigth:800,
   },
   "& .buttonStyle":{
    position: 'relative' as 'relative',
    background: 'linear-gradient(to right, #3858E3, #BA05F7)',
    cursor: 'pointer' as 'pointer',
    zIndex: 1,
    overflow: 'hidden',
    WebkitBackgroundClip: 'padding-box',
    backgroundClip: 'padding-box',
    height:"48px",
    width:"278px",
    padding:"0px",
    borderRadius:'10px',
    gap:"8px",
   },

   "& .buttonStyle > span":{
    width:"278px",
    textTransform:'none'
   },

   "& .innerButtonStyle":{
    borderRadius: '10px',
    background: '#fff',
    padding: '13px 16px',
    width:"242px",
    fontFamily:"Manrope",
    fontSize:'12px',
    fontWeight:800,
    position: 'relative' as 'relative',
    zIndex: 2,
   },
   
   "& .userList_Container":{
     height:"232px",
     overflow:'auto'
   }
   

}))

const StyleContainer = styled(Box)({

  "& .iconImage":{
  height:'24px',
  width:'24px'
  },

  "& .iconImage-Account":{
    height:'24px',
    width:'24px',
    cursor:'pointer',
  },

  "& .child_css":{
    width:"calc(100% - 289px)",
    "@media(max-width:960px)":{
      width:"calc(100% - 150px)",
    }
  },

  "& .list":{
    marginTop:'20px'
  },
  "& .listItem":{
    "&.MuiListItem-button": {
      height:'50px'  
    },
    "&.MuiListItem-button:hover": {
      backgroundColor: "transparent"
    },
    "@media(max-width:960px)":{
      marginLeft:-10
    }
  },
  "& .userProfileWrapper": {
    flexDirection: "column" as "column",
    justifyContent: "center",
    display: "flex",
    paddingTop: 30,
    alignItems: "center",
  },
  "& .userProfile": {
    width: 100,
  },
  "& .userDesignation": {
    fontSize: 14,
    color: "#ccc",
  },
  "& .logout": {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  "& .drawerItemIcon": {
    "&.MuiListItemIcon-root":{
      minWidth:'36px'
    }
  },
  "& .moreIcon":{
    "&.MuiListItemIcon-root":{
      minWidth:'40px !important'
    }

  },
  "& .tabsContainer":{
    width:'250px',
    "@media(max-width:960px)":{
      width:"100%"
    }
  },
  "& .paper":{
    display:"flex",
    backgroundColor:'#070707',
    backgroundImage:`url(${darkBackground})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:'100% 100%',
    height:'100vh',
    width:"100vw"
  },
  "& .paperLightTheme":{
    display:"flex",
    backgroundColor:'#fff',
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${lightBackground})`,
    backgroundSize:'100% 100%',
    height:'100vh',
    width:"100vw",
  },
  "& .container":{
    width:'236.33px',
    position:'relative',
    borderRight:'2px solid #222222',
    height:'100vh',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    paddingLeft:'50px',
    "@media(max-width:960px)":{
      width:'100px',
    }
  },

  "& .user_details_info":{
    "@media(max-width:960px)":{
      display:"none"
    }
  },
  "& .userInfo":{
   marginTop:"60px",
   marginBottom:32,
   position:"fixed",
   bottom:0,
   height:43,
   gap:38,
   width:221,
   "@media(max-width:960px)":{
     width:"100px"
   }
  },
  "& .userverifiedImage":{
     display:"flex",
     gap:5,
     alignItems:'center',
  },
  "& .userInfoGroup":{
   display:"flex",
   gap:10,
  },
  "& .avatarImage":{
     height:"35px",
     width:"35px",
     borderRadius:"100px",
     objectFit:'cover'
  },
  "& .userContainer":{
    display:"flex",
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center'
  },

  "& .userNameStyle":{
    color: "#81999E",
    fontFamily:"Manrope",
    fontSize:"11px",
    fontWeight:400,
    marginTop:2
  },
  "& .user-name-text":{
   fontSize:14,
   fontWeight:500,
   fontFamily:"Manrope",
  },

  "& .logoImage":{
    alignSelf:'flex-start',
    marginLeft:"2px",
    marginTop:'10px'
  },
  "& .itemText":{

  "& .MuiTypography-root":{
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
   },
   "@media(max-width:960px)":{
    display:"none"
   }
  },
  "& .activeItemText":{
    "& .MuiTypography-root":{
      fontFamily: 'Manrope',
      fontSize: '24px',
      fontWeight: 800,
      lineHeight: '27.12px',
      letterSpacing: '-0.02em',
     },
     "@media(max-width:960px)":{
        display:"none"
     }
   },

});
// Customizable Area End
