import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Avatar,
  TextField, 
  Grid,
  Paper,
  createTheme,
  ThemeProvider,
  Dialog,
  styled,
  IconButton
} from "@material-ui/core";
import { dashboardBackground, lightBackground, logo, darkView, view, RightIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";


// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";



// Customizable Area Start

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

const ImageContainer=styled(Grid)(({ theme })=>({
  [theme.breakpoints.down("xs")]: {
      backgroundImage:'unset !important',
   }
}))
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});
const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#3858E3",
    },
    type: "light",
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      primary: {
        main: "#3858E3",
      },
      type: "dark",
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });

const inputStyles={
      customInput: {
        fontSize: '13px',
        fontFamily: "Manrope",
        fontStyle: "normal" as 'normal', 
        borderRadius: "10px",      
       }
}


const CustomButton = styled(Button)({
  "&.MuiButton-root.Mui-disabled": {
    color: '#E9E9E9',
    opacity:"0.64 !important"
  }    
})


// Customizable Area End

export default class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


   BoxContainer= styled(Box)({
    "& .textFieldOtp":{
      width: "100%",
      "& .MuiInputBase-input": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal" as 'normal',
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-input": {
        padding: "10px, 16px, 10px, 16px",
      },  
      borderRadius: "10px !important",
      display: "flex",
      height: "50px",
      justifyContent: "center" as 'center',
      "& .fieldset": {
        border: "none",
      },
      "& .MuiTypography-root":{
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "14px",
        fontStyle: "normal",
      }
    },
    "& .textFieldUserName":{
      width: "100%",
      display: "flex",
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
        },
      borderRadius: "10px !important",
      justifyContent: "center" as 'center',
      "& .MuiTypography-root":{
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "Manrope",
        fontSize: "12px",
      }
    
    },
    "& .textFieldUserNameColor":{
      width: "100%",
      "& .MuiInputBase-input": {
        fontWeight: 500,
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal",
        border:'1px solid #6B6B6B !important',
        borderRadius:'10px !important'
        },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
        },
      borderRadius: "10px !important",
      display: "flex",
      justifyContent: "center" as 'center',
      "& .MuiTypography-root":{
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "12px",
        fontStyle: "normal",
      }
    
    },

    "& .textFieldOtp > .MuiInputBase-root > .MuiOutlinedInput-input":{
     padding:"16px 14px"
    },
    "& .textFieldUserNameColor > .MuiInputBase-root > .MuiOutlinedInput-input":{
      padding:"16px 14px"
     },

    "& .textFieldUserName > .MuiInputBase-root > .MuiOutlinedInput-input":{
      padding:"16px 14px"
     }
   })

  renderNewPassword=()=>(
    <Box style={webStyle.formBox}>
       <Avatar style={this.props.checked?webStyle.avatarLight:webStyle.avatarStyle} onClick={()=>this.props.navigation.goBack()} data-test-id='backbuttonOtp'>
       <IconButton style={{height:"100%",width:'100%',borderRadius:'unset'}}>
       <img src={this.props.checked?darkView:view} />
       </IconButton>
       </Avatar>      

       <Typography style={webStyle.otpText} data-test-id='sendCode'>
       New password
       </Typography>
       <Typography style={webStyle.verifyOtpText}>
          Enter a new password here         
       </Typography>

       {this.state.apiResError &&<Box style={webStyle.errorBox}>
       <Typography
           style={webStyle.errorText}
           data-test-id="apiError">
           {this.state.apiResError}
         </Typography>             
     </Box>}

     {(!this.state.validationError && this.state.confirm_passwordError) &&<Box style={webStyle.errorBox}>
       <Typography
           style={webStyle.errorText}
           data-test-id="apiError">
           {this.state.confirm_passwordError}
         </Typography>             
     </Box>}     
    
    {this.state.validationError &&<Box style={webStyle.errorBox}>
    <Typography data-test-id="passwordError" style={webStyle.errorText}>
        {this.state.validationError}
      </Typography>                          
    </Box>}
       <this.BoxContainer
         component="form"
         data-test-id="form">
         <Box style={webStyle.inputContainer}>
           <Typography style={webStyle.label}>
           New Password
           </Typography>
            <TextField 
             data-test-id="newPasswordId"
             onChange={this.handlePasswordChange}
             value={this.state.password}
             id="outlined-basic"
             variant="outlined" 
             error={(this.state.validationError !==""||this.state.apiResError!=="")}          
             className={this.conditionFilter((this.state.validationError !==""||this.state.apiResError!==""),"textFieldOtp",this.conditionFilter(this.state.password.length>0,"textFieldUserNameColor", "textFieldUserName"))}
             placeholder={"Enter  New Password"}
             InputProps={{style:inputStyles.customInput}}
             autoComplete="off"            
           />
         </Box>

         <Box style={webStyle.inputContainer}>
           <Typography style={webStyle.label}>
           Confirm New Password
           </Typography>
            <TextField 
             data-test-id="newConfirmPasswordId"
             value={this.state.confirm_password}
             id="outlined-basic"
             variant="outlined"
             error={(this.state.confirm_passwordError !==""||this.state.apiResError!=="")}
             className={this.conditionFilter((this.state.confirm_passwordError !==""||this.state.apiResError!==""),"textFieldOtp",this.conditionFilter(this.state.confirm_password.length>0,"textFieldUserNameColor", "textFieldUserName"))}
             placeholder={"Re-enter new password"}
             InputProps={{style:inputStyles.customInput}}
             autoComplete="off"
             onChange={this.handleConfirmPassword}
           />
         </Box>
         
         <CustomButton
            data-test-id='next'
            disabled={(this.state.password ===""||this.state.confirm_password ===""||this.state.confirm_passwordError!==""||this.state.validationError!=="")}
            style={webStyle.button}
            fullWidth
            onClick={this.resetPassword}
            endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}
          >
            Set New Password
          </CustomButton>

          <Typography style={webStyle.accountText}>
                                         Back to <span data-test-id='backLogin'  onClick={this.handleNavigateToLoginPage} style={{
                                            fontFamily: 'Lato',
                                            fontSize: 14,
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '113%',
                                            letterSpacing: '-0.28px',
                                            marginLeft: "0.25rem",
                                            cursor: "pointer",
                                             background: 'linear-gradient(323deg, #3858E3 -17.41%, #BD02F7 80.89%)',
                                             backgroundClip: 'text',
                                             WebkitBackgroundClip: 'text',
                                             WebkitTextFillColor: 'transparent',
                                             textShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
                                             textDecoration:"underline",
                                             textDecorationColor:"rgba(142, 56, 227)"                                             
                                         }}>Log In</span>
         </Typography> 
       </this.BoxContainer>
     </Box>
 )

  popupRender =()=>{
    return <Dialog data-test-id="dialog-allow-connect" onClose={this.handleNavigateToLoginPage} open={this.state.successModel} 
        PaperProps={{
          style: this.conditionFilter(!this.props.checked,webStyle.modelDarkStyle,webStyle.modelLightStyle)
            }}
        classes={{paperWidthSm:this.conditionFilter(!this.props.checked,webStyle.modelDarkStyle,webStyle.modelLightStyle)}} 
        BackdropProps={{style:this.conditionFilter(!this.props.checked,webStyle.backdropsStyleDark,webStyle.backdropsStyle)}} 
   >
       <Grid container justifyContent="center" alignItems="center" direction="column" style={{
              padding: "50px 60px",                                
        }}>
          <Box style={{
               color: this.conditionFilter(this.props.checked,"black","#fff"),
               height: "44px",
               width: "40px",
               borderRadius: "8px",                     
               padding:"10px",
               marginBottom: "-54px",
               marginLeft:"-395px"
                 }} data-test-id="backBtn2"
                 onClick={this.handleNavigateToLoginPage} > 
          <IconButton style={{
                 height:"100%",
                 width:"100%",
                 borderRadius:'unset'
                 }} 
                 >
               <img src={this.props.checked?darkView:view} />           
          </IconButton>
          </Box>
            <img src={logo} style={webStyle.IconsStyle} alt=""  />
         <Typography style={{                                
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: '27.12px',
          letterSpacing: '-0.414px',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',                                    
          fontFamily: 'Manrope',
          fontSize: "24px",
          width:"327px",
          textAlign: "center",
          marginBottom:"6px"                 

      }}>
        Congratulations! 
      </Typography>
      <Typography style={webStyle.arrowEndText}>
        Ready to send some Arrows?
      </Typography>
     

      <Typography style={{
          fontFamily: 'Manrope',
          fontStyle: 'normal',
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: '19px',
          marginBottom: "30px",
          textAlign: "center",
          width:"327px"
      }}>
          Your password has been changed!
      </Typography>

      <Box style={{display:"flex" }}>
      <Button fullWidth variant="contained" color="primary"  style={{
        width:"319px",
        padding:"15px 30px" ,
        color:"#fff",
        textTransform:'none',
        fontSize:14,
        height:48,
        borderRadius:10,
        fontWeight:700,
        fontFamily:'Manrope',
        background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" 
      }} 
         className={this.conditionFilter(this.props.checked,webStyle.buttonStyleDArk ,webStyle.buttonStyle)} data-test-id="navigateBtn" onClick={this.handleNavigateToLoginPage} endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}>
        Login
        </Button>
      </Box>                      
      </Grid>
     </Dialog>
 }


  conditionFilter = (condition:boolean,trueStatement:any,falseStatement:any) =>{
    return condition ?  trueStatement :falseStatement
   }
   // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}>
     <Loader loading={this.state.forgotPasswordPageLoading}/>
      <Paper style={this.props.checked? webStyle.thinColor:webStyle.thickColor}>
      <ImageContainer style={!this.props.checked? webStyle.imageBackground:webStyle.thinBackground}>
      <Grid container style={webStyle.gridContainer}>
             <Grid item style={webStyle.imageGridItem} md={5} sm={6} lg={6}>
             </Grid>
        <Grid item xs={10} sm={5} md={5} lg={4} style={webStyle.gridItem}>
        {this.renderNewPassword()}
        {this.popupRender()}
        </Grid>
        </Grid>
     </ImageContainer>
     </Paper>
     </ThemeProvider>
    
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle={
  thinColor:{
    backgroundColor:'#fff'
  },
  thickColor:{
    backgroundColor:'#070707'
  },
  imageBackground:{
    backgroundImage: `url(${dashboardBackground})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition:'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'} 
  },
  thinBackground:{
    backgroundImage: `url(${lightBackground})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition:'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'} 
  },
  gridContainer: {
    justifyContent: 'space-between' as'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop:"3rem",
    [theme.breakpoints.down('xs')]: 
    {justifyContent: 'center' as 'center'}  
  },
  imageGridItem: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  otpText: {
    fontFamily: "Manrope",
    fontSize: "32px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop:'28px',
    textAlign: "left" as "left",
    textShadow:
      "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
    
  },
  verifyOtpText: {
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    textAlign: "left" as "left",
    fontFamily: "Manrope",
    fontSize: "16px",    
  },
  gridItem:{
    display:'flex',
    justifyContent:'flex-start'
  },
  formBox:{
  width:'80%',
},
avatarStyle: {
  borderRadius: "8px",
  backgroundColor:'#222222',
  cursor:'pointer'
},
avatarLight: {
  borderRadius: "8px",
  backgroundColor:'#E9E9E9',
  cursor:'pointer'
},
backIcon:{
  color:'#fff',
  fontSize:'30px'
},
backIconLightTheme:{
  color:'#222222',
  fontSize:'30px'
},
errorBox:{
  display: 'flex',
  padding: '0.7rem 0.7rem',
  alignItems: 'center',
  borderRadius: "0.625rem",
  borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
  background: 'linear-gradient(92deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0.00) 28.72%), rgba(33, 33, 33, 0.29)',
  backdropFilter: 'blur(24.5px)',
  color: '#DC2626',
  fontFamily: 'Manrope',
  fontSize: "12px",
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '120%',
  marginBottom: "18px",
  marginTop:'22px'
},
errorText: {
  color: "#DC2626",
  fontFamily: "Manrope",
  fontSize: "11px",
  fontStyle: "normal" as 'normal',
  fontWeight: 500,
  textTransform:"capitalize" as "capitalize" 
},
inputContainer: {
  marginTop: "10px",
},
label: {
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as 'normal',
  fontWeight: 500,
  textAlign: "left" as "left",
  marginBottom:'6px',
  marginTop:'26px'
},
textFieldOtp:{
  width: "100%",
  "& .MuiInputBase-input": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px, 16px, 10px, 16px",
  },  
  borderRadius: "10px !important",
  border: "1px solid #DC2626 !important",
  height: "50px",
  display: "flex",
  justifyContent: "center" as 'center',
  "& fieldset": {
    border: "none",
  },
  "&:hover": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiTypography-root":{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  }
},
textFieldUserName:{
  width: "100%",
  "& .MuiInputBase-input": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    border:'1px solid #6B6B6B !important',
    borderRadius:'10px !important'
    },
  "& .MuiOutlinedInput-input": {
    padding:'10px, 16px, 10px, 16px',
    
  },
  borderRadius: "10px !important",
  display: "flex",
  justifyContent: "center" as 'center',
  "& fieldset": {
    borderRadius:'10px',
  },
  "&:hover": {
    "& fieldset": {
      borderRadius:'10px',
    },
  },
  "& .MuiTypography-root":{
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
  }

},
suggestionText: {
  textAlign: "left" as "left",
  textShadow:
    "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as'normal',
  fontWeight: 500,
  background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  marginTop: "10px",
  cursor:'pointer'
},  
accountText:{
    fontFamily: 'Manrope',
    fontSize: "14px",
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: '-0.28px',
    textAlign: "center" as 'center',
    marginTop: "4rem"
  },
button: {
  borderRadius: "10px",
  background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
  textTransform: "capitalize" as "capitalize",
  color: "#FFF",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  marginTop: '35px',
  height:'46px',
},
number: {
  textAlign: "left" as "left",
  textShadow:
    "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
  fontFamily: "Manrope",
  fontSize: "16px",
  fontStyle: "normal" as 'normal',
  background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
},
modelDarkStyle:{ 
  opacity: "0.82",
  background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #0E0E0E ,#5B5B5B)",
  borderRadius: "10px",
  backgroundClip: "padding-box, border-box",
  backgroundOrigin: "padding-box, border-box",
  border: "1px solid transparent",
  backdropFilter: "blur(24.5px)",
  boxShadow:"none"         
     
},
modelLightStyle:{
  opacity: "0.82",   
  background: "linear-gradient(to right, #fff, #fff) padding-box,linear-gradient(to right, #dfd6d6 ,#5B5B5B) border-box",
  borderRadius: "10px",
  backgroundClip: "padding-box, border-box",
  backgroundOrigin: "padding-box, border-box",
  border: "1px solid transparent",
  backdropFilter: "blur(24.5px)",
  boxShadow:"none",
 },
backdropsStyle:{
    backdropFilter: "blur(2px)",
    background:"rgba(255, 255, 255, 0.9)",
  },
backdropsStyleDark:{
     backdropFilter: "blur(1px)",
    background: "rgba(8,4,8,0.6) !important" 
  },
IconsStyle:{
    margin:"16px",
    width: "54px",
    height: "53px", 
  },
  arrowEndText: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "24px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    WebkitTextFillColor: 'transparent', 
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    lineHeight: `27.12px`,
    letterSpacing:` -0.155px`,                                
    textAlign: "center" as "center",
    marginBottom:"6px",                       
  },
  buttonStyle: {          
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '8px',
    padding:"16px", 
    boxShadow:"none !important" ,
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    fontFamily: "Manrope"        
},
buttonStyleDArk: {  
fontWeight: 700,
  marginTop: '8px',
  padding:"16px", 
  boxShadow:"none !important",
  background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
  backgroundClip: "padding-box, border-box",
  backgroundOrigin: "padding-box, border-box",
  border :"1px solid transparent",     
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as 'normal',  
  borderRadius: "10px",
  textTransform: "capitalize" as "capitalize",
  color: "black",         
},
}
// Customizable Area End
