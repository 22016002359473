Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/send_otp";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.logoDescription='A social network created by';
exports.bottomLogoDescription='the community for the community.'
exports.welcome='Welcome to';
exports.arrows='Arrows';
exports.signup='Sign Up';
exports.name='Name';
exports.phone='Phone';
exports.dateOfBirth='Date of birth';
exports.useEmail='Use email instead';
exports.continue='continue';
exports.account='Already have an account?';
exports.enterName='Enter name';
exports.enterPhoneNumber='Enter phone number';
exports.enterDob='Enter date of birth';
exports.login='Log in';
exports.phoneNumberSuggestion='Use Phone instead';
exports.email='Email';
exports.enterEmail='Enter email'
exports.phoneNumberPattern=/^91\d{10}$/;
exports.emailRegex=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
exports.enterName='Enter name';
exports.enterNumber='Enter phone number';
exports.enterValidNumber='Please enter a valid phone number.';
exports.enterMail='';
exports.enterValidEmail='Please enter a valid Email id.';
exports.selectDate='please select DOB';
exports.invalidDate='date of birth cannot be more than present';
exports.nameError='Please enter name';
exports.phoneError='';
exports.emailError='';
exports.light='Light';
exports.dark='Dark';
exports.clientId='888888598771-jsfhc86qahu30js99s0t9prm8t3g0ldi.apps.googleusercontent.com';
exports.googleAuthEndPoint='bx_block_login/login';
exports.loggedInEndPoint='account_block/accounts/logged_user';

// Customizable Area End
