import React from 'react'
// Customizable Area Start 
import {
    Box,
    Typography,
    styled
} from '@material-ui/core'
import SubscribedUserController,{Props} from './SubscribedUserController.web';
import { UserImage, verifyIcon, Subscriber } from './assets';
// Customizable Area End


export default class SubscribedUser  extends SubscribedUserController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start
    // Customizable Area End
    render(){
    // Customizable Area Start
    return(
       <BoxContainer>
        <Box className='subscribed_User_Container'>
         <Box className='user_image'>   
         <Box><img src={UserImage} /></Box>
         <Box className='username_icon'>
            <Box><Typography>Poetic outlaws</Typography></Box>
            <Box><img src={verifyIcon} /></Box>
         </Box>
         </Box>

         <Box>
            <Typography className='time_duration'>2 min ago</Typography>
         </Box>
        </Box>
         <Box className="user_description_container">
            <Box className='subscribed_user_details'>
                <Typography className='userName'>Farewell Allen Ginsberg</Typography>
            <Box>
                  <Typography className='user_description'>Poems, stories about bad men, good men, bad women, good women, lost loves, the exhaustion of life, the exhaustion of the chase, the poetry that is the world. In the grit and in the seeds of a blooming flower. Poems, stories about bad men, good men, bad women, good women, lost loves, the exhaustion of life, the exhaustion of the chase, the poetry that is the world. In the grit and in the seeds of a blooming flower.</Typography>  
            </Box>
            </Box>
            <Box>
                <img src={Subscriber} className='image'/>
            </Box>
         </Box>
       </BoxContainer>
    )
    // Customizable Area End    
    }
}
// Customizable Area Start
const BoxContainer = styled(Box)({
 height:136,
 padding:'10px',
 "& .subscribed_User_Container":{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-between',
    alignItems:'center'
 },

 "& .time_duration":{
    color: '#81999E',
    fontFamily: 'Manrope',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '15.03px',
    letterSpacing: '0.01em',
    textAlign: 'left'
 },

 "& .user_image":{
    display:'flex',
    gap:'8px',
    alignItems:'center'
 },
 '& .username_icon':{
    display:'flex',
    gap:'5px'
 },
 "& .userName":{
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'left'

 },
 "& .user_description":{
    color:'#78716C',
    fontFamily:'Manrope',
    fontSize:'12px',
    fontWeight: 500,
    marginTop:10,
    height:'45px',
    overflow:'hidden',
    whiteSpace:'normal',
    overflowWrap:'break-word',
    textOverflow:'ellipsis',
    lineHeight: '14.4px',
    wordWrap:"break-word",
    paddingRight:'16px',
    textAlign: 'left'

 },
 "& .user_description_container":{
   display:'flex',
   flexDirection:'row',
   marginTop:10,
   justifyContent:'center',
   alignItems:'center'
  },

  "& .image":{
   borderRadius:'10px',
   height:80,
   width:80
  }
})
// Customizable Area End