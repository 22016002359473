// Customizable Area Start
export const user = require("../assets/user.png");
export const eyeIcon = require("../assets/eyeIcon.png");
export const shareIcon = require("../assets/shareIcon.png");
export const profileLogo = require("../assets/face.png");
export const favIcon = require("../assets/favIcon.png");
export const crossIcon = require("../assets/crossIcon.svg");
export const crossIconLight = require("../assets/crossIconLight.svg");
export const sendIcon = require("../assets/sendIcon.png");
export const leftArrow = require("../assets/leftArrow.svg");
export const rightArrow = require("../assets/rightArrow.svg");
export const muteIcon = require("../assets/muteIcon.svg");
export const unmuteIcon = require("../assets/unmuteIcon.svg");
export const likes = require("../assets/likes.svg");
export const storyLike = require("../assets/storyLike.svg");
export const storyLikeEmpty = require("../assets/storyLikeEmpty.svg");
export const leftArrowLight = require("../assets/leftArrowLight.svg");
export const rightArrowLight = require("../assets/rightArrowLight.svg");




// Customizable Area End
