import React from "react";
// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Avatar,
  TextField,
  InputAdornment,
  Checkbox,
  IconButton,
  Grid,
  Paper,
  styled
} from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import { format } from "libphonenumber-js";
import Loader from "../../../components/src/Loader.web";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {faceImage,checkboxImage,darkThemeBg,lightThemeBg,faceLight, view, darkView, RightIcon} from "./assets";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TermsConditionModal from "./TermsConditionModal.web";
// Customizable Area End

// Customizable Area Start

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });

const IcheckBox=withStyles(({
  root:{
     
    "&.Mui-checked": {
      color: "#6200EA",
      borderRadius: "30px",
    },
    "&.MuiCheckbox-root:hover, &.MuiCheckbox-root.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px",
    },
    "&.MuiIconButton-root": {
      borderRadius: "150px !important",
      padding: "10px",
    },
    marginLeft: "-10px",

  }
}))(Checkbox)

const inputStyles={
  customInput: {
    fontSize: '14px',
    fontFamily: "Manrope",
    fontStyle: "normal" as 'normal', 
   },
}

// Customizable Area End

import OTPInputAuthController, {
  Props,
  configJSON,
} from "./OTPInputAuthController";

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderPasswordScreen=(classes:{ [key: string]: string })=>(
    <Box className={classes.formBoxPassword}>
      <Avatar className={this.props.checked ? classes.avatarLightTheme : classes.avatar} onClick={this.navigateToOtp}>
      <IconButton className={classes.IconButtonStyle} disableFocusRipple disableRipple > 
          <img src = {this.props.checked?darkView:view} />
        </IconButton>
      </Avatar>
      {this.state.accountCreationApiError && <Box className={this.props.checked ? classes.errorBoxLight : classes.errorBox}>
        <Typography data-test-id="accountCreation" className={classes.errorText}>
          {this.state.accountCreationApiError}
        </Typography>
      </Box>}
      {this.state.passwordError && <Box className={this.props.checked ? classes.errorBoxLight : classes.errorBox}>
        <Typography data-test-id="passwordError" className={classes.errorText}>
          {this.state.passwordError}
        </Typography>
      </Box>}
      <Typography className={classes.otpText} data-test-id='checkText'>
        {configJSON.checkText}
      </Typography>
      <Typography className={classes.characterText}>
        {configJSON.charText}
      </Typography>
      <Box
        component="form"
        data-test-id="createAccountForm"
        onSubmit={this.createUserAccount}>
        <Box className={classes.otpContainer}>
          <Typography className={classes.label}>
            {configJSON.check}
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className={this.state.passwordError !== configJSON.enterValidPassword ? this.conditionShort(this.state.password.length>0,classes.textFieldPasswordColor, classes.textFieldPassword) : classes.textFieldError}
            placeholder={configJSON.placeholderPassword}
            autoComplete="off"
            onChange={this.handlePasswordChange}
            data-test-id="password"
            value={this.state.password}
            type={this.state.isShow ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-test-id="togglePassword"
                    onClick={this.showPassword}
                    edge="end"
                  >{this.state.isShow ? (
                    <VisibilityOffIcon className={classes.eyeColor} />
                  ) : (
                    <VisibilityIcon className={classes.eyeColor} />
                  )}
                  </IconButton>
                </InputAdornment>
              ),
              style: inputStyles.customInput
            }}
          />
        </Box>
        <Box className={classes.checkBoxContainer}>
          <IcheckBox
            data-test-id="checkbox"
            onChange={this.handleCheckBox}
            checked={this.state.checked}
            icon={<RadioButtonUncheckedIcon className={classes.icons} />}
            checkedIcon={<img src={checkboxImage} className={classes.customIcon} />}
          />
          <Typography className={classes.agree} >
            {configJSON.agree}
            <Box component="span" data-test-id='terms' className={classes.terms} onClick={this.navigateToTermsAndConditions}>
              {configJSON.terms}
            </Box>
            <Box component="span" className={classes.agree}>
              {configJSON.and}
            </Box>
            <Box component="span" onClick={this.navigateToTermsAndConditions} className={classes.terms}>
              {configJSON.privacy}
            </Box>
          </Typography>
        </Box>
        <Button
          className={classes.buttonPassword}
          data-test-id='passwordNext'
          fullWidth
          disabled={this.state.isPasswordButtonClickable}
          type="submit"
          endIcon={<StyledIcon> <img src={RightIcon}/></StyledIcon>}
        >{configJSON.next}
        </Button>
      </Box>
    </Box>
  )

  renderUserNameScreen=(classes:{ [key: string]: string })=>(
    <Box className={classes.formBoxUserName}>
      {this.state.userNamesApiError&& <Box className={this.props.checked ? classes.errorBoxLight : classes.errorBox}>
        <Typography data-test-id="createAccountError" className={classes.errorText}>
          {this.state.userNamesApiError}
        </Typography>
      </Box>}
      <Typography className={classes.otpText} data-test-id='user'>
        {configJSON.userHeading}
      </Typography>
      <Typography className={classes.verifyOtpText}>
        {configJSON.userDescription}
      </Typography>
      <Box className={classes.inputContainer}>
        <Typography className={classes.label}>
          {configJSON.userName}
        </Typography>
        <TextField
          data-test-id="username"
          value={this.state.userName}
          variant="outlined"
          className={this.conditionShort(this.state.userNamesApiError!=="",classes.textFieldOtpError,
          this.conditionShort(this.state.userName.length>0,classes.textFieldUserNameColor,classes.textFieldUserName))}
          autoComplete="off"
          onChange={this.handleChangeUserName}
        />
        <Box className={classes.nameSuggestionContainer}>
          {(this.state.showUsers ? this.state.userNames : this.state.suggestedUserNames).map((name) => <Typography key={name} data-test-id='suggestions'
            onClick={() => this.addUserName(name)}
            className={classes.names}>{name}</Typography>)}
        </Box>
        {!this.state.showUsers && <Typography data-test-id='showMore'
          onClick={this.showMoreSuggestions}
          className={classes.showMore}>
          {configJSON.showMore}
        </Typography>}
      </Box>
      <Box className={classes.userButtonsContainer}>
        <Button className={this.props.checked ? classes.skipButtonLight : classes.skipButton} onClick={this.gotoUploadPicture} data-test-id='skipButton'>
          {configJSON.skip}
        </Button>
        <Button
          disabled={this.state.userName !== '' ? false : true}
          data-test-id='nextButton'
          className={classes.userNextButton}
          fullWidth
          onClick={this.updateUserNameAPI}
          endIcon={<StyledIcon> <img src={RightIcon}/></StyledIcon>}
        >{configJSON.next}
        </Button>
      </Box>
    </Box>
  )

  renderUploadPictureScreen=(classes:{ [key: string]: string })=>(
    <Box className={classes.formBoxUser}>
      <Avatar className={this.props.checked ? classes.avatarLightTheme : classes.avatar} onClick={this.gotoUserName} data-test-id='backButton'>
      <IconButton className={classes.IconButtonStyle} disableFocusRipple disableRipple> 
          <img src = {this.props.checked?darkView:view} />
        </IconButton>
      </Avatar>
      {this.state.uploadPicApiError && <Box className={classes.errorBox}>
        <Typography data-test-id='uploadError' className={classes.errorText}>{this.state.uploadPicApiError}</Typography>
      </Box>}
      <Typography className={classes.otpText} data-test-id='profile'>
        {configJSON.profileHeading}
      </Typography>
      <Typography className={classes.verifyOtpText}>
        {configJSON.profileDescription}
      </Typography>
      <Box className={classes.imgContainer} >
        {this.state.profilePicture ?
          <Box className={classes.coverContainer}>
            <Box component='label'
              className={classes.imageLabel}>
              <PhotoCameraOutlinedIcon
                className={classes.photoIcon} />
              <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangePicture} data-test-id='uploadImage' />
            </Box>
            <Box>
              <Box className={this.props.checked ? classes.divLight : classes.div}></Box>
              <img src={this.state.userImage} className={classes.profileImagLoaded} />
              <CloseIcon data-test-id='close'
                className={classes.closeIcon} onClick={this.closeuploadPicture} />
            </Box>
            </Box> :
            <Box className={classes.coverContainer}>
          <Box component='label'>
            <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangePicture} data-test-id='uploadImage' />
            <img src={this.props.checked ? faceLight : faceImage} className={classes.profileImage} />
            <Box className={classes.picContainer}>
              <PhotoCameraOutlinedIcon className={classes.iconColorPhoto} />
              <Typography className={classes.uploadText} data-test-id='uploadText'>
                {configJSON.uploadPicture}
              </Typography>
            </Box>
          </Box>
          </Box>}
      </Box>
      <Typography>{this.state.selectPictureError}</Typography>
      <Box className={classes.buttonsContainer}>
        <Button className={this.props.checked ? classes.skipButtonLight : classes.skipButton} onClick={this.navigateToCategories}>
          {configJSON.skip}
        </Button>
        <Button
          data-test-id='profileNextButton'
          className={classes.userNextButton}
          disabled={this.state.profilePicture ? false : true}
          fullWidth
          onClick={this.uploadProfilePicture}
          type="button"
          endIcon={<StyledIcon> <img src={RightIcon}/></StyledIcon>}
        >{configJSON.next}
        </Button>
      </Box>
    </Box>
  )

  renderOtpScreen=(classes:{ [key: string]: string })=>(
    <Box className={classes.formBox}>
      <Avatar className={this.props.checked ? classes.avatarLightTheme : classes.avatar} onClick={this.navigateToEmail} data-test-id='backbuttonOtp'>
        <IconButton className={classes.IconButtonStyle} disableFocusRipple disableRipple > 
          <img src = {this.props.checked?darkView:view} />
        </IconButton>
      </Avatar>
      {(this.state.apiError && !this.state.otpError) && <Box className={this.props.checked ? classes.errorBoxLight : classes.errorBox}>
        <Typography
          className={classes.errorText}
          data-test-id="apiError">
          {this.state.apiError}
        </Typography>
      </Box>}

      {this.state.otpError && <Box className={this.props.checked ? classes.errorBoxLight : classes.errorBox}>
        <Typography
          className={classes.errorText}
          data-test-id="apiError">
          {this.state.otpError}
        </Typography>
      </Box>}
      <Typography className={classes.otpText} data-test-id='sendCode'>
        {configJSON.sendCode}
      </Typography>
      <Typography className={classes.verifyOtpText}>
        {configJSON.enterOtpVerify}
        <Box component="span" className={classes.number}>
          {this.state.userData.email === '' ? format(`+${this.state.userMobileNumber}`,"INTERNATIONAL")+"." : this.state.userData.email+"."}
        </Box>
      </Typography>
      <Box
        component="form"
        onSubmit={this.verifyOtp}
        data-test-id="form">
        <Box className={classes.inputContainer}>
          <Typography className={classes.label}>
            {configJSON.otp}
          </Typography>
          <TextField
            data-test-id="otp"
            value={this.state.otpInput}
            id="outlined-basic"
            variant="outlined"
            className={this.conditionShort(this.state.otpError !== ''||this.state.apiError!=="",classes.textFieldOtp,
            this.conditionShort(this.state.otpInput.length>0,classes.textFieldUserNameColor,classes.textFieldUserName))}
            placeholder={configJSON.enterOtp}
            InputProps={{ style: inputStyles.customInput }}
            autoComplete="off"
            onChange={this.handleChangeOtp}
          />
        </Box>
        <Typography className={classes.suggestionText}>
          {configJSON.codeNotReceived}
        </Typography>
        <Button
          data-test-id='next'
          disabled={this.state.isOtpButtonClickable}
          className={classes.button}
          fullWidth
          type="submit"
          endIcon={<StyledIcon> <img src={RightIcon}/></StyledIcon>}
        >{configJSON.next}
        </Button>
      </Box>
    </Box>
  )

  getUserNameProfileScreens=(classes:{ [key: string]: string })=>{
    switch (this.state.changeScreen) {
      case 1:
        return this.renderPasswordScreen(classes)

      case 2:
        return this.renderUserNameScreen(classes)

      case 3:
        return this.renderUploadPictureScreen(classes)

      default:
        return this.renderOtpScreen(classes)
    }
 }

  // Customizable Area End

  render() { 
  // Customizable Area Start
    const { classes } = this.props;
   
  // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}>
        <Paper className={this.props.checked ? classes.thinColor : classes.thickColor}>
          <Loader  loading={this.state.loggedUserLoading}/>
          <Box className={!this.props.checked ? classes.imageBackground : classes.thinBackground}>
            <Grid container className={classes.gridContainer}>
              <Grid item className={classes.imageGridItem} md={5} sm={6} lg={6}>
              </Grid>
              <Grid item xs={10} sm={5} md={5} lg={4} className={classes.gridItem}>
                {this.getUserNameProfileScreens(classes)}
              </Grid>
            </Grid>
          </Box>
          <TermsConditionModal 
           data-test-id="termsConditionModal"
           open={this.state.openModal}
           checked={this.props.checked}
           handleClose={this.handleClose}
           handlenext={this.handleTermsAndConditions}
          />
        </Paper>
     </ThemeProvider>
 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  coverContainer:{
    display:'flex',
    flexDirection:'row' as 'row',
    justifyContent:'center',
    alignItems:'center',  
    height: '156px',
    width: '156px'
  },
  div: {
    position: 'absolute' as 'absolute',
    top: '0%',
    left: '0%',
    height: '156px',
    width: '156px',
    background: "rgba(0,0,0,0.5)",
    borderRadius: '50%',
    margin:'auto'
  },
  divLight: {
    position: 'absolute' as 'absolute',
    top: '0%',
    left: '0%',
    height: '156px',
    width: '156px',
    background: "rgba(255,255,255,0.5)",
    borderRadius: '50%',
    margin:'auto'
  },
  arrowStartText: {
    fontFamily: "Lato",
    fontSize: "20.718px",
    fontStyle: "normal" as "normal",
    fontWeight: 800,
    textAlign: "left" as "left",
  },
  arrowEndText: {
    background: "linear-gradient(323deg, #3858E3 -17.41%, #BD02F7 80.89%)",
    fontFamily: "Lato",
    fontSize: "20.718px",
    fontStyle: "normal" as "normal",
    fontWeight: 800,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
  },

  button: {
    borderRadius: "10px",
    background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: '35px',
    height: '46px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
  },
  gridContainer: {
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
    [theme.breakpoints.down('xs')]:
      { justifyContent: 'center' as 'center' }
  },
  label: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: "left" as "left",
    marginBottom: '6px',
    marginTop: '26px',
  },
  suggestionText: {
    textAlign: "left" as "left",
    textShadow: "4px 4px 4px #00000040",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: "10px",
    cursor: 'pointer',
    textDecoration: "underline",
    textDecorationColor:"rgb(136, 51, 255, 0.3)",
  },
  arrowImage: {
    width: "50%",
    height: "auto",
    flexShrink: 0,
  },
  description: {
    fontFamily: "Manrope",
    fontSize: "21px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    width: "70%",
  },
  imageGridItem: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  otpText: {
    textAlign: "left" as "left",
    textShadow:
      "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: '28px',
    letterSpacing: '-0.005em'
  },
  verifyOtpText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    textAlign: "left" as "left",
  },
  number: {
    textAlign: "left" as "left",
    fontWeight: 600,
    textShadow:
      "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  avatar: {
    borderRadius: "8px",
    backgroundColor: '#222222',
    cursor: 'pointer',
  },
  avatarLightTheme: {
    borderRadius: "8px",
    backgroundColor: '#E9E9E9',
    cursor: 'pointer'
  },
  characterText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    textAlign: "left" as "left",
  },
  password: {
    textAlign: "left" as "left",
    textShadow:
      "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
    fontFamily: "Manrope",
    fontSize: "32px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  agree: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: '-2px',
  },
  terms: {
    color: "#83F",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: "right" as "right",
    WebkitBackgroundClip: "text",
    textDecoration: "underline",
    marginLeft: "6px",
    marginRight: "6px",
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inputContainer: {
    marginTop: "10px",
  },
  errorText: {
    color: "#DC2626",
    fontFamily: "Manrope",
    fontSize: "11px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
  },
  iconColor: {
    cursor: "pointer",
    fontSize: '20px'
  },
  iconColorPhoto: {
    cursor: "pointer",
    fontSize: '24px'
  },
  otpContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  textFieldUserNameColor: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #8833FF',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
  textFieldUserName: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
  showMore: {
    textAlign: "left" as "left",
    textShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color: '#8833FF',
    marginTop: '10px',
    cursor: 'pointer',
  },
  userNextButton: {
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    width: '45%',
    height: '48px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
  },
  skipButton: {
    background: `url(${darkThemeBg}) padding-box fixed ,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    backgroundRepeat:"no-repeat",
    backgroundPosition:'center',
    backgroundSize:'cover',
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    width: '45%',
    height: '48px',
    [theme.breakpoints.down('xs')]:{
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    }, 
  },

  "IconButtonStyle":{
      height:"100%",
      borderRadius:'unset',
      width:"100%",
   },

  skipButtonLight: {
    background: `url(${lightThemeBg}) padding-box fixed,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    backgroundPosition:'center',
    backgroundRepeat:"no-repeat",
    backgroundSize:'cover',
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    width: '45%',
    height: '48px',
    [theme.breakpoints.down('xs')]:{
      backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border :"1px solid transparent",
        borderRadius: "10px",
      background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    }, 
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  imgContainer: {
    position: 'relative' as 'relative',
    height: '156px',
    width: '156px',
    marginTop: '40px',
    marginBottom: '40px',
    display:'flex',
    flexDirection:'row' as 'row',
    justifyContent:'center',
    alignItems:'center'
  },
  picContainer: {
    position: 'absolute' as 'absolute',
    top: '35%',
    left: '-0.5%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImage: {
    borderRadius: '50%',
    cursor:'pointer',
    border: '4px solid #8833FF',
    height: '156px',
    width: '156px',
  },
  uploadText: {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '4px'
  },
  names: {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color: '#6B6B6B',
    marginRight: '4px',
    cursor: 'pointer',
    marginTop: '8px'

  },
  nameSuggestionContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: '100%',
    flexWrap: 'wrap' as 'wrap',
    boxSizing: 'border-box' as 'border-box'
  },
  picCloseContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoIcon: {
    cursor: 'pointer',
    fontSize: '20px'
  },
  closeIcon: {
    position: 'absolute' as 'absolute'
    , top: '44%', right: '28%',
    cursor: 'pointer',
    fontSize: '20px'
  },
  imageLabel: {
    position: 'absolute' as 'absolute',
    top: '44%',
    zIndex: 1,
    left: '30%',
  },
  backIcon: {
    color: '#fff',
    fontSize: '30px'
  },
  backIconLightTheme: {
    color: '#222222',
    fontSize: '30px'
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '20px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px",
    },

  },
  textFieldOtp: {
    width: "100%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#f44336"
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336 !important',
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #f44336',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },

  textFieldOtpError: {
    width: "100%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#f44336"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: '1px solid #6B6B6B',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
  eyeColor: {
    color: '#4E4E4E',
    fontSize: '25px'
  },
  profileImagLoaded: {
    borderRadius: '50%',
    border: '4px solid #8833FF',
    height: '156px',
    objectFit:'cover' as "cover",
    width: '156px',
  },

  formBox: {
    width: '80%',
  },
  formBoxPassword: {
    width: '80%',
    boxSizing: 'border-box' as 'border-box',
    [theme.breakpoints.down('xs')]: { paddingLeft: '0px' },
    [theme.breakpoints.down('sm')]: { paddingLeft: '0px' }
  },
  formBoxUser: {
    width: '85%',
    maxWidth:"430px"
  },
  formBoxUserName: {
    width: '80%',
  },
  textFieldUser: {
    width: "98%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      border: '1px solid #6B6B6B !important',
      borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px, 16px, 10px, 16px',

    },
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center" as 'center',
    "& fieldset": {
      borderRadius: '10px',
    },
    "&:hover": {
      "& fieldset": {
        borderRadius: '10px',
      },
    },
    "& .MuiTypography-root": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
    },
  },
  userButtonsContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    marginTop: '12px',
    width: '98%'
  },
  textFieldError: {
    width: "98%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#f44336"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      height: '24px',
      borderColor:"#8833FF",
      borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px 16px',

    },
  },
  buttonPassword: {
    borderRadius: "10px",
    background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: '22px',
    height: '46px',
    width: '98%',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  errorBox: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: "18px",
    marginTop: '22px',
  },
  errorBoxLight: {
    display: 'flex',
    padding: '0.7rem 0.7rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(0deg, rgba(33, 33, 33, 0.07), rgba(33, 33, 33, 0.07)),linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: "18px",
    marginTop: '22px',
  },
  icons: {
    fontSize: '21px',
    marginTop: '1px'
  },
  customIcon: {
    height: '21px',
    width: '21px',
    marginTop: '3px',
    marginRight: '4px',
    marginBottom: '2px'
  },
  textFieldPassword: {
    width: "98%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      height: '24px',
      borderColor:"#8833FF",
      borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px 16px',

    },
   

  },

  textFieldPasswordColor: {
    width: "98%",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& .MuiOutlinedInput-notchedOutline':{
        borderColor:"#8833FF"
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth:"1px",
        borderColor:"#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      height: '24px',
      borderColor:"#8833FF",
      borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px 16px',

    },

  },
  thinColor: {
    backgroundColor: '#fff'
  },
  thickColor: {
    backgroundColor: '#070707'
  },
  imageBackground: {
    backgroundImage: `url(${darkThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  thinBackground: {
    backgroundImage: `url(${lightThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
};

export const verifyOtp = withStyles(styles)(OTPInputAuth);
// Customizable Area End
