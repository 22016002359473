Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_notifications/notifications?";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.mentionNotifictionEndPoint ="bx_block_notifications/notifications/mentions"
// Customizable Area End