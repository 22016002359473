import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActivityInterface } from "./dataInterface";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import {debounce, cloneDeep} from 'lodash';

export interface PostActivityInterface {
  impressions: number
  engagements: number;
  detail_expands: number;
  new_followers: number;
  profile_visits: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes:any;
  checked:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activities: Array<ActivityInterface>;
  fetchedPages: number;
  activityFilter: string;
  token: string;
  accountId: number;
  pickerModalVisibility: boolean,
  activeIndex:number,
  followers:any[],
  anchorEl:null | HTMLElement;
  feeds:any[],
  pagination:any,
  feedType:string,
  isModel:boolean,
  suggestions:any[],
  tailoredMember:any[],
  page:number,
  tailoredPagination:any,
  suggestionsFilter:string
  activeSiggestion:number
  modelType:string,
  activeUser:any
  muteModel:boolean
  userInfo:any
  bottompoper:boolean
  suggestionsPagination:any
  showReportPostModal: boolean
  reportCatId: number|null;
  reportPostMainList: any[];
  reportPostSubList: any[];
  reportSubCatId: number|null;
  reportCurrentView: string;
  reportingPostId: string;
  reportPageLoading: boolean;
  reportPostSubmittedModal: boolean;
  postActivityModel:boolean
  activePostId:string
  showComments: boolean
  selectedCommentsPost: any
  postActivity:PostActivityInterface
  reportPostLoading: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
export interface ApiData{
  body?: {}
  method: string;
  contentType: string;
  endPoint: string;
}
// Customizable Area End

export default class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMainCatId: string = "";
  getSubCatId: string = "";
  getReportPostSubmitId: string = "";
  getActivityApiCallId: string = "";
  getFeedApiCallId:string = "";
  followApiCallId:string =""
  getSuggestionsApiCallId:string=""
  addUserApiCallId:string =""
  getTailoredMemberApiCallId:string =""
  tailoredMember:string=""
  removeUserApiCallId:string=""
  muteUserApiCallId:string =""
  getLoggedUserApiCallId:string =""
  unMuteUserApiCallId:string=""
  blockAccountApiCallId:string =""
  likePostApiCallId:string =""
  savePostApiCallId:string =""
  getPostActivityApiCallId:string =""
  activityTypes: string[] = [
    "BxBlockComments::Comment",
    "BxBlockChat::Chat",
    "BxBlockOrderManagement::Order",
    "BxBlockPosts::Post"
  ]
  scrollParentRef = React.createRef<HTMLDivElement>();
  scrollSuggestionParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activities: [],
      fetchedPages: 1,
      activityFilter: "",
      token: "",
      accountId: 0,
      pickerModalVisibility: false,
      activeIndex:0,
      followers:[{name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""}
      ],
      feeds:[],
      anchorEl:null,
      pagination:{},
      feedType:"all",
      isModel:false,
      suggestions:[],
      tailoredMember:[],
      page:1,
      tailoredPagination:{},
      suggestionsFilter:"",
      activeSiggestion:0,
      modelType:"",
      activeUser:{},
      muteModel:false,
      userInfo:{},
      bottompoper:false,
      suggestionsPagination:{},
      showReportPostModal: false,
      reportPostMainList: [],
      reportPostSubList: [],
      reportCatId: null,
      reportSubCatId: null,
      reportCurrentView: "main",
      reportPageLoading: true,
      reportingPostId: "",
      reportPostSubmittedModal: false,
      postActivityModel:false,
      activePostId:"",
      showComments: false,
      selectedCommentsPost: null,
      postActivity:{
        "impressions": 0,
        "engagements": 0,
        "detail_expands": 0,
        "new_followers": 0,
        "profile_visits": 0
      },
      reportPostLoading: false
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getFeeds()
    this.loggedUser()
  }

  setFilter=debounce((event:any)=> {    
    this.setState({ activityFilter: event.target.value, fetchedPages: 1, feeds: [] }, () => {
      this.getFeeds();
    });
  },300)

  handleSuggestionFilter = debounce((event:any)=> {    
    this.setState({ suggestionsFilter: event.target.value, page: 1, suggestions: [] }, () => {
     this.state.activeSiggestion ==1 ?this.getSuggestions():this.gettailoredUserList();
    });
  },300)


  setModalVisibility(value: boolean) {
    this.setState({ pickerModalVisibility: value })
  }

  handleClosePostActivityModel =()=>{
    this.setState({postActivityModel:false ,activePostId: ""})
  }

  handlePostActivityModel=(id?:string,postedById?:string)=>{  
    if(postedById ==this.state.userInfo.id){
      this.setState({postActivityModel:true ,activePostId: id||""})
      this.getPostAcitivityData(id)
    }
  }

  getPostAcitivityData = async(id?:string) =>{
    let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPostActivityApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.postaActivityEndpoint + `?post_id=${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getAcitivityData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPoint + `?_page=${this.state.fetchedPages}&_limit=10${this.state.activityFilter ? '&trackable_type=' + this.state.activityFilter : ''}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFeeds = async() =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedEndpoint + `?page=${this.state.fetchedPages}&per_page=10&type=${this.state.feedType}&query=${this.state.activityFilter}&sort_order=desc`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  loggedUser = async() =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLoggedUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedUserEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSuggestions =async() =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSuggestionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suggestionsEndpoint + `?page=${this.state.page}&per_page=20&query=${this.state.suggestionsFilter} `
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  muteConformation =() =>{
    this.setState({muteModel:true})
    this.handleClose()
  }

  handleMuteModel =(status:string) =>{
    if (status=="no"){
      this.setState({muteModel:false})
    }else{
      this.handleMuteAcoount(this.state.activeUser?.id)
    }
  }

  handleMuteAcoount =async(id:string) =>{    
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody ={
      account_id:id
    }

    this.muteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.muteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleUnmuteUser = async(id:string) =>{    
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody ={
      account_id:id
    }

    this.unMuteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unmuteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  openModel =()=>{
    this.setState({isModel:true},()=>{
      this.getSuggestions()
    })
  }

  handleMemberModel=()=>{
    this.setState({isModel:true,modelType:"edit"})
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {
    let types = ["all","following","tailored"]
    
    this.setState({ activeIndex: newValue,feedType:types[newValue],fetchedPages:1,feeds:[],page:1 },async()=>{      
      if(types[newValue] === "tailored"){
        await this.gettailoredUserList()
        await this.getFeeds()
      } else this.getFeeds()   
    });
  };

  handleSuggestionTabsChange = (event: ChangeEvent<{}>, newValue: number) => {    
    this.setState({ activeSiggestion: newValue,page:1 },async()=>{  
      if(newValue==1){
       this.setState({suggestions:[]},()=>this.getSuggestions()) 
      }else{
        this.setState({tailoredMember:[]},()=>this.gettailoredUserList())
      }    
      
    });
  };

  handleAdduser =async(id:string) =>{
    let token = await getStorageData("authToken")
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let formdata = new FormData();
    formdata.append("member_id",id)
   
    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.tailoredMemberEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleRemoveUser = async(id:string) =>{
    let token = await getStorageData("authToken")
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let formdata = new FormData();
    formdata.append("member_id",id)
   
    this.removeUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.tailoredMemberEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

 gettailoredUserList = async() =>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getTailoredMemberApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.tailoredMemberEndpoint + `?page=${this.state.page}&per_page=20&query=${this.state.suggestionsFilter}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

 }

  handleClick = (activeUser:string, postId?: string) => {
    this.setState({ activeUser:activeUser, reportingPostId: postId|| ""});
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleModelClose =()=>{
    this.setState({isModel:false,modelType:"",fetchedPages:1,feeds:[],suggestionsFilter:"",page:1},async()=>{
      await this.gettailoredUserList()
      await this.getFeeds()
      
    })
  }

  handleButtompoperlClose = ()=>{
    this.setState(
      {bottompoper:false, showComments: false, selectedCommentsPost: null}, 
      () => {
        this.dataFilter()
      }
    )
  }

  dataFilter =() =>{
    let feeds = [...this.state.feeds]
      let newarray = feeds.filter(item => this.state.activeUser?.id != item.attributes.post_by.id);
      this.setState({feeds:newarray})
  }


  handleMuteModelClose =()=>{
    this.setState({muteModel:false,})
  }

  handleFollow =async(id:number,type:string) =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      account_id:id,      
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.followEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="follow"?configJSON.apiPostMethod:configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)  
  }

  handleLikePost = async(id:string,type:string,likeable_type:string) =>{
    this.setState({accountId:JSON.parse(id)})
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      likeable_id:id,
      likeable_type:likeable_type   
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="like"?configJSON.apiPostMethod:configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)  
  }

  handleSavePost = async(id:string,type:string) =>{
    let authToken = await getStorageData("authToken")
    const header = {
      token: authToken,
      "Content-Type": configJSON.apiContentType,
    };

    let httpBody = {
      post_id:id,    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),type=="save"?configJSON.savePostEndpoint:configJSON.unSavePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="save"?configJSON.apiPostMethod:configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)  
  }

  handleBlockAccount = async(id:number) =>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      account_id:id,      
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.blockAccountEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)  
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if(errorResponse) {
      this.parseApiErrorResponse(errorResponse)
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getActivityApiCallId.length > 0 &&
      this.getActivityApiCallId === apiRequestCallId &&
      responseJson && responseJson.activities?.length > 0
    ) {
      this.setState({
        activities: [
          ...this.state.activities,
          ...responseJson.activities.filter((activity: ActivityInterface) => activity.owner)
        ],
        fetchedPages: this.state.fetchedPages + 1
      });
    }
    this.handleFeedsResponse(message)
    this.handleLoggedUserResponse(message)
    this.handleFollowResponse(message)
    this.handleSuggestionsRes(message)
    this.hanadleAdduserRes(message)
    this.handleDeleteUser(message)
    this.hanadleailoredMemberRes(message)
    this.handleMuteUsersRes(message)
    this.handleUnmuteRes(message)
    this.handleBlockUser(message)
    this.handleLikePostRes(message)
    this.handleSavePostRes(message)
    this.handleReportPostMainCat(message)
    this.handleReportPostSubCat(message)
    this.handleReportPostSubmit(message)
    this.handlePostActivityRes(message)
  }

  handleFeedsResponse =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getFeedApiCallId.length > 0 &&
    this.getFeedApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      this.setState({feeds:[...this.state.feeds,...responseJson.data], fetchedPages: this.state.fetchedPages + 1 , pagination:responseJson.pagination_details})
    
    }
  }

  handlePostActivityRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getFeedApiCallId.length > 0 &&
    this.getPostActivityApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      this.setState({postActivity:responseJson.data?.attributes?.post_activity})
    }
  }

  handleLikePostRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.likePostApiCallId.length > 0 &&
    this.likePostApiCallId === apiRequestCallId &&
    responseJson && responseJson.messages){
      let feeds = [...this.state.feeds]
      let newarray = feeds.map(post => {
        if (post.attributes.id === this.state.accountId) {
          return {
                 ...post,
                 attributes: {
                   ...post.attributes,
                   is_liked:  responseJson.messages[0]=="Liked Successfully"?true:false,
                   total_likes:responseJson.messages[0]=="Liked Successfully"?post.attributes.total_likes+1:post.attributes.total_likes-1
                 }
               };
             }
             return post;
       });
       if (this.state.accountId.toString() === this.state.selectedCommentsPost?.id) {
        const updateCommentsPost = newarray?.find(post => post.id === this.state.selectedCommentsPost.id)
        this.setState({selectedCommentsPost: cloneDeep(updateCommentsPost)})
       }
       this.setState({feeds:newarray})    
    }
  }

  handleSavePostRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.savePostApiCallId.length > 0 &&
    this.savePostApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      let newfeeds = [...this.state.feeds]
      let feeds = newfeeds.map(post => {
        if (post.attributes.id == responseJson.data.id) {
          return {
                 ...post,
                 attributes: {
                   ...post.attributes,
                   is_saved: responseJson.data.attributes.is_saved
                 }
               };
             }
             return post;
       });
       if (responseJson.data.id === this.state.selectedCommentsPost?.id) {
        const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedCommentsPost.id)
        this.setState({selectedCommentsPost: cloneDeep(updateCommentsPost)})
       }
       this.setState({feeds:feeds})    
    }
  }

  handleLoggedUserResponse =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getLoggedUserApiCallId.length > 0 &&
    this.getLoggedUserApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      this.setState({userInfo:responseJson.data.attributes})    
    }
  }

  handleMuteUsersRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.muteUserApiCallId.length > 0 &&
    this.muteUserApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){      
      this.setState({muteModel:false,bottompoper:true})    
    }
  }

  handleBlockUser =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.blockAccountApiCallId.length > 0 &&
    this.blockAccountApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){      
      this.dataFilter()  
      this.handleCloseCommentsModal() 
      this.handleClose()
    }
  }

  handleUnmuteRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.unMuteUserApiCallId.length > 0 &&
    this.unMuteUserApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){      
      this.setState({bottompoper:false})    
    }
  }

  handleSuggestionsRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getSuggestionsApiCallId.length > 0 &&
    this.getSuggestionsApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      this.setState({suggestions:[...this.state.suggestions,...responseJson.data],page:this.state.page+1,suggestionsPagination:responseJson.pagination_details})
    }
  }

  hanadleAdduserRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.addUserApiCallId.length > 0 &&
    this.addUserApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      let suggestion = [...this.state.suggestions]        
      this.setState({suggestions:suggestion.filter(item => item.id !== responseJson.data.id)})
    }
  }

  handleDeleteUser =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.removeUserApiCallId.length > 0 &&
    this.removeUserApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
      let tailoredMember = [...this.state.tailoredMember]        
      this.setState({tailoredMember:tailoredMember.filter(item => item.id !== responseJson.data.id)})
    }
  }

  hanadleailoredMemberRes =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getTailoredMemberApiCallId.length > 0 &&
    this.getTailoredMemberApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){      
      this.setState({tailoredMember:[...this.state.tailoredMember,...responseJson.data],page:this.state.page+1,tailoredPagination:responseJson.pagination_details})
    }
  }
  
  handleFollowResponse =(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if( getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.followApiCallId.length > 0 &&
    this.followApiCallId === apiRequestCallId &&
    responseJson && responseJson.data){
     let feed = [...this.state.feeds]    
    let newFeed = feed.map((post) =>
      post.attributes.post_by.id == responseJson.data.id
        ? {
            ...post,
            attributes: {
              ...post.attributes,
              post_by: {
                ...post.attributes.post_by,
                is_following: responseJson.data.attributes.is_following
              },
            },
          }
        : post
    );
    if (responseJson.data?.attributes?.id === this.state.selectedCommentsPost?.attributes.post_by.id) {
      const updateCommentsPost = newFeed?.find(post => post.attributes.post_by.id === this.state.selectedCommentsPost.attributes.post_by.id)
      this.setState({selectedCommentsPost: cloneDeep(updateCommentsPost)})
     }
      this.setState({feeds:newFeed },()=>this.handleClose()) 
    }
  }

  handleGetNewFeed = () => {
    this.setState({fetchedPages: 1, feeds: []})
    this.getFeeds()
  }

  handleReportPostMainCat = (message:Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getMainCatId.length > 0 && this.getMainCatId === apiRequestCallId && responseJson && responseJson.data){
      this.setState({reportPostMainList: responseJson.data})
    }
  }

  handleReportPostSubCat = (message:Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getSubCatId.length > 0 && this.getSubCatId === apiRequestCallId && responseJson && responseJson.data){
      this.setState({ reportPostSubList: responseJson.data, reportPostLoading: false })
    }
  }

  handleReportPostSubmit = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if( getName(MessageEnum.RestAPIResponceMessage)===message.id && this.getReportPostSubmitId.length > 0 && this.getReportPostSubmitId === apiRequestCallId && responseJson){
      this.setState({
        reportCurrentView: "submit"
      })
    }
  }

  apiCall = (data:ApiData) => {
    const { contentType, method, endPoint, body} = data;
    const token = localStorage.getItem("authToken") || "";
     
    const header = { "Content-Type": contentType, token: token};
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  showReportPost = () => {
    this.handleClose();
    this.setState({
      showReportPostModal: true,
      reportPageLoading: true,
      anchorEl: null
    })
    this.getMainCatId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiMethod,
      endPoint: "bx_block_posts/report_issues"
    });
  }

  closeReportPostModal = () => {
    this.setState({
      showReportPostModal: false,
      reportCatId: null,
      reportSubCatId: null,
      reportingPostId: "",
      reportCurrentView: "main",
      reportPostSubList: []
    })
  }

  setReportPostMainCat = (id: number) => {
    this.setState({
      reportCatId: id
    })
  }
  setReportPostSubCat = (id: number) => {
    this.setState({
      reportSubCatId: id
    })
  }
  changeReportView =() =>{
    if(this.state.reportSubCatId !== null&& this.state.reportCatId !== null){
      this.getReportPostSubmitId = this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiPostMethod,
        endPoint: "bx_block_posts/report_post",
        body: {
          "report": {
            "post_id": Number(this.state.reportingPostId),
            "report_issue_id": this.state.reportCatId,
            "report_sub_issue_id": this.state.reportSubCatId
          }
        }
      });
    }else if(this.state.reportCatId !== null){
      this.setState({
        reportCurrentView: "subcat",
        reportPostLoading: true
      })
      this.getSubCatId = this.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiMethod,
        endPoint: "bx_block_posts/report_sub_issues?id="+this.state.reportCatId,
      });
    }
  }

  handleClickComments = (postId: string) => {
    this.setState((prev) => {
      const selectedPost = prev.feeds?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedCommentsPost: selectedPost
      }
    })
  }

  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedCommentsPost: null})
  }

  handleUpdateNumberCommentOfPost = () => {
    const cloneFeeds = [...this.state.feeds]
    const updateFeeds = cloneFeeds.map((post) => {
      if (post.id === this.state.selectedCommentsPost?.id) {
        return {
          ...post,
          attributes: {
            ...post.attributes,
            total_comments: post.attributes.total_comments + 1
          }
        }
      } else {
        return post
      }
    })
    this.setState({feeds: updateFeeds})
  }
  // Customizable Area End
}
