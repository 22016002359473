import React, { Fragment } from "react";
// Customizable Area Start

import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { searchDark, searchLight } from "./assets";
import { Box, Dialog, Grid, IconButton, InputAdornment, TextField, ThemeProvider, Typography, withStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";


import LocationController, { Props } from "./LocationController.web";
// Customizable Area End

export  class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditionFilter = (condition:boolean,trueStatement:string,falseStatement:string) =>{
   return condition ? trueStatement : falseStatement
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const {classes} = this.props
    return (
        <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}>          
          <Dialog data-test-id="loactionModel" onClose={() => 
            this.handlelClose()
          } 
          open={this.state.isModel} 
          PaperProps={{
            className: this.conditionFilter(!this.props.checked,classes.modelDark,classes.modelLight)
          }}
           fullWidth
          classes={{paperWidthSm:this.conditionFilter(!this.props.checked,classes.modelDark,classes.modelLight)}} 
        >
       <Grid container justifyContent="center" alignItems="center" direction="column" style={{
              padding: "15px 24px 15px 24px"  ,rowGap:"15px"                             
        }}>          
          
        <Box style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center" }}>
          <IconButton style={{
               color: this.conditionFilter(!this.props.checked,"#fff","black"),
               height: "48px",
               width: "48px",                     
               padding:"10px 12px",               

                 }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2"
                 onClick={() => this.handlelClose()}
          >
           <ChevronLeftIcon  style={{height:"44px",width:"28px"}}/>
          </IconButton> 
          <Typography  className={classes.topHead}>Add Location</Typography>
          <Typography>{" "}</Typography>
        </Box>
             
        <Box style={{width:"100%"}}> 
           <TextField  
              variant="outlined"
               placeholder="Search"
               data-test-id="searchLocation"
               onChange={this.handleSearchLocation}
               className={classes.searchStyle}
               style={{width:"100%"}}
               fullWidth
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <img src={this.conditionFilter(this.props.checked,searchDark,searchLight)} alt="" />
                 </InputAdornment>
               ),
             }}
            />
         </Box>

         <Box style={{display:"flex",rowGap:"20px",flexDirection:"column",width:"100%" }}>

          <div  className={classes.locationList} >  
          {[{city:"indore",country:"india"},
          {city:"chennai",country:"india"},
          {city:"khandwa",country:"india"},
          {city:"bhopal",country:"india"},
          {city:"jabalpur",country:"india"},
          {city:"udaipur",country:"india"},{city:"indore",country:"india"},
          {city:"chennai",country:"india"},
          ].map((val)=><Box className={this.props.checked?classes.listStyle:classes.listStyleDark}>
            <Typography className={classes.city}>{val.city}</Typography>
            <Typography className={classes.country}>{val.country}</Typography>
          </Box> )}   
          </div> 
        </Box>                      
      </Grid>
     </Dialog>          
    </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
    modelDark:{ 
        borderRadius: "10px",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        backdropFilter: "blur(1 px)",
        background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
        boxShadow:"none"        
      },
      modelLight:{
        borderRadius: "10px", 
        boxShadow:"none",
        border: "1px solid transparent",
        background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#5B5B5B)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",  
        backdropFilter: "blur(1 px)",    
       },
       searchStyle:{
        width:"80%",
        '& .MuiOutlinedInput-root': {
          borderRadius: "10px", 
          '&.Mui-focused fieldset': {
            border :"1px solid #7A2BF5",       
          },
        },       
      },
      topHead:{       
        lineHeight:"21px",
        whiteSpace:"nowrap" as "nowrap",
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800,
      },
      city:{
        fontWeight:600,
        fontSize:16,
        fontFamily:'Manrope',
        lineHeight:"19px"
      },
      country:{
        fontWeight:500,
        fontSize:10,
        fontFamily:'Manrope',
        lineHeight:"12px"
      },
      listStyle:{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"4px",
        '&:hover': {
            background: '#d3d3d3'
          }
      },
      listStyleDark:{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"4px",
        '&:hover': {
            background: 'black'
          }       
      },
      locationList:{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"10px",
        height: "60vh",
        overflowY: "scroll" as "scroll",
        padding:"0 23px"
      }
  
};

export default withStyles(styles)(Location);
// Customizable Area End
