import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configEmailJSON = require("../../email-account-login/src/config");
export interface InitialValues {
  fieldValue: string;
}
export interface DataAPi{
  method: string;
  contentType: string;
  body?: {}
  endPoint: string; 
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  checked?: boolean;
  editType: string;
  onClickBackButton: () => void;
  fieldValue: string;
  setMessage: (type:string, msg:string) => void;
  updateUserName: (name: string) => void;
  updateError: string;
  updateErrorMsg: (msg: string) => void;
  apiCall: (data: DataAPi) => string
  // Customizable Area End
}

interface S {  
  // Customizable Area Start
  isLoading: boolean;
  fieldName: string;
  oldFieldValue: string;
  newFieldValue: string;
  showOtpScreen: boolean;
  otpToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitEmailApiId:string ="";
  submitOTPApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      // Customizable Area Start
      fieldName: "",
      oldFieldValue: "",
      newFieldValue: "",
      showOtpScreen: false,
      isLoading: false,
      otpToken: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.submitEmailApiId) {
          if(responseJson.errors){
            this.props.updateErrorMsg(responseJson.errors[0])
          }
          if(responseJson.data){
            this.setState({
              showOtpScreen: true,
              isLoading: false,
              otpToken: responseJson.meta.token
            })
          }
        }
        if(apiRequestCallId === this.submitOTPApiId){
          if(responseJson.errors){
            this.props.updateErrorMsg(responseJson.errors[0])
          }else{
            this.props.setMessage("suc", `Your ${this.state.fieldName} has been updated successfully`);
            setTimeout(() => {
              this.props.setMessage("", "");
              this.props.onClickBackButton();
            }, 1500)
          }
        }
      }
    }
    // Customizable Area End
  }
  async componentDidMount()  {
    const editType = this.props.editType;
    const value = this.props.fieldValue;     
    this.setState({
      oldFieldValue: value,
      fieldName: editType === "name" ? "UserName" : editType,
    })     
  }

  validate = (value: string): string => {
    let errors = "";
    if (this.state.fieldName === "email") {
      if (!configEmailJSON.emailRegex.test(value.toLowerCase())) {
        errors = "Invalid email format";
      }
    } else if (this.state.fieldName === "phoneNumber") {
      if (!configEmailJSON.phoneRegex.test(value)) {
        errors = "Please enter a valid number";
      }
    }
    return errors;
  };

  submitEditForm = (values: {filedValue: string}) => {
     const errors: string = this.validate(values?.filedValue);
     
    if(errors !== ""){      
      this.props.updateErrorMsg(errors)
      return ;
    }else{
      this.props.updateErrorMsg("")
    }

    if(this.props.editType === "name"){
      this.props.updateUserName(values?.filedValue);
    }else{
      const keyName = this.state.fieldName === "phoneNumber"? "full_phone_number" : "email"
       this.submitEmailApiId = this.props.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPostMethod,
        endPoint: "account_block/get_otp",
        body: {
          "data": {
            "attributes": {
              [keyName]: values?.filedValue
            }
          }
        }
       })
      this.setState({
        newFieldValue: values?.filedValue,
      })
    }
  }

  submitOtp = (values: {otp: string}) => {
    this.submitOTPApiId = this.props.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPatchMethod,
        endPoint: "account_block/change_email_or_phone",
        body: {
          "token": this.state.otpToken,
          "otp_code": values?.otp
        }
    })
  }

  // Customizable Area End
}
