Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.timeout = 2000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.arrowLogostartText="Welcome to "
exports.arrowLogoEndText="ARROWS"
exports.adoptersText='Early adopters to Arrows:';
exports.skip='Skip now';
exports.skipButton='Skip now'
exports.user='User';
exports.twitter='Twitter (X)';
exports.instagram='Instagram';
exports.facebook='Facebook';
exports.follow='follow';
exports.back='Back';
exports.logout='Logout'
exports.save='Save';
exports.light='Light';
exports.dark='Dark'
exports.getUsersApiEndPoint='account_block/accounts';
exports.getUsersApiMethodType = "GET";

exports.btnExampleTitle = "CLICK ME";
exports.followersListEndPoint='account_block/accounts';
exports.loggedInUserEndPoint='account_block/accounts/logged_user';
exports.edit='edit';
exports.followApiEndpoint='bx_block_followers/follows';
exports.following='following';
exports.unfollowUserEndpoint='bx_block_followers/follows';
exports.deleteApiMethodType = "DELETE";
exports.followers='followers';
// Customizable Area End