export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");

export const back_btn = require("../assets/back_btn.svg");
export const back_btn_black = require("../assets/back_btn_black.svg");
export const right_arrow = require("../assets/right_arrow.svg");
export const right_arrow_black = require("../assets/right_arrow_black.svg");


export const backArrowDark = require("../assets/backArrow-dark.png");
export const backArrowWhite = require("../assets/backArrow-white.png");