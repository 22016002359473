import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Avatar,
  TextField, 
  Grid,
  Paper,
  createTheme,
  ThemeProvider,
  styled,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { dashboardBackground, lightBackground, darkView,  view, RightIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { Alert } from "@material-ui/lab";
// Customizable Area End

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";




// Customizable Area Start

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

const darkTheme=createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary:{
    main:"rgba(255,255,255,0.75)"
    }
   }
  });

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    contrastText: "#fff",
    },
  },
  typography: {    
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});
const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });


const inputStyles={
      customInput: {
        fontFamily: "Manrope",
        fontStyle: "normal" as 'normal', 
        fontSize: '13px', 
        borderRadius:"10px",
        borderColor:"#8833FF"     
      }
}

const CustomButton = styled(Button)({
  "&.MuiButton-root.Mui-disabled": {
    color: '#E9E9E9',
    opacity:"0.64 !important"
  }    
})

const CustomTextField= styled(TextField)({
  "& .MuiInputBase-input": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    height: '24px',
    border: '1px solid #6B6B6B',
    borderRadius: '10px'
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 16px",
  }, 

   "& .MuiOutlinedInput-notchedOutline":{
    borderRadius: '10px',
    borderWidth:"1px",
    borderColor:"#8833FF"
   },

  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderRadius: '10px',
    borderWidth:"1px",
    borderColor:"#8833FF"
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderRadius: '10px',
    borderWidth:"1px",
    borderColor:"#f44336"
  },
})

// Customizable Area End

export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSnackBar=()=>{
  return <Snackbar  anchorOrigin={{ vertical:"top", horizontal:'right' }} open={this.state.snackBarOpen} autoHideDuration={2000} onClose={this.handleCloseSnackbar}>
     <Alert icon={false} severity={"success"} variant="filled" onClose={this.handleCloseSnackbar}>
      {this.state.alertMessage?.message}
    </Alert>
   </Snackbar>
  }

  renderOtpScreen=()=>{
    const TextField = webStyle.textFieldUserName
    return(
    <Box style={webStyle.formBox}>
       <Avatar style={this.props.checked?webStyle.avatarLightTheme:webStyle.avatar} onClick={()=>this.props.navigation.goBack()} data-test-id='backbuttonOtp'>
       <IconButton style={{
                       height:"100%",
                       width:'100%',
                       borderRadius:'unset'
                      }}  data-test-id="backBtn" >
                      <img src={this.props.checked? darkView:view}/>
                  </IconButton>
       </Avatar>
       
       <Typography style={webStyle.otpText} data-test-id='sendCode'>
       Reset Password
       </Typography>
       <Typography style={webStyle.verifyOtpText}>
       No problem, You can use the information associated with your account.
         
       </Typography>

       {(this.state.apiError && !this.state.otpError)&&<Box style={webStyle.errorBox}>
       <Typography
           style={webStyle.errorText}
           data-test-id="apiError">
           {this.state.apiError}
         </Typography>             
     </Box>}
     {this.state.otpError &&<Box style={webStyle.errorBox}>
       <Typography
           style={webStyle.errorText}
           data-test-id="apiError">
           {this.state.otpError} 
         </Typography>             
     </Box>}
       <Box
         component="form"
         onSubmit={this.verifyOtp}
         data-test-id="Otpform">
         <Box style={webStyle.inputContainer}>
           <Typography style={webStyle.label}>
           OTP
           </Typography>
            <CustomTextField 
             data-test-id="otp"
             value={this.state.otpInput}
             id="outlined-basic"
             variant="outlined"
             error={(this.state.otpError!==''|| this.state.apiError!=="")}
             className={this.state.otpInput?.length>0?"TextFieldUserName":""}
             style={(this.state.otpError!==''|| this.state.apiError!=="")? webStyle.textFieldOtp:TextField }
             placeholder={"Enter your OTP"}
             InputProps={{style:inputStyles.customInput}}
             autoComplete="off"
             onChange={this.handleChangeOtp}
           />
         </Box>
         <Typography style={webStyle.suggestionText} data-test-id="resendOtpBtn" onClick={()=>this.handleResendOtp()}>
         Resend OTP
         </Typography>
         <CustomButton
            data-test-id='next'
            disabled={this.state.isOtpButtonClickable}
            style={webStyle.button}
            fullWidth
            type="submit"
            endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}
          >
            Next
          </CustomButton>
       </Box>
     </Box>
 )
}
 // Customizable Area End
  render() {
    // Customizable Area Start
    const image = !this.props.checked? webStyle.imageBackground:webStyle.thinBackground
    return (
      <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}>
        <Loader loading={this.state.otpPageLoading}/>
      <Paper style={this.props.checked? webStyle.thinColor:webStyle.thickColor}>
      <Box style={ this.state.isXs?{...image,backgroundImage:"unset"}: image }>
      <Grid container style={webStyle.gridContainer}>
             <Grid item style={webStyle.imageGridItem} md={5} sm={6} lg={6}> 
             </Grid>
        <Grid item xs={10} sm={5} md={5} lg={this.state.changeScreen>=1?5:4} style={webStyle.gridItem}>
        {this.renderOtpScreen()}
        </Grid>
        </Grid>
     </Box>
     </Paper>
     {this.renderSnackBar()}
     </ThemeProvider>
    
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle={
  thinColor:{
    backgroundColor:'#fff'
  },
  thickColor:{
    backgroundColor:'#070707'
  },
  imageBackground:{
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition:'center',
    backgroundAttachment: "scroll",
    backgroundImage: `url(${dashboardBackground})`,
    height: "100vh",
    width: "100%",    
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'} 
  },
  thinBackground:{
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition:'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'},
    backgroundImage: `url(${lightBackground})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
     
  },
  gridContainer: {    
    [theme.breakpoints.down('xs')]: 
    {justifyContent: 'center' as 'center'} ,
    justifyContent: 'space-between' as'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop:"3rem", 
  },
  imageGridItem: {
    [theme.breakpoints.down("xs")]: { 
      display: "none !important" 
    },
    display: "flex",
    flexDirection: "column" as "column",    
    alignItems: "center",
    justifyContent: "center",
    
  },
  otpText: {   
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    fontFamily: "Manrope",
    fontSize: "32px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    WebkitTextFillColor: "transparent",
    marginTop:'28px',
    textAlign: "left" as "left",
    textShadow:
      "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
   
  },
  verifyOtpText: {
    fontWeight: 600,
    textAlign: "left" as "left",
    fontStyle: "normal" as 'normal',    
    fontFamily: "Manrope",
    fontSize: "16px",   
  },
  gridItem:{   
    justifyContent:'flex-start',
    display:'flex',
  },
  formBox:{
  width:'80%',
},
avatar: {
  cursor:'pointer',
  borderRadius: "8px",
  backgroundColor:'#222222', 
},
avatarLightTheme: {
  borderRadius: "8px", 
  cursor:'pointer',
  backgroundColor:'#E9E9E9',
  
},
backIcon:{  
  fontSize:'30px',
  color:'#fff',
},
backIconLightTheme:{
  fontSize:'30px',
  color:'#222222',
},
errorBox:{
  color: '#DC2626',
  fontFamily: 'Manrope',
  fontSize: "12px",
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '120%',
  marginBottom: "18px",
  marginTop:'22px',
  display: 'flex',
  padding: '0.7rem 0.7rem',
  alignItems: 'center',
  borderRadius: "0.625rem",
  borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
  background: 'linear-gradient(92deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0.00) 28.72%), rgba(33, 33, 33, 0.29)',
  backdropFilter: 'blur(24.5px)', 
},
errorText: {
  fontFamily: "Manrope",
  fontSize: "11px",
  fontStyle: "normal" as 'normal',
  fontWeight: 500,
  color: "#DC2626",
  
},
inputContainer: {
  marginTop: "10px",
},
label: {
  textAlign: "left" as "left",
  marginBottom:'6px',
  marginTop:'16px',
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as 'normal',
  fontWeight: 500,
  
},
textFieldOtp:{
  width: "100%",  
  "& .MuiOutlinedInput-input": {
    padding: "10px, 16px, 10px, 16px !important",
  },
  "& fieldset": {
    border: "none",
  },
  borderRadius: "10px !important",
  border: "1px solid #DC2626 !important",
  height: "50px",
  display: "flex",
  justifyContent: "center" as 'center',
  "& .MuiInputBase-input": {    
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    fontFamily: "Manrope",
    fontSize: "12px",
  },
  "& .MuiTypography-root":{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },  
  "&:hover": {
    "& fieldset": {
      border: "none",
    },
  },
  
},
textFieldUserName:{
  width: "100%",
  "& fieldset": {
    borderRadius:'10px',
  },
  "&:hover": {
    "& fieldset": {
      borderRadius:'10px',
    },
  },
  "& .MuiTypography-root":{
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
  },  
  borderRadius: "10px !important",
  display: "flex",
  justifyContent: "center" as 'center',  
  "& .MuiInputBase-input": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    border:'1px solid #6B6B6B !important',
    borderRadius:'10px !important'
    },
  "& .MuiOutlinedInput-input": {
    padding:'10px, 16px, 10px, 16px',
    
  },

},

textFieldUserNameColor:{
  border: "1px solid #8833FF !important",
  width: "100%",
  "& .fieldset": {
    borderRadius:'10px',
    border:"1px solid #8833FF !important"
  },
  "& .MuiTypography-root":{
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
  },  
  borderRadius: "10px !important",
  display: "flex",
  justifyContent: "center" as 'center',  
  "& .MuiInputBase-input": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal",
    borderColor:"#8833FF !important",
    fontWeight: 500,
    borderRadius:'10px !important'
    },
  "& .MuiOutlinedInput-input": {
    padding:'10px, 16px, 10px, 16px',
  },

},
suggestionText: {
  background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  marginTop: "10px",
  cursor:'pointer',
  textAlign: "left" as "left",
  textShadow:
    "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as'normal',
  fontWeight: 500,
  textDecoration:"underline",
  textDecorationColor:"rgba(142, 56, 227)"  
},  
button: {
  color: "#FFF",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  marginTop: '35px',
  height:'46px',
  borderRadius: "10px",
  background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
  textTransform: "capitalize" as "capitalize",  
},
number: {
  fontFamily: "Manrope",
  fontSize: "16px",
  fontStyle: "normal" as 'normal',
  background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "left" as "left",
  textShadow:
    "0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
 
},
}
// Customizable Area End
