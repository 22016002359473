// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles } from "@material-ui/core";
import PostFooterController, {
  Props,
} from "./PostFooterController.web";
import { commentIcon, favActive, favIcon, reverseIcon, saveActive, saveIcon, shareIcon } from "./assests";

export class PostFooter extends PostFooterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      checked,
      totalComments,
      repostCount,
      postId,
      onLikePost,
      isLiked,
      modelName,
      totalLikes,
      onSavePost,
      isSaved,
      showPostActivity,
      postedById,
      isComments,
      onClickComments,
    } = this.props;

    return (
      <Box 
        className={classes.postFooter}
        style={ isComments ? {borderBottom: "0.5px solid #222222", borderTop: "0.5px solid #222222", paddingTop: '15px'} : { borderBottom: checked ? "1px solid #c9c4c4" : "1px solid #3d3939" }}
      >
        <span 
          data-test-id="showCommentsBtn"
          className={classes.actionBtn}
          onClick={() => onClickComments(postId)}
        >
          <img src={commentIcon} alt="" />
          {totalComments}
        </span>
        <span className={classes.actionBtn}><img src={reverseIcon} alt="" /> {repostCount}</span>
        <span className={classes.actionBtn}
          data-test-id="likeBtn"
          onClick={() => onLikePost(postId, isLiked ? "unlike" : "like", modelName)}
          style={{ color: isLiked ? "red" : "" }}>
          <img src={isLiked ? favActive : favIcon} alt="" />{totalLikes}
        </span>
        <span className={classes.actionBtn}
        data-test-id="showActivityId"
         onClick={()=>showPostActivity&& showPostActivity(postId,postedById)}><img src={shareIcon} alt="" />0</span>
        <span className={classes.actionBtn}
          data-test-id="saveBtn"
          onClick={() => onSavePost(postId, isSaved ? "unsave" : "save")}
        >
          <img src={isSaved ? saveActive : saveIcon} alt="" />
        </span>
      </Box>
    )
  }
}

const styles = createStyles({
  postFooter: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "15px",
  },
  actionBtn: {
    display: "flex",
    alignItems: "center" as "center",
    gap: "5px",
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    cursor: "pointer",
    color: "#6B6B6B"
  },
});

export default withStyles(styles)(PostFooter);
// Customizable Area End