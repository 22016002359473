Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Hashtags";
exports.labelBodyText = "Hashtags Body";

exports.btnExampleTitle = "CLICK ME";

exports.hashtagApiEndPoint = "bx_block_hashtags/hashtags/search_hashtags";
exports.hashtagPostApiEndPoint = "bx_block_hashtags/hashtags/hashtag_posts";
exports.getMentionEndpoint='account_block/mentions';
exports.getHashEndpoint='bx_block_posts/tag_search';
exports.trending='Trending';

// Customizable Area End