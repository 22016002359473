import React from "react";

import { Container, Box, Button, Typography } from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import EventRegistrationController, {
  Props,
  configJSON,
  eventType,
} from "./EventRegistrationController";

export default class EventRegistration extends EventRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

 

  // Customizable Area Start
  // Customizable Area End

  render() {
    const event: eventType | null = this.state.event;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography
              variant="subtitle1"
              component="div"
              data-test-id="labelBody"
            >
              {configJSON.labelBodyText}
            </Typography>
            {event ? (
             
              <Box sx={webStyle.detailsContainer}>
                 <div style={webStyle.row}>
                <img
                  data-test-id="eventImage"
                  src={event && event.attributes.images[0]}
                  style={webStyle.imageBanner}
                />
                <div style={webStyle.column}>
                <div style={webStyle.row}>
                <Typography variant="h6" style={webStyle.eventName}>
                  {configJSON.eventNameLabel}
                </Typography>
                <Typography
                  variant="h6"
                  data-test-id="EventNameText"
                  style={webStyle.eventName}
                >
                  {event.attributes.event_name}
                </Typography>
                </div>
                <div style={webStyle.row}>
                <Typography variant="h6" style={webStyle.label1}>
                  {configJSON.locationLabel}
                </Typography>

                <Typography data-test-id="locationText" style={webStyle.text}>
                  {event.attributes.location}
                </Typography>
                </div>
                <div style={webStyle.row}>
                <Typography style={webStyle.label1}>
                  {configJSON.desciptionLabel}
                </Typography>

                <Typography
                  data-test-id="descriptionText"
                  style={webStyle.text}
                >
                  {event.attributes.description}
                </Typography>
                </div>
                <Box
                  data-test-id="registerEventButton"
                  onClick={this.registerEvent}
                  component="div"
                  sx={webStyle.buttonStyle}
                >
                  <Button color={"primary"}>
                    {configJSON.registerEventButton}
                  </Button>
                </Box>
                </div>
                </div>
              </Box>
             
            ) : (
              <></>
            )}
          </Box>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  row: {
    display: "flex",
    flexDirection: "row" as const
  },
  column: {
    display: "flex",
    flexDirection: "column" as const,
    marginLeft: "20%",
    marginTop: "18%"
  },
  detailsContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ededed",
    borderRadius: "10px",
    width: "100%",
  },
  imageBanner: {
    width: "25%",
    height: "20%",
    // aspectRatio: 16 / 9, 
  },
  eventName: {
    marginTop: "10px",
    fontSize: "18",
    fontWeight: "bold",
  },
  label: {
    marginTop: "10px",
    fontSize: 18,
    fontWeight: "bold",
  },
  label1: {
    marginTop: "10px",
    fontSize: 14,
    fontWeight: "bold",
    color: "#858585"
  },
  text: {
    marginTop: "10px",
    paddingLeft: "10px",
    fontSize: 14,
  },
  attributeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
};
// Customizable Area End
