import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import NetInfo from "@react-native-community/netinfo";
import { AppleLoginResponse } from "./types";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  testId: string;
  buttonTitle: string;
  callback: (response?: AppleLoginResponse) => void;
  onPress: () => void;
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNetConnected: boolean | null;
  userId: string;
  identityToken: string;
  email: string;
  firstName: string;
  lastName: string;
  appleProvider:{};
  appleData:{}|undefined;
  appleError:{}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppleLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  appleSignInApiCallId: string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.appleSignInApiCallId = "";
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isNetConnected: true,
      userId: "",
      identityToken: "",
      email: "",
      firstName: "",
      lastName: "",
      appleProvider:{},
      appleData:{},
      appleError:{}
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    NetInfo.addEventListener((state) => {
      this.setState({ isNetConnected: state.isConnected });
    });
    // Customizable Area End
  }

  // Customizable Area Start
  handleSignInWithApple = async () => {
    if (!this.state.isNetConnected) {
      this.noInternetConnection();
      return;
    }
  };

  noInternetConnection = () => {
    this.showAlert(configJSON.alertTitle, configJSON.noInternetAlertMessage);
  };

  appleSignIn = async () => {};

  handleAppleCatchError(errorCode: string) {}

  getAppleErrorMessage(errorCode: string) {}

  getAppleAuthResponse = async () => {};

  setUserDetailIfItHas = async () => {};

  isValidate = () => {};

  validateData() {}

  setAppleLoginData=( provider:{}, data:{}|undefined )=>{
   this.setState({appleProvider:provider,appleData:data})
  }
  
  setAppleLoginError=(error:{})=>{
  this.setState({appleError:error})
  }

  // Customizable Area End
}
