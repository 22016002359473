import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface Props {
  navigation: any;
  id: string;
  checked:boolean;
  classes?:{[key:string]:string}
}
interface S {
  renderAdditionalResources: string,
  selectedTab: number,
  name: string,
  phoneNumber: string,
  email: string,
  message: string,
  succMessage: string;
  messageType:string;
  validPhoneNumber:boolean,
  messageWordsLength: number,
  isLoading: boolean,
  selectedFAQGroup: number,
  FAQGroupList: Array<any>,
  FAQList: Array<any>;
  filteredFAQList: Array<any>;
  searchedFaq:string;
  selectedFAQItem: number;
  countryCode:string;
  countryCodeSelected: string;
  errors:{nameError:string,phoneNumberError:string,dobError:string,emailError:string};
  apiError:string;
}
interface SS {}

export default class AdditionalResourcesController extends BlockComponent<Props, S, SS>{

  FAQGroupsId: string = "";
  FAQsId: string = "";
  contactsId: string = "";

  constructor(props: Props){
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    
    this.state = {
      renderAdditionalResources: "Additional Resources",
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
      succMessage:"",
      messageType:"",
      validPhoneNumber:false,
      messageWordsLength:0,
      selectedTab: 0,
      isLoading: false,
      selectedFAQGroup:0,
      FAQGroupList:[],
      FAQList:[],
      filteredFAQList:[],
      searchedFaq: "",
      selectedFAQItem:0,
      countryCode:"91",
      countryCodeSelected: "",
      errors:{nameError:'',phoneNumberError:'',dobError:'',emailError:''},
      apiError:"string",

    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.FAQGroupsId) {
          this.setState({FAQGroupList: responseJson.data});
          this.getFAQList(responseJson.data[0]['attributes']['name'], 0);
        }
        if(apiRequestCallId === this.FAQsId){
          this.setState({FAQList:responseJson.data, isLoading:false});
        }
        if(apiRequestCallId === this.contactsId){
          this.setState({isLoading:false});
          this.setMessage("suc", "Your message has been sent successfully.");
          setTimeout(() => {
            this.setState({messageType: "", succMessage: "", name:'', email:'', phoneNumber:'', message:'', messageWordsLength: 0, validPhoneNumber:false});
          }, 2000)
        }
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setMessage = (type:string, msg:string) => {
    this.setState({
      messageType: type,
      succMessage: msg
    })
  }

  getDataHelpCenter = () => {
    this.setState({renderAdditionalResources:'Help Center', isLoading:true});
    this.FAQGroupsId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "bx_block_contact_us/faq_groups"
    })
  }

  getFAQList = (query :string, groupId: number) => {
    this.setState({selectedFAQGroup:groupId, isLoading:true, selectedFAQItem:0});
    this.FAQsId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: `bx_block_contact_us/faqs?faq_group_id=${groupId+1}`
    })
  }

  changeTab = (selected: number) => {
    this.setState({selectedTab:selected});
  }

  handleFAQSearch = (value:string) => {
    this.setState({searchedFaq:value});
    this.setState({filteredFAQList:this.state.FAQList.filter(item => item.attributes.question.toLowerCase().includes(value.toLowerCase()) || item.attributes.answer.toLowerCase().includes(value.toLowerCase()))});
  }

  expandQuestionAndAns = (index:number) => {
    if(index === this.state.selectedFAQItem){
      this.setState({selectedFAQItem:-1})
    } else{
      this.setState({selectedFAQItem:index})
    }
  }

  changePhoneNumber = (phone:string) => {
    this.setState({phoneNumber:phone})
    if(configJSON.phoneNumberRegex.test(phone)){
      this.setState({validPhoneNumber:true});
    } else{
      this.setState({validPhoneNumber:false});
    }
  }

  changeMessage = (text:string) => {
    const words = text.match(configJSON.wordRegex) || [];
    this.setState({messageWordsLength: words.length});
    if(words.length <= 99){
      this.setState({message:text});
    }
  }

  contactUsSubmit = () => {
    if(this.state.validPhoneNumber){
      this.setState({isLoading:true});
      this.contactsId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPostMethod,
        endPoint:'bx_block_contact_us/contacts',
        body:{
        "name":this.state.name,
        "email":this.state.email,
        "full_phone_number":`+91${this.state.phoneNumber}`,
        "description":this.state.message
        }
      })
    }
  }

}
