import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ErrorInfo } from "react";
export const configJSON = require("./config");

export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface MockEventType {
  target: {
    checked: boolean;
  };
}

export interface Props {
  navigation: any;
  id: string;
  checked?:boolean;
}
interface S {
  renderAccessibility:string,
  mediaPreviewSwitch:boolean,
  soundEffectSwitch:boolean,
  inAppBrowserSwitch:boolean,
  screenReaderSwitch:boolean,
  reduceMotionSwitch:boolean,
  imgDescReminderSwitch:boolean,
  topToSearchSwitch:boolean,
  dataSaverSwitch:boolean,
  syncDataSwitch:boolean,
  renderModelName:string,
  imageQuality:string,
  imageUploadQuality:string,
  videoQuality:string,
  videoUploadQuality:string,
  videoAutoPlay:string,
  selectedThemeName:string,
  syncInterval:string,
  selectedQualityModelOption:number,
  dataUseOptionList: string[],
  intervalOptionList: string[];
  themeOptionList: string[];
  optionsList: string[];
  messageType:string;
  message:string;
  isLoading:boolean;
}
interface SS {
  id: any;
}

export default class AccessibilityAndDisplayController extends BlockComponent<Props, S, SS>{

  displayAndSoundDefaultApiId: string = "";
  dataUsagesDefaultApiId:string = "";
  displayAndSoundApiId:string = "";
  dataUsagesApiId:string = "";

  constructor(props: Props){
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // window.accessibilityController = this;

    this.state = {
      renderAccessibility: 'Accessibility and display',
      mediaPreviewSwitch:false,
      soundEffectSwitch:false,
      inAppBrowserSwitch:false,
      screenReaderSwitch:false,
      reduceMotionSwitch:false,
      imgDescReminderSwitch:false,
      topToSearchSwitch:false,
      dataSaverSwitch:false,
      syncDataSwitch:false,
      renderModelName: '',
      imageQuality:'Mobile data & Wi-Fi',
      imageUploadQuality:'Mobile data & Wi-Fi',
      videoQuality:'Mobile data & Wi-Fi',
      videoUploadQuality:'Mobile data & Wi-Fi',
      videoAutoPlay:'Mobile data & Wi-Fi',
      selectedThemeName:'Light Mode',
      syncInterval:'15 minutes',
      dataUseOptionList: ['Mobile data & Wi-Fi', 'Wi-Fi only', 'Never'],
      intervalOptionList: ['15 minutes', '30 minutes', '1 Hours', '2 Hours', '4 Hours', 'Daily'],
      themeOptionList: ['Light Mode', 'Dark Mode', 'Use device settings'],
      optionsList: [],
      selectedQualityModelOption: 0,
      messageType:"",
      message:"",
      isLoading:false,
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();

    this.displayAndSoundDefaultApiId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: 'bx_block_settings/settings/show_default_displaysound_settings',
    })

    this.dataUsagesDefaultApiId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: 'bx_block_settings/settings/show_data_usage_settings',
    })

    window.addEventListener("localStorageThemeChanged", this.handleStorageChange);

    let themeJson = localStorage.getItem('theme')
    console.log(themeJson);
    
    if(themeJson && JSON.parse(themeJson)){
      this.setState({selectedThemeName:'Light Mode'});
    } else{
      this.setState({selectedThemeName:'Dark Mode'});
    }
  }

  handleStorageChange = () => {
  };
  
  async componentWillUnmount() {
    window.removeEventListener("localStorageChanged", this.handleStorageChange);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if(apiRequestCallId === this.displayAndSoundDefaultApiId){
          if(responseJson.settings){
            const data = responseJson.settings
            let theme = '';
            if(data.display === 'use_device_settings'){
              theme = 'Use device settings'
            } else {theme = data.display.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            this.setState({
              mediaPreviewSwitch: data.media_previews,
              selectedThemeName: theme,
              soundEffectSwitch: data.sound_effects,
              inAppBrowserSwitch: data.use_in_app_browser,
            })
          }
        }
        else if(apiRequestCallId === this.dataUsagesDefaultApiId){
          if(responseJson.settings){
            const data = responseJson.settings.data.attributes
            const syncMap: any = {'minutes_15': '15 minutes', 'minutes_30': '30 minutes', 'hour_1': '1 Hours', 'hour_2': '2 Hours', 'hour_4': '4 Hours', 'daily':'Daily'};
            this.setState({
              dataSaverSwitch: data.data_saver,
              imageQuality: data.high_quality_images,
              imageUploadQuality: data.high_quality_image_uploads,
              videoQuality: data.high_quality_video,
              videoUploadQuality: data.high_quality_video_upload,
              videoAutoPlay: data.video_autoplay,
              syncDataSwitch: data.sync_data,
              syncInterval: syncMap[data.sync_interval]
            })
          }
        }
        else if(apiRequestCallId === this.displayAndSoundApiId){
          if(responseJson.error){
            this.displayAndSoundDefaultApiId = this.apiCall({
              contentType: configJSON.ApiContentTypeJson,
              method: configJSON.apiGetMethod,
              endPoint: 'bx_block_settings/settings/show_default_displaysound_settings',
            })
          }
          console.log("Media perview updated successfully");
        } else if (apiRequestCallId === this.dataUsagesApiId){
          if(responseJson.error){
            this.dataUsagesDefaultApiId = this.apiCall({
              contentType: configJSON.ApiContentTypeJson,
              method: configJSON.apiGetMethod,
              endPoint: 'bx_block_settings/settings/show_data_usage_settings',
            })
          }
        }
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
    // Customizable Area End
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  displayAndsounfApiMiddle = (payload: {}) => {
    this.displayAndSoundApiId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPatchMethod,
      endPoint: 'bx_block_settings/settings/update_display_and_sound_settings',
      body:{
        "display_and_sound_settings": payload
      }
    })
  }

  dataUsagesApiMiddle = (payload: {}) => {
    this.dataUsagesApiId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPatchMethod,
      endPoint: 'bx_block_settings/settings/update_data_usage_settings',
      body: {
        "data_usage_settings": payload
      },
    })
  }

  onMediaPreviewChange = () => {
    var payload = { "media_previews": !this.state.mediaPreviewSwitch }
    this.displayAndsounfApiMiddle(payload);
    this.setState({mediaPreviewSwitch : !this.state.mediaPreviewSwitch})
  }

  onSoundEffectChange = () => {
    var payload = { "sound_effects": !this.state.soundEffectSwitch }
    this.displayAndsounfApiMiddle(payload);
    this.setState({soundEffectSwitch : !this.state.soundEffectSwitch})
  }

  onBrowserChange = () => {
    var payload = { "use_in_app_browser": !this.state.inAppBrowserSwitch }
    this.displayAndsounfApiMiddle(payload);
    this.setState({inAppBrowserSwitch : !this.state.inAppBrowserSwitch})
  }

  onDataSaverChange = () => {
    var payload = { "data_saver": !this.state.dataSaverSwitch }
    this.dataUsagesApiMiddle(payload);
    this.setState({dataSaverSwitch: !this.state.dataSaverSwitch});
  }

  onSyncDataChange = () => {
    var payload = { "sync_data": !this.state.syncDataSwitch }
    this.dataUsagesApiMiddle(payload);
    this.setState({syncDataSwitch: !this.state.syncDataSwitch});
  }

  onModalOpen = (name:string) => {
    if (name === 'High quality image'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.dataUseOptionList.indexOf(this.state.imageQuality), optionsList: this.state.dataUseOptionList});
    }
    else if (name === 'High-quality image uploads'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.dataUseOptionList.indexOf(this.state.imageUploadQuality), optionsList: this.state.dataUseOptionList});
    }
    else if (name === 'High quality video'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.dataUseOptionList.indexOf(this.state.videoQuality), optionsList: this.state.dataUseOptionList});
    }
    else if (name === 'High-quality video uploads'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.dataUseOptionList.indexOf(this.state.videoUploadQuality), optionsList: this.state.dataUseOptionList});
    }
    else if (name === 'Video autoplay'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.dataUseOptionList.indexOf(this.state.videoAutoPlay), optionsList: this.state.dataUseOptionList});
    }
    else if (name === 'Sync interval'){
      this.setState({renderModelName: name, selectedQualityModelOption: this.state.intervalOptionList.indexOf(this.state.syncInterval), optionsList: this.state.intervalOptionList});
    }
  }

  onModalClose = () => {
    var payload = {};
    if (this.state.renderModelName === 'High quality image'){
      this.setState({imageQuality: this.state.dataUseOptionList[this.state.selectedQualityModelOption]});
      payload = { "high_quality_images": this.state.dataUseOptionList[this.state.selectedQualityModelOption] }
    }
    else if (this.state.renderModelName === 'High-quality image uploads'){
      this.setState({imageUploadQuality: this.state.dataUseOptionList[this.state.selectedQualityModelOption]});
      payload = { "high_quality_image_uploads": this.state.dataUseOptionList[this.state.selectedQualityModelOption] }
    }
    else if (this.state.renderModelName === 'High quality video'){
      this.setState({videoQuality: this.state.dataUseOptionList[this.state.selectedQualityModelOption]});
      payload = { "high_quality_video": this.state.dataUseOptionList[this.state.selectedQualityModelOption] }
      
    }
    else if (this.state.renderModelName === 'High-quality video uploads'){
      this.setState({videoUploadQuality: this.state.dataUseOptionList[this.state.selectedQualityModelOption]});
      payload = { "high_quality_video_upload": this.state.dataUseOptionList[this.state.selectedQualityModelOption] }
      
    }
    else if (this.state.renderModelName === 'Video autoplay'){
      this.setState({videoAutoPlay: this.state.dataUseOptionList[this.state.selectedQualityModelOption]});
      payload = { "video_autoplay": this.state.dataUseOptionList[this.state.selectedQualityModelOption] }
      
    }
    else if (this.state.renderModelName === 'Sync interval'){
      this.setState({syncInterval:this.state.intervalOptionList[this.state.selectedQualityModelOption]});
      const syncMap: any = {'15 minutes': 'minutes_15', '30 minutes': 'minutes_30', '1 Hours': 'hour_1', '2 Hours': 'hour_2', '4 Hours': 'hour_4', 'Daily': 'daily'};
      payload = { "sync_interval": syncMap[this.state.intervalOptionList[this.state.selectedQualityModelOption]] }
    }
    
    this.dataUsagesApiMiddle(payload);
    this.setState({renderModelName:'', optionsList: []})
  }

  onThemeModalOpen = () => {
    this.setState({renderModelName:'Theme', selectedQualityModelOption: this.state.themeOptionList.indexOf(this.state.selectedThemeName)});

  }

  onThemeChange = (name: string, index: number) => {
    this.setState({selectedQualityModelOption:index, selectedThemeName: name});
    if(index === 0) {
      localStorage.setItem('theme',JSON.stringify(true));
    } else if(index === 1){
      localStorage.setItem('theme',JSON.stringify(false));
    } else{
      this.useDeviceTheme();
    }
    window.dispatchEvent(new Event("localStorageThemeChanged"));

    var payload = { "display": name.toLocaleLowerCase().split(' ').join("_") }
    this.displayAndsounfApiMiddle(payload);

  }

  useDeviceTheme = () => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkModeMediaQuery.matches) {
      localStorage.setItem('theme',JSON.stringify(false));
    } else {
      localStorage.setItem('theme', JSON.stringify(true));
    }
  }
}