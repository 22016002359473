import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
import React, { ReactNode } from "react";
import { debounce } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string}
  children?:ReactNode
  checked:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabPanelNo: number;
  errorMsg: string;
  token: string;
  isModel:boolean;
  followers:any
  followMessage:string,
  searchFollowers:string,
  page:number
  pagination:any
  userDetail:{followersCount:number,followingCount:number}
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ActivityFollowersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  followerCallId: string = "";
  unFallowCallId:string ="";
  followCallId:string =""
  getLoggedUserApiCallId:string =""
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabPanelNo: 0,
      errorMsg: "",
      token: "",
      pagination:{},
      isModel:true,
      followers :[],
      followMessage:'',
      searchFollowers:'',
      page:1,
      userDetail:{followersCount:0,followingCount:0}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    await this.getToken();
    setTimeout(async () => {
      await this.loggedUser()
      await this.getListOfFollowers("followers");
    }, 0);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiResponse.errors){
      this.setState({ errorMsg: apiResponse.errors });
      return
    }   
  
    this.handleResponseForFollowers(from, message);
    this.followUserApi(from, message);
    this.handleLoggedUser(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = async () => {
    const token: string =
      Platform.OS == "android" || Platform.OS == "ios"
        ? await getStorageData("token-access")
        : localStorage.getItem("authToken");
    this.setState({ token: token });
  };

  getListOfFollowers = async (type:string) => {
    const token = this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followerCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type== "followers" ?
       configJSON.getFollowersEndPoint + `?_page=${this.state.page}&per_page=10&query=${this.state.searchFollowers}&sort_order=desc`:
       configJSON.getFollowingEndPoint + `?_page=${this.state.page}&per_page=10&query=${this.state.searchFollowers}&sort_order=desc`
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  loggedUser = async() =>{
    const token =  this.state.token;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLoggedUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedUserEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleSearchFollowers = debounce((event:any)=> {    
    this.setState({searchFollowers:event.target.value,page:1,followers:[]},()=>{
      this.state.tabPanelNo==0?this.getListOfFollowers("followers"):this.getListOfFollowers("following")
    });
  },300)

  handleResponseForFollowers = (from: string, message: Message) => {
    if (
      this.followerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!apiResponse.errors) {
        this.setState({ followers: [...this.state.followers,...apiResponse.data],page:this.state.page+1,pagination:apiResponse.pagination_details });
      }
    }
  };

  hanleTabs = (event: object, value: number) => {
    this.setState({ tabPanelNo: value ,page:1,followers:[]},()=>{
      this.state.tabPanelNo==0?this.getListOfFollowers("followers"):this.getListOfFollowers("following")
    });
  };

  handleFollow =(id:string) =>{
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    let httpBody = {
      account_id:id
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followApiEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
  }

  handleFollowing=(userId:number)=>{
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    let httpBody = {
      account_id:userId
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfollowUserEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
     

  }

  handlelClose =() =>{
    this.setState({isModel:false})
  }

  followUserApi=(from: string, message: Message)=>{
    if (
      this.followCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!apiResponse.errors) {
        let followers = [...this.state.followers]  
        let newFollowers =[]
        if(this.state.tabPanelNo==0){    
         newFollowers = followers.map((item) =>
          item.id == apiResponse.data.id
            ? { ...item, attributes: { ...item.attributes, is_following: apiResponse.data.attributes.is_following } }
            : item
        )
        this.setState({followers:newFollowers}) 
      }
        else{
         newFollowers = followers.filter((item) => item.id !== apiResponse.data.id);
         this.setState({followers:newFollowers,userDetail:{...this.state.userDetail,followingCount:this.state.userDetail.followingCount-1}}) 
        }     
        
      }
    }
  }

  handleLoggedUser =(from: string, message: Message)=>{
    if (
      this.getLoggedUserApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!apiResponse.errors) {
        let user_details ={followersCount:apiResponse.data.attributes.followers_count,followingCount:apiResponse.data.attributes.following_count}
        this.setState({ userDetail: user_details });
      }
    }
  }
  // Customizable Area End
}
