export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const TwitterIcon = require("../assets/image_.svg");
export const FacebookIcon = require("../assets/image_(1).svg");
export const InstagramIcon = require("../assets/Symbol.svg");
export const logo = require("../assets/logo.svg");
export const Profile = require("../assets/profile.png");
export const verifyIcon = require("../assets/verifyIcon.svg");
export const TwitterIconLight = require("../assets/twitterLight.svg");;
export const searchDark = require("../assets/searchDark.svg");
export const searchLight = require("../assets/searchLight.svg");
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const leftArrowDark = require("../assets/leftArrowDark.png");
export const leftArrow = require("../assets/leftArrorw.png");
export const defualtUserAvatar = require("../assets/user_1.png");
export const RightIcon = require("../assets/RightIcon.svg");


