// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Generativegifcreationforpost from "../../blocks/generativegifcreationforpost/src/Generativegifcreationforpost";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import {ActivityFeeds} from "../../blocks/activityfeed/src/ActivityFeed.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Membershipbuyin from "../../blocks/membershipbuyin/src/Membershipbuyin";
import Share from "../../blocks/share/src/Share";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOtp.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import {Subscriptions} from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Multitieredpricing from "../../blocks/multitieredpricing/src/Multitieredpricing";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import {LandingPageWithStyle} from "../../blocks/splashscreen/src/Splashscreen.web";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emaildeliverability from "../../blocks/emaildeliverability/src/Emaildeliverability";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import {WebNotifications} from "../../blocks/notifications/src/Notifications.web";
import Repost from "../../blocks/repost/src/Repost";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import {AccountCreation} from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Emaillists2 from "../../blocks/emaillists2/src/Emaillists2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Integration3rdpartyapiforissuingsharesegcarta from "../../blocks/integration3rdpartyapiforissuingsharesegcarta/src/Integration3rdpartyapiforissuingsharesegcarta";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import CallRecording from "../../blocks/call-recording/src/CallRecording";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu, {StyledNavigationMenu} from "../../blocks/navigationmenu/src/NavigationMenu.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Generateimagecreationforcontent from "../../blocks/generateimagecreationforcontent/src/Generateimagecreationforcontent";
import PostCreation from "../../blocks/postcreation/src/PostCreation.web";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Trending from "../../blocks/trending/src/Trending";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Chat from "../../blocks/chat/src/Chat.web";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import {SocialFollowers} from "../../blocks/followers/src/Followers";
import ActivityFollowers from "../../blocks/followers/src/ActivityFollowers.web";
import {verifyOtp} from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import MixpanelIntegration from "../../blocks/mixpanelintegration/src/MixpanelIntegration";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import {CategoriesScreen} from '../../blocks/otp-input-confirmation/src/Categories.web'
import {BecomeOwnerScreen} from '../../blocks/customisableusersubscriptions/src/BecomeOwner.web'
import {UserProfile} from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import Profile  from "../../blocks/customisableuserprofiles/src/Profile.web"
import AppleLogin from "../../blocks/applelogin/src/AppleLogin";


const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
SettingsNotification:{
  component:Settings2,
 path:"/SettingsNotification"},
// BulkUploading:{
//  component:BulkUploading,
// path:"/BulkUploading"},
// Generativegifcreationforpost:{
//  component:Generativegifcreationforpost,
// path:"/Generativegifcreationforpost"},
// StripePayments:{
//  component:StripePayments,
// path:"/StripePayments"},
ActivityFeed:{
 component:ActivityFeeds,
 path:"/ActivityFeed",
 toggle:"OFF"
},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
// Membershipbuyin:{
//  component:Membershipbuyin,
// path:"/Membershipbuyin"},
// Share:{
//  component:Share,
// path:"/Share"},
// Avatar:{
//  component:Avatar,
// path:"/Avatar"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
AppleLogin:{
  component:AppleLogin,
 path:"/AppleLogin"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
// RecommendationEngine:{
//  component:RecommendationEngine,
// path:"/RecommendationEngine"},
Customisableusersubscriptions:{
 component:Subscriptions,
path:"/Customisableusersubscriptions"},
// SubscriptionDetails:{
//  component:SubscriptionDetails,
// path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
// TermsConditionsUsers:{
//  component:TermsConditionsUsers,
// path:"/TermsConditionsUsers"},
// Videos:{
//  component:Videos,
// path:"/Videos"},
// InvoiceBilling:{
//  component:InvoiceBilling,
// path:"/InvoiceBilling"},
// Catalogue:{
//  component:Catalogue,
// path:"/Catalogue"},
// Emailnotifications2:{
//  component:Emailnotifications2,
// path:"/Emailnotifications2"},
// Multitieredpricing:{
//  component:Multitieredpricing,
// path:"/Multitieredpricing"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
// OfflineBrowsing:{
//  component:OfflineBrowsing,
// path:"/OfflineBrowsing"},
// CountryCodeSelector:{
//  component:CountryCodeSelector,
// path:"/CountryCodeSelector"},
// PasswordStrength:{
//  component:PasswordStrength,
// path:"/PasswordStrength"},
// DailyScheduleNotifications:{
//  component:DailyScheduleNotifications,
// path:"/DailyScheduleNotifications"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
// EventRegistration:{
//  component:EventRegistration,
// path:"/EventRegistration"},
// SaveAsPdf:{
//  component:SaveAsPdf,
// path:"/SaveAsPdf"},
// PhoneNumberInput:{
//  component:PhoneNumberInput,
// path:"/PhoneNumberInput"},
// AdditionalDetailForm:{
//  component:AdditionalDetailForm,
// path:"/AdditionalDetailForm"},
Settings:{
  component: Settings2,
  path:"/Settings",
  toggle: "OFF"
},
SettingsProfile:{
  component: Settings2,
  path:"/SettingsProfile",
  exact: true,
  toggle: "OFF"
},
SettingsSecurity:{
  component: Settings2,
  path:"/SettingsSecurity",
  exact: true
},
SettingsAccessibility:{
  component: Settings2,
  path:"/SettingsAccessibility",
  exact: true
},
SettingsResources:{
  component: Settings2,
  path:"/SettingsResources",
  exact: true
},
SettingsSecurity:{
  component: Settings2,
  path:"/SettingsSecurity",
  exact: true
},
SettingsProfileByType:{
  component: Settings2,
  path:"/SettingsProfile/:settingType",
},
Settings2:{
 component:Settings2,
path:"/Settings2"},
PrivacySafety:{
  component: Settings2,
  path:"/privacySafety",
  toggle: "OFF"
},
// Hashtags:{
//  component:Hashtags,
// path:"/Hashtags"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
// Notificationsettings:{
//  component:Notificationsettings,
// path:"/Notificationsettings"},
// DesktopNotifications:{
//  component:DesktopNotifications,
// path:"/DesktopNotifications"},
// Comment:{
//  component:Comment,
// path:"/Comment"},
// CreateComment:{
//  component:CreateComment,
// path:"/CreateComment"},
// Dataencryption2:{
//  component:Dataencryption2,
// path:"/Dataencryption2"},
// VideoLibrary:{
//  component:VideoLibrary,
// path:"/VideoLibrary"},
// Pushnotifications:{
//  component:Pushnotifications,
// path:"/Pushnotifications"},
// Contactus:{
//  component:Contactus,
// path:"/Contactus"},
// AddContactus:{
//  component:AddContactus,
// path:"/AddContactus"},
// Emaildeliverability:{
//  component:Emaildeliverability,
// path:"/Emaildeliverability"},
// Subscriptionbilling2:{
//  component:Subscriptionbilling2,
// path:"/Subscriptionbilling2"},
Notifications:{
 component:WebNotifications,
path:"/Notifications",
toggle: "OFF"
},
// Repost:{
//  component:Repost,
// path:"/Repost"},
// PdfEdit:{
//  component:PdfEdit,
// path:"/PdfEdit"},
// Paymentadmin2:{
//  component:Paymentadmin2,
// path:"/Paymentadmin2"},
// MobileAccountLoginBlock:{
//  component:MobileAccountLoginBlock,
// path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:AccountCreation,
path:"/EmailAccountRegistration"},
// Emaillists2:{
//  component:Emaillists2,
// path:"/Emaillists2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
// Appointments:{
//  component:Appointments,
// path:"/Appointments"},
// AddAppointment:{
//  component:AddAppointment,
// path:"/AddAppointment"},
// Integration3rdpartyapiforissuingsharesegcarta:{
//  component:Integration3rdpartyapiforissuingsharesegcarta,
// path:"/Integration3rdpartyapiforissuingsharesegcarta"},
// Adminconsole2:{
//  component:Adminconsole2,
// path:"/Adminconsole2"},
// ShoppingCartOrders:{
//  component:ShoppingCartOrders,
// path:"/ShoppingCartOrders"},
// AddShoppingCartOrderItem:{
//  component:AddShoppingCartOrderItem,
// path:"/AddShoppingCartOrderItem"},
// Location:{
//  component:Location,
// path:"/Location"},
// Analytics:{
//  component:Analytics,
// path:"/Analytics"},
// CallRecording:{
//  component:CallRecording,
// path:"/CallRecording"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
// CameraAccess:{
//  component:CameraAccess,
// path:"/CameraAccess"},
// Blockedusers:{
//  component:Blockedusers,
// path:"/Blockedusers"},
// AddBlockeduser:{
//  component:AddBlockeduser,
// path:"/AddBlockeduser"},
// AdvancedSearch:{
//  component:AdvancedSearch,
// path:"/AdvancedSearch"},
// Generateimagecreationforcontent:{
//  component:Generateimagecreationforcontent,
// path:"/Generateimagecreationforcontent"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
// CarouselDisplay:{
//  component:CarouselDisplay,
// path:"/CarouselDisplay"},
// Trending:{
//  component:Trending,
// path:"/Trending"},
// Rolesandpermissions2:{
//  component:Rolesandpermissions2,
// path:"/Rolesandpermissions2"},
// Maps:{
//  component:Maps,
// path:"/Maps"},
// AutomaticRenewals:{
//  component:AutomaticRenewals,
// path:"/AutomaticRenewals"},
// GroupVideoCall:{
//  component:GroupVideoCall,
// path:"/GroupVideoCall"},
Chat:{
 component:Chat,
path:"/Chat",
toggle:"OFF"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
// Scheduling:{
//  component:Scheduling,
// path:"/Scheduling"},
// OrderManagement:{
//  component:OrderManagement,
// path:"/OrderManagement"},
Followers:{
 component:SocialFollowers,
path:"/Followers"},
ActivityFollowers:{
  component:ActivityFollowers,
 path:"/ActivityFollowers"},
OTPInputAuth:{
 component:verifyOtp,
path:"/OTPInputAuth"},
Categories:{
  component:CategoriesScreen,
  path:"/Categories"
},
BecomeOwner:{
  component:BecomeOwnerScreen,
  path:"/BecomeOwner"
},
// MixpanelIntegration:{
//  component:MixpanelIntegration,
// path:"/MixpanelIntegration"},
// Likeapost2:{
//  component:Likeapost2,
// path:"/Likeapost2"},

  Home: {
    component: LandingPageWithStyle,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  CustomisableUserProfiles:{
    component:UserProfile,
   path:"/CustomisableUserProfiles"},

   Profile:{
    component: Profile,
    path:"/Profile/:id"
  } 

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw',overflowX:"hidden" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer />
      </View>
    );
  }
}

export default App;