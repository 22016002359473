import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  InputAdornment,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { styled } from "@material-ui/styles"
import { arrowRightRed, backArrowWhite, backArrowDark, arrowWhite,arrowDark, accountSettingsList, arrowRight24White, accountInformationList, logoutIcon, sucIcon, calenderDark, calenderWhite, calender20White, calender20Dark} from "./assets";
import { Formik, Form, Field } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const inputStyles={  
     
    darkBackdrop:{
       backgroundColor:'#0E0E0E',
       opacity:0.8
    },
     fade:{
        border:'1px solid #5B5B5B',
        borderRadius:'10px',
        display: 'flex'
    },
    lightBackdrop:{
      backgroundColor:'#FFFFFF',
      opacity:0.9
    },
    logoutButtonContainer: {
        display: "flex",
        gap: "12px",
    },
    logoutCancelButton: {
        width: "160px",
        height: "48px",
        padding: "18px 16px 18px 16px",
        gap: "10px",
        borderRadius: "10px",
        border: "1px solid"
    },
    logoutYesButton:{
        width: "160px",
        height: "48px",
        padding: "18px 16px 18px 16px",
        gap: "10px",
        borderRadius: "10px",
        background: "rgba(255, 77, 103, 1)"
    },
    iconButton:{
        color: "#6B6B6B",
    }
}
// Customizable Area End

import AccountSettingsController, {
  Props,  
  IUserData
} from "./AccountSettingsController.web";
import Loader from "../../../components/src/Loader.web";
import AccountUpdateForm from "./UserUpdateForm.web";

export default class AccountSettings extends AccountSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSubCat = () => {
    const {checked} = this.props
    return(
        <div className="subCategory">
            <Link to="/settings" style={{ textDecoration: 'none' }}>
                <Button  className="backButton pl16" variant="text">
                    <span><img src={checked ? backArrowDark : backArrowWhite} /></span>
                    <div className="textAlignInitial">
                        <span>Your Account</span>
                        <p>See information about your account, download an archive of your data, or learn about your account deactivation options.</p>
                    </div>
                </Button>
            </Link>
            <List className={"list"}>
                {accountSettingsList.map((item, index: number) =>
                    (<ListItem alignItems="center"
                        className={"listItem"}
                        button
                        data-test-id={`subcat-${index}`}
                        key={`setingsCat${index}`} 
                        onClick={()=>this.changeAccountSettingsRenderType(item.path)}
                        >
                        <ListItemIcon className={"subCatIconContainer"}>
                            <img src={checked ? item.darkIcon : item.whiteIcon} className={"iconImage"} />
                            </ListItemIcon>
                            <ListItemText className="subcatTitleContainer" primary={item.name} secondary={item.description} />
                            <ListItemIcon className={"justify-content-center"}>
                            <img src={checked ? arrowDark : arrowWhite} className={"iconImage"} />
                        </ListItemIcon>
                    </ListItem>))
                }         
            </List>
        </div>       
    )
  }

  verifyWithPassword = () => {
    
    return(
        <React.Fragment>
            {this.renderBackButton()}
            <div className="verificationContainer">
                
                <p>Re-enter your Arrows password to continue.</p>   
                {this.state.pasError !== "" && <div className="pasErrorContainer " > 
                {this.state.pasError}
                </div> }          
                <Formik
                    initialValues={{             
                        password: "",
                    }}
                    onSubmit={this.validatePassword}
                    data-test-id="validate-fromik-form"
                    validate={this.onVerifyPasswordChang}
                >
                    {(formikProps) => {
                    const { values} = formikProps;
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="fieldContainer">
                                <label htmlFor="passwordInput"> New Password</label>
                                <Field
                                    component={FormikInputField}
                                    id="passwordInput"
                                    name="password"
                                    value={values.password}                                   
                                    placeholder="At least 8 characters"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    InputLabelProps={{
                                        className: "13",
                                    }}
                                    className = "passField"
                                />
                            </div>
                            <Button  data-test-id="verify-password-submit" className="submitButton" type="submit">
                                <p className="label">Verify</p> 
                                <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
                            </Button>
                        </Form>
                        );
                    }}
                </Formik>
            </div>
        </React.Fragment>

    )
  }

  renderBackButton = () => {
    const {checked} = this.props
    return (
        <Button data-test-id="backButtonArrow" onClick={this.onClickBackButton} className="backButton" variant="text">
            <span className="icon"><img src={checked ? backArrowDark : backArrowWhite} /></span>
            {this.renderBackButtonTitle()}
        </Button>
    )
  }

  renderLogoutModal = () => {
    const isVerifyPassword = this.state.renderAccountSettingsType === "accountInformation"
    const modalHeading = isVerifyPassword ? "Logout" : "Deactivate account";
    const modalDescription = isVerifyPassword ? "Are you sure you want to log out?" : "Are you sure you want to Deactivate your account?";
    const buttonLabel = isVerifyPassword ? "Yes, Logout" : "Yes, Deactivate Now"
    return(
        <Modal           
            className={`logoutModal  dark-${!this.props.checked} ${!isVerifyPassword ? "deactivateModal": ""}`}
            open={this.state.logoutModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
          >
            <Fade 
                in={this.state.logoutModal} 
                style={inputStyles.fade}
            >
               <div className={`logoutContainer dark-${!this.props.checked}`}>
                    <h6 className="arrowFontFamily font18 m0">{modalHeading}</h6>
                    <p className="arrowFontFamily font16 m0">{modalDescription}</p>
                    <div style={inputStyles.logoutButtonContainer}>
                        <Button className="cancelButton" onClick={this.onCloseLogout} style={inputStyles.logoutCancelButton} >
                            Cancel
                        </Button>
                        <Button data-test-id="logout-confirm-modal" className="confirmButton" onClick={this.onLogout} style={inputStyles.logoutYesButton} variant="text">                                  
                            {buttonLabel}
                        </Button>
                    </div>
               </div>
            </Fade>
          </Modal>
    )
  }
  renderAccountInformation = () => {
    const {checked} = this.props
    return(
        <div className="">            
           {this.renderBackButton()}
            <List className={"userList"}>
                {accountInformationList.map((each, index: number) =>{
                    const keyValue = this.state.userData && each.keyPair in this.state.userData ? this.state.userData[each.keyPair as keyof IUserData] : ''
                    return(
                        <ListItem alignItems="center"
                            className={"userListItem"}
                            button
                            data-test-id={`account-information-${index}`}
                            key={`setingsCat${index}`} 
                            onClick={()=>this.redirectToEditForm(each.keyPair, keyValue)}
                        >
                            <ListItemIcon className={"drawerItemIcon"}>
                                <img src={checked ? each.darkIcon : each.whiteIcon} className={"iconImage"} />
                            </ListItemIcon>
                            <ListItemText className="mainCatLabel" primary={each.label} />
                            <ListItemIcon className={"userDataValue"}>
                                {keyValue ? keyValue : "Add"}
                                <img src={checked ? arrowDark : arrowWhite} className={"iconImage"} />
                            </ListItemIcon>
                    </ListItem>
                    )
                })}
                <ListItem 
                    alignItems="center"
                    className={"listItem"}
                    button
                >
                    <ListItemIcon className={"drawerItemIcon"}>
                        <img src={ checked? calenderDark: calenderWhite } className={"iconImage"} />
                    </ListItemIcon>
                    <ListItemText className="mainCatLabel"  primary="Date of Birth"/>
                    <ListItemIcon className={"userDataValue"}>
                        {this.state.userData?.dateOfBirth}
                    </ListItemIcon>
                </ListItem>              
            </List>
        </div>
    )
  }
  
  renderMessage = () => {
    return(
        <div className="messageScreen">
            <div className="messageContainer">
                <img src={this.state.messageType === "suc" ? sucIcon : ""} />
                <span>{this.state.message}</span>
            </div>
        </div>
    )
  }

  renderDeactivate = () =>{
    return(
        <div className="deactivateContainer">
            {this.renderBackButton()}            
            <p> Complete your deactivation request by entering the password associated with your account. </p>
            {this.state.pasError !=="" && <div className="pasErrorContainer"> {this.state.pasError}</div> }
            <div className="verificationContainer">
                <Formik
                    initialValues={{ password: "" }}
                    onSubmit={this.validatePassword}
                    // validationSchema={this.loginSchema}
                    data-test-id="validate-password-form"
                >
                    {(formikProps) => {
                    const { values} = formikProps;
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="fieldContainer">
                                <label htmlFor="passwordInput2">Password</label>
                                <Field
                                    component={FormikInputField}
                                    id="passwordInput2"
                                    className = "passField"
                                    name="password"
                                    value={values.password}
                                    placeholder="Enter Your Password"
                                    variant="outlined"
                                    fullWidth
                                    type={this.state.showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={this.onClickVisiblePassword}>
                                            <IconButton style={inputStyles.iconButton}
                                            >
                                            {this.state.showPassword ? (<VisibilityOffIcon /> ) : (<VisibilityIcon />)}
                                            </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                            </div>
                            <Button className="deactivateButton" type="submit">
                                <p className="label">Deactivate</p> 
                                <span className="icon"><img src={arrowRightRed} className={"iconImage"} /></span>
                            </Button>
                        </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
  }

  renderChangeForm = (values: { current_password:string, new_password:string, confirm_password:string}) => {
    return (
        <Form className="changePassForm" noValidate autoComplete="off">
            <div className="fieldContainer">
                <label htmlFor="passwordInput2">Current Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput2"
                    className = "passField"
                    name="current_password"
                    value={values.current_password}
                    placeholder="Enter Your Password"
                    variant="outlined"
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={this.onClickVisiblePassword}>
                            <IconButton style={inputStyles.iconButton}
                            >
                            {this.state.showPassword ? (<VisibilityIcon /> ) : (<VisibilityOffIcon />)}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />
            </div>
            <div className="fieldContainer">
                <label htmlFor="passwordInput"> New Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput"
                    name="new_password"
                    value={values.new_password}                                   
                    placeholder="At least 8 characters"
                    variant="outlined"
                    fullWidth
                    type="password"
                    InputLabelProps={{
                        className: "13",
                    }}
                    className = "passField"
                />
            </div>
            <div className="fieldContainer">
                <label htmlFor="passwordInput2">Confirm Password</label>
                <Field
                    component={FormikInputField}
                    id="passwordInput2"
                    className = "passField"
                    name="confirm_password"
                    value={values.confirm_password}
                    placeholder="Enter Your Password"
                    variant="outlined"
                    fullWidth
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={this.onClickConfirmVisible}>
                            <IconButton style={inputStyles.iconButton}
                            >
                            {this.state.showConfirmPassword ? ( <VisibilityIcon />) : (<VisibilityOffIcon />)}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Button  data-test-id="verify-password-submit" className="submitButton" type="submit">
                <p className="label">Update Password</p> 
                <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
            </Button>            
            <Link to="/ForgotPassword" style={{ textDecoration: 'none' }} className="arrowFontFamily font14 font500 txtGradiant forgotPswd">Forgot password?</Link>            
            
        </Form>
        );
  }

  renderChangePswrd = () => {
    return(
        <React.Fragment>
            {this.renderBackButton()}
            <div className="changepswrdContainer">
            {this.state.pasError !== "" && <div className="pasErrorContainer " > 
                {this.state.pasError}
            </div>}
                <Formik
                    initialValues={{ 
                        current_password: "",
                        new_password: "",
                        confirm_password: ""
                    }}
                    onSubmit={this.updateNewPswrd}
                    data-test-id="validate-change-password-form"
                >
                    {(formikProps) => {
                    const { values} = formikProps;
                        return(this.renderChangeForm(values))
                    }}
                </Formik>
            </div>
        </React.Fragment>
    )
  }

  renderSection = () => {
    return (
        <React.Fragment>
            {this.state.renderAccountSettingsType === "default" && this.renderSubCat()}
            {this.state.renderAccountSettingsType == "verifyPassword" && this.verifyWithPassword()}
            {this.state.renderAccountSettingsType === "accountInformation" && this.renderAccountInformation()}
            {this.state.renderAccountSettingsType === "deactivateAccount" && this.renderDeactivate()}   
            {this.state.renderAccountSettingsType === "changePassword" && this.renderChangePswrd()}   
            {this.state.renderAccountSettingsType === "userEditForm" && <AccountUpdateForm 
                editType={this.state.editUserType} 
                onClickBackButton={this.onClickBackButton} 
                fieldValue={this.state.editUserTypeValue}
                setMessage={this.setMessage}
                updateUserName={this.updateUserName}
                updateError={this.state.updateError}
                updateErrorMsg={this.updateErrorMsg}
                apiCall={this.apiCall}
            />}
            {this.state.messageType !=="" &&  this.renderMessage()}   
            {this.state.logoutModal && this.renderLogoutModal()}                      
        </React.Fragment>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
      <React.Fragment>
         <Loader loading={this.state.isLoading} />
        <StyleContainer>
            {!this.state.isLoading && this.renderSection()}   
        </StyleContainer>  
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleContainer = styled(Box)({
    "& .logoutModal":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    " .logoutContainer": {
        height: "240px",
        width: "375px"
    },
    "& .messageScreen": {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: "0px",
        left: "0px",
        zIndex: 100,
        background: "#000000c7",
        display: "flex",
        justifyContent: "center"
    },
    "& .messageScreen .messageContainer": {         
        background: "#222222",        
        padding: "10px 20px 10px 20px",
        gap: "8px",
        borderRadius: "6px",
        marginTop: "auto",
        marginBottom: "5%",
        display: "flex"
    },
    "& .messageContainer span": {
        margin: "auto",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px"
    },
    "& .subCategory": {
        // marginTop: "20px",
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily:"Manrope",
    
    },
    "& .subCategory .list": {
        paddingTop: "0px",    
    },
    "& .backButton:hover":{
        background: "transparent"
    },
    "& .backButton": {
        fontFamily:"Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0",
        textTransform: "none",
        paddingLeft: "0px"
    },
    "& .pl16": {
        paddingLeft: "16px"
    },
    "& .backButton .MuiButton-label": {
        justifyContent: "left"
    },
    "& .backButton span": {
        marginRight: "30px"        
    },
    "& .backButton .icon":{
        height: "48px",
        width: "48px",
        textAlign: "center",
        alignContent: "center",
        marginRight: "0px"       
    },
    "& .backButton .icon img":{        
        marginTop: "5px"       
    },
    "& .subCategory .backButton .MuiButton-label img": {
        paddingBottom: "37px"
    },
    "& .subCategory .backButton ": {
        paddingTop: "11px"
    },
    "& .justify-content-center": {
        justifyContent: "center"
    },
    "& .subCatIconContainer": {
        marginTop: "6px",
        marginBottom: "auto"
    },
    "& .textAlignInitial": {
        textAlign: "initial"
    },
    "& .textAlignInitial p,& .subcatTitleContainer p":{
        fontSize: "14px",
        margin: "0px",
        fontWeight: "500",
        // textTransform: "none",
        lineHeight: "21px",
        color: "#6B6B6B",
    },
    "& .subcatTitleContainer p":{
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px",
        color: "#6B6B6B",
        maxWidth: "67%"
    },

    "& .subcatTitleContainer span": {
        fontSize: "16px",
        // margin: "0px",
        fontWeight: "600",
        lineHeight: "19px",
    },
    "& .verificationContainer": {
        gap: "30px"
    },
    "& .verificationContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        padding: "10px 0px"
    },
    "& .verificationContainer .pasErrorContainer":{
        width:"auto",
        marginBottom: "20px"
    },
    "& .fieldContainer": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px"
    },
    "& .fieldContainer .MuiOutlinedInput-root":{
        borderRadius: "10px"
    },
    "& .fieldContainer label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        marginBottom: "8px"
    },
    "& .submitButton": {
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    "& .deactivateButton":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        color: "rgba(239, 68, 68, 1)",
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid rgba(239, 68, 68, 1)"
        // lineHeight: 16.8px;
        // text-align: center;

    },

    "& .submitButton .label":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "unset",
        color: "white"
    },
    "& .userDataValue": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        justifyContent: "end"
    },
    "& .userDataValue .iconImage": {
        marginLeft: "10px"
    },
    "& .deactivateContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        margin: "0px"
    },
    "& .deactivateContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    "& .pasErrorContainer":{
        width: "420px",
        height: "36px",
        padding: "12px 16px 12px 16px",
        gap: "8px",
        borderRadius: "10px",
        border: "0px 0px 0px 1.3px",
        background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
        alignContent: "center",
        color: "rgba(239, 68, 68, 1)"
    },
    "& .deactivateModal .logoutContainer p":{
        maxWidth: "319px"
    },
    "& .changepswrdContainer":{
        paddingTop: "24px"
    },
    "& .changePassForm": {
        display: "flex",
        flexDirection: "column",
    },
    "& .changePassForm .submitButton": {
        margin: "auto",
        width: "unset"
    },
    "& .changepswrdContainer .pasErrorContainer":{
        marginBottom: "30px"
    },
    "& .forgotPswd":{
        margin: "auto",
        marginTop: "15px"
    }
    
});
// Customizable Area End
