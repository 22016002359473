import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start
import React from "react"
import { ownerShipIcon, verifyIcon, verifyOrange, verifyYellow } from "./assets"
import {cloneDeep} from 'lodash'

export interface IUserProfileResponse {
  data: IUserProfile
}

export interface IError {
  errors: string[]
}

export interface IUserProfile {
  id: string,
  type: string,
  attributes: IUserProfileAttributes
}

export interface IUserProfileAttributes {
  bio: string,
  cover_photo: string,
  profile_photo: string,
  full_name: string,
  user_name: string,
  id: number,
  user_ownership: boolean,
  user_subscription: string,
  occuption: string,
  location: string,
  website: string,
  followers_count: number,
  following_count: number,
  created_at: string,
  is_saved:boolean,
  is_blocked: boolean,
  is_following: boolean,
  is_subscribed: boolean

}

export interface IActiveUser{
  fullName: string
id: number
isFollowing: string
profilePhoto: string|null
userName: string
userOwnership: boolean
userSubscription: string
}


export interface PaginationDetails {
  page_number: number
  records_in_this_page: number
  records_per_page: number
  total_pages: number
  total_records: number
}


export interface IPostResponse {
  data: Array<IPost>,
  pagination_details: PaginationDetails
}

export interface IPost {
  id: string;
  attributes: {
    total_reposts: number;
    is_repost: boolean;
    id: number
    body: string;
    body_mentions: string[];
    hash_tags: { hash_tags: string, indices: Array<number> }[];
    created_at: string;
    post_by: PostBy;
    media: Media[];
    media_mentions: MediaMentions[];
    is_liked: boolean;
    is_saved: boolean;
    model_name: string;
    repost_count: number;
    total_comments: number;
    total_likes: number;
  };
}

export interface PostBy {
  id: number;
  full_name: string;
  user_name: string;
  is_following: boolean;
  profile_photo: string;
  user_ownership: boolean;
  user_subscription: string;
}

export interface Media {
  content_type: string;
  url: string;
  type: string;
  id:string;
}
export interface MediaMentions {
  id: number;
  mention: string;
}


export interface IReportIssue {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string,
    title: string,
    rank: number
  }
}

export interface ILikePostResponse {
  messages: Array<string>
}

export interface IReporPostResponse {
  data: Array<IReportIssue>
}


export interface IPostActivity{
  impressions: number
  engagements: number;
  detail_expands: number;
  new_followers: number;
  profile_visits: number
}

export interface IPostActivityResponse{
  data:{
    id:string,
    attributes:{
      post_activity:IPostActivity
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: string
  anchorEl: null | HTMLElement;
  isLoggedUser: boolean;
  userProfile: IUserProfile | undefined;
  loading: boolean;
  feed: Array<IPost>;
  fetchedPages: number;
  postLoading: boolean;
  pagination: PaginationDetails | undefined;
  LoggedUser: IUserProfile | undefined;
  muteModal: boolean;
  activeUser: IActiveUser|undefined;
  reportPostMainList: Array<IReportIssue>;
  reportPostSubCatList: Array<IReportIssue>;
  bottompoper: boolean;
  reportingPostId: string;
  showReportPostModal: boolean;
  reportPageLoading: boolean;
  reportIssue: number | undefined
  reportSubIssue: number | undefined
  reportCurrentView: string;
  reportPostLoading: boolean
  accountId: number;
  showComments:boolean;
  showPostActivity:boolean;
  selectedPostComment:IPost|undefined;
  ActivePostActivityId:string|undefined
  ActivePostActivity:IPostActivity |undefined
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserProfileDataCallId: string = "";
  apiGetUserPostsCallId: string = "";
  followApiCallId: string = "";
  apiGetLoggedUserCallId: string = "";
  blockAccountApiCallId: string = "";
  muteUserApiCallId: string = "";
  unMuteUserApiCallId: string = "";
  apiGetReportIssuesCallId: string = "";
  apiGetReportSubIssueCallId: string = "";
  getPostActivityApiCallId:string="";
  apiReportPostSubmitCallId: string = ";"
  likePostApiCallId: string = "";
  savePostApiCallId:string="";
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeIndex: 'Arrows',
      accountId: 0,
      anchorEl: null,
      isLoggedUser: false,
      loading: false,
      userProfile: undefined,
      feed: [],
      postLoading: false,
      fetchedPages: 1,
      LoggedUser: undefined,
      pagination: undefined,
      muteModal: false,
      activeUser: undefined,
      reportingPostId: '',
      bottompoper: false,
      showReportPostModal: false,
      reportPageLoading: false,
      reportPostMainList: [],
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportCurrentView: 'main',
      reportPostSubCatList: [],
      reportPostLoading: false,
      showComments:false,
      selectedPostComment:undefined,
      showPostActivity:false,
      ActivePostActivity:undefined,
      ActivePostActivityId:undefined
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponse(apiRequestCallId, responseJson)

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getUserProfile()
    this.getLoggedUserData()
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.props.navigation.getParam('id') !== prevProps.navigation.getParam('id')) {
      this.setState({
        fetchedPages: 1,
        feed: [],
        pagination: undefined
      })
      this.getUserProfile()
      this.getLoggedUserData()
    }
  }

  getPostAcitivityData = async(id?:string) =>{
    let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.jsonApiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPostActivityApiCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postaActivityEndpoint + `?post_id=${id}`);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleLikePost = async (id: string, type: string, likeable_type: string) => {
    this.setState({ accountId: JSON.parse(id) })
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      likeable_id: id,
      likeable_type: likeable_type
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "like" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSubmitReport = async () => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      "report": {
        "post_id": Number(this.state.reportingPostId),
        "report_issue_id": this.state.reportIssue,
        "report_sub_issue_id": this.state.reportSubIssue
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReportPostSubmitCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ReportPost);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleBlockAccount = async (id: number) => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.blockAccountEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleUnmuteUser = async (id: string) => {
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody = {
      account_id: id
    }

    this.unMuteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unmuteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleFollow = async (id: number, type: string) => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.followEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "follow" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  getUserPosts = async () => {
    const userId = this.props.navigation.getParam('id')
    let token = await getStorageData("authToken");
    this.setState({
      postLoading: true
    })
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserPostsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserPosts}?page=${this.state.fetchedPages}&per_page=10&account_id=${userId}&sort_order=desc`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReportUserMainCategories = async () => {
    let token = await getStorageData("authToken");
    this.setState({
      postLoading: true
    })
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportIssuesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getReportIssues)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReportUserSubCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportSubIssueCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getReportSubIssues}${this.state.reportIssue}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMuteAcoount = async (id: string) => {
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.jsonApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let httpBody = {
      account_id: id
    }

    this.muteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.muteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSavePost = async(id:string,type:string) => {
    let authToken = await getStorageData("authToken")
    const header = {
      token: authToken,
      "Content-Type": configJSON.jsonApiContentType,
    };

    let httpBody = {
      post_id:id,    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),type=="save"?configJSON.savePostEndpoint:configJSON.unSavePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="save"?configJSON.httpPostMethod:configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }  

  getUserProfile = async () => {
    const userId = this.props.navigation.getParam('id')
    let token = await getStorageData("authToken");
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetUserProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getUserProfile}/${userId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }


  getLoggedUserData = async () => {
    let token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetLoggedUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getLoggedUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleResponse = (apiRequestCallId: string, responseJson: IPostActivityResponse & ILikePostResponse & string & IReporPostResponse & IPostResponse & IUserProfileResponse & IError) => {

    switch (apiRequestCallId) {

      case this.apiGetUserProfileDataCallId:
        return this.handleProfileResponse(responseJson)

      case this.apiGetUserPostsCallId:
        return this.handlePostsResponse(responseJson)

      case this.apiGetLoggedUserCallId:
        return this.handleLoggedUserResponse(responseJson)

      case this.followApiCallId:
        return this.handleFollowResponse(responseJson)

      case this.blockAccountApiCallId:
        return this.handleBlockAccountResponse(responseJson)

      case this.muteUserApiCallId:
        return this.handleMuteUserResponse(responseJson)

      case this.unMuteUserApiCallId:
        return this.handleUnmuteUserResponse(responseJson)

      case this.apiGetReportIssuesCallId:
        return this.handleResportIssuesRespones(responseJson)

      case this.apiGetReportSubIssueCallId:
        return this.handleReportSubIssuesResponse(responseJson)

      case this.apiReportPostSubmitCallId:
        return this.handleReportPostSubmitResponse(responseJson)

      case this.likePostApiCallId:
        return this.handleLikePostResponse(responseJson)

      case this.savePostApiCallId:
        return this.handleSavePostResponse(responseJson)  
      
      case this.getPostActivityApiCallId:
        return this.handlePostActivityResponse(responseJson)
    }

  }


  handlePostActivityResponse=(responseJson:IPostActivityResponse & IError)=>{
    if(!responseJson.errors){
    this.setState({
      ActivePostActivity:responseJson.data?.attributes?.post_activity
    })
  }
  }

  handleClosePostActivityModel =()=>{
    this.setState({showPostActivity:false ,ActivePostActivityId: ""})
  }


  closeReportPostModal = () => {
    this.setState({
      showReportPostModal: false,
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportingPostId: '',
      reportCurrentView: "main",
      reportPostSubCatList: []
    })
  }


  handleFollowResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson.errors) {
      let feed = [...this.state.feed]
      let newFeed = feed.map((post) =>
        (post.attributes.post_by.id).toString() === responseJson.data.id
          ? {
            ...post,
            attributes: {
              ...post.attributes,
              post_by: {
                ...post.attributes.post_by,
                is_following: responseJson.data.attributes.is_following,
                is_blocked: responseJson.data.attributes.is_blocked
              },
            },
          }
          : post
      );
      if (responseJson.data?.attributes?.id === this.state.selectedPostComment?.attributes.post_by.id) {
        const updateCommentsPost = newFeed?.find(post => post.attributes.post_by.id === this.state.selectedPostComment?.attributes.post_by.id)
        this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
       }

      this.setState({
        feed: newFeed
      }, this.getUserProfile)
    }
  }

  handleBlockAccountResponse = (responsejson: IUserProfileResponse & IError) => {
    if (!responsejson?.errors) {
      this.setState({
        feed: []
      }, this.getUserProfile)
    }
  }


  handleLikePostResponse = (responseJson: ILikePostResponse & IError) => {
    if (!responseJson.errors) {
      let feeds = [...this.state.feed]
      let newarray = feeds.map(post => {
        if (post.attributes.id === this.state.accountId) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              is_liked: responseJson.messages[0] == "Liked Successfully" ? true : false,
              total_likes: responseJson.messages[0] == "Liked Successfully" ? post.attributes.total_likes + 1 : post.attributes.total_likes - 1
            }
          };
        }
        return post;
      });
      if (this.state.accountId.toString() === this.state.selectedPostComment?.id) {
        const updateCommentsPost = newarray?.find(post => post.id === this.state.selectedPostComment?.id)
        this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
       }
      this.setState({ feed: newarray })
    }
  }

  handleSavePostResponse=(responseJson:IUserProfileResponse & IError)=>{
   if(!responseJson.errors){
    let newfeeds = [...this.state.feed]
    let feeds = newfeeds.map(post => {
      if (post.attributes.id.toString() == responseJson.data.id) {
        return {
               ...post,
               attributes: {
                 ...post.attributes,
                 is_saved: responseJson.data.attributes.is_saved
               }
             };
           }
           return post;
     });
     if (responseJson.data.id === this.state.selectedPostComment?.id) {
      const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPostComment?.id)
      this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
     }
     this.setState({feed:feeds}) 
   }
  }

  handlePostsResponse = (responseJson: IPostResponse & IError) => {
    this.setState({
      postLoading: false
    })
    if (!responseJson.errors) {
      this.setState({
        feed: [...this.state.feed, ...responseJson.data],
        fetchedPages: this.state.fetchedPages + 1,
        pagination: responseJson.pagination_details
      })
    }
  }

  handleButtompoperlClose = ()=>{
    this.setState(
      {
        bottompoper:false
      }, 
    )
  }

  changeReportView = () => {
    if (this.state.reportIssue !== undefined && this.state.reportSubIssue !== undefined) {
      this.handleSubmitReport()
    } else if (this.state.reportIssue !== undefined) {
      this.setState({
        reportCurrentView: "subcat",
        reportPostLoading: true
      }, this.getReportUserSubCategories)
    }
  }

  handleReportSubIssuesResponse = (responseJson: IReporPostResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        reportPostSubCatList: responseJson.data,
        reportPostLoading: false
      })
    }
  }


  handleClickComments = (postId: string) => {
    this.setState((prev) => {
      const selectedPost = prev.feed?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedPostComment: selectedPost
      }
    })
    
  }

  handleResportIssuesRespones = (responseJson: IReporPostResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        reportPostMainList: responseJson.data,
        postLoading: false
      })
    }
  }

  handleReportPostSubmitResponse = (responseJson: string & IError) => {
    if (!responseJson.errors) {
      this.setState({
        reportCurrentView: "submit"
      })
    }
  }

  setReportPostIssue = (id: number) => {
    this.setState({
      reportIssue: id
    })
  }

  setReportPostSubIssues = (id: number) => {
    this.setState({
      reportSubIssue: id
    })
  }
  handleLoggedUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson.errors) {
      this.setState({
        LoggedUser: responseJson.data
      }, this.getUserPosts)
    }
  }


  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedPostComment: undefined})
  }


  handleProfileResponse = (responseJson: IUserProfileResponse & IError) => {
    this.setState({
      loading: false
    })
    if (!responseJson.errors) {
      this.handleProfiles(responseJson)
    }
  }


  handleMuteUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson?.errors) {
      this.setState({ muteModal: false, bottompoper: true })
    }
  }

  handleUnmuteUserResponse = (responseJson: IUserProfileResponse & IError) => {
    if (!responseJson?.errors) {
      this.setState({ bottompoper: false })
    }
  }


  handleSubscriberFollow = () => {
    const userProfile = this.state.userProfile as IUserProfile
    if (!userProfile?.attributes.is_following) {
      this.handleFollow(userProfile?.attributes.id, 'follow')
    }
  }


  handlefollowUnfollow = () => {
    const userProfile = this.state.userProfile as IUserProfile
    if (userProfile?.attributes.is_following) {
      this.handleFollow(userProfile?.attributes.id, 'unfollow')
    } else {
      this.handleFollow(userProfile?.attributes.id, 'follow')
    }
  }


  handleProfiles = (responseJson: IUserProfileResponse) => {
    if (responseJson.data.type === "logged_user") {
      this.handleLoggedUserProfile(responseJson)
    } else {
      this.handleOtherUserProfile(responseJson)
    }
  }

  handleLoggedUserProfile = (responseJson: IUserProfileResponse) => {
    this.setState({
      userProfile: responseJson.data,
      isLoggedUser: true,
    })
  }

  handleOtherUserProfile = (responseJson: IUserProfileResponse) => {
    this.setState({
      userProfile: responseJson.data,
      isLoggedUser: false,
    })
  }

  handleMuteModel = (status: string) => {
    if (status == "no") {
      this.setState({ muteModal: false })
    } else {
      this.handleMuteAcoount(this.state.activeUser?.id.toString() as string)
    }
  }


  renderVerifyIcon = (val: { user_subscription: string | null }) => {

    switch (val.user_subscription) {
      case 'I am an individual':
        return <img src={verifyIcon} alt="" height={18} width={18} />;
      case 'I am a business':
        return <img src={verifyYellow} alt="" height={18} width={18} />;
      case 'I am a news organization':
        return <img src={verifyOrange} alt="" height={18} width={18} />;
      case null:
        return ""
    }
  }

  renderOwnershipIcon = (val: { user_ownership: boolean }) => {
    if (val.user_ownership) {
      return <img src={ownerShipIcon} alt="" height={18} width={18} />;
    }
  }


  handleShare = () => {
    this.setState({
      anchorEl: null
    })
  }


  handleClick = (activeUser: IActiveUser, postId: string) => {
    this.setState({
      activeUser: activeUser,
      reportingPostId: postId || ""
    })
  }

  handleMuteModelClose = () => {
    this.setState({ muteModal: false, })
  }

  muteConformation = () => {
    this.setState({ muteModal: true })
  }

  showReportPost = () => {
    this.setState({
      showReportPostModal: true,
      reportPageLoading: true,
    }, this.getReportUserMainCategories)
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      activeIndex: newValue
    })
  }

  openMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  }


  handlePostActivityModel=(id?:string,postedById?:string)=>{  
    if(postedById == this.state.LoggedUser?.attributes.id){
      this.setState({showPostActivity:true ,ActivePostActivityId: id||""})
      this.getPostAcitivityData(id)
    }
  }


  handleUpdateCommentNumberOfPost = () => {
    const cloneFeeds = [...this.state.feed]
    const updateFeed = cloneFeeds.map((post) => {
      if (post.id === this.state.selectedPostComment?.id) {
        return {
          ...post,
          attributes: {
            ...post.attributes,
            total_comments: post.attributes.total_comments + 1
          }
        }
      } else {
        return post
      }
    })
    this.setState({feed: updateFeed})
  }

  // Customizable Area End
}
