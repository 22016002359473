// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { EmojiData } from "emoji-mart";
import { convertMentionHashtagText, sendAPIRequest } from "../../../components/src/utils";
import React from "react";
import { toast } from 'react-toastify'
import { Media as UploadMedia } from '../../postcreation/src/PostCreationController.web'

export const configJSON = require("./config");

interface HashTag {
  hash_tag: string;
  indices: number[];
}

interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: number[];
}

export interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}

interface CommentBy {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  admin_verification: boolean;
  profile_photo: string | null;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

interface MentionedAccount extends CommentBy {}

interface CommentAttributes {
  id: number;
  body: string;
  created_at: string;
  updated_at: string;
  is_reply: boolean;
  is_liked: boolean;
  total_likes: number;
  total_replies: number;
  model_name: string;
  hash_tags: HashTag[];
  body_mentions: BodyMention[];
  media: Media[];
  comment_by: CommentBy;
  mentioned_accounts: MentionedAccount[];
}

export interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface CommentAttributesWithReplies extends CommentAttributes {
  replyPage: number
  replies: CommentData[]
} 

export interface CommentDataWithReplies {
  id: string;
  type: string;
  attributes: CommentAttributesWithReplies;
}

export interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}

export interface Props {
  classes?: any
  checked: boolean
  open: boolean
  onClose: () => void
  postData: any
  userInfo: {
    id: number
  },
  onFollow: (id: number, type: string) => void,
  onClickMoreOption: (activeUser: any, postId: string) => void
  onMuteConformation: () => void
  onBlockAccount: (id: number) => void
  onShowReportPost: () => void
  onLikePost: (id: string, type: string, likeableType: string) => void
  onSavePost: (id: string, type: string) => void
  onUpdateNumberOfComments: () => void
}

interface S {
  token: string
  emojiAnchorEl: null | HTMLElement
  commentValue: string
  loading: boolean
  comments: CommentDataWithReplies[]
  paginationDetails: PaginationDetails
  fetchPage: number
  postBodyTextHeight: number
  selectedReplyComment: CommentDataWithReplies | null
  commentMedia: Array<UploadMedia>
  inputHeight: number
  inputWidth: number
}

interface SS {
  id: any;
}

export default class CommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCommentListId: string = ''
  likeCommentId: {id: string, commentId: string, isReply: boolean, replyId: string} = {id: '', commentId: '', isReply: false, replyId: ''}
  submitCommentId: string = ''
  replyCommnetId: string = ''
  getRepliesId: {id: string, commentId: string} = {id: '', commentId: ''}
  uploadAttachmentIds: Array<string> = []

  scrollCommentsRef = React.createRef<HTMLDivElement>()
  postBodyTextRef = React.createRef<HTMLDivElement>()
  inputWrapperRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: '',
      emojiAnchorEl: null,
      commentValue: '',
      loading: true,
      comments: [],
      paginationDetails: {
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0,
      },
      fetchPage: 1,
      postBodyTextHeight: 0,
      selectedReplyComment: null,
      commentMedia: [],
      inputHeight: 0,
      inputWidth: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken') || ''
    this.getListComments(token)
    this.setState({ token })
    setTimeout(this.updateBodyTextHeight, 0)
    setTimeout(this.updateInputHeight, 0)
    setTimeout(this.updateInputWidth, 0)
    window.addEventListener('resize', this.updateBodyTextHeight);
    window.addEventListener('resize', this.updateInputWidth);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateBodyTextHeight);
    window.removeEventListener('resize', this.updateInputWidth);
  }

  updateBodyTextHeight = () => {
    if (this.postBodyTextRef.current) {
      const height = this.postBodyTextRef.current.offsetHeight;
      this.setState({ postBodyTextHeight: height });
    }
  };

  updateInputHeight = () => {
    if (this.inputWrapperRef.current) {
      const height = this.inputWrapperRef.current.offsetHeight;
      this.setState({ inputHeight: height });
    }
  };

  updateInputWidth = () => {
    if (this.inputWrapperRef.current) {
      const width = this.inputWrapperRef.current.offsetWidth;
      this.setState({ inputWidth: width });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.uploadAttachmentIds.includes(apiRequestCallId)) {
        const attachment = responseJson?.data?.attributes
        this.handleUploadAttachmentResponse(attachment)
        return
      }

      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getCommentListId]: this.handleGetCommentListResponse,
      [this.likeCommentId.id]: this.handleLikeCommentResponse,
      [this.submitCommentId]: this.handleSubmitCommentResponse,
      [this.replyCommnetId]: this.handleReplyCommentResponse,
      [this.getRepliesId.id]: this.handleGetRepliesResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleUploadAttachmentResponse = (attachment: any) => {
    if (attachment) {
      const media: UploadMedia = {
        id: attachment.id,
        url: attachment.url,
        fileName: attachment.filename,
        contentType: attachment.content_type,
      }
      this.setState({commentMedia: [media, ...this.state.commentMedia]})
    }
  }

  handleGetRepliesResponse = (responseJson: any) => {
    const replies: CommentData[] = responseJson?.data;

    if (replies) {
      const cloneComment = [...this.state.comments]
      const updateComent: CommentDataWithReplies[] = cloneComment.map(comment => {
        if (comment.id === this.getRepliesId.commentId) {
          return {
            ...comment, 
            attributes: {
              ...comment.attributes,
              replyPage: comment.attributes.replyPage + 1,
              replies: [...comment.attributes.replies, ...replies],
            }
          }
        } else {
          return comment
        }
      })
      this.setState({comments: updateComent})
    }
  }

  handleReplyCommentResponse = (responseJson: any) => {
    const replyData = responseJson?.data
    if (replyData) {
      const cloneComment = [...this.state.comments]
      const updateComent: CommentDataWithReplies[] = cloneComment.map(comment => {
        if (comment.id === this.state.selectedReplyComment?.id) {
          return {
            ...comment, 
            attributes: {
              ...comment.attributes,
              total_replies: comment.attributes.total_replies + 1,
              replies: comment.attributes.replies.length !== 0 ? [replyData, ...comment.attributes.replies] : []
            }
          }
        } else {
          return comment
        }
      })
      this.setState({comments: updateComent})
    }
    this.setState({selectedReplyComment: null})
  }

  handleSubmitCommentResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const updateComments = [...this.state.comments]
      const newComment: CommentDataWithReplies = {
        ...responseJson?.data,
        attributes: {
          ...responseJson?.data?.attributes,
          replyPage: 1,
          replies: [],
        }
      }
      updateComments.unshift(newComment)
      this.setState({comments: updateComments})
      this.props.onUpdateNumberOfComments()
    }
  }

  handleLikeCommentResponse = (responseJson: any) => {
    const message = responseJson?.messages?.[0];
    const isLike = message === "Liked Successfully";
    const isUnlike = message === "Unliked Successfully";

    if (isLike || isUnlike) {
      const cloneComment = [...this.state.comments];
      const updateComment: CommentDataWithReplies[] = cloneComment.map(comment => 
        this.updateCommentLikes(comment, this.likeCommentId, isLike)
      );
      this.setState({ comments: updateComment });
    }
  }

  updateCommentLikes = (
    comment: CommentDataWithReplies,
    likeCommentId: { commentId: string; isReply: boolean; replyId?: string },
    isLike: boolean
  ): CommentDataWithReplies => {
    if (comment.id === likeCommentId.commentId) {
      if (likeCommentId.isReply && likeCommentId.replyId) {
        return {
          ...comment,
          attributes: {
            ...comment.attributes,
            replies: this.updateReplies(comment.attributes.replies, likeCommentId.replyId, isLike),
          }
        };
      } else {
        return {
          ...comment,
          attributes: {
            ...comment.attributes,
            is_liked: isLike,
            total_likes: this.updateLikesCount(comment.attributes.total_likes, isLike),
          }
        };
      }
    }
    return comment;
  };
  
  updateReplies = (
    replies: CommentData[],
    replyId: string,
    isLike: boolean
  ): CommentData[] => {
    return replies.map(reply => {
      if (reply.id === replyId) {
        return {
          ...reply,
          attributes: {
            ...reply.attributes,
            is_liked: isLike,
            total_likes: this.updateLikesCount(reply.attributes.total_likes, isLike),
          }
        };
      }
      return reply;
    });
  };
  
  updateLikesCount = (totalLikes: number, isLike: boolean): number => {
    return isLike ? totalLikes + 1 : totalLikes - 1;
  };

  handleGetCommentListResponse = (responseJson: any) => {
    const comments: CommentData[] = responseJson?.data;
    const pagination: PaginationDetails = responseJson?.pagination_details
    if (comments) {
      const customComments: CommentDataWithReplies[] = comments.map(comment => ({
        ...comment,
        attributes: {
          ...comment.attributes,
          replyPage: 1,
          replies: [],
        }
      }))
      this.setState({
        comments: [...this.state.comments,...customComments], 
        loading: false,
        fetchPage: this.state.fetchPage + 1,
        paginationDetails: pagination,
      })
    }
  }

  getListComments = (token: string) => {
    this.getCommentListId = sendAPIRequest(
      `bx_block_comments/comments?commentable_type=post&commentable_id=${this.props.postData.id}&page=${this.state.fetchPage}&per_page=10&sort_order=asc`,
      {
        method: 'GET',
        headers: {
          token: token,
        },
      }
    )
  }

  likeComment = (commentId: string, isLike: boolean, isReply: boolean, replyId: string) => {
    const httpBody = {
      likeable_id: isReply ? replyId : commentId,
      likeable_type:'comment'   
    }
    const apiId = sendAPIRequest(
      `bx_block_like/likes`,
      {
        method: isLike ? 'POST' : 'DELETE',
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body: httpBody,
      }
    )
    this.likeCommentId = {id: apiId, commentId, isReply: !!isReply, replyId}
  }

  submitComment = () => {
    const mediaIds = this.state.commentMedia.map(item => item.id)

    const formData = new FormData()
    formData.append('body', convertMentionHashtagText(this.state.commentValue))
    formData.append('attachment_ids[]', mediaIds.join(','))

    if (this.state.selectedReplyComment) {
      formData.append('commentable_type', 'comment')
      formData.append('commentable_id', this.state.selectedReplyComment?.id)
      this.replyCommnetId = sendAPIRequest(
        `bx_block_comments/replies`,
        {
          method: 'POST',
          headers: {
            token: this.state.token,
          },
          body: formData,
        }
      )
      this.setState({commentValue: '', commentMedia: []})
      return
    }
    formData.append('commentable_type', 'post')
    formData.append('commentable_id', this.props.postData.id)
    this.submitCommentId = sendAPIRequest(
      `bx_block_comments/comments`,
      {
        method: 'POST',
        headers: {
          token: this.state.token,
        },
        body: formData,
      }
    )
    this.setState({commentValue: '', commentMedia: []})
  }

  handleComment = (text: string) => {
    this.updateInputHeight()
    this.setState({commentValue: text})
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelectEmoji = (emoji: EmojiData) => {
    this.setState({commentValue: this.state.commentValue + (emoji as any).native});
  };

  handleClickCommentBtn = () => {}

  get commentListHeight() {
    let offsetHeight = 0
    if (this.state.selectedReplyComment) {
      offsetHeight += 52
    }
    if (this.state.commentMedia.length > 0) {
      offsetHeight += 70
    }
    return `calc(85vh - 190px - ${this.state.postBodyTextHeight}px - ${this.state.inputHeight}px - ${offsetHeight}px)`
  }

  handleClickReply = (comment: CommentDataWithReplies) => {
    this.setState({selectedReplyComment: comment})
  }

  handleCloseReply = () => {
    this.setState({selectedReplyComment: null})
  }

  getReplies = (commentId: string, replyPage: number) => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const apiId = requestMessage.messageId
    this.getRepliesId = {id: apiId, commentId}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_comments/replies?comment_id=${commentId}&page=${replyPage}&per_page=10`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  closeReplies = (commentId: string) => {
    const cloneComment = [...this.state.comments]
    const updateComent: CommentDataWithReplies[] = cloneComment.map(comment => {
      if (comment.id === commentId) {
        return {
          ...comment, 
          attributes: {
            ...comment.attributes,
            replyPage: 1,
            replies: [],
          }
        }
      } else {
        return comment
      }
    })
    this.setState({comments: updateComent})
  }

  onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const numberOfRemainFile = 4 - this.state.commentMedia.length

    if (files && files.length > 0 && files.length <= numberOfRemainFile) {
      for (const file of files) {
        this.uploadAttachment(file)
      }
    } else if (files && files.length > numberOfRemainFile) {
      toast.info('Please choose up to 4 photos or videos.')
    }
  }

  uploadAttachment = (file: File) => {
    const formData = new FormData()
    formData.append('media', file)

    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadAttachmentIds.push(requestMessage.messageId)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading/attachments"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeCommentMedia = (mediaId: number) => {
    const filteredMedia = this.state.commentMedia.filter(item => item.id !== mediaId)
    this.setState({commentMedia: filteredMedia})
  }
}

// Customizable Area End