Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getDiscoverySettings= 'bx_block_discovery_settings/discovery_settings'
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.deletePhoneAddressBook="bx_block_discovery_settings/address_books"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "discoverysettings3";
exports.labelBodyText = "discoverysettings3 Body";
exports.controlDiscoverabilytyHeading = "Control your discoverability settings and manage contacts youve imported.";
exports.Discoverability="Discoverability";
exports.LetsFindPeopleEmail="Let people who have your email address find you on Arrows";
exports.LetsFindPeopleEmailsConnectToArrows="Let people who have your email address find and connect with you on Arrows.";
exports.LetsFindPeoplePhone="Let people who have your phone number find you on Arrows";
exports.LetsFindPeoplePhoneConnectToArrows= "Let people who have your phone number find and connect with you on Arrows.";
exports.Contacts= "Contacts";
exports.ContactsDiscriptionWeb="Manage contacts that you have imported from your mobile devices."
exports.ContactAddressDescription="Contacts from your address book will be uploaded to Arrows on an ongoing basis to help connect you with your friends and personalise content, such as making suggestions for you and others. Turning off syncing will not remove previously uploaded contacts."
exports.syncAddressBookContacts="Sync address book contacts";
exports.RemoveAllContacts="Remove all contacts";
exports.RemoveAlContactsDescription="Remove any contacts you've previously uploaded and turn off syncing with Arrows on all devices. Please be aware that this takes a little time.";
exports.RemoveAllContactsPopup="Remove all contacts?";
exports.RemoveAllContactsPopupDescription= "This removes any contacts you've previously uploaded and turns off address book syncing with Arrows on all your devices. Please be aware that this takes a little time.";
exports.Cancel="Cancel";
exports.Remove="Remove";
exports.DiscoverabilityAndContactsHeading  ="Discoverability and contacts"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End