Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.arrowLogostartText="Welcome to "
exports.arrowLogoEndText="ARROWS"
exports.adoptersText='Early adopters to Arrows: 14';
exports.skip='Skip now';
exports.skipButton='Skip now'
exports.user='User';
exports.twitter='Twitter (X)';
exports.instagram='Instagram';
exports.follow='follow';
exports.back='Back';
exports.logout='Logout'
exports.save='Save';
exports.light='Light';
exports.dark='Dark';
exports.productText="“If you are not paying for the product, you are the product.";
exports.unknownText=" -Unknown";
exports.individualText='Individual';
exports.priceText='$25/1 Year';
exports.business='Business';
exports.organisationText='News Organization';
exports.freeText='Free';
exports.investText="  When you become an Arrows member you get the option to invest in Arrow Social,Inc.and be an owner with a casted interest in our success. $100-$10,000 max.";
exports.revenueText='Individual creators and eligible to receive 50% of the advertising and data revenue generated by their content';
exports.welcomeToText='Welcome to';
exports.arrowsText=' Arrows';
exports.verifyText='Join Us(you will be verified)';
exports.googleButton='Continue with Google';
exports.appleButton='Continue with Apple';
exports.createAccount='Create Account';
exports.terms='By signing up, you are agree to the Terms of';
exports.service=' Service and Privacy Policy for ';
exports.arrowsThickText='Arrows net';
exports.accountText='Already have an account?';
exports.logIn='Log In';
exports.termsConditionlink='Terms of Service '
exports.termstext='By signing up, you are agree to the ';
exports.and='and ';
exports.privaciyPolicyText='Privacy Policy ';
exports.toArrows='for Arrows '
exports.signupLoginText='Log in / Sign up';
exports.clientId='888888598771-jsfhc86qahu30js99s0t9prm8t3g0ldi.apps.googleusercontent.com';
exports.googleAuthEndPoint='bx_block_login/login';
exports.apiMethodTypeAddDetail = "POST";
exports.contentTypeApiAddDetail = "application/json";
exports.loggedInEndPoint='account_block/accounts/logged_user';
exports.validationApiMethodType = "GET";
// Customizable Area End