import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Popover,
  Input,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { backArrowDark, backArrowWhite, callDark, callLight, emojiIcon, gallaryIcon, profile, searchDark, searchWhite, sendIcon, threeDots, threeDotsLight } from "./assets";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start
const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMessages = () => {
    const { chatData } = this.state;
    const messages = chatData?.attributes?.messages ??  [
      {
        id: "message_001",
        type: "chat_message",
        attributes: {
          id: 1,
          message: "Hello everyone!",
          account_id: 123,
          chat_id: 1,
          created_at: "2024-08-20T12:00:00Z",
          updated_at: "2024-08-20T12:05:00Z",
          is_mark_read: true,
          attachments: null,
        },
      },
      {
        id: "message_002",
        type: "chat_message",
        attributes: {
          id: 2,
          message: "Hi, how are you?",
          account_id: 456,
          chat_id: 1,
          created_at: "2024-08-20T12:10:00Z",
          updated_at: "2024-08-20T12:12:00Z",
          is_mark_read: false,
          attachments: [
            { id: 1, url: "https://picsum.photos/200" },
          ],
        },
      },
    ]
    return (
      <>
        {messages
          ?.slice(0)
          .reverse()
          .map((item: IMessage, index: number) => (
            <Box
              key={`message-${index}`}
              sx={this.props.checked? webStyles.messageBoxContainerLight:webStyles.messageBoxContainer}
            >
              {item.attributes.attachments ? <img src={item.attributes.attachments[0].url} style={webStyles.previewImage} /> : null}
             <div className="messages_ouiter">
             <Typography className="messages">{item.attributes?.message}</Typography>
             <Typography className="created_at_message" >
             {this.formatTime(item.attributes?.created_at)}
              </Typography>
             </div>
            </Box>
          ))}
      </>
    );
  };

  renderEmotionButton = () => {
    const { emojiAnchorEl } = this.state;
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
      <Box>
        <IconButton data-testid="emoji-picker-button" onClick={this.handleClickEmoji}>
          <img src={emojiIcon} />
        </IconButton>
        <EmojiPopover
          id={id}
          open={open}
          anchorEl={emojiAnchorEl}
          onClose={this.handleCloseEmoji}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ marginTop: -66 }}
        >
          <Picker 
            data-testid="emoji-picker" 
            theme="dark" 
            onSelect={this.handleSelect} 
            autoFocus
          />
        </EmojiPopover>
      </Box>
    )
  }
  renderImagePickerButton = () => {
    return (
      <Box>
        <IconButton
          data-test-id="btnInsertImage"
          onClick={this.handleInsertImage}
           >
         <img className="cursor-style" src={gallaryIcon}   alt="Gallery" />
        </IconButton>
         <Input
           data-test-id="FileInput"
           inputRef={this.fileInputRef}
           style={webStyles.fileInput}
           type="file"
           inputProps={{ accept: "image/png, image/jpeg" }}
           onChange={this.handleFileChange}
         />
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { checked } = this.props;
    return (
      <ThemeProvider theme={this.props?.checked ? lightTheme : darkTheme}>
        <StyleContainer>
          <div data-test-id="viewChatId" className="view-chat-wrapper">
           
            <div className="view-chat-header">
              <div className="view-chat-first">
                 <span className="view-chat-backIcon">
                   <img  className="view-chat-img" src={checked ? backArrowDark : backArrowWhite} />
                  </span>
                <img className="view-chat-profile"  style={{border:checked?"1px solid #D1B7F5":"1px solid #FFFF"}}  src={profile} alt="" />
                <div className="view-chat-first-conatain">
                 <Typography className="chatHeadLabel">devendra yadav </Typography>
                 <Typography className="chatHeadSubLabel">Typing... </Typography>
                </div>
              </div>
             <div className="view-chat-second">
             <span className="view-chat-backIcon"> <img className="view-chat-img" src={checked?callDark:callLight} alt="" /></span> 
             <span className="view-chat-backIcon"><img className="view-chat-img" src={checked?searchDark:searchWhite} alt="" /></span> 
             <span className="view-chat-backIcon"> <img className="view-chat-img" src={checked?threeDots:threeDotsLight} alt="" /></span> 
             </div>
            </div>
           <div className="view-chat-message-outer"> 
            <div className="view-chat-profile-outer">
              <img  className="view-chat-profile-img"
                 style={{border:checked?"1px solid #D1B7F5":"1px solid #FFFF"}}
                 src={profile} alt=""
               />
              <div className="view-chat-profile-inner">
                <Typography className="chatProfileLabel">devendra yadav </Typography>
                <Typography className="chatProfileSubLabel">@dev_2607 </Typography>
              </div> 
            </div>

            <div className="view-chat-profile-message-display" >
            <Typography align="center">
              <span className={`createAt ${checked?"backgroundLight":"backgroundDark"}`}>Today</span> 
            </Typography>
            {this.renderMessages()}
            </div>
            </div> 

            <div className="view-chat-foot"> 
            <TextField
               variant="outlined"
               placeholder="Message..."
               style={webStyles.searchField}
               fullWidth
               data-test-id="messageInputId"
               value={this.state.message}
               onChange={(event)=>this.handleMessageChange(event.target.value)}
               InputProps={{
                style: {
                  borderRadius: "10px",
                },
               startAdornment: (
                 <InputAdornment position="start">
                   {this.renderEmotionButton()}
                 </InputAdornment>
               ),
               endAdornment:(
                <InputAdornment position="end">
                    {this.renderImagePickerButton()}
              </InputAdornment>
               )
             }}
            />
           <Button className="send-button">
            <img src={sendIcon} alt="" />
           </Button>
           </div>
          </div>

        </StyleContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  searchField:{
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px", 
      '&.Mui-focused fieldset': {
        border :"1px solid #7A2BF5",       
      },
    },
    width:"100%"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: 600,
    border: "1px solid #ccc",
    justifyContent: "space-between",
    padding: 20,
    alignItems: "center",
  },
  headerButtonView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    width: 600,
    height: 600,
    overflow: "scroll",
  },
  messageBoxContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#222222",
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 40,
  },
  messageBoxContainerLight:{
    marginTop: 10,
    marginBottom: 10,
    marginRight: 40,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E9E9E9",
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 15,
  },
  messageInputView: {
    width: "100%",
    marginRight: 10,
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 20,
    width: 600,
    border: "1px solid #ccc",
    background: "#fff",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewModalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    width: '80%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewImage: {
    width: "100%",
    height: "auto"
  },
  previewInput: {
    marginLeft: 10,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  previewModalButtons: {
    margin: 10,
    display: "flex",
    flexDirection: "row"
  },
  fileInput: {
    display: "none",
  }
};

const EmojiPopover = styled(Popover)({
  '& .emoji-mart-search': {
    '& input': {
      borderColor: '#ae65c5',
    },
  },
  
  '& .emoji-mart-anchor': {
    cursor: 'pointer',
  },

  '& .emoji-mart-emoji': {
    '& span': {
      cursor: 'pointer !important',
    }
  },
})
const StyleContainer = styled(Box)({
  "& .main-category": {
    marginTop: "20px",
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily:"Manrope",
  },
  "& .send-button":{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height:"56px",
    width:"56px",
    borderRadius:"16px",
    padding:'10px'
  },
  "& .cursor-style":{
    cursor:"pointer"
  },
  "& .back-button-settings .MuiButton-label": {
    justifyContent: "space-between",
    textTransform: "capitalize"
  },
  "& .justify-content-right": {
    justifyContent: "end"
  },
  "& .chatHeadLabel": {
    fontSize: "24px",
    fontWeight: "800",
    fontFamily:"Manrope",
    lineHeight:"27.12px",
    textTransform:"capitalize"
  },
  "& .chatProfileLabel":{
    fontSize: "16px",
    fontWeight: "600",
    fontFamily:"Manrope",
    lineHeight:"19.2px",
    textTransform:"capitalize",
    height:"19px",
    display:"flex",
    gap:"4px"
  },
  "& .chatProfileSubLabel":{
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:"Manrope",
    lineHeight:"21.2px",
    textTransform:"capitalize",
    height:"21px",
  },
  "& .chatHeadSubLabel ":{
    fontSize: "12px",
    fontWeight: "500",
    fontFamily:"Manrope",
    color:"#757575"
  },
  "& .mainCatLabel1": {
    fontSize: "12px",
    fontWeight: "700",
    fontFamily:"Manrope",
    color:"#6B6B6B",
    lineHeight:"19.2px",
    margineTop:"0px",
    width:"175px"
  },
  "& .view-chat-wrapper":{
    display:"flex",
    flexDirection:"column",
    gap:"30px",
    paddingTop:"15px"
  },
  "& .view-chat-profile-outer":{
    display:"flex",
    flexDirection:"column",
    gap:"14px",
    alignItems:"center"
  },
  "& .view-chat-message-outer":{
    height:"calc(100vh - 205px)",
    display:"flex",
    flexDirection:"column",
    gap:"30px",
    overflowY:"scroll",
    scrollbarWidth: "none" ,
    "&::-webkit-scrollbar": {
      display: "none" ,
    },
  },
  "& .view-chat-profile-message-display":{
    display:"flex",
    flexDirection:"column",
    padding:"0 37.5px",
    gap:"15px"
  },
  "& .view-chat-foot":{
    display:"flex",
    gap:"10px"
  },
  "& .createAt":{
    fontSize: "10px",
    fontWeight: "500",
    fontFamily:"Manrope",
    lineHeight:"12px",
    color:"#757575",
    minWidth:"48px",
    padding:"6px 10px",
    borderRadius:"6px"
    },
    "& .backgroundLight":{
      background: "#E9E9E9"
    },
    "& .backgroundDark":{
      background: "#1C1822"
    },
  "& .view-chat-profile-inner":{
     textAlign:"center"
  },
  "& .view-chat-profile-img":{
     width:"90px",
     height:"90px",
     borderRadius:"50%",
  },
  "& .view-chat-header":{
    display:"flex",
    justifyContent:"space-between"
  },
  "& .view-chat-first":{
    display:"flex",
    alignItems: "center",
  },
  "& .view-chat-backIcon":{
    width:"24px",
    height:"24px",
    padding:"10px 12px",
    gap:"8px",
    display:"flex",
    alignItems: "center",
    justifyContent:"center",
     "& .view-chat-img":{
      width:"24px",
      height:"24px",
      cursor:"pointer"
     }
  },
  "& .view-chat-first-conatain":{
    display:"flex",
    flexDirection:"column",
    marginLeft:"12px"
  },
  "& .view-chat-second":{
    display:"flex",
  },
  "& .view-chat-profile":{
    width:"40px",
    height:"40px",
    borderRadius:"50%",
  },
  "& .messages":{
    fontWeight: "500",
    fontFamily:"Manrope",
    fontSize:"14px",
    lineHeight:"21px"
  },
  "& .created_at_message":{
    fontWeight: "500",
    fontFamily:"Manrope",
    fontSize:"10px",
    lineHeight:"12px",
    alignSelf:"flex-end",
    marginTop: "auto",
  },
  "& .messages_ouiter":{
    display:"flex", 
    justifyContent: "space-between",
  }
})
// Customizable Area End
