import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
import { NavigationStackProp } from "react-navigation-stack";
// Customizable Area End

export const configJSON = require("./config");

export type eventType = {
  id: string,
  type: string,
  attributes: {
    social_club_id: number,
    event_name: string,
    location: string,
    max_participants: number,
    min_participants: number,
    description: string,
    age_should_be: number,
    created_at: string,
    updated_at: string,
    fee_amount: string,
    images: string[],
    start_date_and_time: string,
    end_date_and_time: string,
    start_time: string,
    end_time: string,
  }
};

export interface Props {
  // Customizable Area Start
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  event: eventType | null,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EventRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  registerEventCall: string = '';
  getEventCall: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      event: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
    // Customizable Area Start
      if (!errorReponse) {
        if (this.registerEventCall === apiRequestCallId) {
          if(Platform.OS === "web") {
            alert(JSON.stringify(responseJson.meta.message));
          } else {
            Alert.alert("Event Registered", JSON.stringify(responseJson.meta.message));
          }
          
        } else if (this.getEventCall === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ event: responseJson.data[0] });
          }
        }
      } else {
        Alert.alert("Error", JSON.stringify(errorReponse));
      }
    }

    // Customizable Area End
  }

  registerButtonProps = {
    onPress: () => this.registerEvent(),
  }

  async getEvents () {
    const token = configJSON.tokenKey;
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEventCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEventsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEventAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  registerEvent = async () => {
    const token = configJSON.tokenKey;
    const header = {
      'content-type': configJSON.exampleApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerEventCall = requestMessage.messageId;

    if (this.state.event) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.registerEventEndPoint}?event_id=${this.state.event.id}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.registerAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



    componentDidMount(): Promise<void> {
      // Customizable Area Start
        return this.getEvents();
      
      // Customizable Area End
    }

    

    // Customizable Area Start
    // Customizable Area End


  

}
