import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData,getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  render:(args:{})=>void
  toggle:string
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  checked:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  
  // Customizable Area End
}

export default class CommonSwitchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      checked:false,
    
      // Customizable Area End
    };

    // Customizable Area Start
    
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const theme=await getStorageData('theme')
    const parsedTheme=JSON.parse(theme)
    this.setState({checked:parsedTheme})
   }
     
   handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     this.setState({checked:event.target.checked},()=>{
      setStorageData('theme',JSON.stringify(event.target.checked))
    })
  };

  // Customizable Area End
}
