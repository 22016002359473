Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";
exports.feedEndpoint ="bx_block_posts/posts"
exports.savePostEndpoint ="bx_block_posts/save_post"
exports.unSavePostEndpoint ="bx_block_posts/unsave_post"
exports.apiPostMethod = "Post";
exports.likePostEndpoint ="bx_block_like/likes"
exports.followEndpoint ="bx_block_followers/follows"
exports.suggestionsEndpoint ="account_block/tailored_accounts/suggestions"
exports.apiFormContentType = "multipart/form-data";
exports.apiPostMethod = "Post";
exports.apiDeleteMethod ="Delete"
exports.tailoredMemberEndpoint ="account_block/tailored_accounts" 
exports.muteUserEndpoint = "bx_block_followers/mute_account"
exports.loggedUserEndpoint ="account_block/accounts/logged_user"
exports.unmuteUserEndpoint = "bx_block_followers/unmute_account"
exports.blockAccountEndpoint = "bx_block_followers/block_account"
exports.postaActivityEndpoint = "bx_block_posts/post_activity"





// Customizable Area End
