import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
	TextField,
	Grid,
	styled,
	Modal,
	Backdrop,
	Fade,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";

import Settings2Controller, { Props, configJSON } from "./Settings2Controller";
import { Formik, Form, Field } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
import Loader from "../../../components/src/Loader.web";
import React from "react";
import { connectedAccountDark, appsAndSessionDark, appsAndSessionLight, backArrowDark, sucIcon, connectedAccountLight, forwardArrowDark, forwardArrowLight, googleLogo, lockIconDark, lockIconLight, phoneIconDark, phoneIconLight, crossIconLight, crossIconDark, webIconDark, webIconLight, backArrowWhite } from "./assets";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import SecurityAndAccountAccessController from "./SecurityAndAccountAccessController";
import { arrowRight24White, radioCheckedDark, radioUnChecked, radioCheckedLight, } from "../../settings2/src/assets";

const inputStyles={  
     
  darkBackdrop:{
     backgroundColor:'#0E0E0E',
     opacity:0.8
  },
   fade:{
      border:'1px solid #5B5B5B',
      borderRadius:'10px',
      display: 'flex'
  },
  lightBackdrop:{
    backgroundColor:'#FFFFFF',
    opacity:0.9
  },
  logoutButtonContainer: {
      display: "flex",
      gap: "12px",
  },
  logoutCancelButton: {
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      border: "1px solid"
  },
  logoutYesButton:{
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      background: "rgba(255, 77, 103, 1)"
  },
  iconButton:{
      color: "#6B6B6B",
      margin:0,
      padding:0,
      position:'absolute' as 'absolute',
      right:'4px',
			marginRight:'8px'
  },
	disconnectBtn:{
		border: "1px solid #EF4444",
    borderRadius: "10px",
    padding: "1rem 1.5rem",
    backgroundColor: "transparent",
    color: "#EF4444",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Manrope",
		minWidth: '182px',
	},
	eyeColor: {
    color: '#4E4E4E',
    fontSize: '25px'
  },
	customInput: {
		fontSize: '14px',
		fontFamily: "Manrope",
		fontStyle: "normal" as 'normal', 
	},
	unregisterCancelButton: {
		width: "160px",
		height: "48px",
		padding: "18px 16px 18px 16px",
		gap: "10px",
		borderRadius: "10px",
		background: "unset",
		border: "1px solid"
	},
	unregisterYesButton:{
		width: "160px",
		height: "48px",
		padding: "18px 16px 18px 16px",
		gap: "10px",
		borderRadius: "10px",
		background: "rgba(255, 77, 103, 1)"
	},
	submitButton: {
		width: "160px",
		height: "48px",
		padding: "15px 30px 15px 30px",
		gap: "5px",
		borderRadius: "10px",
	},
	subHeading: {
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: 1.38,
		letterSpacing: '0.2px'
	},
	outlineBtnLight:{
		height:'48px',
		width:'160px',
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		marginTop: '8px',
		padding:"16px", 
		boxShadow:"none !important",
		background: "linear-gradient(to right, #fcfaff, #fcfaff) padding-box,linear-gradient(to right, #3858E3 , #BA05F7) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "10px",
		textTransform: "capitalize" as "capitalize",
		color: "black",  
	},
	outlineBtnDark:{
		background: "linear-gradient(to right, #110b1b, #110b1b) padding-box,linear-gradient(to right, #3858E3 , #BA05F7) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "10px",
		textTransform: "capitalize" as "capitalize",
		color: "#FFF",
		height:'48px',
		width:'160px',
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		marginTop: '8px',
		padding:"16px", 
		boxShadow:"none !important"  
	},
	colorfulBtnTxt: {
		background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		WebkitTextFillColor: 'transparent', 
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',  
		fontFamily: "Manrope",
		textTransform:"none" as "none"
	},
}


export default class SecurityAndAccountAccess extends SecurityAndAccountAccessController {
	constructor(props: Props) {
    super(props);
  }

	renderHeader = (name:string, goTo:string) => {
		return (
			<Box className="headerNav" onClick={() => { (goTo === 'Settings') ? this.props.navigation(goTo) : this.setState({renderSecurityAndAccountAccessType: goTo})}} data-test-id="back-btn">
				<Grid container className="gridContainer" style={{alignItems:'center'}}>
					<Grid item style={{marginLeft:'12px'}}><img src={this.props.checked? backArrowDark : backArrowWhite} /></Grid>
					{name != "Verify Your password" ? 
						<Grid item style={{marginLeft:'12px'}}><Typography className="h2" style={this.props.checked ? {color:'#222222'} : {color:'#fff'}}>{name}</Typography></Grid>
						:
						<Grid item style={{marginLeft:'12px'}}><Typography className="coloredTxt" style={{fontSize:'24px', fontWeight:800, lineHeight:'27px'}}>{name}</Typography></Grid>
					}
				</Grid>
			</Box>
		)
	}

	renderRightArrow = () => {
    const {checked} = this.props;
    return (<Grid item style={{marginLeft:'auto'}}><img src={checked ? forwardArrowLight : forwardArrowDark } /></Grid>)
  }

	renderConnectedDevice = (name: string, lightIcon: string, darkIcon: string, time: string, id:string) => {
		return (
			<Grid container>
				<Grid item><img src={this.props.checked ? lightIcon : darkIcon}/></Grid>
				<Grid item className="flexWrapper" style={{gap:'10px', marginLeft:'10px'}}>
					<Typography className="h5">{name}</Typography>
					<Typography className='h6' style={{color:'#6B6B6B'}}>{time}</Typography>
				</Grid>
				<Grid item style={{marginLeft:'auto'}}>
					<img onClick={() => this.showUnregistermodal(id)} src={this.props.checked ? crossIconLight : crossIconDark}/>
				</Grid>
			</Grid>
		)
	}


	renderSubCat = () => {
    const {checked} = this.props;

		return (
			<Box className="wrapper" data-test-id="SAAA">
				{this.renderHeader("Security & account access", "Settings")}
				<Box className="flexWrapper" style={{gap:'30px'}}>
					<Typography className="h5">Manage your account's security and keep track of your account's usage including apps that you have connected to your account.</Typography>
					<Box className="flexWrapper" style={{gap:'20px'}}>
						<Grid container className="gridContainer" style={{margin:'4.4px 0'}} onClick={() => {this.setState({renderSecurityAndAccountAccessType:'Security'})}} data-test-id="security-btn">
							<Grid item><img src={this.props.checked ? lockIconLight : lockIconDark} style={{marginRight:'20px'}}/></Grid>
							<Grid item xs={8}>
								<Box className="flexWrapper" style={{gap:'10px'}}>
									<Typography className="h5">Security</Typography>
									<Typography className="h6">Manage your account's security.</Typography>
								</Box>
							</Grid>
							{this.renderRightArrow()}
						</Grid>

						<Grid container className="gridContainer" style={{margin:'4.4px 0'}} onClick={this.getAccountsSessions} data-test-id="session-btn">
							<Grid item><img src={this.props.checked ? appsAndSessionLight : appsAndSessionDark } style={{marginRight:'20px'}}/></Grid>
							<Grid item xs={8}>
								<Box className="flexWrapper" style={{gap:'10px'}}>
									<Typography className="h5">Sessions</Typography>
									<Typography className="h6">See information about when you logged into your account and the apps you connected to your account.</Typography>
								</Box>
							</Grid>
							{this.renderRightArrow()}
						</Grid>

						<Grid container className="gridContainer" style={{margin:'4.4px 0'}} onClick={() => this.handleConnectedAccount()} data-test-id="connected-account-btn">
							<Grid item><img src={this.props.checked ? connectedAccountLight : connectedAccountDark} style={{marginRight:'20px'}}/></Grid>
							<Grid item xs={8}>
								<Box className="flexWrapper" style={{gap:'10px'}}>
									<Typography className="h5">Connected accounts</Typography>
									<Typography className="h6">Manage Google or Apple accounts connected to Arrows to log in.</Typography>
								</Box>
							</Grid>
							{this.renderRightArrow()}
						</Grid>
					</Box>
				</Box>
			</Box>
		)
	}

	renderSecurity = () => {
    const {checked} = this.props;

		return (
			<Box className="wrapper">
				{this.renderHeader("Security", "Security & account access")}
				<Box className="flexWrapper" style={{gap:'20px'}}>
					<Typography className="h4" style={{fontWeight:800}}>Manage your account’s security</Typography>
					<Box className={checked ? "grayBoxLight" : "grayBoxDark" } style={{gap:'10px'}}>
						<Box><Button className="comingSoonButon">Coming Soon</Button></Box>
						<Typography className="h5">Two-factor authentication (Login)</Typography>
						<Typography className="h6">Protect your account from unauthorised access by requiring a second authentication method in addition to your Arrows password. You can choose a text message, authentication app, <span style={{color:'#663bea'}}>Learn more.</span></Typography>
					</Box>
				</Box>
			</Box>
		)
	}

	renderUnregisterModal = () => {
		 
		return(
			<Modal           
			className={`logoutModal  dark-${!this.props.checked}`}
			open={ this.state.showUnRegistermodal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
			}}
			>
				<Fade 
					in={this.state.showUnRegistermodal} 
					style={inputStyles.fade}
				>
					 <div className={`unregiterModalContainer dark-${!this.props.checked}`}>
						<h6 className="arrowFontFamily font24 m0">Unregister your device?</h6>
						<p className="description arrowFontFamily font16 txtCenter">Unregistering a device means you'll no longer have access to encrypted messages on it.</p>
						<div style={inputStyles.logoutButtonContainer}>
							<Button className="cancelButton" 
							onClick={this.closeUnRegisterModal} 
							style={inputStyles.unregisterCancelButton}
							>
								Cancel
							</Button>
							<Button className="confirmButton" onClick={this.onConfirmUnregister} 
							style={inputStyles.unregisterYesButton} variant="text">                                  
								Unregister
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		)
	}

	renderVerifyPasswordForUnregister = () => {
		return (
			<Box className="wrapper">
				{this.renderHeader("Verify Your password", "Sessions")}
				<Typography className="arrowFontFamily font16 m0 font500" style={inputStyles.subHeading}>Re-enter your Arrows password to continue.</Typography>				
					
				<div className="verificationContainer">
					{this.state.passwordError !== "" && <div className="pasErrorContainer">{this.state.passwordError}</div> }
					<Formik
						initialValues={{password: "" }}
						onSubmit={this.validPassword}
						data-test-id="validate-fromik-form"
						dirty={this.verifyPasswordDirty}
					>
						{(formikProps) => {
						const { values} = formikProps;
						return (
							this.renderPasswordForm(values)	
						);
						}}
					</Formik>
            	</div>						
			</Box>				
		)
	}

	renderPasswordForm = (values: {password: string}) => {
		return (
			<Form noValidate autoComplete="off">
				<div className="fieldContainer">
					<label htmlFor="passwordInput">Password</label>
					<Field
						component={FormikInputField}
						id="passwordInput2"
						className = "passField"
						name="password"
						value={values.password}
						placeholder="Enter Your Password"
						variant="outlined"
						fullWidth
						type={this.state.isPasswordVisible ? "text" : "password"}
						InputProps={{endAdornment: (
							<InputAdornment
								position="end"
								onClick={this.showPassword}>
								<IconButton style={inputStyles.iconButton}
								>
								{this.state.isPasswordVisible ? (<VisibilityIcon /> ) : (<VisibilityOffIcon />)}
								</IconButton>
							</InputAdornment>
							)}}
					/>
									
					<p className="arrowFontFamily font14 font500 txtGradiant forgotPswd txtEnd m0">Forgot password?</p>
				</div>
				<div className="justifyContentLast" style={inputStyles.logoutButtonContainer}>
					<Button variant="contained" color="primary" onClick={this.goToSessions} style={this.props.checked ? inputStyles.outlineBtnLight : inputStyles.outlineBtnDark} className="m0">
						<Box component="span" style={inputStyles.colorfulBtnTxt}>Cancel</Box>
					</Button>
					<Button style={inputStyles.submitButton} className="submitButton m0" type="submit">
						<p className="txtInMode arrowFontFamily">Next</p> 
						<span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
					</Button>
				</div>								
			</Form>
		)
	}

	renderVerificationCodeForm = (values: {verificationCode: string}) => {
		return (
			<Form noValidate autoComplete="off">
				<div className="fieldContainer">
					<label htmlFor="passwordInput">Verification code</label>
					<Field
						component={FormikInputField}
						id="passwordInput2"
						className = "passField"
						name="verificationCode"
						value={values.verificationCode}
						placeholder="Enter Your Verification code"
						variant="outlined"
						fullWidth
						type={this.state.isPasswordVisible ? "text" : "password"}
						InputProps={{endAdornment: (
							<InputAdornment
								position="end"
								onClick={this.showPassword}>
								<IconButton style={inputStyles.iconButton}
								>
								{this.state.isPasswordVisible ? (<VisibilityIcon /> ) : (<VisibilityOffIcon />)}
								</IconButton>
							</InputAdornment>
							)}}
					/>
									
					<p className="arrowFontFamily font14 font500 txtGradiant forgotPswd txtEnd m0">Didn't received code ?</p>
				</div>
				<div className="justifyContentLast" style={inputStyles.logoutButtonContainer}>					 
					<Button style={inputStyles.submitButton} className="submitButton m0" type="submit">
						<p className="txtInMode arrowFontFamily">Next</p> 
						<span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
					</Button>
				</div>								
			</Form>
		)
	}

	maskEmail = (email:string) => {
		const [name, domain] = email.split('@');
		const maskedName = name.slice(0, 2) + '*'.repeat(name.length - 2);
		const [domainName, domainExtension] = domain.split('.');
		const maskedDomain = domainName.slice(0, 1) + '*'.repeat(domainName.length - 1);
		return `Send an email to ${maskedName}@${maskedDomain}.${domainExtension}`;
	};
	  
	maskPhoneNumber = (phoneNumber:string) => {
		if(phoneNumber){
			const maskedNumber = '*'.repeat(phoneNumber.length - 2) + phoneNumber.slice(-2);
			return `Send an sms to ${maskedNumber}`;
		}
		
	};

	renderSelectAccountEmail = () =>{
		const {checked} = this.props;
		const checkedradioIcon = !checked ? radioCheckedDark : radioCheckedLight;
		return(
			<Box className="wrapper">
				{this.renderHeader("Select your Account", "Sessions")}
				<Box> 
					<Typography className="arrowFontFamily font16 m0 font500" style={inputStyles.subHeading}>You can use the information associated with your account.</Typography>
				</Box>
				<Box>
					<List className={"listReportPost w100"}>
						 
						{(this.state.userData.email && this.state.userData.email!=="" )&& <ListItem alignItems="center" className={`listItemAccountSelect cursorPointer ${this.state.selectedAccountTypeForOtp === "email"?"highlight":""}`} button onClick={() => this.selectAccountType("email")}>
							<ListItemText className="label-container" primary={this.maskEmail(this.state.userData.email)}/>
							<ListItemIcon className="radio-icon">
								<img src={this.state.selectedAccountTypeForOtp === "email" ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
							</ListItemIcon>
						</ListItem>}
						{(this.state.userData.phoneNumber && this.state.userData.phoneNumber !=="")&& <ListItem alignItems="center" className={`listItemAccountSelect cursorPointer ${this.state.selectedAccountTypeForOtp === "phoneNumber"?"highlight":""}`} button onClick={() => this.selectAccountType("phoneNumber")}>
							<ListItemText className="label-container" primary={this.maskPhoneNumber(this.state.userData.phoneNumber)}/>
							<ListItemIcon className="radio-icon">
								<img src={this.state.selectedAccountTypeForOtp === "phoneNumber" ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
							</ListItemIcon>
						</ListItem>} 
						 
					</List>
				</Box>
				<div className="justifyContentLast" style={inputStyles.logoutButtonContainer}>
					<Button onClick={this.goToVerifyCode} style={inputStyles.submitButton} className="submitButton" type="submit">
						<p className="txtInMode arrowFontFamily">Send now</p> 
						<span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
					</Button>
				</div>
				<Loader loading={this.state.sessionsLoading} />
			</Box>
		)
	}

	renderVerifyTheCode = () => {
		const userData = this.state.userData;
		const selectedType = this.state.selectedAccountTypeForOtp ==="email"?  this.maskEmail(userData.email): this.maskPhoneNumber(userData.phoneNumber);
		return(
			<Box className="wrapper">
				<Loader loading={this.state.sessionsLoading} />
				{this.renderHeader("We sent you a code", "selectAccountEmail")}
					<Box> 
						<Typography className="arrowFontFamily font16 m0 font500" style={inputStyles.subHeading}>Enter it below to verify {selectedType}</Typography>
					</Box>

					<Box>
						{this.state.passwordError !== "" && <div className="pasErrorContainer">{this.state.passwordError}</div> }
						<Formik
							initialValues={{verificationCode: "" }}
							onSubmit={this.submitTheOtp}
							data-test-id="validate-fromik-form"
						>
							{(formikProps) => {
							const { values} = formikProps;
							return (
								this.renderVerificationCodeForm(values)	
							);
							}}
						</Formik>
					</Box>
				</Box>
		)
	}

	renderRegisteredString = (isoString:string) => {
		const date = new Date(isoString);
		return `Registered ${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
	}

	renderSession = () => {
		
		return (
			<Box className="wrapper" data-test-id="session-tab">
				{this.renderHeader("Sessions", "Security & account access")}
				{this.state.sessionsLoading ? "" : <React.Fragment>
				
					<Box className="flexWrapper" style={{gap:'20px'}}>
						<Typography className="h4" style={{fontWeight:800}}>Current device</Typography>
						{this.renderConnectedDevice("Web", webIconLight, webIconDark, this.renderRegisteredString(this.state.current_device.attributes?.updated_at),this.state.current_device.id)}
					</Box>

					<Box className="flexWrapper">
						<Box>
							<Typography className="h4" style={{fontWeight:800}}>Other device</Typography>
							<Typography className="h6">You’re registered for encrypted direct messaging on these other devices. <span style={{color:'#663bea'}}>Learn more</span></Typography>
						</Box>
						{(this.state.other_devices || []).map((other: any) => this.renderConnectedDevice("Web", webIconLight, webIconDark, this.renderRegisteredString(other.attributes?.updated_at),other.id))
						}					
					</Box>
				</React.Fragment>}
			</Box>
		)
	}

	renderConnectedAccounts = () => {
    const {checked} = this.props;

		return (
			<Box className="wrapper">
				{this.renderHeader("Connected accounts", "Security & account access")}
				<Typography className="h4" style={{fontWeight:800, lineHeight:'21.6px'}}>There are the social accounts you connected to your Arrows account to log in. you can disable access here.</Typography>
				{this.state.platform !== null && <Box className={checked ? "grayBoxLight" : "grayBoxDark" } style={{gap:'10px'}}>
						<Box>
							<Grid container className="gridContainer" style={{alignItems:'center'}}>
								<Grid item className=""><img src={googleLogo}/></Grid>
								<Grid item className="h5" style={{marginLeft:'20px'}}>Google</Grid>
								<Grid item style={{marginLeft:'auto'}} onClick={() => {this.setState({renderSecurityAndAccountAccessType:'Verify Your password'})}} data-test-id="google-connected"><Typography className="greenText">Connected</Typography></Grid>
							</Grid>	
						</Box>
					</Box>}
				{this.state.platform !== null && <Box style={{marginLeft:"auto"}}>
						<button style={inputStyles.disconnectBtn}>Disconnect</button>
					</Box>}
			</Box>
		)
	}

	renderVerifYourPassword = () => {
		return (
			<Box className="wrapper" data-test-id="password-verfiy-tab">
				{this.renderHeader("Verify Your password", "Connected Accounts")}
				<Grid container>
					<Grid item xs={10}> 
						<Typography className="h5">To disconnect from Google, Please verify your password.</Typography>
					</Grid>
				</Grid>
				{this.state.passError !== "" && <div className="pasErrorContainer" > 
					{this.state.passError}
				</div> }   

				<Box>
				<Formik initialValues={{name: "", email:"", phone:"", message:"" }} onSubmit={() => {}}>
				{(formikProps) => {
              const {values} = formikProps;
              return <Form className="changePassForm" noValidate autoComplete="off">
                    	<div className="fieldContainer">
                      	<label htmlFor="phone">Password</label>
                        <Field
                            component={FormikInputField}
                            id=""
                            className = "passField"
                            name="phone"
                            data-test-id="password-input"
                            value={this.state.password}
                            onChange={(e: { target: { value: any; }; }) => {this.handlePasswordChange(e)}}
														type={this.state.isPasswordVisible ? "text" : "password"}
                            placeholder="Enter your Password"
                            variant="outlined"
                            fullWidth
                            InputProps={{
															endAdornment: (
															<InputAdornment position="start">
																	<IconButton style={inputStyles.iconButton}>
																		{this.state.isPasswordVisible 
																			? (<VisibilityIcon style={inputStyles.eyeColor} data-test-id="visibility-icon-btn" onClick={() => this.setState({isPasswordVisible:false})}/> ) 
																			: (<VisibilityOffIcon style={inputStyles.eyeColor} data-test-id="visibility-icon-btn" onClick={() => this.setState({isPasswordVisible: true})}/>)}
																	</IconButton>
															</InputAdornment>
															),
														}}
                        />
												<Typography className="coloredTxt forgotPassTxt" style={{textAlign:'end', marginTop:'12px'}}>Forgot password?</Typography>
                    	</div>

											<Box style={{display:"flex", justifyContent:'end'}}>
												<button style={inputStyles.disconnectBtn} onClick={() => this.handleDissconnect()} data-test-id="disconnect-btn">Disconnect</button>
											</Box>
										</Form>
            }}
				</Formik>
				</Box>
			</Box>
		)
	}

	renderMessage = () => {
    return(
        <div className="messageScreen">
            <div className="messageContainer">
                <img src={this.state.messageType === "suc" ? sucIcon : ""} />
                <span>{this.state.message}</span>
            </div>
        </div>
    )
  }

	renderSection = () => {
		return (
			<Box>
				{this.state.renderSecurityAndAccountAccessType === "Security & account access" && this.renderSubCat()}
				{this.state.renderSecurityAndAccountAccessType === "Security" && this.renderSecurity()}
				{this.state.renderSecurityAndAccountAccessType === "Sessions" && this.renderSession()}
				{(this.state.renderSecurityAndAccountAccessType === "Connected Accounts" && !this.state.isLoading) && this.renderConnectedAccounts()}
				{this.state.renderSecurityAndAccountAccessType === "Verify Your password" && this.renderVerifYourPassword()}
				{this.state.messageType !=="" &&  this.renderMessage()} 
				{this.state.renderSecurityAndAccountAccessType === "verifyPasswordForUnregister" && this.renderVerifyPasswordForUnregister()}  
				{this.state.renderSecurityAndAccountAccessType === "selectAccountEmail" && this.renderSelectAccountEmail()}
				{this.state.renderSecurityAndAccountAccessType === "verifyTheCode" && this.renderVerifyTheCode()}
				{this.renderUnregisterModal()}
			</Box>
		);
	}


	render() {

		return (
			<React.Fragment>
         <Loader loading={this.state.isLoading} />
        <StyleContainer>
          {this.renderSection()}
        </StyleContainer>
      </React.Fragment>
		)
	}
}


const StyleContainer = styled(Box)({

  "& .fieldContainer": {
    position:'relative',
    display: "flex",
    flexDirection: "column",
    paddingBottom: "30px"
  },
  "& .fieldContainer .MuiOutlinedInput-root":{
      borderRadius: "10px"
  },
  "& .fieldContainer label": {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      marginBottom: "8px"
  },
  "& .messageField": {
    height: '100px',
    borderRadius:'8px',
    border: '1px solid #6B6B6B',
    padding:'9px 16px',
    fontFamily: "Manrope",
  },
  "& .messageField::placeholder":{
    fontFamily: "Manrope",
    fontSize:'14px',
    color:'#6B6B6B',
    fontWeight:600
  },
  "& .messageField:focus":{
    outline:'none',
    border:'2px solid #3858E3'
  },
  "& .wrapper":{
    fontFamily: "Manrope",
    display:'flex',
		flexDirection:'column' as 'column',
		gap: '30px',
		padding:'10px 0',
  },
  "& .flexWrapper":{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',
  },
  "& .headerNav":{
    fontSize:'24px',
		color:'#fff',
  },
  "& .search-container": {
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .fillBtn":{
    borderRadius: "100px",
    padding: "8px 20px",
		background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
		color:"#fff",
		fontWeight:700,
		textTransform:'capitalize' as 'capitalize',
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		fontFamily: "Manrope",
		fontSize: "12px",
		cursor:'pointer',
  },
  "& .fillBtn .label":{
    fontWeight:700,
    fontSize:'14px',
    lineHeight:"16.9px"
  },
  "& .outlineBtnLight": {
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		boxShadow:"none !important",
		background: "linear-gradient(330.07deg, #fcfaff, #fcfaff) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .outlineBtnDark":{
    background: "linear-gradient(330.07deg, #110b1b, #110b1b) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
		color: "#FFF",
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		boxShadow:"none !important",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .submitButton": {
    width: "160px",
    height: "48px",
    padding: "15px 30px 15px 30px",
    gap: "5px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    marginLeft:'auto'
  },
  "& .submitButton .label":{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.8px",
    textAlign: "center",
    textTransform: "unset",
    color: "white"
  },
	"& .comingSoonButon": {
		background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
		padding:'6px 10px 6px 10px',
		borderRadius:'5px',
		border:'none',
		color:"#fff",
		fontWeight:700,
		textTransform:'capitalize' as 'capitalize',
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		fontFamily: "Manrope",
		fontSize: "12px",
		cursor:'pointer',
		height:'24px'
	},
	"& .gridContainer":{
    cursor:'pointer',
  },
	"& .pasErrorContainer":{
			width: "420px",
			padding: "12px 16px 12px 16px",
			gap: "8px",
			borderRadius: "10px",
			border: "0px 0px 0px 1.3px",
			background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
			alignContent: "center",
			color: "rgba(239, 68, 68, 1)"
	},
	"& .messageScreen": {
		position: "fixed",
		width: "100vw",
		height: "100vh",
		top: "0px",
		left: "0px",
		zIndex: 100,
		background: "#000000c7",
		display: "flex",
		justifyContent: "center"
	},
	"& .messageScreen .messageContainer": {         
			background: "#222222",        
			padding: "10px 20px 10px 20px",
			gap: "8px",
			borderRadius: "6px",
			marginTop: "auto",
			marginBottom: "5%",
			display: "flex"
	},
	"& .messageContainer span": {
			margin: "auto",
			fontFamily: "Manrope",
			fontSize: "12px",
			fontWeight: "500",
			lineHeight: "14.4px"
	},
  "& .horizontalLine":{
		width:'100%',
		height:'1px',
		backgroundColor:'#222222',
	},
  "& .grayBoxLight":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #E9E9E9, #E9E9E9) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
  "& .grayBoxDark":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #181818, #181818) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
	"& .coloredTxt": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		WebkitTextFillColor: 'transparent', 
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',  
		fontFamily: "Manrope",
		textTransform:"none" as "none",
    lineHeight:"16.9px"	
  },
	"& .forgotPassTxt":{
    bottom:'0',
		textDecoration:'underline',
		textDecorationColor:'rebeccapurple',
		fontWeight:500,
		cursor:'pointer'
  },
	"& .greenText":{
		color: "#10B981",
		fontSize:'12px',
		fontWeight: 700,
		fontFamily: "Manrope",
	},
  "& .h1":{
    fontWeight: 'bold',
		fontSize: '32px',
		lineHeight: 1.25,
		letterSpacing: '0.27px',
		fontFamily: "Manrope",
  },
  "& .h2":{
    fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: 1.33,
		letterSpacing: '0.2px',
		fontFamily: "Manrope",
  },
  "& .h3":{
    fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: 1.2,
		letterSpacing: '0.2px',
		fontFamily: "Manrope",
  },
  "& .h4":{
    fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
		fontFamily: "Manrope",
  },
  "& .h5":{
    fontWeight: 600,
		fontSize: '16px',
		lineHeight: 1.38,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h6":{
    fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '14.4px',
		color:'#6B6B6B',
    fontFamily: "Manrope",
  },
  "& .verificationContainer .pasErrorContainer":{
	width:"auto",
	marginBottom: "20px"
},
})