export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const edit = require("../assets/edit.png");
export const backIcon = require("../assets/image_icon.png");
export const camera = require('../assets/icons8-camera-24.png');
export const ButtonImage = require("../assets/button.png");
export const search = require("../assets/search.png");
export const checkCircle = require("../assets/checkCircle.png");
export const circle = require("../assets/circle1.png");
export const like = require("../assets/like.png");
export const Fulllike = require("../assets/like_full.png");
export const closeIcon = require("../assets/closeIcon.png");
export const userImage = require("../assets/user.png");
export const storyImage = require("../assets/story.png");