import React from "react";
// Customizable Area Start

import {
    Avatar,
  Grid, Typography,Box, Button, Paper,
  IconButton,
  styled
} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {darkThemeBg,lightThemeBg, view, darkView, RightIcon} from "./assets";
// Customizable Area End

// Customizable Area Start

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
     },
  },
  typography: {
    subtitle1: {
      margin: "10px",
    },
  },
});

const lightThemeCategories = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3858E3",
      },
      secondary:{
        main:'rgba(0,0,0,0.75)'
      }
     }
    });
  
  const darkThemeCategories=createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#3858E3",
        },
        secondary:{
        main:"rgba(255,255,255,0.75)"
        }
       }
      });
// Customizable Area End

import CategoriesController, {
  Props,
  configJSON,
} from "./CategoriesController";

export default class Categories extends CategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

// Customizable Area End
  }

  // Customizable Area Start
  showCategoriesScreen=(classes:{ [key: string]: string })=>{
    let style=this.props.checked?classes.categoryCardLightTheme:classes.categoryCard
    let container= this.props.checked ? classes.cardsContainerLight:classes.cardsContainer
  return(
    <Box className={classes.contentBox}>
        <Avatar className={this.props.checked?classes.avatarLightTheme:classes.avatar} onClick={this.navigateToProfileScreen} data-test-id='backbuttonOtp'>
          <IconButton  style = {{height:"100%",width:'100%',borderRadius:'unset'}}>
          <img src = {this.props.checked?darkView:view} />
          </IconButton>
        </Avatar>
        <Typography data-test-id='selectCategory' className={classes.otpText}>{configJSON.selectCategory}</Typography>
        <Typography className={classes.verifyOtpText}>{configJSON.chooseCategory}</Typography>
        <Loader loading={this.state.isLoading}/>
        <Box className={container}>
        {this.state.categoriesData.map((each)=>(
         <Box key={each.id} data-test-id='categoryCards' className={each.isSelected?classes.activeCategoryCard:
         style} onClick={()=>this.handleSelectCategory(each.id)}>
            <Typography data-test-id='categories' className={classes.categoryName}>{each.name}</Typography>
          </Box>
          ))}
          </Box>
          <Box className={classes.userButtonsContainer}>
            <Button className={this.props.checked ? classes.skipButtonLight : classes.skipButton} data-test-id='skipButton' onClick={this.skipSubCategory}>
             {configJSON.skip}
            </Button>
            <Button
            data-test-id='nextButton'
            className={classes.userNextButton}
            fullWidth
            disabled={this.state.isButtonVisible}
            onClick={this.handleSubmitCategories}
            endIcon={<StyledIcon><img src={RightIcon}/></StyledIcon>}
          >{configJSON.next}
          </Button>
          </Box>
        </Box>
  )
  }
 
  showSubCategoryScreen=(classes:{ [key: string]: string })=>{
    let style=this.props.checked? classes.subCategoriesCardLighTheme:classes.subCategoriesCard
    let ScrollContainter = this.props.checked? classes.scrollContainerLight:classes.scrollContainer
    return(
      <Box className={classes.contentBox}>
        <Avatar className={this.props.checked?classes.avatarLightTheme:classes.avatar} onClick={this.navigateToCategoryScreen} data-test-id='backbuttonSubCategory'>
        <IconButton  style = {{height:"100%",width:'100%',borderRadius:'unset'}}>
          <img src = {this.props.checked?darkView:view} />
          </IconButton>
        </Avatar>
        <Typography data-test-id='subCategoryHeading' className={classes.otpText}>{configJSON.selectSubCategory}</Typography>
        <Typography className={classes.verifyOtpText}>{configJSON.chooseSubCategory}</Typography>
        <Box className={ScrollContainter}>
        {this.state.subCategoryArray?.map((each)=><Box key={each.id} className={classes.cardsBox}>
        <Box className={this.props.checked ?classes.subCategoryMainCardLightTheme:classes.subCategoryMainCard}>
        <Typography className={classes.subCategoryHeading}>{each.attributes.name}</Typography>
       </Box>
       <Box className={classes.subCardsContainer}>
       {each.attributes.sub_categories.map((each)=>
        <Box data-test-id='subCategoryCards' key={each.id} className={each.is_selected? 
        classes.activeSubCategory:style
        } onClick={()=>this.selectSubCategories(each.id)}>
         <Typography data-test-id='subCategories' className={classes.subCategory}>{each.name}</Typography>
         </Box>
        )}
        </Box>
        </Box>
        )}
        
        </Box>
        <Box className={classes.categoryButtonsContainer}>
            <Button className={this.props.checked? classes.skipButtonSubCategoryLight:classes.skipButtonSubCategory} onClick={this.submitCategories} data-test-id='skipButtonCategories'>
             {configJSON.skip}
            </Button>
            <Button
            data-test-id='nextButtonSubCategories'
            className={classes.userNextButtonSubCategory}
            fullWidth
            disabled={this.state.subCategoryDisabled}
            onClick={this.handleSubmitCategoriesSubCategories}
            endIcon={<StyledIcon><img src={RightIcon}/></StyledIcon>}
          >{configJSON.next}
          </Button>
          </Box>
          </Box>
    )
  }
  // Customizable Area End

  render() { 
  // Customizable Area Start
  const {classes}=this.props 
  // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.props.checked ? lightThemeCategories:darkThemeCategories}>
         <Paper className={this.props.checked? classes.thinColor:classes.thickColor}>
         <Box className={!this.props.checked? classes.imageBackgroundDarkTheme:classes.imageBackgroundLIghtTheme}>
         <Grid container className={classes.gridContainer}>
             <Grid item className={classes.imageGridItem} md={5} sm={6} lg={6}></Grid>
        <Grid item xs={9} sm={5} md={5} lg={5} className={classes.gridItem}>
         {!this.state.isScreenChanged? this.showCategoriesScreen(classes):this.showSubCategoryScreen(classes) }
        </Grid>
        </Grid>
       </Box>
        </Paper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    backIconLightTheme:{
        color:'#222222',
        fontSize:'30px'
      },
    avatarLightTheme: {
        borderRadius: "8px",
        backgroundColor:'#E9E9E9',
        cursor:'pointer'
      },
    imageBackgroundDarkTheme:{
        backgroundImage: `url(${darkThemeBg})`,
        height: "100vh",
        width: "100%",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition:'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('xs')]: 
        {backgroundImage: 'none'} 
      },
      imageBackgroundLIghtTheme:{
        backgroundImage: `url(${lightThemeBg})`,
        height: "100vh",
        width: "100%",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition:'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('xs')]: 
        {backgroundImage: 'none'} 
      },
    gridContainer: {
        justifyContent: 'space-between' as'space-between',
        alignItems: 'center',
        height: '90vh',
        paddingTop:"3rem",
        [theme.breakpoints.down('xs')]: 
        {justifyContent: 'center' as 'center'}  
      },
    thinColor:{
        backgroundColor:'#fff'
      },
      thickColor:{
        backgroundColor:'#070707'
      },
    arrowStartText: {
      fontFamily: "Lato",
      fontSize: "20.718px",
      fontStyle: "normal" as "normal",
      fontWeight: 800,
      textAlign: "left" as "left",
    },
  gridItem:{
    display:'flex',
    justifyContent:'center'
  },
  backIcon:{
    color:'#fff',
    fontSize:'30px'
  },
  avatar: {
    borderRadius: "8px",
    backgroundColor:'#222222',
    cursor:'pointer'
  },
  otpText: {
    textAlign: "left" as "left",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    width:421,
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop:'28px',
    letterSpacing:'-0.005'
  },
  verifyOtpText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    width:421,
    textAlign: "left" as "left",
    marginBottom:'30px',
  },
  contentBox:{
   boxSizing:'border-box' as 'border-box',
   width:'430px', 
   marginTop:'40px',
   [theme.breakpoints.down('xs')]: {width:'100%' },
  },
  categoryCard:{
    height:'60px',
    maxWidth:'200px',
    width:"47%",
    borderRadius:'10px',
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    backgroundColor:'#222222',
    "&.MuiBox-root > p":{
      paddingLeft:15
    }
  },

  

  categoryCardLightTheme:{
    height:'60px',
    width:'200px',
    borderRadius:'10px',
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    backgroundColor:'#E9E9E9',
    "&.MuiBox-root > p":{
      paddingLeft:15
    }
  },

  cardsContainer:{
    display:'flex',
    flexDirection:'row' as'row',
    flexWrap:"wrap" as'wrap',
    width:'100%',
    gap:'20px',
    marginTop:'15px',
    paddingRight:15,
    overflow:'auto',
    height:'45vh',
    cursor:'pointer',
   },

   cardsContainerLight:{
    display:'flex',
    flexDirection:'row' as'row',
    flexWrap:"wrap" as'wrap',
    width:'100%',
    gap:'20px',
    marginTop:'15px',
    paddingRight:15,
    overflow:'auto',
    height:'45vh',
    cursor:'pointer',
    "&::-webkit-scrollbar":{
      width: "6px",
    },
    "&::-webkit-scrollbar-track":{
      background: "#E9E9E9",
      borderRadius: "5px",
    },
   "&::-webkit-scrollbar-thumb":{
      background: "#BABABA",
      borderRadius: "5px",
      height:"62px",
    },
    "&::-webkit-scrollbar-thumb:hover":{
      background: "#BABABA"
    }
   },

  categoryName:{
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
  },
  userButtonsContainer:{
    display:'flex',
    flexDirection:'row' as 'row',
    marginTop:'12px',
    width:'100%',
    gap:'20px',
    boxSizing:'border-box' as 'border-box',
    },
  skipButton:{
    background: `url(${darkThemeBg}) padding-box fixed ,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    backgroundRepeat:"no-repeat",
    backgroundPosition:'center',
    backgroundSize:'cover',
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    height:'48px',
    width:'200px',
    lineHeight: '16.8px',
    [theme.breakpoints.down('xs')]:{
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    }, 
    },
    skipButtonLight: {
      background: `url(${lightThemeBg}) padding-box fixed,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      backgroundPosition:'center',
      backgroundRepeat:"no-repeat",
      backgroundSize:'cover',
      textTransform: "capitalize" as "capitalize",
      color: "#8833FF",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      marginTop: "8px",
      height:'48px',
      width:'200px',
      lineHeight: '16.8px',
      [theme.breakpoints.down('xs')]:{
        backgroundClip: "padding-box, border-box",
          backgroundOrigin: "padding-box, border-box",
          border :"1px solid transparent",
          borderRadius: "10px",
        background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      }, 
    },
    skipButtonSubCategory:{
      background: `url(${darkThemeBg}) padding-box fixed ,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      backgroundRepeat:"no-repeat",
      backgroundPosition:'center',
      backgroundSize:'cover',
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "#8833FF",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      marginTop: "8px",
      height:'48x',
      width:'200px',
      [theme.breakpoints.down('xs')]:{
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border :"1px solid transparent",
        borderRadius: "10px",
        background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      }, 
      },
      skipButtonSubCategoryLight:{
        background: `url(${lightThemeBg}) padding-box fixed,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border :"1px solid transparent",
        borderRadius: "10px",
        backgroundPosition:'center',
        backgroundRepeat:"no-repeat",
        backgroundSize:'cover',
        textTransform: "capitalize" as "capitalize",
        color: "#8833FF",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        marginTop: "8px",
        height:'48px',
        width:'200px',
        [theme.breakpoints.down('xs')]:{
          backgroundClip: "padding-box, border-box",
            backgroundOrigin: "padding-box, border-box",
            border :"1px solid transparent",
            borderRadius: "10px",
          background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
        }, 
        },
    userNextButton:{
      borderRadius: "10px",
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      textTransform: "capitalize" as "capitalize",
      color: "#E9E9E9",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      marginTop: "8px",
      height:'48px',
      width:'200px',
      "&.MuiButton-root.Mui-disabled": {
        color: '#E9E9E9',
        opacity:0.64
      }
  },
  userNextButtonSubCategory:{
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    height:'48px',
    width:'200px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
},
  activeCategoryCard:{
    height:'60px',
    maxWidth:'200px',
    width:"47%",
    background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius:'10px',
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    cursor:'pointer',
    color:'#ffffff',
    "&.MuiBox-root > p":{
      paddingLeft:15
    }
  },
  subCategoryMainCard:{
    width: "100%",
    height:"42px",
    borderRadius: "10px",
    backgroundColor:'#181818',
    display:'flex',
    alignItems:'center',
    paddingLeft:'10px',
    boxSizing:'border-box' as 'border-box',
    marginTop:'5px'

  },
  
  subCategoryMainCardLightTheme:{
    width: "100%",
    height:"42px",
    borderRadius: "10px",
    backgroundColor:'#E9E9E9',
    display:'flex',
    alignItems:'center',
    paddingLeft:'10px',
    boxSizing:'border-box' as 'border-box',
    marginTop:'5px'

  },


  subCategoriesCard:{
    width:'49%',
    height:'34px',
    backgroundColor:'#222222',
    borderRadius:'10px',
    marginTop:'10px',
    display:'flex',
    alignItems:'center',
    paddingLeft:'15px',
    boxSizing:'border-box' as 'border-box',
    cursor:'pointer'
  },
  subCategoriesCardLighTheme:{
    width:'49%',
    height:'34px',
    backgroundColor:'#E9E9E9',
    borderRadius:'10px',
    marginTop:'10px',
    display:'flex',
    alignItems:'center',
    paddingLeft:'15px',
    boxSizing:'border-box' as 'border-box',
    cursor:'pointer'
  },
  subCardsContainer:{
    display:'flex',
    justifyContent:'space-between',
    flexWrap:'wrap' as 'wrap',
    width:'100%',
    marginBottom:'20px',
  },
  cardsBox:{
    width:'100%',
    boxSizing:'border-box' as 'border-box',
  },
  scrollContainer:{
    height:'50vh',
    overflow:'auto',
    maxWidth:"421px",
    paddingRight:22,
    width:"100%",
    marginTop:'20px',
  },
  scrollContainerLight:{
    height:'50vh',
    overflow:'auto',
    maxWidth:"421px",
    paddingRight:22,
    width:"100%",
    marginTop:'20px',
    "&::-webkit-scrollbar":{
      width: "6px",
    },
    "&::-webkit-scrollbar-track":{
      background: "#E9E9E9",
      borderRadius: "5px",
    },
   "&::-webkit-scrollbar-thumb":{
      background: "#BABABA",
      borderRadius: "5px",
      height:"62px",
    },
    "&::-webkit-scrollbar-thumb:hover":{
      background: "#BABABA"
    }
  },
  activeSubCategory:{
    width:'49%',
    height:'34px',
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius:'10px',
    marginTop:'10px',
    display:'flex',
    alignItems:'center',
    paddingLeft:'15px',
    boxSizing:'border-box' as 'border-box',
    cursor:'pointer',
    color:'#ffffff'
  },
  subCategoryHeading:{
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as'normal',
    fontWeight: 600,
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  subCategory:{
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
  },
  categoryButtonsContainer:{
    display:'flex',
    flexDirection:'row' as 'row',
    marginTop:'12px',
    gap:'20px',
    maxWidth:"421px",
    boxSizing:'border-box' as 'border-box',
  }
  };
  export const CategoriesScreen = withStyles(styles)(Categories)
// Customizable Area End
