// Customizable Area Start
import React from "react";
import GifController, { Props } from "./GifController.web";
import { styled, Box, IconButton, Modal, TextField, InputAdornment, CircularProgress } from "@material-ui/core";
import { closeLightBtn, gif, searchLight } from "./assets";

export default class Gif extends GifController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { loadingStates, gifList, showGifModal, searchGif } = this.state;

    return (
      <Box>
        <IconButton data-testid="gifButton" onClick={this.handleClickOpenGifModal}>
          <img src={gif} />
        </IconButton>
        <ModalTagPeople
          open={showGifModal}
          onClose={this.handleClickCloseGifModal}
        >
          <Box className="modalWrapper">
            <Box className="modalHeaderWrapper">
              <IconButton onClick={this.handleClickCloseGifModal}>
                <img src={closeLightBtn} width={20} height={20} />
              </IconButton>
              <TextField
                data-testid="search-gif-text-field"
                fullWidth
                autoComplete="off"
                placeholder="Search for GIFs"
                value={searchGif}
                onChange={this.handleChangeSearchGif}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchLight} width={16} height={16} />
                    </InputAdornment>
                  ),
                }}
                className="gifInput"
                autoFocus
              />
            </Box>
            <Box className="gifList">
              {gifList.map(gif => {
                return (
                  <Box key={gif.id} className="gifItem">
                    {loadingStates[gif.id] !== false && (
                      <Box className="gifLoadingWrapper">
                        <CircularProgress size={32} style={{ color: '#8a33f0' }} />
                      </Box>
                    )}
                    <img
                      data-testid="gif-image"
                      key={gif.id}
                      src={gif.downsizeUrl}
                      className="gifItemImg"
                      alt={gif.name}
                      onLoad={() => this.handleImageLoaded(gif.id)}
                      onClick={() => {
                        this.handleClickCloseGifModal()
                        this.props.onSelectGif(gif.url, gif.name, this.props.postIndex)
                      }}
                      style={loadingStates[gif.id] !== false ? { display: 'none' } : {}}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </ModalTagPeople>
      </Box>
    );
  }
}

const ModalTagPeople = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: 'rgba(151, 151, 151, 0.2)',

  '& .modalWrapper': {
    width: '600px',
    borderRadius: '12px',
    backgroundColor: '#191520',
    minHeight: '460px',
    marginTop: '80px',
    paddingBottom: '16px',
  },
  '& .modalHeaderWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px 12px 4px'
  },
  "& .gifInput": {
    border: "1px solid #8a33f0",
    borderRadius: '12px',

    '& [class*="MuiInputAdornment-positionStart"]': {
      marginLeft: '16px',
    },
    '& [class*="MuiInput-underline"]': {
      '&:before': {
        borderWidth: "0",
      },
      '&:after': {
        borderWidth: "0",
      },
      '&:hover': {
        borderWidth: "0",
      }
    },
    '& [class*="MuiInputBase-input"]': {
      padding: '8px 0 10px 12px',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: 500,
    },
  },

  "& .gifList": {
    overflowY: 'scroll',
    maxHeight: '60vh',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    padding: '0 16px',
  },

  "& .gifItem": {
    width: '273px',
    height: '273px',
  },
  "& .gifItemImg": {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  "& .gifLoadingWrapper": {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

// Customizable Area End
