import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface CurrentDevice{
  id: string;
  attributes: {
    updated_at: string
  }
}

export interface IuserData{
  email: string;
  phoneNumber: string;
}

export interface ResponseJson{
  errors?: Array<string | {}>;
  messages?: [{}]| [string];
  data?: {}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  renderSecurityAndAccountAccessType: string;
  from: string;
  isLoading: boolean;
  isPasswordVisible: boolean;
  password: string;
  passError: string;
  platform: string;
  apiError:string;
  messageType:string;
  message:string;
  unregDeviceId: string;
  showUnRegistermodal: boolean;
  current_device: CurrentDevice;
  other_devices: CurrentDevice[] ;
  passwordError: string;
  sessionsLoading: boolean;
  userData: IuserData;
  selectedAccountTypeForOtp: string;
  otpToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SecurityAndAccountAccessController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loggedUserResponseId: string = "";
  dissconnectAccountId: string = "";
  getAccountsSessionsId: string = "";
  valiPasswordId: string = "";
  accountDataId: string ="";
  getOtpApiId: string = "";
  submittedOTPApiId: string = "";
  unregisterApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      renderSecurityAndAccountAccessType: 'Security & account access',
      from: '',
      isLoading: false,
      isPasswordVisible: false,
      password: "",
      passError: "",
      platform: "",
      apiError: "",
      messageType:"",
      message:"",
      unregDeviceId: "",
      showUnRegistermodal: false,
      current_device: {} as CurrentDevice,
      other_devices: [],
      passwordError: "",
      sessionsLoading: true,
      userData: {} as IuserData,
      selectedAccountTypeForOtp: "",
      otpToken: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.loggedUserResponseId) {
          this.setState({platform:responseJson.data.attributes.platform, isLoading:false});
        } else if(apiRequestCallId === this.dissconnectAccountId){
          if (responseJson.errors){
            this.setState({apiError:responseJson?.errors[0],isLoading:false, passError:responseJson?.errors[0]})
            return
          } else{
            this.setMessage("suc", "Your account has been disconnected from google.");
            setTimeout(() => {
              this.setState({
                messageType: "",
                message: ""
              });
              this.setState({renderSecurityAndAccountAccessType:'Security & account access'});
            },2000)
            this.setState({isLoading:false, password:''});
          }
        }
        this.onSessionsApiSuc(message)         
        this.onValiPassAPiResponse(message)
        this.onAccountsApiRes(message)
        this.onOtpSentSuc(message);
        this.onOtpverifiedSuc(message);
        this.unregisterApiCallSuc(message);
       
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }

    // Customizable Area End
  }


  // web events

  // Customizable Area Start
  unregisterApiCallSuc = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.unregisterApiCallId === apiRequestCallId && responseJson){
      if(responseJson.message){
        this.setState({ sessionsLoading: false, renderSecurityAndAccountAccessType: "Sessions"})                                                                                    
        this.setMessage("suc", "Your device has been unregister.");
        setTimeout(() => {
          this.setState({
            messageType: "",
            message: ""
          });
        },2000)
       } else{
         this.setState({sessionsLoading: false})                                                                                    
         this.setMessage("err", "Something went wrong");
         setTimeout(() => {
           this.setState({
             messageType: "",
             message: ""
           });
         },2000)
       }
    }
  }

  onAccountsApiRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.accountDataId === apiRequestCallId && responseJson){
      if(responseJson.data){
        const data = responseJson.data.attributes
        this.setState({
          userData: {
            phoneNumber: data.full_phone_number,
            email: data.email
          }          
        })
      }
    }
  }

  onOtpverifiedSuc = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&  this.submittedOTPApiId === apiRequestCallId){
      if(responseJson.data){
       this.unregisterApiCall()
      } else{
        this.setState({sessionsLoading: false})                                                                                    
        this.setMessage("err", "Something went wrong");
        setTimeout(() => {
          this.setState({
            messageType: "",
            message: ""
          });
        },2000)
      }
    }
  }

  onOtpSentSuc = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.getOtpApiId === apiRequestCallId){
      if(responseJson.errors){
        this.setMessage("err", responseJson.errors[0]);
        setTimeout(() => {
          this.setState({
            messageType: "",
            message: ""
          });
        },2000)
        this.setState({sessionsLoading: false})
      }else if(responseJson.data){
        this.setState({
          renderSecurityAndAccountAccessType:'verifyTheCode',sessionsLoading: false, otpToken: responseJson.meta.token
        })
      }else{
        this.setState({
          sessionsLoading: false
        }) 
      }
    }
  }

  onValiPassAPiResponse = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if( getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.valiPasswordId === apiRequestCallId && responseJson){
      if(responseJson.errors){
        this.setState({ passwordError: responseJson.errors[0]});
      }else if(responseJson.messages && responseJson.messages[0] === "Verified"){
        this.setState({renderSecurityAndAccountAccessType:'selectAccountEmail'})
        this.getAccountData()
      }
    }
  }
   

  verifyPasswordDirty = () => {
    if(this.state.passwordError!==""){
      this.setState({ passwordError: "" });
    }
  }

  onSessionsApiSuc = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if( getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.getAccountsSessionsId === apiRequestCallId && responseJson){
      let other_devices: [CurrentDevice] | never[] = [];    
      if(responseJson.Other_devices.data.length > 0){
        other_devices = responseJson.Other_devices.data
      }
      this.setState({
        current_device: responseJson.current_device?.data,
        other_devices: other_devices,
        sessionsLoading: false
      })
    }
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setMessage = (type:string, msg:string) => {
    this.setState({
      messageType: type,
      message: msg
    })
  }

  handleConnectedAccount = () => {
    this.setState({renderSecurityAndAccountAccessType:'Connected Accounts'});
    this.setState({isLoading:true});
    this.loggedUserResponseId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "account_block/accounts/logged_user"
    })
  }

  handleDissconnect = () => {
    if(this.state.password){
      this.setState({isLoading:true});
      this.dissconnectAccountId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiDeleteMethod,
        endPoint: "account_block/accounts/disconnect_social_account",
        body: {
          'password':this.state.password
        }
      });
    } else{
      this.setState({passError:'Enter Password'})
    }
  }

  handlePasswordChange = (event: { target: { value: string } }) => {
    this.setState({passError:''})
    this.setState({password:event.target.value});
  };

  getAccountsSessions = () => {
    this.setState({renderSecurityAndAccountAccessType:'Sessions'})
    this.getAccountsSessionsId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "account_block/auth_tokens"
    });
  }

  showUnregistermodal = (id: string|undefined) => {
    this.setState({
      showUnRegistermodal: true,
      unregDeviceId: id ? id: ""
    })
  }

  closeUnRegisterModal = () => {
    this.setState({showUnRegistermodal: false, unregDeviceId: ""})
  }
  goToSessions = () => {
    this.setState({renderSecurityAndAccountAccessType:'Sessions'})
  }
  
  goToVerifyCode =() => {
    this.setState({ sessionsLoading: true })
    const keyName = this.state.selectedAccountTypeForOtp === "phoneNumber"? "full_phone_number" : "email"
    const keyValue = this.state.selectedAccountTypeForOtp === "phoneNumber"? this.state.userData.phoneNumber : this.state.userData.email
    this.getOtpApiId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPostMethod,
      endPoint: "account_block/auth_tokens/send_code",
      body: {
        "data": {
          "attributes": {
            [keyName]: keyValue,
            "sms_otp": this.state.selectedAccountTypeForOtp === "phoneNumber"? true: false,
            "email_otp": this.state.selectedAccountTypeForOtp === "phoneNumber"? false: true
          }
        }
      }
     }) 
  }

  onConfirmUnregister = () => {
    this.setState({
      showUnRegistermodal: false,
      renderSecurityAndAccountAccessType: "verifyPasswordForUnregister",
      passwordError: "",
      isPasswordVisible: false
    })
  }
  showPassword = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };
  validPassword = (values:{password: string}) => {
    this.valiPasswordId = this.apiCall({
      contentType:configJSON.ApiContentTypeJson,
      method:configJSON.apiPostMethod,
      endPoint:configJSON.validatePasswordApi,
      body: { password:  values?.password }
    });    
  }
  getAccountData = () => {
    this.accountDataId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "account_block/accounts/logged_user",
    });
  }
  selectAccountType = (accountType: string) => {
    this.setState({
      selectedAccountTypeForOtp: accountType
    })
  }

  submitTheOtp = (values: {verificationCode: string}) => {
    this.setState({
      sessionsLoading: true
    })
    this.submittedOTPApiId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPatchMethod,
        endPoint: "account_block/change_email_or_phone",
        body: {
          "token": this.state.otpToken,
          "otp_code": values?.verificationCode
        }
    })
  }
  unregisterApiCall = () => {
    this.unregisterApiCallId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiDeleteMethod,
      endPoint: `account_block/auth_tokens/${this.state.unregDeviceId}`,
  })
  }
  // Customizable Area End
}
