// Customizable Area Start
import React from "react";
import CommentsController, { Props } from "./CommentsController.web";
import { Box, Typography, Modal, withStyles, createStyles, styled, CircularProgress, Button } from "@material-ui/core";
import PostHeader from "../../activityfeed/src/PostHeader.web";
import PostFooter from "../../activityfeed/src/PostFooter.web";
import { highlightText } from "../../../components/src/utils";
import { darkView, darkRightAngle, attachments, emotion, closeLightBtn, closeIcon } from "../../postcreation/src/assets";
import ReactPlayer from "react-player";
import SwiperCore, { Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { sendIcon } from "./assets";
import { Picker } from "emoji-mart";
import { EmojiPopover } from "../../postcreation/src/PostCreation.web";
import InfiniteScroll from 'react-infinite-scroller';
import CommentItem from './CommentItem.web'
import Hashtags from '../../hashtags/src/Hashtags.web'

SwiperCore.use([Pagination]);

export class Comments extends CommentsController {
  constructor(props: Props) {
    super(props);
  }

  renderVideoPlayer = (url: string) => {
    return (
      <>
        <ReactPlayer
          url={url}
          controls={true}
          width={"100%"}
          height={"100%"}
        />
      </>
    )
  }

  renderUploadingButton = () => {
    return (
      <Box style={{cursor: 'pointer'}} role={undefined} tabIndex={-1} component="label">
        <img src={attachments} width={24} height={24} alt="attachment-icon" />
        <input
          data-testid="filePicker"
          type="file"
          hidden
          multiple
          accept=".png,.jpeg,.webp,.jpg,.mp4"
          style={{
            display: "none",
          }}
          onChange={this.onChangeFile}
        />
      </Box>
    )
  }

  renderEmotionButton = () => {
    const { emojiAnchorEl } = this.state;
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
      <Box>
        <Box 
          data-testid="emoji-picker-button"
          onClick={this.handleClickEmoji} 
          style={{ cursor: 'pointer' }}
        >
          <img src={emotion} width={24} height={24} alt="emoji-icon" />
        </Box>
        <EmojiPopover
          id={id}
          anchorEl={emojiAnchorEl}
          open={open}
          onClose={this.handleCloseEmoji}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Picker
            data-testid="emoji-picker"
            autoFocus
            theme="dark"
            onSelect={this.handleSelectEmoji}
          />
        </EmojiPopover>
      </Box>
    )
  }

  renderComments = (classes: any) => {
    if (this.state.loading) {
      return (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }

    if (this.state.comments.length === 0) {
      return (
        <Box className={classes.emptyCommentWrapper}>
          <Typography className={classes.noCommentTitle}>No comments yet</Typography>
          <Typography className={classes.noCommentDesc}>Start the conversation</Typography>
        </Box>
      )
    }
    return (
      <div 
        className={classes.listCommentContainer} 
        style={{ height: this.commentListHeight}}
        ref={this.scrollCommentsRef}
      >
        <InfiniteScroll
          pageStart={this.state.fetchPage}
          loadMore={() => this.getListComments(this.state.token)}
          hasMore={this.state.fetchPage <= this.state.paginationDetails?.total_pages}
          loader={
            <Box className={classes.loadingWrapper}> 
              {this.state.fetchPage <= this.state.paginationDetails?.total_pages && <CircularProgress size={32} style={{ color: '#8a33f0' }} />}
            </Box>
          }
          useWindow={false}
          getScrollParent={() => this.scrollCommentsRef?.current}
        >
          {this.state.comments.map(comment => {
            return (
              <CommentItem 
                key={comment.id} 
                comment={comment} 
                onLikeComment={this.likeComment}
                onClickReply={this.handleClickReply}
                getReplies={this.getReplies}
                closeReplies={this.closeReplies}
              />
            )
          })}
        </InfiniteScroll>
      </div>
    )
  }

  render() {
    const {
      classes,
      open,
      onClose,
      postData,
      checked,
      userInfo,
      onFollow,
      onClickMoreOption,
      onMuteConformation,
      onBlockAccount,
      onShowReportPost,
      onLikePost,
      onSavePost,
    } = this.props
    const highlights = [...postData.attributes.body_mentions, ...postData.attributes.hash_tags];
    const highlightedBody = postData.attributes.body && highlightText(postData.attributes.body, highlights);

    const hasMedia = postData.attributes.media.length > 0
    const hasMoreThanOneMedia = postData.attributes.media.length > 1

    return (
      <Modal
        className={classes.commentModal}
        open={open}
        onClose={onClose}
      >
        <Box
          className={classes.modalContainer}
          style={{ width: hasMedia ? '75vw' : '600px' }}
        >
          {hasMedia && (
            <MediaWrapper className={classes.mediaContainer}>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                navigation={{ nextEl: `.swiper-button-next`, prevEl: `.swiper-button-prev` }}
                pagination={{ clickable: true }}
                loop
              >
                {postData.attributes.media.map((source: any) => {
                  if (source.content_type.slice(0, 5) === "video") {
                    return (
                      <SwiperSlide key={source.id} className={classes.mediaSwipeWrapper}>
                        {this.renderVideoPlayer(source.url)}
                      </SwiperSlide>
                    )
                  } else if (source.content_type.slice(0, 5) === "image") {
                    return (
                      <SwiperSlide key={source.id} className={classes.mediaSwipeWrapper}>
                        <img src={source.url} alt={source.filename} style={{ width: '100%', objectFit: 'contain' }} />
                      </SwiperSlide>
                    )
                  } else if (source.content_type.slice(0, 5) === "audio") {
                    return (
                      <SwiperSlide key={source.id} className={classes.mediaSwipeWrapper}>
                        <audio src={source.url} controls >
                          Your browser does not support the audio element.
                        </audio>
                      </SwiperSlide>
                    )
                  } else {
                    return null
                  }
                })}
              </Swiper>
              {hasMoreThanOneMedia && (
                <div>
                  <div className='swiper-button-prev'>
                    <img src={darkView} height={12} width={12} />
                  </div>
                  <div className='swiper-button-next'>
                    <img src={darkRightAngle} height={12} width={12} />
                  </div>
                </div>
              )}
            </MediaWrapper>
          )}
          <Box className={classes.contentContainer}>
            <Box className={classes.headerWrapper}>
              <PostHeader
                checked={checked}
                createdAt={postData.attributes.created_at}
                id={postData.id}
                postBy={{
                  id: postData.attributes.post_by?.id,
                  fullName: postData.attributes.post_by?.full_name,
                  userName: postData.attributes.post_by?.user_name,
                  isFollowing: postData.attributes.post_by?.is_following,
                  profilePhoto: postData.attributes.post_by?.profile_photo,
                  userOwnership: postData.attributes.post_by?.user_ownership,
                  userSubscription: postData.attributes.post_by?.user_subscription,
                }}
                userInfo={{
                  id: userInfo?.id,
                }}
                onFollow={onFollow}
                onClickMoreOption={onClickMoreOption}
                onMuteConformation={onMuteConformation}
                onShowReportPost={onShowReportPost}
                onBlockAccount={onBlockAccount}
              />
            </Box>
            {!!highlightedBody && (
              <div ref={this.postBodyTextRef} className={classes.postBodyContain}>
                <Typography className={classes.postBodyText} dangerouslySetInnerHTML={{ __html: highlightedBody }} />
              </div>
            )}
            <Box style={{ flex: 1 }}>
              {this.renderComments(classes)}
            </Box>
            <Box style={{marginBottom: '20px'}}>
              <PostFooter
                checked={checked}
                isComments
                isLiked={postData.attributes.is_liked}
                isSaved={postData.attributes.is_saved}
                modelName={postData.attributes.model_name}
                postId={postData.id}
                repostCount={postData.attributes.repost_count}
                totalComments={postData.attributes.total_comments}
                totalLikes={postData.attributes.total_likes}
                onLikePost={onLikePost}
                onSavePost={onSavePost}
                onClickComments={this.handleClickCommentBtn}
                />
            </Box>
            {this.state.selectedReplyComment && (
              <Box className={classes.replyWrapper}>
                <Typography className={classes.replyText}>
                  {`Replying to ${this.state.selectedReplyComment.attributes.comment_by.user_name}`}
                </Typography>
                <img src={closeLightBtn} alt="close-button" className={classes.closeReplyBtn} onClick={this.handleCloseReply} />
              </Box>
            )}
            {this.state.commentMedia.length > 0 && (
              <Box className={classes.attachmentWrapper}>
                {this.state.commentMedia.map(media => (
                  <Box key={media.id} style={{position: 'relative'}}>
                    {media.contentType.slice(0, 5) === "video" ? (
                      <ReactPlayer
                        url={media.url}
                        controls={false}
                        height='60px'
                        width='auto'
                        style={{ borderRadius: "10px" }}
                      />
                    ) : (
                      <img src={media.url} className={classes.attachmentImage} />
                    )}
                    <img data-testid="removeMedia" src={closeIcon} className={classes.closeAttcachBtn} onClick={() => this.removeCommentMedia(media.id)} />
                  </Box>
                ))}
              </Box>
            )}
            <Box className={classes.inputContainer}>
              <div className={classes.inputWrapper} ref={this.inputWrapperRef}>
                {this.renderEmotionButton()}
                <div style={{flex: 1}} >
                  <Hashtags
                    data-testid='comment-input'
                    navigation
                    id=""
                    checked={checked}
                    value={this.state.commentValue}
                    onChangeValue={(text: { target: { value: string } }) => this.handleComment(text.target.value)}
                    isComment
                    suggestionWidth={this.state.inputWidth}
                    placeholder="Add comment..."
                  />
                </div>
                {this.renderUploadingButton()}
              </div>
              <Button
                className={classes.submitBtn} 
                disabled={this.state.commentValue.trim() === ''}
                onClick={this.submitComment}
              >
                <img src={sendIcon} width={24} height={24} alt="send-icon" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
}

const MediaWrapper = styled(Box)({
  '& .swiper-pagination-bullet': {
    background: '#E9E9E9',
    opacity: 0.7,
    width: '12px',
    height: '12px',
  },

  '& .swiper-pagination-bullet-active': {
    background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
    width: '12px',
    height: '12px',
  },

  '& .swiper-button-prev, .swiper-button-next': {
    position: 'absolute',
    top: '50%',
    width: '32px',
    height: '32px',
    marginTop: '-16px',
    zIndex: 10,
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50%',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    '&::after': {
      content: '""',
    }
  },
})

const styles = createStyles({
  commentModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(151, 151, 151, 0.5)',
  },
  modalContainer: {
    backgroundColor: '#0B0B0B',
    borderRadius: '10px',
    display: 'flex',
    outline: 'none',
    border: '1px solid #35383F',
    overflow: 'hidden',
    height: '85vh',
  },
  mediaContainer: {
    flex: 6,
    overflow: 'hidden',
    position: 'relative',
  },
  mediaSwipeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    flex: 4,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerWrapper: {
    borderBottom: "0.5px solid #222222",
    paddingBottom: '10px',
  },
  postBodyContain: {
    padding: '20px 10px 10px', 
    borderBottom: "0.5px solid #222222",
  },
  postBodyText: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    color: '#fff',
  },
  replyWrapper: {
    marginBottom: '10px', 
    padding: '12px 20px', 
    backgroundColor: '#181818', 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
  },
  replyText: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    color: '#6B6B6B',
  },
  closeReplyBtn: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  attachmentWrapper: {
    display: 'flex',
    gap: '20px',
    marginBottom: '10px'
  },
  attachmentImage: {
    objectFit: 'contain',
    borderRadius: '10px',
    width:'auto',
    height:'60px'
  },
  closeAttcachBtn: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  inputContainer: {
    display: 'flex',
    gap: '20px',
  },
  inputWrapper: {
    flex: 1,
    borderRadius: '10px',
    border: '1px solid #6B6B6B',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0 20px',
  },
  submitBtn: {
    width: '56px',
    height: '56px',
    borderRadius: '10px',
    background: 'linear-gradient(-45deg, #3858E3, #BA05F7)',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
    },
  },
  noCommentTitle: {
    fontSize: "24px",
    fontFamily: "Manrope",
    fontWeight: 800,
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  noCommentDesc: {
    ontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    color: '#FFFFFF',
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: '16px',
  },
  emptyCommentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listCommentContainer: {
    marginTop: '20px',
    overflow: 'auto',
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

export default withStyles(styles)(Comments);

// Customizable Area End