// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Button, Chip, Menu, MenuItem, Typography } from "@material-ui/core";
import PostHeaderController, {
  Props,
} from "./PostHeaderController.web";
import { ownerShipIcon, profileLogo, reshotIcon, reshotLightIcon, threeDots } from "./assests";

export class PostHeader extends PostHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      postBy,
      onClickMoreOption,
      userInfo,
      onFollow,
      createdAt,
      checked,
      id,
      onMuteConformation,
      onBlockAccount,
      onShowReportPost
    } = this.props;

    return (
      <Box className={classes.container}>
        <img
          src={postBy.profilePhoto || profileLogo}
          alt="profile-photo"
          className={classes.postImage}
        />

        <Box className={classes.headconatin}>
          <Box className={classes.ContentHead}>
            <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography className={classes.name}>{postBy.fullName}</Typography>
              <img src={this.verifyImg()} alt='verify-icon' />
              {postBy.userOwnership && <img src={ownerShipIcon} alt="" />}
              <Typography className={classes.userName}>{postBy.userName}</Typography>
              {!(postBy.isFollowing || userInfo?.id == postBy.id) && (
                <Button
                  data-test-id="followId"
                  className={classes.followBtn}
                  onClick={() => onFollow(postBy.id, "follow")}
                >
                  Follow
                </Button>
              )}
            </Box>

            <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span className={classes.statustime}>{createdAt}</span>
              <Chip className={classes.reshotImg} style={{ background: checked ? "" : "rgba(232, 227, 227, 0.13)" }}
                icon={<img src={checked ? reshotLightIcon : reshotIcon} />} label="re-shot" />
            </Box>

          </Box>
          <Box
            data-test-id="menuBtn"
            onClick={(e) => {
              this.handleClickMenu(e)
              onClickMoreOption(postBy, id)
            }}
          >
            <img src={threeDots} className={classes.threedots} alt="more-option" />
          </Box>
          <Menu
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableAutoFocusItem
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className={classes.menuStyle}
          >
            {postBy.id !== userInfo.id ? (
              [
                <MenuItem
                  key="follow"
                  data-test-id="MenuItems1"
                  style={{
                    padding: "9px 20px 9px 16px",
                    marginBottom: "8px"
                  }}
                  className={classes.subcriptionBody}
                  onClick={() =>
                    onFollow(
                      postBy.id,
                      postBy.isFollowing ? "UnFollow" : "follow"
                    )
                  }
                >
                  {postBy.isFollowing ? "UnFollow" : "Follow"} {postBy.userName}
                </MenuItem>,
                <MenuItem
                  key="mute"
                  onClick={onMuteConformation}
                  style={{
                    padding: "9px 20px 9px 16px",
                    marginBottom: "8px"
                  }}
                  className={classes.subcriptionBody}
                >
                  Mute {postBy.userName}
                </MenuItem>,
                <MenuItem
                  key="block"
                  data-test-id="MenuItems3"
                  onClick={() => onBlockAccount(postBy.id)}
                  style={{
                    padding: "9px 20px 9px 16px",
                    marginBottom: "8px"
                  }}
                  className={classes.subcriptionBody}
                >
                  Block {postBy.userName}
                </MenuItem>
              ]
            ) : (
              null
            )}
            <MenuItem
              onClick={this.handleClose}
              style={{ padding: "9px 20px 9px 16px", marginBottom: "8px" }}
              className={classes.subcriptionBody}
            >
              View post engagement
            </MenuItem>
            <MenuItem
              onClick={this.handleClose}
              style={{ padding: "9px 20px 9px 16px", marginBottom: "8px" }}
              className={classes.subcriptionBody}
            >
              Embed post
            </MenuItem>
            <MenuItem
              onClick={onShowReportPost}
              style={{ padding: "9px 20px 9px 16px", marginBottom: "8px" }}
              className={classes.subcriptionBody}
            >
              Report post
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    width: "100%",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },

  postImage: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    objectFit: "cover",
  },

  headconatin: {
    flex: 1,
    display: "flex",
    alignItems: 'flex-start',
    justifyContent: "space-between"
  },

  ContentHead: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  name: {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    whiteSpace: "nowrap",
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  userName: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "14px",
    whiteSpace: "nowrap" as "nowrap",
    color: "#6B6B6B",
  },

  followBtn: {
    width: "65px",
    height: "25px",
    borderRadius: "10px",
    padding: "4px 8px 4px 8px",
    gap: "7px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "white",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },

  statustime: {
    fontSize: "11px",
    fontFamily: "Manrope",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#81999E"
  },

  reshotImg: {
    height: "16px",
    borderRadius: "6px",
    padding: "5px 8px 5px 6px",
    gap: "4px",
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
  },

  threedots: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },

  menuStyle: {
    borderRadius: "5px",
    padding: "10px 0px 10px 0px",
    gap: "8px",
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },

  subcriptionBody: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
  },
});
// Customizable Area End

export default withStyles(styles)(PostHeader);