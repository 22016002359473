import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getStorageData} from "../../../framework/src/Utilities";
export interface HashtagAttributes {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  swag_shots_count: number;
  posts_count: number;
}

interface HashtagData {
  id: string;
  type: string;
  attributes: HashtagAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  checked:boolean
  value: string
  onChangeValue: (e:{target:{value:string}}) => void
  onFocus?: () => void
  showClosePostBtn?: boolean
  onClosePostBtn?: () => void
  isComment?: boolean
  suggestionWidth?: number
  placeholder: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  trendArray:{id:string|number,display?:string}[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HashtagsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tagUsersCallId:string='';
  callBackHandler:(arg:[])=>void=()=>{};
  hashtagHandler:(arg:[])=>void=()=>{}
  hashTagCallId:string='';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      token: "",
      trendArray:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.getMentionUsers(message)
    this.getHahTags(message)

    // Customizable Area End
  }

  // Customizable Area Start
  hashtagApiCallId: string = "";

  componentDidMount = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token:token })
  }

  getMentionUsers=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && !responseJson.errors) {
        if (apiRequestCallId === this.tagUsersCallId) {
          if (responseJson) {
            const result = responseJson.data.map((each: { id: number, attributes: { full_name: string, user_name: string | null, profile_photo: string | null, followers_count: number } }) => ({ id: each.id, fullName: each.attributes.full_name, display: each.attributes.user_name, photo: each.attributes.profile_photo, followers: each.attributes.followers_count }))
            this.callBackHandler(result)

          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else {
        this.hashtagHandler([])
      }
    }
  }
 

 getHahTags=(message:Message)=>{

   if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
     const apiRequestCallId = message.getData(
       getName(MessageEnum.RestAPIResponceDataMessage)
     );

     const responseJson = message.getData(
       getName(MessageEnum.RestAPIResponceSuccessMessage)
     );


     const errorReponse = message.getData(
       getName(MessageEnum.RestAPIResponceErrorMessage)
     );

     if (apiRequestCallId && !responseJson.errors) {
       if (apiRequestCallId === this.hashTagCallId) {
         if (responseJson) {
           const result = responseJson.map((each: { id: number, name: string, taggings_count: number }) => ({ id: each.id, display: each.name, count: each.taggings_count }))
           this.setState({ trendArray: result.slice(0, 4) })
           this.hashtagHandler(result)

         }
         this.parseApiCatchErrorResponse(errorReponse);
       }
     }
     else {
       this.hashtagHandler([])
     }
   }

 } 

  fetchUsers=(query:string,callback:(users: {id:string|number,photo:string|null,fullName?:string,display:string,followers:number}[]) => void)=>{
    this.callBackHandler = callback
    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tagUsersCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMentionEndpoint}?query=${query}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  fetchHastags=(query:string,callback:(users: { display: string; id: string }[]) => void)=>{
    this.hashtagHandler = callback
    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hashTagCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHashEndpoint}?query=${query}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleFocus = () => {
    this.props.onFocus && this.props.onFocus()
  }
  // Customizable Area End
}
