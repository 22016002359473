import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { PostActivityInterface } from "../../activityfeed/src/ActivityFeedController";
import { IActiveUser, IPost, IUserProfileAttributes } from "./ProfileController.web";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    checked:boolean;
    onClosePostActivityModel:()=>void
    postActivityModel:boolean
    post:IPost
    onFollow:(id: number, type: string) => void;
    onClickMoreOption:(activeUser: IActiveUser, postId: string) => void;
    onMuteConformation:() => void;
    onBlockAccount:(id: number) => void;
    userInfo:IUserProfileAttributes;
    onShowReportPost:() => void;
    highlightedBody:string;
    postActivity:PostActivityInterface
    // Customizable Area End
  }
  
  interface S {
     // Customizable Area Start
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
export default class PostActivityModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}