export const insertPhoto = require("../assets/insert_photo.png");
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");
export const searchWhite=require("../assets/search-white.png");
export const searchDark=require("../assets/search-dark.png");
export const profile=require('../assets/profile.png')
export const callDark=require('../assets/callDark.png')
export const callLight=require('../assets/callLight.png')
export const threeDots=require('../assets/threeDots.png')
export const threeDotsLight=require('../assets/threeDotsLight.png')
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const emojiIcon = require("../assets/emojiIcon.png");
export const gallaryIcon = require("../assets/gallaryIcon.png");
export const sendIcon = require("../assets/sendIcon.png");
export const usersDark = require("../assets/users 3.jpg");
export const usersLight = require("../assets/usersLight.svg");





export const memberList = [
    {
        name:"devendra yadav",
        profile:profile,
        message:"Can you please call me",
        unreadCount:6,
        createAt:"10:00 am"        
    },
    {
        name:"dev yadav",
        profile:profile,
        message:"Can you please call ",
        unreadCount:7,
        createAt:"11:00 am"        
    },
    {
        name:"devendra yadav",
        profile:profile,
        message:"Can you please call me",
        unreadCount:2,
        createAt:"12:00 am"        
    },
    {
        name:"dev yadav",
        profile:profile,
        message:"Can you please call",
        unreadCount:12,
        createAt:"10:00 am"        
    },
    {
        name:"raj",
        profile:profile,
        message:"Can you please call",
        unreadCount:7,
        createAt:"8:00 am"        
    },
    {
        name:"aman yadav",
        profile:profile,
        message:"Can you please call me",
        unreadCount:2,
        createAt:"12:00 am"        
    },
    {
        name:"kevin line",
        profile:profile,
        message:"Can you please call",
        unreadCount:12,
        createAt:"9:00 am"        
    },
        
]