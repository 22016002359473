import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Dialog,
  Grid,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { createTheme,  withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import ReactPlayer from "react-player";
import { callDark, callLight,  checkbox,  checkboxFill,  commentIcon,  favActive,  favIcon,  filterIcon,  filterIconDark, notificationIcon, ownerShipIcon,  postLogo,  profileLogo, reshotIcon, reshotLightIcon, reverseIcon, saveActive, saveIcon, searchDark, searchLight, shareIcon, verifyIcon, verifyOrange, verifyYellow } from "./assets";
import {
  BodyMention,
  HashTag,
  Media,
  User,
  FollowersData,
  MentionedData,
} from "./NotificationsController";
import InfiniteScroll from "react-infinite-scroller";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditionRemover =(condition:boolean|undefined,trueStatement:string|undefined,falseStatement:string|undefined)=>{
   return condition?trueStatement:falseStatement
  }

  highlightText = (body:string, highlights:(BodyMention | HashTag)[]) => {    
    const combinedHighlights = [...highlights].sort((a, b) => a.indices[0] - b.indices[0]);
  
    let highlightedBody = '';
    let currentIndex = 0;
  
    combinedHighlights.forEach(highlight => {
      const start = highlight.indices[0];
      const end = highlight.indices[1];
      
      highlightedBody += body.substring(currentIndex, start);
      
      highlightedBody += `<span style="color: ${"#8429Ef"};" >${body.substring(start, end)}</span>`;
      currentIndex = end;
    });
  
    highlightedBody += body.substring(currentIndex);    
    return highlightedBody;
  };

  filterModel =(classes?:{[key:string]:string})=>{
    return <Dialog data-test-id="filterModel" onClose={() => 
      this.handleClose()
     } 
        open={this.state.filterModel} 
        PaperProps={{
          className: this.conditionRemover(!this.props.checked,classes?.modelDarkStyle,classes?.modelLightStyle)
        }}
         
        classes={{paperWidthSm:this.conditionRemover(!this.props.checked,classes?.modelDarkStyle,classes?.modelLightStyle)}} 
     >
       <Grid container justifyContent="center" alignItems="center" direction="column" style={{
              padding: "20px" ,rowGap:"24px",width:"453px",height:"253px"                            
        }}>
          <Box className={classes?.filterBody}>           
        <Box>
          <Typography className={classes?.name}>Filter by </Typography>          
        </Box>   

         <span className={this.props.classes?.lineStyle}/>

         {this.state.filterOption.map((item, index) => (
          <div
            key={index}
            data-test-id="filterOption"
            className={this.props.classes?.filterMessageBody}
            onClick={() => this.handleItemClick(index)}
          >
            <Typography className={this.props.classes?.filterMessage}>{item}</Typography>
            <img
              src={this.state.selectedItems===index ? checkboxFill : checkbox}
              alt=""
              className={this.props.classes?.filterIcon}
            />
          </div>
        ))}
        </Box>                             
      </Grid>
     </Dialog>
  }

  mediaRender = (src:Media,index:number,length:number,classes: { [key: string]: string } = {},) =>{
    const borderRadiusClass = this.getBorderRadiusClass(index, length,classes);
     if(src.content_type.startsWith("image")){
      return <img key={src.id} src={src.url} loading="lazy" alt="post" style={{height:this.conditionRemover(length>2,"165px","264px")}}
       className={`${classes?.postImageStyle} ${borderRadiusClass}`} />
      }else if(src.content_type.startsWith("video")){
        return  <div
        key={src.id}
        className={`${classes?.postImageStyle} ${borderRadiusClass}`}
        style={{height:this.conditionRemover(length>2,"165px","264px")}} 
      >
         <ReactPlayer 
          url = {src.url}
          controls={true}
          loading="lazy"
          className={`${classes?.reactPlayerStyle} ${borderRadiusClass}`}          
         />
         </div> 
      }   
      else if(src.content_type.startsWith("audio")){
      return <audio key={src.id} src={src.url} controls >
        Your browser does not support the audio element.
      </audio>
      }  
  
  }

  renderVerifyIcon= (val:User) =>{
    if(val.user_subscription ){
      switch (val.user_subscription) {
        case 'I am a business':
          return <img src={verifyYellow} alt="" />;
        case 'I am an individual':
          return <img src={verifyIcon} alt="" />;
          case 'I am a news organization':
          return <img src={verifyOrange} alt="" />;          
      }       
     }
  }

  renderOwnershipIcon= (val:User) =>{
    if(val.user_ownership ){
          return <img src={ownerShipIcon} alt="" />;         
      }       
  }

  getBorderRadiusClass = (index:number, length:number,classes?:{[key:string]:string}) => {
    if (length === 1) return classes?.borderRadius;
    const borderClasses = [];
    if (index === 0) borderClasses.push(classes?.topLeftRadius);
    if (index === 1) {
      if (length === 2) {
        borderClasses.push(classes?.topRightRadius, classes?.bottomRightRadius);
      } else {
        borderClasses.push(classes?.topRightRadius);
      }
    }
    if (index === length - 2) {
      if (length === 3) {
        borderClasses.push(classes?.bottomRightRadius);
      } else {
        borderClasses.push(classes?.bottomLeftRadius);
      }
    }
    if (index === length - 1){
      if (length === 3) {
        borderClasses.push(classes?.bottomLeftRadius);
      } else {
        borderClasses.push(classes?.bottomRightRadius);
      }
    }
    return borderClasses.join(' ');
  };

  renderError = ()=>{
    return   <Box className={this.props.classes?.emptyContainer}>
    <img src={notificationIcon} alt="" className={this.props.classes?.notificationIconStyle}  />
    <div className={this.props.classes?.emptyBodyOuter}>
    <Typography  className={this.props.classes?.errorMessage}> No notifications yet</Typography>
    <Typography  className={this.props.classes?.errorSubMessage}> Likes, Comments, & Replies will appear here</Typography>
    </div>
  </Box>
  }

  renderNotification =() =>{
    const {page,pagination}= this.state
    return  <div className={this.props.classes?.bodyStyle} ref={this.scrollParentRef}>
      {this.state.data.length>0? <InfiniteScroll
            pageStart={page}
            loadMore={()=>this.getNotifications()}
            hasMore={page<=pagination?.total_pages}
            loader={<div className={this.props.classes?.loader} key={0}> 
            {page <= pagination?.total_pages&&<CircularProgress disableShrink />}</div>}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef.current}
            data-test-id="notificatioScroller"
            className={this.props.classes?.scrollerStyle}
        >
    <Box className={this.props.classes?.todayContain}>
    <Typography className={this.props.classes?.todayHead}> Today</Typography>
    
   {this.state.data.filter(task => task.attributes.today).map(task => <div className={this.props.classes?.notificationDisplayOuter}> 
    <div className={this.props.classes?.notificationDisplay}>
         <img className={this.props.classes?.imageStyle} src={task.attributes?.notifiable?.post_by?.profile_photo|| profileLogo} alt=""  />
          <div>                
            <Typography className={this.props.classes?.notificationMessage}> {task.attributes?.notifiable?.post_by?.full_name} <img src={verifyIcon} alt="" /></Typography>
            <Typography className={this.props.classes?.notifiactionRegion}>{task.attributes?.action} • {this.timeSince(task.attributes?.created_at)}</Typography>
          </div>
         </div>
         {task.attributes.notification_type =="new_follower"? 
         <Button className={this.props.classes?.followBtn1}>Follow</Button>:
         <img src={postLogo} alt=""  className={this.props.classes?.postlogo} />
        }
    </div>)}
    
    </Box>
    
    <span className={this.props.classes?.lineStyle}/> 

    <Box className={this.props.classes?.todayContain}>
    <Typography className={this.props.classes?.todayHead}> This week</Typography>

    {this.state.data.filter(notifiction => !notifiction.attributes.today).map(notifiction =>  <div className={this.props.classes?.notificationDisplayOuter}> 
      <div className={this.props.classes?.notificationDisplay}>
         <img src={notifiction.attributes?.notifiable?.post_by?.profile_photo|| profileLogo} alt=""  className={this.props.classes?.imageStyle}/>
          <div>                
            <Typography className={this.props.classes?.notificationMessage}> {notifiction.attributes?.notifiable?.post_by?.full_name}</Typography>
            <Typography className={this.props.classes?.notifiactionRegion}>{notifiction.attributes?.action} • {this.timeSince(notifiction.attributes?.created_at)}</Typography>
          </div>
         </div>
         {notifiction.attributes.notification_type !="new_follower"? 
         <img src={postLogo} alt=""  className={this.props.classes?.postlogo} />:
         <Button className={this.props.classes?.followBtn1}>Follow</Button>
        }
    </div>)}
     
    </Box>
    </InfiniteScroll>:this.renderError()}
   </div>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu  id="" checked={this.props.checked} navigation={this.props.navigation}>
      <Box className={this.props.classes?.wrapper}>    
     
      <div style={{display:"flex",columnGap:"24px",width:"100%"}} >
          <Box className={this.props.classes?.notification}>
            {this.filterModel(this.props.classes)}
          <Box className={this.props.classes?.headContainer} >
            <Typography  className={this.props.classes?.headMessage}> Alerts</Typography>
             <img  
              data-test-id="filterbtn"
             onClick={this.handleOpenModel} src={this.conditionRemover(this.props?.checked,filterIconDark,filterIcon)} className={this.props.classes?.filterIconStyle} alt="" />
            </Box> 
            <Box className={this.props.classes?.tabsContainer}>
              <Tabs
                value={this.state.activeIndex}
                onChange={this.handleTabsChange}
                classes={{ indicator: this.props.classes?.indicator }}
                textColor="primary"
                variant="fullWidth"
                data-test-id="tabBtn"
              >
                <Tab label="All" className={this.props.classes?.tabstyle} />
                <Tab label="mentioned" className={this.props.classes?.tabstyle} />
               
              </Tabs>
            </Box>  
           
           { this.state.activeIndex==0 ? 
           this.renderNotification():
             <div className={this.props.classes?.feedBody}>
             { this.state.mentionData.map((val:MentionedData)=>{
               const highlights = [...val.attributes.body_mentions, ...val.attributes.hash_tags];
               const highlightedBody = val.attributes.body && this.highlightText(val.attributes.body, highlights);

            return <Box className={this.props.classes?.postDisplay} key={val.id}>             
              <Box className={this.props.classes?.postHead}>
                 <img
                    src={val.attributes.post_by.profile_photo?val.attributes.post_by.profile_photo :profileLogo}
                    alt="img"
                    className={this.props.classes?.postImage}
                  />

              <Box className={this.props.classes?.headconatin}>                  
                  <Box className={this.props.classes?.ContentHead}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={this.props.classes?.name}>{val.attributes.post_by?.full_name}</Typography>
                      {this.renderVerifyIcon(val.attributes.post_by)} {this.renderOwnershipIcon(val.attributes.post_by)}
                      <Typography className={this.props.classes?.userName}>{val.attributes.post_by?.user_name}</Typography>
                      <span className={this.props.classes?.statustime}>{val.attributes.created_at}</span>
                    </Box>

                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Chip className={this.props.classes?.reshotImg} style={{background: this.conditionRemover(this.props?.checked,"","rgba(232, 227, 227, 0.13)")}}
                       icon={<img src={this.conditionRemover(this.props.checked,reshotLightIcon,reshotIcon)}  />} label="re-shot"/>
                    </Box>

                  </Box>                 
              </Box>

              </Box>

              <Box className={this.props.classes?.postBody}>
              <Typography className={this.props.classes?.postBodyContain} dangerouslySetInnerHTML={{ __html: highlightedBody }} />

              <Box className={this.conditionRemover((val.attributes.media.length>1), this.props.classes?.feedmultiImages,this.props.classes?.feedImages)}>
                {val.attributes.media.map((src:Media,index:number) => (
                  this.mediaRender(src,index,val.attributes.media.length,this.props.classes) 
                ))}                               
              </Box>              
              </Box>  

              <Box className={this.props.classes?.postFooter} 
                style={{borderBottom: this.conditionRemover(this.props.checked,"1px solid #c9c4c4","1px solid #3d3939")}}>
                <span className={this.props.classes?.actionBtn}><img src={commentIcon} alt="" />{val.attributes.total_comments}</span>
                <span className={this.props.classes?.actionBtn}><img src={reverseIcon} alt="" /> {val.attributes.total_reposts}</span>
                <span className={this.props.classes?.actionBtn} 
                  data-test-id="likeBtn"
                 style={{color:this.conditionRemover(val.attributes.is_liked,"red","")}}> 
                <img src={this.conditionRemover(val.attributes.is_liked,favActive,favIcon)} alt="" />{val.attributes.total_likes}
                </span>
                <span className={this.props.classes?.actionBtn}><img src={shareIcon} alt="" />0</span>
                <span className={this.props.classes?.actionBtn}
                   data-test-id="saveBtn"
                >
                  <img src={this.conditionRemover(val.attributes.is_saved,saveActive,saveIcon)} alt="" />
                </span>                
              </Box>
            </Box>
            })}
            </div>}
              </Box>
        
        <Box className={this.props.classes?.sideContain}>
         <Box className={this.props.classes?.sideTop}>
          <TextField
           variant="outlined"
            placeholder="Search"
            onChange={this.setFilter}
            className={this.props.classes?.searchField}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.props?.checked?searchDark:searchLight} alt="" />
              </InputAdornment>
            ),
          }}
         />

         <img src={this.conditionRemover(this.props?.checked,callDark,callLight)} alt="" />
         </Box>

          <Box style={{display:"flex",flexDirection:"column",rowGap:"18px"}}>
          <Box className={this.conditionRemover(this.props?.checked,this.props.classes?.subscriptionBoxLight,this.props.classes?.subscriptionBox)}>
            <Typography className={this.props.classes?.subscrptiontext}>Choose the right subscription for you!</Typography>
            <Typography className={this.props.classes?.subcriptionBody}>Subscribe to unlock new features and if eligible, receive a share of ads revenue.</Typography>
            <Button className={this.props.classes?.continueBtn}>Post</Button>
          </Box>

          <Box className={this.props.classes?.whathappiningBox} style={{border:this.conditionRemover(this.props.checked,"0.5px solid #222222","0.5px solid #1D1D1D"),
          maxHeight:"310px"}} >
             <Typography className={this.props.classes?.name}>Who to follow</Typography>
   
             <Box className={this.props.classes?.followScreen} >            
          {this.state.followers.map((val:FollowersData)=>{
           return  <Box style={{display:"flex",justifyContent:"space-between"}}>
              <Box  style={{display:"flex",gap:"5px",alignItems:"center"}}>            
                 <img
                    src={profileLogo}
                    alt="img"
                    className={this.props.classes?.postImage}
                  />
              <Box className={this.props.classes?.headconatin}>                  
                  <Box className={this.props.classes?.ContentHead}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={this.props.classes?.name}>{val.name}</Typography>
                      <img src={verifyIcon} alt="" />                      
                    </Box>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={this.props.classes?.userName}>{val.username} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
            <Button className={this.props.classes?.followBtn}>Follow </Button>
            </Box>             
          })}            
             </Box>  
             <Typography className={this.props.classes?.showmore}>Show more</Typography> 

          </Box>
          </Box>        
        </Box>
        
       </div> 
           
      </Box>
      </NavigationMenu> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  notification:{
    display:"flex",
    width:"60%",
    maxWidth:"810px",
    flexDirection:"column" as "column",
    rowGap:"24px",
    padding:"0 20px 0px 20px",
    height: "calc(100vh - 20px)",
    "@media (max-width:1000px)": {
      width:"80%",
    },    
  },
  filterMessageBody:{
    display:"flex",
    width:"333px",
    justifyContent:"space-between"
  },
  avatarGroup: {
    display: 'flex',
    position: 'relative' as 'relative', 
    width: 'fit-content', 
    height: '40px',
    marginRight:"53px"
  },
  avatar: {
    position: 'absolute' as 'absolute', 
    left: 0,
    marginLeft: '-10%',
    height:"32px",
    width:"32px",
    border: '1px solid white', 
  },
  postBodyContain:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
    paddingRight:"24px"
  },
  postFooter:{
    display:"flex",
    justifyContent:"space-between",
    marginLeft:"67px",
    paddingBottom:"15px",
   },
   actionBtn:{
    display:"flex",
    alignItems:"center" as "center",
    gap:"5px",
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500, 
    cursor:"pointer",
    color:"#6B6B6B"   
  },
  feedImages :{
    display: "grid" as "grid",
    gap: "10px",   
  },
  postImageStyle: {
    width: "100%",
  },
  feedmultiImages:{
    display: "grid" as "grid",
    gap: "10px",  
    gridTemplateColumns:'1fr 1fr',    
  },
  postBody:{
    paddingLeft:"67px",
    display:"flex",
    flexDirection:"column" as "column",
    rowGap:"20px"   
   },
   reactPlayerStyle:{
    width: "100% !important",
    height:"100%  !important",    
    "& :first-child ":{
      objectFit:"fill  !important",
      borderRadius:"16px"
    }
  },
  modelDarkStyle:{ 
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow:"none"        
  },
  modelLightStyle:{
    borderRadius: "10px", 
    boxShadow:"none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",  
    backdropFilter: "blur(1 px)",    
   },
  wrapper: {
    width: "100%",
    paddingTop:"20px",
    display:"flex",
  },
  filterBody:{
    padding:"20px 0px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"16px",
    width:"100%",
    alignItems:"center"
  },
  filterMessage:{
    fontSize:"16px",
    fontFamily:"Manrope",
    fontWeight:600,
  },
  postImage:{
    height:"35px",
    width:"35px",
    borderRadius:"50%",
    objectFit:"cover" as "cover"
  },
  borderRadius: {
    borderRadius: "16px",
  },
  topLeftRadius: {
    borderTopLeftRadius: "16px",
  },
  topRightRadius: {
    borderTopRightRadius: "16px",
  },
  bottomLeftRadius: {
    borderBottomLeftRadius: "16px",
  },
  bottomRightRadius: {
    borderBottomRightRadius: "16px",
  },
  headconatin:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between"
  },
  continueBtn:{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    Padding:"6px 20px 6px 20px",
    gap:"7px",
    borderRadius:"10px",
    display:"flex",
    width:"35%",
    color:"white",
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    textTransform:"none" as "none",
  },
  followBtn1:{
    width:"72pxpx",
    height:"32px",
    borderRadius:"10px",
    padding:"6px 16px",
    gap:"7px",
    fontSize:"14px",
    fontFamily:"Manrope",
    color:"white",
    fontWeight:500,
    textTransform:"capitalize" as "capitalize",
    background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  followBtn:{
    width:"102px",
    height:"32px",
    borderRadius:"10px",
    padding:"4px 8px 4px 8px",
    gap:"7px",
    fontSize:"12px",
    fontFamily:"Manrope",
    color:"white",
    fontWeight:500,
    textTransform:"capitalize" as "capitalize",
    background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  tabsContainer:{
    height:"35px"
  },
  todayContain:{
    display:"flex",
    flexDirection:"column" as "column",
    gap:"12px",
    width:"100%"
  },
  lineStyle:{
    width:"100%",
    height:"0.5px",
    background:"#222222"
  },
  todayHead:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:700,
  },
  notificationMessage:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:700,
    gap:"4px",
    display:"flex",
    alignItems:"center"
  },
  notificationDisplay:{
    display:"flex",
    gap:"9px",
    alignItem:"center"
  },
  notificationDisplayOuter:{
    display:"flex", 
    justifyContent:"space-between",
  },
  imageStyle:{
   width:"32px",
   height:"32px",
   borderRadius:"50%",
   border:"1px solid #F2F4F7"
  },
  postlogo:{
    width:"38px",
    height:"38px",
    borderRadius:"4px"
  },
  notifiactionRegion:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    color:"#667085"
  },
  postDisplay:{
    padding:"8px 0px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"20px"
  },
  postHead:{
    width:"100%",
    height:"47px",
    padding:"0px 0px 0px 24px",
    display:"flex",
    gap:"10px",
    alignItems:"center"
  },
  feedBody:{
    width:"100%",
    height:"calc(100vh - 89px)",
    overflowY:"scroll" as "scroll",
    rowGap:"24px",
    display:"flex",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    flexDirection:"column" as "column"
  },
  feedsDisplay:{
    display: "flex",
    flexDirection:"column" as "column",
    rowGap: "24px",
  },
  scrollerStyle:{
    width:"100%",
    gap:"20px",
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  bodyStyle:{
    width:"100%",
    height:"calc(100vh - 89px)",
    overflowY:"scroll" as "scroll",
    rowGap:"24px",
    display:"flex",
    padding:"20px",
    alignItems:"center",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    flexDirection:"column" as "column"
  },
  emptyContainer:{
   maxWidth:"375px",
   height:"173px",
   gap:"20px",
   display:"flex",
   flexDirection:"column" as "column",
   alignItems:"center",
   top:"40%",
   position:"absolute" as "absolute"
  },
  emptyBodyOuter:{
    padding:"0px 67px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"15px",
  },
  notificationIconStyle:{
    height:"60px",
    width:"60px"
  },
  errorMessage:{
    fontWeight:800,
    fontSize:"24px",
    fontFamily:"Manrope",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent",

  },
  errorSubMessage:{
    fontWeight:600,
    fontSize:"16px",
    fontFamily:"Manrope",
    lineHeight:"19.2px",
    textAlign:"center" as "center"
  },
  headContainer:{
    marginTpp:"24px",
    display:"flex",
    justifyContent:"space-between",
    padding:"4px 22px" ,
    alignItems: "center"
  },
  headMessage:{
    fontWeight:800,
    fontSize:"24px",
    fontFamily:"Manrope"
  },
  filterIconStyle:{
    height:"13.18px",
    width:"16.88px"
  },
  ContentHead:{
    display:"flex",
    flexDirection:"column" as "column",
    gap:"5px",
  },
  sideContain:{
    height:"96vh",
    maxWidth:"510px",
    overflow:"scroll" as "scroll",
    width:"40%",
    paddingRight:"98px",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    "@media (max-width:1000px)": {
      display:"none" as "none"
    },
  },
  followScreen:{
    display:"flex",
    flexDirection:"column" as "column",
    rowGap:"12px",
  },
  sideTop:{
    display:"flex",
    gap:"21px",
    marginBottom:"28px"
  },
  searchField:{
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px", 
      '&.Mui-focused fieldset': {
        border :"1px solid #7A2BF5",       
      },
    },
    width:"80%"
  },
  subscriptionBox:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #1D1D1D",  
  },
  tabstyle:{
    fontFamily:"Manrope",
    fontWeight:600,
    fontSize:"16px",
    textTransform:"capitalize" as "capitalize",
    borderBottom:"3px solid gray",    
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  } ,
  indicator:{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  whathappiningBox:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column"
  },
  subscriptionBoxLight:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #222222"
  },
  subscrptiontext:{
    fontSize:"18px",
    fontFamily:"Manrope",
    fontWeight:800, 
    lineHeight:"21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent"
  }, 
  subcriptionBody:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
  },
  showmore:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  name:{
    fontSize:"18px",
    fontFamily:"Manrope",
    fontWeight:800,
    lineHeight:"21px",
    whiteSpace:"nowrap" as "nowrap"
  },
  userName:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"14px",
    whiteSpace:"nowrap" as "nowrap",
    color:"#6B6B6B"
  },
  statustime:{
    fontSize:"11px",
    fontFamily:"Manrope",
    fontWeight:400,
    lineHeight:"15px",
    color:"#81999E"
  },
  reshotImg:{
    height:"16px",
    borderRadius:"6px",
    padding:"5px 8px 5px 6px",
    gap:"4px",
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
  },
};

export const WebNotifications = withStyles(webStyle)(Notifications);
// Customizable Area End
