import React from "react";
// Customizable Area Start
import {
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  Avatar,
  IconButton,
  styled
} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'
import { createTheme,ThemeProvider, withStyles} from "@material-ui/core/styles";
import {darkThemeBg,lightThemeBg,darkView,view, RightIcon} from "./assets";

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
});

const lightThemeOwner = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const darkThemeOwner=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    })

// Customizable Area End

import BecomeOwnerController, {
  Props,
  configJSON,
} from './BecomeOwnerController';

export default class BecomeOwner extends BecomeOwnerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
      // Customizable Area Start
      const {classes}=this.props
      const classStyles = classes ? classes : {}
      let style=this.props.checked? classStyles.subCategoriesCardLighTheme:classStyles.subCategoriesCard
      // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.props.checked ? lightThemeOwner:darkThemeOwner}>
        <Paper className={this.props.checked ? classStyles.ownerThinColor : classStyles.color}>
          <Box className={!this.props.checked ? classStyles.ownerImageBackground : classStyles.ownerThinBackground}>
            <Grid container className={classStyles.ownerGridContainer}>
              <Grid item className={classStyles.ownerImageGridItem} md={5} sm={6} lg={6}>
              </Grid>
              <Loader loading={this.state.isLoading} />
              <Grid item xs={10} sm={5} md={5} lg={5} className={classStyles.ownerGridItem}>
                <Box className={classStyles.ownerFormBox}>
                  <Avatar className={this.props.checked ? classStyles.ownerAvatarLightTheme : classStyles.ownerAvatar} data-test-id='backbuttonOtp' onClick={this.navigateToSubscription}>
                  <IconButton style={{borderRadius:'unset',height:'100%',width:"100%"}}> 
                    <img src = {this.props.checked?darkView:view} />
                 </IconButton>
                  </Avatar>
                  <Typography data-test-id='ownerHeading' className={classStyles.ownerHeading}>{this.state.ownerDetails.title}</Typography>
                  <Typography className={classStyles.ownerDescription}>
                    {this.state.ownerDetails.description}
                  </Typography>
                  <Box className={this.props.checked ? classStyles.subCategoryMainCardLightTheme : classStyles.subCategoryMainCard}>
                    <Typography className={classStyles.subCategoryHeading}>{configJSON.sharePrice}</Typography>
                  </Box>
                  <Box className={classStyles.subCardsContainer}>
                    {this.state.ownerShipDetails?.map((ownerShip) =>
                      <Box key={ownerShip.id} data-test-id={`price${ownerShip.id}`} className={ownerShip.attributes.owned ?
                        classStyles.activeSubCategory : style}
                        onClick={() => this.selectPrice(ownerShip.attributes.id)}>
                        <Typography className={classStyles.subCategory}>{ownerShip.attributes.price}</Typography>
                      </Box>
                    )
                    }
                  </Box>
                  <Box className={classStyles.categoryButtonsContainer}>
                    <Button className={this.props.checked?classStyles.skipButtonLight:classStyles.skipButton} data-test-id='skip' onClick={this.navigateToFollower}>
                      {configJSON.skipNow}
                    </Button>
                    <Button
                      data-test-id='submit'
                      className={classStyles.userNextButton}
                      fullWidth
                      disabled={!this.state.isButtonEnabled}
                      onClick={this.handleOwnership}
                      endIcon={<StyledIcon><img src = {RightIcon}/></StyledIcon>}
                    >{configJSON.next}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
   </ThemeProvider>                   
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle={
  color: {
    backgroundColor: '#070707'
  },
  skipButton: {
    background: `url(${darkThemeBg}) padding-box fixed ,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    backgroundRepeat:"no-repeat",
    backgroundPosition:'center',
    backgroundSize:'cover',
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    height: '50px',
    width: '49%',
    [theme.breakpoints.down('xs')]:{
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    }, 
  },
  skipButtonLight: {
    background: `url(${lightThemeBg}) padding-box fixed,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    backgroundPosition:'center',
    backgroundRepeat:"no-repeat",
    backgroundSize:'cover',
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    height: '50px',
    width: '49%',
    [theme.breakpoints.down('xs')]:{
      backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border :"1px solid transparent",
        borderRadius: "10px",
      background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    }, 
  },
  userNextButton: {
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: "8px",
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    },

    height: '50px',
    width: '48%',
  },
  categoryButtonsContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginTop: '12px',
    width: '100%',
    gap: '14px',
    boxSizing: 'border-box' as 'border-box'
  },
  subCategoriesCardLighTheme: {
    width: '49%',
    height: '34px',
    backgroundColor: '#E9E9E9',
    borderRadius: '10px',
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer'
  },
  subCategoriesCard: {
    width: '49%',
    height: '34px',
    backgroundColor: '#222222',
    borderRadius: '10px',
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer'
  },
  subCardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap',
    width: '100%',
    marginBottom: '30px',
  },
  subCategory: {
    fontFamily: 'Manrope',
    fontSize: "12px",
    fontStyle: 'normal',
    fontWeight: 500,
  },
  activeSubCategory: {
    width: '49%',
    height: '34px',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius: '10px',
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer',
    color:'#FFFFFF'
  },
  subCategoryHeading: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  subCategoryMainCard: {
    width: "100%",
    height: "42px",
    borderRadius: "10px",
    backgroundColor: '#181818',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    boxSizing: 'border-box' as 'border-box',
    marginTop: '32px'
  },
  subCategoryMainCardLightTheme: {
    width: "100%",
    height: "42px",
    borderRadius: "10px",
    backgroundColor: '#E9E9E9',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    boxSizing: 'border-box' as 'border-box',
    marginTop: '32px'

  },
  activeCard: {
    backgroundColor: '#2121210A',
    marginTop: '15px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    border: '1px solid #8833FF'

  },
  cardsContainer: {
    overflow: 'auto'
  },
  ownerDescription: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as "normal",
    fontWeight: 600,
  },
  ownerHeading: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as "normal",
    fontWeight: 800,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    marginTop: '30px',
    letterSpacing:'-0.005'
  },
  ownerBackIcon: {
    color: '#fff',
    fontSize: '30px'
  },
  ownerBackIconLightTheme: {
    color: '#222222',
    fontSize: '30px'
  },
  ownerFormBox: {
    width: '95%',
    maxWidth:"436px",
    marginTop:'20px',
  },
  ownerThinColor: {
    backgroundColor: '#fff'
  },
  ownerImageBackground: {
    backgroundImage: `url(${darkThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  ownerThinBackground: {
    backgroundImage: `url(${lightThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  ownerGridContainer: {
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
    [theme.breakpoints.down('xs')]:
      { justifyContent: 'center' as 'center' }
  },
  ownerImageGridItem: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  ownerGridItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  ownerAvatarLightTheme: {
    borderRadius: "8px",
    backgroundColor: '#E9E9E9',
    cursor: 'pointer'
  },
  ownerAvatar: {
    borderRadius: "8px",
    backgroundColor: '#222222',
    cursor: 'pointer'
  }
}

export const BecomeOwnerScreen = withStyles(webStyle)(BecomeOwner);
// Customizable Area End
