// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";
import CommentItemUiController, {
  Props,
} from "./CommentItemUiController.web";
import { favActive, favIcon, profileLogo } from "../../activityfeed/src/assests";
import moment from "moment";
import { getBorderRadiusClass } from "../../../components/src/utils";
import ReactPlayer from "react-player";
import { Media } from "./CommentsController.web";

export class CommentItemUi extends CommentItemUiController {
  constructor(props: Props) {
    super(props);
  }

  renderCommentMedia = (classes: any, src: Media, index: number, length: number) => {
    const borderRadiusClass = getBorderRadiusClass(index, length, classes)
    if (src.content_type.startsWith("image")) {
      return (
        <img 
          key={src.id} 
          src={src.url} 
          loading="lazy" 
          alt="post" 
          style={{ height: length > 2 ? "165px" : "264px" }}
          className={`${classes.postImageStyle} ${borderRadiusClass}`} 
        />
      )
    } else if (src.content_type.startsWith("video")) {
      return (
        <div
          key={src.id}
          className={`${classes.postImageStyle} ${borderRadiusClass}`}
          style={{ height: length > 2 ? "165px" : "264px" }}
        >
          <ReactPlayer
            url={src.url}
            controls={true}
            loading="lazy"
            className={`${classes.reactPlayerStyle} ${borderRadiusClass}`}
          />
        </div>
      )
    }
    else if (src.content_type.startsWith("audio")) {
      return (
        <audio key={src.id} src={src.url} controls >
          Your browser does not support the audio element.
        </audio>
      )
    }
  }

  render() {
    const { classes, data, onLikeComment, isReply, commentId } = this.props;

    return (
      <Box 
        className={classes.container}
        style={isReply ? {padding: '10px 0px 10px 20px'} : {}}
      >
        <Box className={classes.headerContainer}>
          <Box className={classes.userInfoContainer}>
            <img
              src={data.attributes.comment_by.profile_photo || profileLogo}
              className={classes.profilePhoto}
              alt="profile-photo"
            />
            <Box className={classes.userNameWrapper}>
              <Typography className={classes.userNameText}>{data.attributes.comment_by.full_name}</Typography>
              <Typography className={classes.createdAtText}>{moment(data.attributes.created_at).fromNow()}</Typography>
            </Box>
          </Box>
          <Box className={classes.actionWrapper}>
            <img
              data-testid="like-comment-button"
              src={data.attributes.is_liked ? favActive : favIcon}
              className={classes.likeButton}
              alt="like-button"
              onClick={() => onLikeComment(commentId, !data.attributes.is_liked, !!isReply, data.id)}
            />
            <Typography className={classes.totalLikeText}>{data.attributes.total_likes}</Typography>
          </Box>
        </Box>
        <Typography className={classes.commentText}>{data.attributes.body}</Typography>
        <Box className={data.attributes.media.length > 1 ? classes.feedmultiImages : classes.feedImages}>
          {data.attributes.media.map((media: Media, index: number) => (
            this.renderCommentMedia(classes, media, index, data.attributes.media.length)
          ))}
        </Box>
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfoContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  profilePhoto: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
  },
  userNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  userNameText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFF',
  },
  createdAtText: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    color: '#FFF',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'center',
  },
  likeButton: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  totalLikeText: {
    color: '#6B6B6B',
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
  },
  commentText: {
    fontFamily: 'Manrope',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 400,
  },
  bottomLeftRadius: {
    borderBottomLeftRadius: "16px",
  },
  bottomRightRadius: {
    borderBottomRightRadius: "16px",
  },
  borderRadius: {
    borderRadius: "16px",
  },
  topLeftRadius: {
    borderTopLeftRadius: "16px",
  },
  topRightRadius: {
    borderTopRightRadius: "16px",
  },
  postImageStyle: {
    width: "100%",
  },
  reactPlayerStyle: {
    height: "100%  !important",
    width: "100% !important",
    "& :first-child ": {
      borderRadius: "16px",
      objectFit: "fill !important",
    }
  },
  feedmultiImages: {
    display: "grid" as "grid",
    gap: "10px",
    gridTemplateColumns: '1fr 1fr',
  },
  feedImages: {
    display: "grid" as "grid",
    gap: "10px",
  },
});

export default withStyles(styles)(CommentItemUi);
// Customizable Area End