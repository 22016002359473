import React from 'react'
// Customizable Area Start 
import ProfileController,{IPost, IUserProfile, IUserProfileAttributes, PaginationDetails, Props} from './ProfileController.web';
import {
    Box,
    styled,
    TextField,
    Button,
    InputAdornment,
    Typography,
    Tabs,
    Tab,
    Popover,
    CircularProgress
    } from "@material-ui/core"
import PostHeader from '../../activityfeed/src/PostHeader.web';
import PostBody from '../../activityfeed/src/PostBody.web';
import PostFooter from '../../activityfeed/src/PostFooter.web';
import { highlightText } from '../../../components/src/utils';
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import { ArrowIcon, callDark,callLight,searchDark,searchLight, MenuDark, MenuLight, backCover, darkArrowIcon, avatar, lightChatIcon, chatIcon, verifyIcon, enterprenuarship, enterprenuarshipDark, locationIconDark, locationIcon, globalIconDark, globalIcon, one, two, addHighlightstory, defaultUserPic, Notification, NotificationDark, followedDark, followIcon, subscribedIcon } from './assets';
import SubscribedUser from './SubscribedUser.web';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../../components/src/Loader.web';
import moment from 'moment';
import MuteModal from './MuteModal.web';
import ReportUserModal from './ReportUserModal.web';
import Comments from '../../comments/src/Comments.web';
import PostActivity from './PostActivityModal.web';

const followers =[
       {
        name:'abc',
        username:'@abc_23'
       },

       {
        name:'xyz',
        username:'@xyz_23'
       }
    
    ]
   
  const HightlightStories = [
       {
       id:1,
       storyTitle:'Award',
       storyData: [],
       stroyImage:one
      },
      {
        id:2,
        storyTitle:'Award',
        storyData: [],
        stroyImage:two
       },
       {
        id:3,
        storyTitle:'Award',
        storyData: [],
        stroyImage:two
       },
       {
        id:4,
        storyTitle:'WAC2024',
        storyData: [],
        stroyImage:two
       },

       {
        id:5,
        storyTitle:'Award',
        storyData: [],
        stroyImage:one
       },
       {
        id:6,
        storyTitle:'Award',
        storyData: [],
        stroyImage:one
       }
   ]
 

// Customizable Area End

export default class Profile  extends ProfileController {
    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
  
    // Customizable Area Start
    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }
     

    renderTablist=()=>{
      const  { activeIndex } = this.state
      return(
        <Box className={'tabsContainer'}>
        <Tabs
          classes={{ indicator:"indicator" }}
          textColor="primary"
          className='tabs_style'
          value={activeIndex}
          onChange={this.handleTabChange}
          variant="scrollable"
          data-test-id="tabBtn"
        >
          <Tab label="Arrows" value="Arrows" className={'tabstyle'} />
          <Tab label="Replies" value="Replies" className={'tabstyle'} />
          <Tab label="Likes" value="Likes"className={'tabstyle'} />
          <Tab label="Subscriber" value="Subscriber" className={'tabstyle'} />
        </Tabs>
      </Box>
      )
    }

    renderHighlights=()=>{
      return(
        <Box className='hightlightStoryContainer'>
         {HightlightStories.map((item)=>{
          return(
            <Box className='story'>
              <Box className='storyImageContainer'>
              <img className='storyImage'  src={item.stroyImage}/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>{item.storyTitle}</Typography>
              </Box>
           </Box>
          )
         })}

          {
          this.state.isLoggedUser&&<Box className='story'>
              <Box className='AddStoryContainer'>
               <img src = {addHighlightstory} height={24} width={24} alt='add_stroy'/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>Add Story</Typography>
              </Box>
           </Box>
           }
          </Box>
      )
    }


    renderPostActivity=(val:IPost,highlightedBody:string)=>{
      return(
        <Box>
        {this.state.ActivePostActivity&&
          <PostActivity 
            checked={this.props.checked}
            data-testid="PostActivity"
            onBlockAccount={this.handleBlockAccount}
            onClickMoreOption={this.handleClick}
            onClosePostActivityModel={this.handleClosePostActivityModel}
            onFollow={this.handleFollow}
            highlightedBody={highlightedBody}
            onMuteConformation={this.muteConformation}
            navigation={this.props.navigation}
            postActivity={this.state.ActivePostActivity}
            postActivityModel={this.state.showPostActivity}
            onShowReportPost={this.showReportPost}
            userInfo={this.state.LoggedUser?.attributes as IUserProfileAttributes}
            post={val}
          />
        }
        </Box>
      )
    }


    renderCommentModal=()=>{
      const {selectedPostComment,showComments,LoggedUser} = this.state
      const {checked} = this.props
       return(
        <>
         {this.state.selectedPostComment && (
              <Comments
                checked={checked}
                open={showComments} 
                data-testid="comment"
                onClose={this.handleCloseCommentsModal}
                postData={selectedPostComment}
                userInfo={{
                  id:LoggedUser?.attributes.id as number,
                }}
                onFollow={this.handleFollow}
                onClickMoreOption={this.handleClick}
                onLikePost={this.handleLikePost}
                onSavePost={this.handleSavePost}
                onMuteConformation={this.muteConformation}
                onShowReportPost={this.showReportPost}
                onBlockAccount={this.handleBlockAccount}
                onUpdateNumberOfComments={this.handleUpdateCommentNumberOfPost}
              />
            )}
        </>
       )      
    }


    renderSubscribedUserList=()=>{
      return(
        <Box style={{width:'100%'}}>
        {["","","",""].map((subscriber)=>(
        <Box>
          <SubscribedUser id={''} checked={this.props.checked} navigation={this.props.navigation}/>
         </Box>
        ))}
         </Box>
      )
    }
    
    renderPostList=()=>{
      const {checked} = this.props
      const {feed,fetchedPages} = this.state
      const pagination = this.state.pagination as PaginationDetails
      return(

        <div ref={this.scrollParentRef}>        
        <InfiniteScroll
        pageStart={fetchedPages}
        loadMore={this.getUserPosts}
        hasMore={fetchedPages <= pagination?.total_pages}
        loader={<div className={"loader"} key={0}> {fetchedPages <= pagination?.total_pages && <CircularProgress disableShrink />}</div>}
        useWindow={false}
        getScrollParent={() => this.scrollParentRef.current}
        data-test-id="postScroller"
      >

        <Box className='postList'>
          {feed.map((post) => {
                      const highlights = [...post.attributes.body_mentions, ...post.attributes.hash_tags];
                      const highlightedBody = post.attributes.body && highlightText(post.attributes.body, highlights);
                      const postId = post.id
                      return <Box className={'postDisplay'} key={post.id}>
                        <Box style={{ paddingLeft: '24px' }}>
                          <PostHeader
                            data-testid="postHeader"
                            checked={checked}
                            createdAt={post.attributes.created_at}
                            id={postId}
                            postBy={{
                              id: post.attributes.post_by?.id,
                              fullName: post.attributes.post_by?.full_name,
                              userName: post.attributes.post_by?.user_name,
                              isFollowing: post.attributes.post_by?.is_following,
                              profilePhoto: post.attributes.post_by?.profile_photo,
                              userOwnership: post.attributes.post_by.user_ownership,
                              userSubscription: post.attributes.post_by?.user_subscription,
                            }}
                            userInfo={{
                              id: this.state.LoggedUser?.attributes.id as number,
                            }}
                            onFollow={this.handleFollow}
                            onClickMoreOption={this.handleClick}
                            onMuteConformation={this.muteConformation}
                            onShowReportPost={this.showReportPost}
                            onBlockAccount={this.handleBlockAccount}
                          />
                        </Box>
                        <PostBody
                          highlightedBody={highlightedBody}
                          media={post.attributes.media}
                          mediaMentions={post.attributes.media_mentions}
                        />
                        <Box style={{marginLeft: "67px"}}>
                          <PostFooter
                            data-testid="PostFooter"
                            checked={checked}
                            isLiked={post.attributes.is_liked}
                            isSaved={post.attributes.is_saved}
                            modelName={post.attributes.model_name}
                            postId={postId}
                            postedById={(post.attributes.post_by?.id).toString()}
                            repostCount={post.attributes.repost_count}
                            totalComments={post.attributes.total_comments}
                            totalLikes={post.attributes.total_likes}
                            onLikePost={this.handleLikePost}
                            onSavePost={this.handleSavePost}
                            onClickComments={this.handleClickComments}
                            showPostActivity={this.handlePostActivityModel}
                          />
                        </Box>
                        {this.state.ActivePostActivityId == postId && this.renderPostActivity(post,highlightedBody)}
                      </Box>
                    })
          }         
        </Box>
        </InfiniteScroll>
        </div>

      )
    }  

    ReporOthertUser=()=>{
      const popper = Boolean(this.state.anchorEl)
      const popperId = popper ? 'simple-popper' : undefined;
     return(
      <CustomPopover
        id={popperId} 
        open={popper} 
        data-testid="MenuPopover"
        anchorEl={this.state.anchorEl} 
        onClose={this.handleMenuClose}
        style={{background: "rgba(0, 0, 0, 0.4)bottom",zIndex:99999}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
        <Box>  
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
            <Box>
              <Typography className="item_list_name">Share</Typography>
            </Box>
          </Box>
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
            <Box>
              <Typography className="item_list_name">Add/remove from  Custom</Typography>
            </Box>
          </Box>
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
            <Box>
              <Typography className="item_list_name">Mute Account </Typography>
            </Box>
          </Box>
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
            <Box>
              <Typography className="item_list_name">Block User</Typography>
            </Box>
          </Box>
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
            <Box>
              <Typography className="item_list_name">Report</Typography>
            </Box>
          </Box>
         </Box>
       </CustomPopover>  
     )
    }


    renderBlockedAccountButton=()=>{
     return ( 
     <Box className="buttonContainer">
      <Box>
      </Box>
      <Box>
        <Button data-testid="Blocked" className="gradientButton" >Blocked</Button>
      </Box>
     </Box>
     )
    }



    renderOtherUserButton=()=>{
       
      if(this.state.userProfile?.attributes.user_subscription!==null){
        return this.renderOtherUserButtonWithSubscribe()
      }
         else{
        return this.renderOtherUserButtonWithoutSubscribe()
      }
    }

    renderLoggedUserButtons=()=>{
    return(
      <Box className="buttonContainer">
          <Box>
          <Button className='gradientBorderButton'>Earnings</Button>
          </Box>
          <Box>
            <Button className="gradientButton" >Create Plan</Button>
          </Box>
      </Box>
    )
    }


    renderOtherUserButtonWithSubscribe=()=>{
      const {checked} = this.props
      const  userProfile   = this.state.userProfile as IUserProfile
      return(
        <Box className="buttonContainer">
            <Box className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,chatIcon,lightChatIcon)}/>
            </Box>
           {userProfile.attributes.is_following&&<Box className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,NotificationDark,Notification)}/>
            </Box>}

            {userProfile.attributes.is_following&&
              <Box 
                onClick={this.handlefollowUnfollow}
                className={this.conditionRemover(userProfile.attributes.is_following,"gradientIcon",this.conditionRemover(checked,"icons","iconsLight"))}>
                <img src = {this.conditionRemover(userProfile.attributes.is_following,followIcon ,this.conditionRemover(checked,followedDark,followIcon))}/>
              </Box>  
            }
            
            {(!userProfile.attributes.is_following || userProfile.attributes.is_subscribed) &&
               <Box className={this.conditionRemover(userProfile.attributes.is_subscribed,"gradientIcon",this.conditionRemover(checked,"icons","iconsLight"))}>
               <img src = {this.conditionRemover(userProfile.attributes.is_subscribed,subscribedIcon ,this.conditionRemover(checked,subscribedIcon,subscribedIcon))}/>
              </Box> 
            }

            {!(userProfile.attributes.is_following&&userProfile.attributes.is_subscribed)&&<Box>
              <Button 
                onClick={this.handleSubscriberFollow}
                className="gradientButton" >
                  {this.conditionRemover(!userProfile.attributes.is_following,"follow", "subscriber")}
                  </Button>
            </Box>}

        </Box>
      )
    }



    renderOtherUserButtonWithoutSubscribe=()=>{
      const {checked} = this.props
      const userProfile = this.state.userProfile as IUserProfile
      return(
        <Box className="buttonContainer">
            <Box className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,chatIcon,lightChatIcon)}/>
            </Box>
           {userProfile.attributes.is_following&&
           <Box className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,NotificationDark,Notification)}/>
            </Box>
            }
            <Box>
              <Button 
              data-testid="followbtn"
              onClick={this.handlefollowUnfollow}
              className={this.conditionRemover(userProfile.attributes.is_following,"gradientBorderButton","gradientButton")} >
                {this.conditionRemover(userProfile?.attributes.is_following,"following",'follow')}
              </Button>
            </Box>
        </Box>
      )
    }


    renderReportModal=()=>{
      return(
        <ReportUserModal 
        navigation={this.props.navigation} 
        id={''}  
        showReportPostModal={this.state.showReportPostModal} 
        reportPostMainList={this.state.reportPostMainList}
        reportPostSubList={this.state.reportPostSubCatList} 
        setReportPostMainCat={this.setReportPostIssue} 
        setReportPostSubCat={this.setReportPostSubIssues}
        closeReportPostModal={this.closeReportPostModal}
        changeReportView={this.changeReportView}
        reportPostLoading={false} 
        reportCurrentView={this.state.reportCurrentView} 
        reportCatId={this.state.reportIssue} 
        reportSubCatId={this.state.reportSubIssue}
        checked={this.props.checked}   
        data-testid="ReportPost"     
        />
      )
    }
    

    renderMuteModal=()=>{
      return(
        <MuteModal 
        navigation={this.props.navigation} 
        id={''} 
        activeUser={this.state.activeUser} 
        data-testid="MuteModal"
        onMuteModelClose={this.handleMuteModelClose} 
        onMuteUser={this.handleMuteModel} 
        checked={this.props.checked} 
        bottompoper={this.state.bottompoper}
        onUnmuteUser={this.handleUnmuteUser}
        onButtompoperlClose={this.handleButtompoperlClose}
        muteModel={this.state.muteModal} />
      )
    }
    
    renderButtons=()=>{
      if(this.state.isLoggedUser){
       return this.renderLoggedUserButtons()
      }
      else if(this.state.userProfile?.attributes.is_blocked){
        return this.renderBlockedAccountButton()
      }
      else{
       return this.renderOtherUserButton()
      }
    }

    // Customizable Area End 
    render() {
      // Customizable Area Start 
      const {checked} = this.props
      const TabItem = {tabItem:'Profile'}
      const NavigationTab = this.state.isLoggedUser? TabItem :{}  
      // Customizable Area End
      return (
        // Customizable Area Start 
         <NavigationMenu id='' navigation={this.props.navigation} checked={this.props.checked} {...NavigationTab} >
          {this.state.userProfile&&<BoxContainer>
          <Box className='centerPortionWithTopNavition'>  
          <Box className='TopNavigation'>
                  <Box><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon,ArrowIcon)} /></Box>
                   <Box onClick={this.openMenu}><img className='topIcon' src={this.conditionRemover(checked,MenuDark,MenuLight)} data-testid="Menu_icon"  /></Box>
                </Box>
            <Box className='centerPortion'>
                <Box className='userProfile'>
                <Box className='BackCover'>
                    <img src={this.state.userProfile.attributes.cover_photo || backCover} className='backCoverImage'/>
                </Box>
                <Box className='userProfileBox'>
                    <Box className='userProfileImageContainer'>
                     <Box className='AvatarImage'>
                       <img src={this.state.userProfile.attributes.profile_photo || defaultUserPic} className='AvatarStyle'/>
                     </Box>
                     {this.renderButtons()}
                    </Box>
                    <Box className='userDetails'>
                    <Box className='userInformation'>
                        <Box className="fullNameStyle">{this.state.userProfile.attributes.full_name}</Box>
                        <Box className="usernameStyle">{this.state.userProfile.attributes.user_name}</Box>
                        <Box> {this.renderVerifyIcon(this.state.userProfile.attributes)} </Box>
                        <Box> {this.renderOwnershipIcon(this.state.userProfile.attributes)} </Box>
                     </Box>
                     <Box className='location_work_info'>
                        {this.state.userProfile.attributes.occuption&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,enterprenuarshipDark,enterprenuarship)}/> </Box>
                           <Box><Typography className='userConnectDetail' >{this.state.userProfile.attributes.occuption}</Typography> </Box>
                        </Box>}
                        {this.state.userProfile.attributes.location&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,locationIconDark,locationIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>{this.state.userProfile.attributes.location}</Typography></Box>
                        </Box>}
                       {this.state.userProfile.attributes.website&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,globalIconDark,globalIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>{this.state.userProfile.attributes.website}</Typography></Box>
                        </Box>}
                     </Box>
                     <Box className='userDescription'>
                       <Typography className='userDescription-paragraph'>{this.state.userProfile.attributes.bio}</Typography>
                     </Box>
                     </Box>

                     <Box className='followers_count_container'>
                       <Box><Typography className='follow_text'>{this.state.userProfile.attributes.followers_count} Followers</Typography></Box> 
                       <Box><Typography className='follow_text'>{this.state.userProfile.attributes.following_count} Following</Typography></Box> 
                       <Box><Typography className='follow_text'> {moment(this.state.userProfile.attributes.created_at).format("MMMM yyyy")}</Typography></Box> 
                     </Box>
                </Box>
                </Box>
                {this.renderHighlights()}
                {this.renderTablist()}
                {this.state.activeIndex === "Arrows" && this.renderPostList()}
                {this.state.activeIndex === "Replies" && this.renderPostList()}
                {this.state.activeIndex === "Likes" && this.renderPostList()}
                {this.state.activeIndex === "Subscriber" && this.renderSubscribedUserList()}
            </Box>
            </Box>
            <Box className='rightPortion'>
            <Box className={"sideTop"}>
          <TextField
           variant="outlined"
            placeholder="Search"
            className={"searchField"}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.conditionRemover(checked,searchDark,searchLight)} alt="" />
              </InputAdornment>
            ),
          }}
         />

         <img src={this.conditionRemover(checked,callDark,callLight)} alt="" />
         </Box>

          <Box style={{display:"flex",flexDirection:"column",rowGap:"18px"}}>
          <Box className={this.conditionRemover(checked,"subscriptionBoxLight","subscriptionBox")}>
            <Typography className={"name"}>Subscribe to Premium</Typography>
            <Typography className={"subcriptionBody"}>Subscribe to unlock new features and if eligible, receive a share of ads revenue.</Typography>
            <Button className={"continueBtn"}>Continue</Button>
          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D")}}>
             <Typography className={"name"} style={{whiteSpace:"wrap"}}  >Whats Happening</Typography>
             {["","","",""].map((trend)=><Box style={{display:"flex",flexDirection:"column",rowGap:"4px"}}>
              <Typography className={"subcriptionBody"}>Trending in India</Typography>
               <Typography className={"name"}>#shardot</Typography>
               <Typography className={"subcriptionBody"}>19.5k posts</Typography>
            </Box>)}
            <Typography className={"showmore"}>Show more</Typography>

          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D"),maxHeight:"299px"}} >
             <Typography className={"name"}>Who to follow</Typography>
   
             <Box className={"followScreen"} >            
          {followers.map((follower:{name:string,username:string})=>{
           return  <Box style={{display:"flex",justifyContent:"space-between"}}>
              <Box  style={{display:"flex",gap:"5px",alignItems:"center"}}>            
                 <img
                    src={avatar}
                    alt="img"
                    className={"postImage"}
                  />
              <Box className={"headconatin"}>                  
                  <Box className={"ContentHead"}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"name"}>{follower.name}</Typography>
                      <img src={verifyIcon} alt="" />                      
                    </Box>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"userName"}>{follower.username} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
            <Button className={"followBtn"}>Follow </Button>
            </Box>             
          })}            
             </Box>   

          </Box>
          </Box> 
            </Box>
            {this.ReporOthertUser()}
            {this.renderMuteModal()}
            {this.renderReportModal()}
            {this.renderCommentModal()}
          </BoxContainer>}

          <Loader loading={this.state.loading} />
         </NavigationMenu>
        // Customizable Area End
      );
    }
  }

  // Customizable Area Start 

  const CustomPopover=styled(Popover)({
    "& .MuiPaper-elevation8-53":{
       boxShadow:'none'
    },

    "& .MenuItem":{
    width: "256px",
    height:"32px",
    display:'flex',
    padding:'9px 20px 9px 16px',
    alignItems:'center',
    cursor:'pointer',
    gap:"8px",
  },
  "& .item_list_name":{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:'Manrope',
    color:'#fff'
   }
  })
  const BoxContainer = styled(Box)({
    width: "100%",
    display:"flex",
    "& .centerPortionWithTopNavition":{
      display:'flex',
      width:'60%',
      flexDirection:'column',
      "@media (max-width:1000px)": {
        width:'100%'
       }
    },
    "& .TopNavigation":{
      display:'flex',
      flexDirection:'row',
      position:'sticky',
      top:1,
      zIndex:1000,
      maxWidth:" 810px",
      padding:"20px",
      justifyContent:'space-between',
      alignItems:'center',
    },
    "& .centerPortion":{
        height: "calc(100vh - 84px)",
        display: "flex",
        padding:" 0 20px 0px 20px",
        rowGap: "24px",
        scrollbarWidth:'none',
        width:'85%',
        overflowY:'scroll',
        maxWidth:" 810px",
        flexDirection: "column",
    },
    "& .topIcon":{
        padding:"10px 12px",
       "&:hover":{
        opacity:0.7,
        background:'grey'
       }
    },
    "& .rightPortion":{
        height:"96vh",
        paddingTop:'20px',
        maxWidth:"510px",
        overflow:"scroll" as "scroll",
        width:"40%",
        paddingRight:"98px",
        scrollbarWidth: "none" as "none",
        "&::-webkit-scrollbar": {
          display: "none" as "none",
        },
        "@media (max-width:1000px)": {
          display:"none" as "none"
        },
    },
    "& .sideTop":{
        display:"flex",
        gap:"21px",
        marginBottom:"28px"
      },

    "& .postDisplay": {
        padding: "8px 0px",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px"
    },

    "& .searchField":{
        '& .MuiOutlinedInput-root': {
          borderRadius: "10px", 
          '&.Mui-focused fieldset': {
            border :"1px solid #7A2BF5",       
          },
        },
        width:"80%"
      },  
      

    "& .subscriptionBoxLight":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #222222"
    },
    "& .subscriptionBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #1D1D1D",  
      },
    
    "& .subscrptiontext":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800, 
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent' as "transparent"
    }, 
    "& .subcriptionBody":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
    },
    
    "& .showmore":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },

    "& .name":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800,
        lineHeight:"21px",
        whiteSpace:"nowrap" as "nowrap",
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        }
    },

    "& .whathappiningBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column"
    },

    "& .followScreen":{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"12px",
        height:"270",
        overflowY:"scroll" as "scroll",
        padding: "12px",
      },
    
    "& .continueBtn":{
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        Padding:"6px 20px 6px 20px",
        gap:"7px",
        borderRadius:"10px",
        display:"flex",
        width:"35%",
        color:"white",
        fontSize:"12px",
        fontFamily:"inter",
        fontWeight:500,
        textTransform:"none" as "none",
    },  

    "& .userProfile":{
       width:'100%',
       position:'relative',
    },

    "& .BackCover":{
        width:"100%",
        height:"229px",
    },

    "& .backCoverImage":{
        objectFit:'cover',
        width:"100%",
        height:'100%'
    },

    "& .userProfileBox":{
      backgroundColor: '#D9D9D933',
      marginLeft:"12px",
      background:'transparent',
      marginRight:'12px',
      width:'calc(100% - 24px)',
      border:'1px solid #fffff',
      borderRadius:'14px',
      backdropFilter:'blur(32px)',
      marginTop:'-60px'
    },
    
    "& .AvatarImage":{
      height:62,
      width:62,
      marginTop:'-24px',
      borderRadius:'50%',
      marginLeft:'18px',
      backgroundClip: "padding-box, border-box",
      border :"3px solid transparent",
      backgroundOrigin: "padding-box, border-box",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,

    },

    "& .AvatarStyle":{
        height:62,
        width:62,
        borderRadius:'50%',
        objectFit:'cover'
      },


    "& .userProfileImageContainer":{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },

    "& .loader": {
      display: "flex",
      width: "100%",
      justifyContent: "center" as "center"
    },
    
    "& .buttonContainer":{
      marginRight:'20px',
      display:'flex',
      gap:'5px'
    },

    "& .Icons":{
        background:'#E9E9E9',
        padding:"2px",
        height:24,
        width:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        borderRadius:"50%"
    },


    "& .IconsLight":{
        background:'#222222',
        padding:"2px",
        height:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        width:24,
        borderRadius:"50%"
    },

    "& .IconImage":{
        height:18,
        width:18
    },

    "& .gradientIcon":{
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        padding:"2px",
        height:24,
        width:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        borderRadius:"50%"
    },

    "& .gradientBorderButton":{
        minWidth:'70px',
        height:'24px',
        padding:"6px 10px",
        textAlign:'center',
        width:'100%',
        borderRadius:'5px',
        textTransform:'capitalize',
        color:'#fff',
        fontFamily:'Manrope',
        fontWeight:700,
        position: 'relative',
        zIndex: 0,
        "&::before":{
          aspectRatio: 1,
          height:24,
          content: "''",
          position: 'absolute',
          zIndex: -1,
          gap:'6px',
          padding: '1px',
          minWidth:'70px',
          borderRadius:'5px',
          width: '100%',
          background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
          webkitMask:  'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          webkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        }
    },

    "& .gradientButton":{
        padding:"6px 10px",
        height:24,
        gap:'6px',
        fontFamily:'Manrope',
        fontSize:12,
        borderRadius:'5px',
        textTransform:'capitalize',
        fontWeight:700,
        color:'#fff',
        minWidth:'70px',
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)'
    },

    "& .userInformation":{
        display:'flex',
        position:'unset',
        alignItems:'center',
        justifyContent:'left',
        height:"unset",
        gap:'5px',
        paddingTop:'5px',
        margin:'unset',
    },

    "& .fullNameStyle":{
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "left",
    },

    "& .usernameStyle":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: "left",
    },

    "& .userDescription-paragraph":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "left",
    },

    '& .userDetails':{
     padding:'0px 20px',
     display:'flex',
     flexDirection:'column',
     gap:'5px'
    },

    "& .location_work_info":{
        margin:'5px 0px',
        display:'flex',
        gap:'20px'
    },

    "& .info_container":{
        display:'flex',
        gap:'5px',
        alignItems:'center',
        justifyContent:'center'
    },

    "& .userConnectDetail":{
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: 500,
        textAlign: 'left',
    },

    "& .followers_count_container":{
        display:'flex',
        padding:20,
        gap:'49px'
    },

    "& .follow_text":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: 'left',
    },

    "& .postImage":{
        height:"35px",
        width:"35px",
        borderRadius:"50%",
        objectFit:"cover" as "cover"
    },

    "& .ContentHead":{
        display:"flex",
        flexDirection:"column" as "column",
        gap:"5px",
    },

    "& .headconatin":{
        display:"flex",
        width:"100%",
        justifyContent:"space-between"
    },

    "& .followBtn":{
        width:"65px",
        height:"25px",
        borderRadius:"10px",
        padding:"4px 8px 4px 8px",
        gap:"7px",
        fontSize:"12px",
        fontFamily:"Manrope",
        color:"white",
        fontWeight:500,
        textTransform:"capitalize" as "capitalize",
        background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },

      '& .postList':{
        width:"100%"
      },
   
      "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        minWidth:'unset !important',
        width:'25%',
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid gray",
        '&.Mui-selected': {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent' as "transparent",
        },
       
        '@media(max-width:1000px)':{
          width:'50%'
        },
      },
      "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
      },

      "& .tabsContainer": {
        height: "35px",
        width:'100%',
      },

      "& .hightlightStoryContainer":{
        margin:12,
        display:'flex',
        flexDirection:'row',
         minHeight:84,
         overflowX:'scroll',
         overflowY:'hidden',
        scrollbarWidth:'none',
        gap:20
      },

      "& .story":{
        display:'flex',
        flexDirection:'column',
        gap:5
      },

      "& .storyImage":{
        border: '2px solid #6B6B6B',
        width: '64px',
        height: '64px',
        borderRadius: '50px'
      },

      "& .AddStoryContainer":{
        width: '64px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '64px',
        borderRadius: '50px',
        border: '2px solid #fff',
        background:'#6B6B6B'
      },

      "& .story_Text":{
        fontFamily: 'Manrope',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '12px',
        textAlign: 'center',
      }
  })
  // Customizable Area End
  