import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton
} from "@material-ui/core";
import { RefObject } from 'react';
import { withStyles, ThemeProvider, createTheme, styled } from '@material-ui/core/styles';
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import {appleDarkLogo, darkBackground, lightBackground, activeDot, inActiveDot, verificationMark, googleLogo, appleLogo,inActiveDotImage, addIcon, RightIcon} from "./assets";
import Loader from "../../../components/src/Loader.web";
import TermsConditionModal from "../../../blocks/otp-input-confirmation/src/TermsConditionModal.web";
import Carousel from 'react-elastic-carousel';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
interface CustomRefType extends RefObject<Carousel> {
  goTo: (val: number) => void;
}


const StyledGrid = withStyles({
  container: {
    "& .rec-carousel-item": {
      display: "flex",
      alignItems: "center",
    },
    "& .rec-carousel-wrapper":{
      flexDirection:'row'
    }
  }
})(Grid);

const StyledIcon = styled(IconButton)({
   padding:0,
   marginLeft:-4,
   "&.MuiIconButton-root:hover":{
    background:'unset'
   }
})


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }

});

const webStyles={
  google:{
    opacity: 0,
    cursor: 'pointer',
    zIndex: 1,
    left: '1.5%',
    position: "absolute" as 'absolute',
    overflow: 'hidden',
    top:'21%',
    height:'100px'
},
corouselContainer:{
  height:'100vh',
  overflow:"auto"
},
loginCorousel:{
  paddingRight:'10px',
  marginLeft:'auto',
  paddingTop:'30px',
},
productText:{
    fontFamily: 'Manrope',
    fontSize: "2.5625rem",
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '63px',
    textAlign:'center' as 'center'
},
unknownText:{
    fontFamily: 'Manrope',
    fontSize: "1.2rem",
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: "3rem",
    textAlign: "right" as 'right',
    cursor: "pointer",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent', 
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
},
optionContainer:{
    borderLeft: "4px solid #7233EC",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
individualText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
priceText:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left', 
},
priceTextLight:{
  fontFamily: 'Manrope',
  fontSize: "1.125rem",
  color: "#6B6B6B",
  fontWeight: 700,
  letterSpacing: '-0.005em',
  textAlign: 'left' as 'left', 
},

businessContainer:{
    borderLeft: "4px solid #FBBF24",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
businessText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
businessPlan:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
organisationContainer:{
    borderLeft: "4px solid #D74532",
    padding: "0.625rem 1.25rem",
    borderRadius: "3px",
    display: "block",
    marginBottom: "1rem"
},
organisationText:{
    fontFamily: 'Manrope',
    fontSize: "1.938rem",
    fontWeight: 800,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
freeText:{
    fontFamily: 'Manrope',
    fontSize: "1.125rem",
    color: "#BABABA",
    fontWeight: 700,
    letterSpacing: '-0.005em',
    textAlign: 'left' as 'left',
},
textGridContainer:{
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
    display:'flex',
    alignItems:'center',
    gap:12
},
revenueTextContainer:{
  display:'flex',
  alignItems:'center',
  gap:12
},
iconContainer:{
    background: "#222222",
    width: "2.125rem",
    height: "2.125rem",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem"
},
investText:{
  fontFamily: 'Manrope',
  fontSize: "14px",
  maxWidth:"440px",
  width:"100%",
  fontWeight: 500,
  textAlign: 'left' as 'left',
  color: '#6B6B6B',
  lineHeight:'21px'
},
iconsContainer:{
    background: "#222222",
    width: "2.125rem",
    height: "2.125rem",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem"
},
revenueText:{
    fontFamily: 'Manrope',
    fontSize: "14px",
    maxWidth:"440px",
    width:"100%",
    fontWeight: 500,
    textAlign: 'left' as 'left',
    color: '#6B6B6B',
},
welcomeToText:{
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: "2rem",
    letterSpacing: '-0.414px',
    marginBottom: "0.5rem"
},
arrowsText:{
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: "2rem",
    letterSpacing: '-0.414px',
},
verifiedContainer:{
  display:'flex',flexDirection:'row' as 'row'
  ,justifyContent:'flex-start',alignItems:'center',
  marginBottom:'20px'
},
verifyText:{
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "18",
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: "1.625rem",
    letterSpacing: '-0.414px',
},
verifyImage:{
  marginLeft:'5px'
},
iconButtons:{
    marginTop: "8px",
    maxWidth:"420px",
    width:"100%",
    height:48,
    fontSize:'14px',
},
createAccountButtons:{
  marginTop: "1.2rem",
  maxWidth:"420px",
  width:"100%",
  height:48,
  fontSize:'14px',
},
iconButton:{
  marginTop: "1.2rem",
  border:'1px solid #8833FF',
  paddingTop:'22px',
  paddingBottom:'22px',
  height:56,
  fontSize:'14px',
  borderRadius:"8px",
  maxWidth:"420px",
},
iconButton1:{
  marginTop: "1.2rem",
  border:'1px solid #8833FF',
  paddingTop:'22px',
  fontSize:'14px',
  borderRadius:"8px",
  height:56,
  paddingBottom:'22px',
  maxWidth:"420px",
  fontWeight:700,
},
termsText:{
    fontFamily: 'Manrope',
    fontSize: "18px",
    fontWeight: 500,
    textAlign: 'left' as 'left',
    lineHeight: "26px",
    marginTop: "1.7rem"
},
arrowsThickText:{
  background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    cursor:'pointer',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 500,
  },
accountText:{
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontWeight: 400,
    textAlign: 'left' as 'left',
    lineHeight: "1.875rem",
    marginTop: "3rem",
    marginBottom: "0px"
},
corousel:{
    top: '50%',
    right: '2rem',
    padding:'0px 5% 0px 10%',
    transform: 'translateY(-50%)',
    display: "flex",
    flexDirection: "column" as 'column',
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    height:0,
},
corouselGridItem:{
    position: "fixed" as 'fixed',
    right: 120,
    zIndex:99999,
    alignItems:'center',
    width:'150px'
},
signupLoginGrid:{
    paddingRight: "1.5rem"
},
signupLoginText:{
   zIndex:9999,
   cursor: "pointer",
    fontWeight: 700,
    marginTop:'26px'
},
cardStyle:{margin:"auto",position:'relative' as 'relative',width:'100%'}
  
}

// Customizable Area End
import SplashscreenController, {
  Props,
  configJSON,
} from "./SplashscreenController";

export default class SplashScreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.renderCard1 = this.renderCard1.bind(this);
    this.renderCard2 = this.renderCard2.bind(this);
    this.renderCard3 = this.renderCard3.bind(this);
    this.carouselRef = React.createRef<CustomRefType>();
    this.goto = this.goto.bind(this);

    // Customizable Area End
  }
  // Customizable Area Start
  private carouselRef: React.RefObject<CustomRefType>;

  goto(value: number) {
    if (this.carouselRef) {
      //@ts-ignore
      this.carouselRef.goTo(Number(value));
    }
  }

  renderCard1 = () => {
    return (
      <Box style={{margin:'auto'}}>
      <Box style={{ maxWidth: "514px",width:"100%", marginRight:"10%"}}>
      <Typography style={webStyles.productText}>
        {configJSON.productText}<span style={webStyles.unknownText}>{configJSON.unknownText}</span>
    </Typography>
    </Box>
    </Box>
    )
  }

  renderCard2 = () => {
    return (
      <Grid container style={{margin:'auto'}}>
        <div style={{marginLeft:'auto', width:'inherit'}}>
        <div style={webStyles.optionContainer}>
          <Typography style={webStyles.individualText}>
            {configJSON.individualText}
          </Typography>
          <Typography style={this.props.checked ?webStyles.priceTextLight:webStyles.priceText}>
            {configJSON.priceText}
          </Typography>
        </div>
        <div style={webStyles.businessContainer}>
          <Typography style={webStyles.businessText}>
            {configJSON.business}
          </Typography>
          <Typography style={this.props.checked ?webStyles.priceTextLight:webStyles.priceText}>
            {configJSON.priceText}
          </Typography>
        </div>
        <div style={webStyles.organisationContainer}>
          <Typography style={webStyles.organisationText}>
            {configJSON.organisationText}
          </Typography>
          <Typography style={this.props.checked ?webStyles.priceTextLight:webStyles.priceText}>
            {configJSON.freeText}
          </Typography>
        </div>
          <Grid alignItems="center" style={webStyles.textGridContainer}>
          <div><img src= {addIcon} /></div>
            <Typography style={webStyles.investText}>{configJSON.investText}</Typography>
          </Grid>
          <Grid  alignItems="center" style={webStyles.revenueTextContainer}>
            <div><img src= {addIcon} /></div>
            <Typography style={webStyles.revenueText}>{configJSON.revenueText}</Typography>
          </Grid>
        </div>
      </Grid>
    )
  }

  renderCard3 = () => {
    return (
      <Box style={webStyles.cardStyle}>
        <Box style={{display:'flex',flexDirection:'column', justifyContent:'flex-end',float:'right',maxWidth:"420px",width:'100%'}}>
        <Typography style={webStyles.welcomeToText} data-test-id='welcome'>
          {configJSON.welcomeToText}<span style={webStyles.arrowsText}>{configJSON.arrowsText}</span>
        </Typography>
        <Box style={webStyles.verifiedContainer}>
        <Typography style={webStyles.verifyText}>
          {configJSON.verifyText}
        </Typography>
        <img src={verificationMark} style={webStyles.verifyImage} alt="verificationMark"/>
        </Box>
        <Button color="primary" variant="outlined" startIcon={<img src={googleLogo} alt="googleLogo"/>} style={webStyles.iconButton1}>{configJSON.googleButton}</Button>
        <GoogleOAuthProvider clientId={configJSON.clientId}> 
        <div style={webStyles.google} >
        <GoogleLogin 
          width='990px'
          data-test-id='googleLogin'
          onSuccess={this.handleLoginSuccess}
          onError={this.handleLoginError}
          />
          </div>
          </GoogleOAuthProvider>
        <Button color="primary" variant="outlined" startIcon={<img src={this.props.checked? appleDarkLogo:appleLogo} alt="appleLogo"/>} style={webStyles.iconButton}>{configJSON.appleButton}</Button>
        <Button color="primary" variant="contained" endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>} style={webStyles.createAccountButtons} id="go-to-signup-btn" onClick={this.goToSignUpPage.bind(this)}>{configJSON.createAccount}</Button>
        <Typography style={webStyles.termsText}>
          {configJSON.termstext} <span style={webStyles.arrowsThickText} data-testid="terms" onClick={this.handleOpenModal}>{configJSON.termsConditionlink}</span>{configJSON.and}<span  style={webStyles.arrowsThickText} onClick={this.handleOpenModal}>{configJSON.privaciyPolicyText}</span>{configJSON.toArrows}
        </Typography>
        <Typography style={webStyles.accountText}>
          {configJSON.accountText}
        </Typography>
        <Button id="go-to-login-btn" style={webStyles.iconButtons} color="primary" variant="contained" endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>} onClick={this.goToLoginPage.bind(this)}>{configJSON.logIn}</Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const theme = this.props.checked ? lightTheme : darkTheme;
    const {classes}=this.props 
    const corouselDotImage=this.props.checked? inActiveDotImage:inActiveDot
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Paper className={this.props.checked? classes?.lightColor:classes?.darkColor}>
          <Loader loading={this.state.splashScreenPageLoading} />
          <Grid container className={this.props.checked ? classes?.lightThemeBackground : classes?.darkThemeBackground}>
              <Grid item  style={webStyles.corouselGridItem}>
              <Typography id="login-signup-goto" onClick={() => this.goto(2)} style={webStyles.signupLoginText}>{configJSON.signupLoginText}</Typography>
                 </Grid>
              <Grid item xs={1} sm={5} md={6} container justifyContent="center" alignItems="center" direction="column">
              </Grid>
              <Grid item xs={10} sm={7} md={6} container justifyContent="center" alignItems="center" alignContent="center" style={webStyles.corouselContainer}>
                  <StyledGrid style={{width:"100%"}} container justifyContent="center" alignItems="center" alignContent="center">
                    <Carousel 
                    verticalMode
                    autoPlaySpeed={3000}
                    enableAutoPlay
                    ref={(ref) => { //@ts-ignore 
                    this.carouselRef = ref}} 
                      data-test-id="vertical-carousel"
                      itemsToShow={1}
                      isRTL={false}
                      showArrows={false}
                      renderPagination={({pages, activePage, onClick}) => {
                      return (
                        <div style={webStyles.corousel}>
                          {pages?.map((page) => {
                            const isActivePage = activePage === page
                            return (
                              <img
                                key={page}
                                alt="activeDot"
                                onClick={() => onClick("" + page)}
                                src={isActivePage ? activeDot : corouselDotImage}
                                style={{cursor: "pointer"}}
                              />
                            )
                          })}
                        </div>
                      )
                    }}>
                      <Box style={{height:"100vh",display:'flex'}}>{this.renderCard1()}</Box>
                      <Box style={{height:"100vh",display:'flex'}}>{this.renderCard2()}</Box>
                      <Box style={{height:"100vh",display:'flex',width:'420px',marginLeft:'auto'}}>{this.renderCard3()}</Box>
                    </Carousel>
                  </StyledGrid>
              </Grid>
          </Grid>
          <TermsConditionModal 
          data-testid="termsConditionModal"
          open={this.state.open}
          checked={this.props.checked}
          handleClose={this.handleClose}
          handlenext={this.handleClose}
            />
          </Paper>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles={
  lightThemeBackground:{
    height: "100vh",
    backgroundImage: `url(${lightBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down('xs')]: 
    {backgroundImage: 'none'}
},
darkThemeBackground:{
  height: "100vh",
  backgroundImage: `url(${darkBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down('xs')]: 
  {backgroundImage: 'none'}
},
lightColor:{
  backgroundColor:'#fff'
},
darkColor:{
  backgroundColor:'#070707'
}
}
export const LandingPageWithStyle=withStyles(styles)(SplashScreen)
// Customizable Area End
