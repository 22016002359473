import React from "react";
import { toast } from "react-toastify";
import { BlockComponent } from '../../framework/src/BlockComponent';
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from '../../framework/src/RunEngine';
import { IBlock } from "../../framework/src/IBlock";
import { removeStorageData } from "../../framework/src/Utilities";

export interface Props {
  isLoggedIn: boolean;
  navigation: any;
  path: string
}

export interface S {
}

export default class SessionGate extends BlockComponent<Props, S, {}> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && (responseJson?.errors?.[0] === 'Invalid token' || responseJson?.errors?.[0] === 'Token has Expired')) {
        if (this.props.path !== '/') {
          toast.info(responseJson?.errors?.[0]);
          this.handleLogout();
        }
      }
    }
  }

  handleLogout = async () => {
    Object.keys(localStorage).forEach(async (key) => {
      await removeStorageData(key);
    });
    this.props.navigation.history.replace("/")
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }
}