import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Grid,
    Typography,
    styled,
    Button
} from '@material-ui/core'

import MuteModalController,{Props} from './MuteModalController.web';
// Customizable Area End

export default class MuteModal  extends MuteModalController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start

    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }

      bottomPoperRender = () => {
        return <DialogBox data-test-id="buttomPoper" onClose={() =>
          this.props.onButtompoperlClose()
        }
          open={this.props.bottompoper}
          PaperProps={{
            className: this.conditionRemover(!this.props.checked, 'bottomModelDarkStyle', 'bottomModelStyle')
          }}
        >
          <GridContainer2 container justifyContent="center" alignItems="center" direction="column" 
          >
            <Box display={"flex"} className='bottomBoxContainer'>
              <Typography className={"subcriptionBody"} >{this.props.activeUser?.userName} has been muted</Typography>
              <Button onClick={() =>this.props.onUnmuteUser(this.props.activeUser?.id.toString() as string)} data-test-id="undoBtn" className={"undoBtn"} >Undo</Button>
            </Box>
    
          </GridContainer2>
        </DialogBox>
      }

    renderModal=()=>{
        return (
        <DialogBox data-test-id="muteModel" onClose={() =>this.props.onMuteModelClose()}
            open={this.props.muteModel}
            PaperProps={{
              className: this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")
            }}
          >
            <GridContainer container justifyContent="center" alignItems="center" direction="column" >
      
              <Box>
                <Typography className={"name"}>Mute {this.props.activeUser?.userName}</Typography>
              </Box>
      
              <Box>
                <Typography className={"emaptySubhead"}>Are you sure you want to mute {this.props.activeUser?.userName}?</Typography>
              </Box>
      
              <Box className='muteAction'>
                <Button onClick={()=>this.props.onMuteUser("no")} 
                   data-test-id="noBtn"
                    className={this.conditionRemover(this.props.checked,"muteBtnDeny","muteBtnDenyDark")}>
                  <Typography className={"showmore"} >No</Typography>
                </Button>
                <Button 
                onClick={()=>this.props.onMuteUser("yes")} 
                data-test-id="muteagree" 
                className={"muteBtn"}>Mute Now</Button>
              </Box>
            </GridContainer>
          </DialogBox>
      )
    }
    // Customizable Area End
    render(){
    // Customizable Area Start
    return(
       <BoxContainer>
        {this.renderModal()}
        {this.bottomPoperRender()}
       </BoxContainer>
    )
    // Customizable Area End    
    }
}
// Customizable Area Start
const BoxContainer = styled(Box)({

})

const GridContainer2 = styled(Grid)({
  padding: "10px 20px", 
  gap: "15px", 
  minWidth: "270px",
  height: "52px",

  "& .bottomBoxContainer":{
    gap: "15px", 
    alignItems: "center" 
  },
  "& .subcriptionBody": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
  },
  "& .undoBtn": {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "6px 10px",
    width: "51px",
    height: "32px",
    color: "white",
    fontSize: "14px",
    fontWeight: 500
  },
})

const DialogBox = styled(Dialog)({
   "& .bottomModelDarkStyle": {
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  },

  "& .modelDarkStyle": {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none"
  },
  "& .modelLightStyle": {
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
  },

  "& .bottomModelStyle":{
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  }
})
const GridContainer = styled(Grid)({
  padding: "27px 24px", 
  rowGap: "24px", 
  width: "375px",
  height: "220px",
  "& .modelDarkStyle": {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none"
  },
  "& .modelLightStyle": {
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
  },
  "& .name": {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    whiteSpace: "nowrap" as "nowrap",
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  "& .emaptySubhead": {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center" as "center"
  },

  "& .muteBtn": {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px",
    color: "white",
    fontSize: "14px",
    fontWeight: 700
  },
  "& .muteBtnDeny": {
    boxShadow: "none !important",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  "& .muteBtnDenyDark": {
    boxShadow: "none !important",
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  "& .showmore": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },

  "& .muteAction":{
    display: "flex", 
    columnGap: "12px" 
    }

   })
   // Customizable Area End