import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Input,
  Paper,
  Grid,
  InputAdornment,
  Tabs,
  Tab,
  IconButton,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { backArrowDark, backArrowWhite, darkBackground, lightBackground, memberList, searchDark, searchWhite, usersDark, usersLight } from "./assets";
import ViewChat from "./ViewChat.web";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderMainCat = () => {
    const {checked} = this.props
    return(
      <div className="main-category">
        <div className="chat-top-header">
          <div>
             <Button className="back-button-settings" variant="text">
              <span><img src={checked ? backArrowDark : backArrowWhite} />
              </span> {this.state.activeIndex==0?"Chats":"Groups"}</Button>
          </div>
          <IconButton
            data-test-id="createGroups"
            onClick={this.handleCreateGroup}
           >
            <img src={checked?usersDark:usersLight} alt="" />
          </IconButton>
        </div>
        <div className="search-container">
        <Input
          id="input-with-icon-adornment"
          className="search-field"
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <img src={checked ? searchDark: searchWhite } />
            </InputAdornment>
          }
        />
        </div>
        <div>
            {this.renderTabs()}
        </div>
        <div className="chat-display">
        {memberList.map((each, index: number) =>
          (<div className="chat-outer" >            
               <div className="chat-chield-first" >
               <img src={each.profile} className={"iconImage"} />            
            <div  className="chat-chield-second" >
              <Typography className="mainCatLabel">{each.name} </Typography>
              <Typography className="mainCatLabel1">{each.message}</Typography>
            </div>

           </div>
            <div>
            <div className="chat-chield-third" >
              <Typography className="mainCatLabel4"> <span className="unreadcound">{each.unreadCount}</span></Typography>
              <Typography className="mainCatLabel3">{each.createAt}</Typography>
            </div>
            </div>
          </div>))}
          </div>
        <div>
        </div>
      </div>       
    )
  }

  renderSideCat=()=>{
    if(this.state.isGroup){
     return  <Typography data-test-id="create-group" className="mainCatLabel">InProgress </Typography>
    } else{
     return <ViewChat navigation={this.props.navigation} checked={this.props.checked} id=""/>
    }                   
  }

  renderTabs=()=>{
    return  <Tabs
    value={this.state.activeIndex}
    onChange={this.handleTabsChange}
    classes={{ indicator: "indicator" }}
    textColor="primary"
    variant="fullWidth"
    data-test-id="tabBtn"
  >
    <Tab label="All" className={"tabstyle"} />
    <Tab label="Groups" className={"tabstyle"} />   
  </Tabs>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props?.checked ? lightTheme : darkTheme}>
        <StyleContainer>
          <Paper className={this.props?.checked? "paperLightTheme" : "paperDarkTheme"}>
            <Loader loading={this.state.isLoading} />
            <Grid container>
              <Grid item className="navigationSection">
                <NavigationMenu navigation={this.props.navigation} id={""} checked={this.props?.checked } />
              </Grid>
              <Grid item data-test-id="chat-id" className="settingsMainCat">
                {!this.state.isLoading && this.renderMainCat()}
              </Grid>
              <Grid item className="viewChatWrapper">
                {this.renderSideCat()}
              </Grid>                
            </Grid>
          </Paper>
        </StyleContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyleContainer = styled(Box)({
  "& .main-category": {
    marginTop: "20px",
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily:"Manrope",
  },
  "& .tabstyle":{
    fontFamily:"Manrope",
    fontWeight:600,
    fontSize:"16px",
    textTransform:"capitalize" as "capitalize",
    borderBottom:"3px solid gray",    
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  } ,
  "& .indicator":{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  "& .viewChatWrapper":{
    width: "535px",
    padding: "10px 20px 20px 20px",
    borderRight: "1px solid #222222",
    height: "100vh",    
  },
  "& .paperDarkTheme":{
    display:"flex",
    backgroundColor:'#070707',
    backgroundImage:`url(${darkBackground})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:'100% 100%',
    height:'100%',
    width:"100vw"
  },
  "& .paperLightTheme":{
    display:"flex",
    backgroundColor:'#fff',
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${lightBackground})`,
    backgroundSize:'100% 100%',
    height:'100%',
    width:"100vw",
  },
  "& .search-container": {
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .chat-display":{
    display:"flex",
    flexDirection:"column",
    rowGap:"20px",
    height: "calc(100vh - 247px)",
    overflowY:"scroll",
    scrollbarWidth: "none" ,
    "&::-webkit-scrollbar": {
      display: "none" ,
    },
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .chat-top-header":{
    display:"flex",
    justifyContent:"space-between",
  },
  "& .back-button-settings": {
    height: "48px",
    width: "110px",
    fontFamily:"Manrope",
    fontSize: "18px",
    fontWeight: "800",
    paddingRight: "0"
  },
  "& .chat-outer":{
   display:"flex",
   maxWidth:"380px",
   justifyContent:"space-between",
   height:"58px",
   padding:"5px 20px",
   alignItems:"center"
  },
  "& .chat-chield-first":{
    display:"flex",
    gap:"10px"
  },
  "& .chat-chield-second":{
   display: 'flex',
   flexDirection: 'column',
   gap:"4px",
   height:"37px",
   marginLeft:"12px"
  },
  "& .chat-chield-third":{
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap:"10px",
      paddingBottom:"6px"
   },
  "& .back-button-settings .MuiButton-label": {
    justifyContent: "space-between",
    textTransform: "capitalize"
  },
  "& .justify-content-right": {
    justifyContent: "end"
  },
  "& .mainCatLabel": {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily:"Manrope",
    lineHeight:"19.2px",
    textTransfer:"capitalize"
  },
  "& .mainCatLabel ":{
    marginTop:"0px !important"
  },
  "& .mainCatLabel1": {
    fontSize: "12px",
    fontWeight: "700",
    fontFamily:"Manrope",
    color:"#6B6B6B",
    lineHeight:"19.2px",
    margineTop:"0px",
    width:"175px"
  },
  "& .mainCatLabel3 ": {
    fontSize: "12px",
    fontWeight: "500",
    fontFamily:"Manrope",
  },
  "& .mainCatLabel4": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color:"white",
    borderRadius:"50%",
    width:"18px",
    height:"18px",
    textAlign: "center",
    lineHeight:"18.2px",
    padding:"3px",
    "& .unreadcound":{
      fontSize: "10px",
    fontWeight: "400",
    width:"6px",
    height:"12px",
    fontFamily:"Manrope",
    }
  },
  "& .list .listItem:hover": {
    backgroundColor: "unset"
  },
  "& .list .listItem": {
    cursor: "pointer"
  },
  "& .list .iconImage":{
   height:"48px",
   width:"48px"
  }

})
const webStyles = {
  createButtonView: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
    marginBottom: 20,
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  mutedChat: {
    backgroundColor: "lightgray"
  },
};
// Customizable Area End
