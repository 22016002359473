import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';


export const COLOR_PALETTE = {
  palette: {
    primary: {
      main: '#8833FF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FFD88A',
      contrastText: '#fff'
    },
    text: {
      primary: '#4b4244',
      secondary: '#666769',
    },
    error: {
      main: "#DC2626"
    }
  }
};

const theme: Theme = createTheme({
  ...COLOR_PALETTE,
  props: {
    MuiDivider: {
      light: true
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1600,
      xl: 1920
    }
  },
  typography: {
    fontFamily: ['Manrope'].join(','),
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: 1.25,
      letterSpacing: '0.27px'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1.33,
      letterSpacing: '0.2px'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: 1.2,
      letterSpacing: '0.2px'
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: 1.78,
      letterSpacing: 'normal'
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: 1.38,
      letterSpacing: '0.2px'
    },
    body1: {
      color: "#000",
    },
    body2: {
      color: "#000",
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          "& #proxy-renderer": {
            overflowY: "hidden"
          }
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Manrope',
        fontSize: "0.8rem",
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: '#000000',
        "&.Mui-error": {
          color: "#DC2626"
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Manrope',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '0.2px',
        color: '#183B56',
        textTransform: 'capitalize'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px'
      }
    },
    MuiButton: {
      root: {
        height: '3rem',
        fontFamily: 'Manrope',
        fontSize: '0.9rem',
        fontWeight: 800,
        textAlign: 'center',
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        borderRadius: "0.625rem",
        textTransform: "none",
        "&.Mui-disabled": {
          background: 'linear-gradient(330.07deg, #C5C5C5 -51.03%, #C5C5C5 138.78%)',
          cursor: 'not-allowed',
          '&:hover': {
            background: 'linear-gradient(330.07deg, #C5C5C5 -51.03%, #C5C5C5 138.78%)',
          },
        }
      },
      outlinedPrimary: {
        border: `2px solid #8833FF`,
        background: 'transparent',
        color: "#000",
        '&:hover': {
          border: `2px solid #8833FF`,
          background: 'transparent',
        },
      },
      endIcon: {
        marginLeft: '0.75rem'
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: '4px'
      }
    },
    MuiOutlinedInput: {
      root: {
        outline: 'none',
        border: `2px solid #6B6B6B`,
        borderRadius: '10px',
        backgroundColor: 'transparent',
        'label + &': {
          marginTop: 30,
        },
        '& > fieldset': {
          border: 'none',
          '& active': {
            border: 'none'
          },
          '& focus': {
            border: 'none'
          }
        },
        '&.Mui-focused': {
          border: `2px solid #8833FF`,
          backgroundColor: 'transparent'
        },
        '&.Mui-error': {
          border: `2px solid #DC2626`,
          backgroundColor: 'transparent',
          '&.Mui-focused': {
            backgroundColor: 'transparent'
          }
        },
        '&.MuiSelect-root': {
          border: 'none',
          outline: 'none'
        }
      },
      input: {
        borderRadius: '0.75rem',
        position: 'relative',
        fontSize: '0.9rem',
        color: "#000",
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        border: 'none',
        '&::placeholder': {
          color: '#6B6B6B',
          fontFamily: 'Manrope',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 500,
        },
        '&:focus': {
          border: 'none',
          borderRadius: '0.75rem',
          backgroundColor: 'transparent',
          '&::placeholder': {
            color: '#6B6B6B'
          }
        }
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '1rem',
        fontFamily: 'Manrope',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        '&:focus': {
          border: 'none',
          borderRadius: '0.75rem',
          backgroundColor: 'transparent',
          '&::placeholder': {
            color: '#6B6B6B'
          }
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#000',
        fontFamily: 'Manrope',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        "&.Mui-focused": {
          color: "#000"
        },
        '&.Mui-error': {
          color: "#000"
        },
      },
      asterisk: {
        fontFamily: "Manrope",
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#000'
      },
      outlined: {
        transform: 'translate(3px, 9px) scale(1)!important'
      },
      shrink: {
        transform: 'translate(3px, 9px) scale(1)!important'
      }
    },
    MuiListItem: {
      root: {
        padding: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        borderRadius: "16px",
        minWidth: "20vw",
        background: 'rgba(33, 33, 33, 0.04)',
        boxShadow: '0px 4px 4px 0px #00000040',
        border: '1px solid',
        borderImageSource: 'linear-gradient(274.89deg, #5B5B5B -25.44%, rgba(80, 80, 80, 0) 113.13%)',
      },
    },
  }
});

export default responsiveFontSizes(theme);
