import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { back_btn,back_btn_black,right_arrow,right_arrow_black } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

 export interface IError{
  errors:{}|string
 }


export interface IGetSettings {
  data: IGetSettingData
}

export interface IGetSettingData{
  id:string,
  type:string,
  attributes:IGetSettingsAttributes
}

export interface IRemoveContactsResp{
  messages:Array<string>
}

export interface IGetSettingsAttributes{
  find_me_with_email : boolean
  find_me_with_phone_number:boolean
  sync_address_book:boolean
  'address_book_synced?' :boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean
  handleBack?:()=>void
  // Customizable Area End
}

interface S {
   // Customizable Area Start
  showRemoveContactsConfirmation: boolean
  emailSwitch: boolean;
  phoneSwitch: boolean;
  loading:boolean;
  sycPhoneBookAddress:boolean;
  isAddressBookSync:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Discoverysettings3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDiscoverySettingCallId: string=""
  apiDeletePhoneSyncCallId: string=""
  apiUpdateDiscoverySettingCallId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showRemoveContactsConfirmation:false,
      emailSwitch:false,
      phoneSwitch:false,
      loading:false,
      sycPhoneBookAddress:false,
      isAddressBookSync:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message); 

    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.handleResponse(apiRequestCallId,responseJson)
    // Customizable Area End
  }

   // Customizable Area Start

  async componentDidMount() {
     this.getDiscoverySettings()
  }

  getDiscoverySettings=async ()=>{
    let token = await getStorageData("authToken");
    this.setState({
      loading:true
    })

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDiscoverySettingCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getDiscoverySettings);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  UpdateSettings=async()=>{
    let token = await getStorageData("authToken");
        
    const payload = {
      find_me_with_email:this.state.emailSwitch,
      find_me_with_phone_number:this.state.phoneSwitch
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUpdateDiscoverySettingCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getDiscoverySettings);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(payload));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  DeleteSycPhoneBookAddresss=async()=>{
    let token = await getStorageData("authToken");
        
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDeletePhoneSyncCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.deletePhoneAddressBook);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.deleteApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  

  handleResponse=(apiRequestCallId:string,responseJson:IRemoveContactsResp & IGetSettings & IError)=>{
    switch(apiRequestCallId){

      case this.apiGetDiscoverySettingCallId:
        return  this.handleGetsettings(responseJson)

      case this.apiUpdateDiscoverySettingCallId:
        return  this.handleUpdatesettings(responseJson)

      case this.apiDeletePhoneSyncCallId:
        return  this.handleDeletesContacts(responseJson)   

    }
  }

  handleSwitch=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState((prev:S)=>({
      ...prev,
      [event.target.name] : event.target.checked
    }),this.UpdateSettings)
  }
   
  handleUpdatesettings=(responseJson: IGetSettings&IError)=>{
    if(!responseJson.errors){
      this.setState({
        emailSwitch:responseJson.data.attributes.find_me_with_email,
        isAddressBookSync:responseJson.data.attributes['address_book_synced?'],
        phoneSwitch:responseJson.data.attributes.find_me_with_phone_number,
        sycPhoneBookAddress:responseJson.data.attributes.sync_address_book,
      })
    }
  }
  
  handleDeletesContacts=(responseJson:IRemoveContactsResp & IError)=>{
    this.setState({
      showRemoveContactsConfirmation:false
    })
    if(responseJson.errors){
      return
    }
  }


  handleGetsettings=(responseJson:IGetSettings&IError)=>{
    if(!responseJson.errors){
      this.setState({
        loading:false,
        emailSwitch:responseJson.data.attributes.find_me_with_email,
        phoneSwitch:responseJson.data.attributes.find_me_with_phone_number,
        sycPhoneBookAddress:responseJson.data.attributes.sync_address_book,
        isAddressBookSync:responseJson.data.attributes['address_book_synced?']
      })
    }
  }




  onClickBackButton=()=>{
    this.props.handleBack && this.props.handleBack()
  }

  getImageUrl= (isDarkMode:boolean, imageType: 'back' | 'right') => {
    const imageMap = { 
      back: { dark: back_btn, light: back_btn_black },
      right: { dark: right_arrow, light: right_arrow_black}
    };
    return isDarkMode ?imageMap[imageType].dark : imageMap[imageType].light;
  }

  showRemoveModal = () => {
    if(this.state.isAddressBookSync){
    this.setState({showRemoveContactsConfirmation: true})
    }
  }

  onCloseRemoveAccountModal = () => {
    this.setState({ showRemoveContactsConfirmation: false })
  }

  onConfirmRemoveAccountModal = () => {
  this.DeleteSycPhoneBookAddresss()
  }
  
  // Customizable Area End
}
