// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BroadcastMessage";
exports.labelBodyText = "BroadcastMessage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStoriesAPiEndPoint = "bx_block_broadcast_message/stories"
exports.getOwnerStoriesAPiEndPoint ="bx_block_broadcast_message/stories/my_stories"
exports.getownSennStoriesAPiEndPoint = "bx_block_broadcast_message/story_viewers"
exports.likeStoriesAPiEndPoint = "bx_block_like/likes"
exports.loggedUserEndpoint ="account_block/accounts/logged_user"
exports.deleteAPiMethod = "DELETE";

// Customizable Area End
