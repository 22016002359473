// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  classes?: any
  highlightedBody: string
  media: any[]
  mediaMentions: any[]
}

interface S {
}

interface SS {
}

export default class PostBodyController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];


    this.state = {
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  generateMentionMediaText = (users: any): string => {
    if (users.length === 1) {
      return users[0].full_name;
    } else if (users.length === 2) {
      return `${users[0].full_name} and ${users[1].full_name}`;
    } else if (users.length > 2) {
      return `${users[0].full_name} and ${users.length - 1} others`;
    } else {
      return '';
    }
  }

}
// Customizable Area End