import React from "react";
// Customizable Area Start

import {
Modal,
Box,
Fade,
Backdrop,
IconButton,
Typography,
Button
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider} from "@material-ui/core/styles";
import {darkView,view,arrowLogo, RightIcon} from "./assets"
import TermsConditionModalController,{configJSON,Props} from "./TermsConditionModalController";

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3858E3",
      },
      secondary:{
        main:'rgba(0,0,0,0.75)'
      }
     }
    });
  
  const darkTheme=createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#3858E3",
        },
        secondary:{
        main:"rgba(255,255,255,0.75)"
        }
       }
      });
  
// Customizable Area End
// Customizable Area Start
export default class TermsConditionModal extends TermsConditionModalController {
    constructor(props: Props) {
        super(props);
    }


 render(){
    return(
      <ThemeProvider theme={this.props.checked?lightTheme:darkTheme}>
        <ModalContainer 
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={"modal"}
            open={this.props.open}
            onClose={this.props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
         >
            <Box style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Fade in={this.props.open} style={inputStyles.fade}>
              {this.state.termsAndConditionsData.length !== 0 ? <div data-testid={"termsCondition"} className={this.props.checked ? "paper" : "paperLight"}>
              <IconButton data-test-id='goBack' className={"termsBackIcon"}  onClick={this.props.handleClose}>  <img src = {this.props.checked?darkView:view} /> </IconButton>
                <img src={arrowLogo} className={"logoPicture"} />
                {this.state.termsAndConditionsData.map((terms) =>
                  <Box key={terms.id}>
                    <Typography data-test-id='title' className={terms.id === 1 ? "title" : "cookiesText"}>{terms.title}</Typography>
                    {terms.description.map((details:any) => <Typography key={details} className={"termsText"}>{details}</Typography>)}
                  </Box>
                )}
                <Button
                  data-test-id='nextButtonModal'
                  className={"modalButton"}
                  fullWidth
                  onClick={this.props.handlenext}
                  endIcon={<StyledIcon><img src ={RightIcon}/></StyledIcon>}
                >{configJSON.next}
                </Button>
              </div> : <div>{this.state.termsAndConditionsError}</div>}
            </Fade>
            </Box>
        </ModalContainer>
      </ThemeProvider>
    )
 }
}

const inputStyles={
    customInput: {
        fontSize: '14px',
        fontFamily: "Manrope",
        fontStyle: "normal" as 'normal', 
       },
       fade:{
        border:'1px solid #5B5B5B',
        borderRadius:'10px'
       },
       darkBackdrop:{
         backgroundColor:'#0E0E0E',
         opacity:0.8,
         zIndex:0
       },
       lightBackdrop:{
        backgroundColor:'#FFFFFF',
        opacity:0.9,
        zIndex:0
       }
}

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });

const ModalContainer = styled(Modal)(({
    "& .logoPicture": {
        marginTop: '20px',
        height: '53px',
        width: '54px',
        marginBottom: '10px'
      },
      "& .termsBackIcon": {
        position: 'absolute' as 'absolute',
        left: '2%',
        fontSize: '25px',
        cursor: 'pointer',
        top: "6%",
        "& .MuiIconButton-root:hover":{
          padding:"0px",
          borderRadius:'0px',
          height:"100%",
          width:"100%"
          }
      },
      "& .modal": {
        display: 'flex',
        alignItems: 'center',
        height:"100%",
        width:'100%',
        justifyContent: 'center',
      },
      "& .termsText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as "normal",
        fontWeight: 500,
        marginTop: '8px',
      },
      "& .cookiesText": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as "normal",
        fontWeight: 500,
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        textAlign: 'left' as 'left',
        marginTop: '6px',
        marginBottom: '1px'
      },
    
      "& .title": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        fontFamily: "Manrope",
        fontSize: "24px",
        fontStyle: "normal" as "normal",
        fontWeight: 800,
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        textAlign: 'center' as 'center'
      },

      "& .modalButton": {
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        textTransform: "capitalize" as "capitalize",
        color: "#FFF",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        marginTop: '35px',
        height: '48px',
        padding: '10px'
      },

      "& .paper": {
        backgroundColor: '#fff !important',
        padding: theme.spacing(2, 4, 3),
        width: '30%',
        height: '85vh',
        overflow: 'auto',
        borderRadius: '10px',
        color: 'black',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        position: 'relative' as 'relative',
        [theme.breakpoints.down('sm')]:
          { width: '50%' }
      },
      "& .paperLight": {
        backgroundColor: 'black !important',
        padding: theme.spacing(2, 4, 3),
        width: '30%',
        height: '85vh',
        overflow: 'auto',
        borderRadius: '10px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        position: 'relative' as 'relative',
        cursor:'pointer',
        "&::-webkit-scrollbar":{
          width: "6px",
        },
        "&::-webkit-scrollbar-track":{
          background: "#E9E9E9",
          borderRadius: "5px",
        },
       "&::-webkit-scrollbar-thumb":{
          background: "#BABABA",
          borderRadius: "5px",
          height:"62px",
        },
        "&::-webkit-scrollbar-thumb:hover":{
          background: "#BABABA"
        },
        [theme.breakpoints.down('sm')]:
          { width: '50%'}
      },
}))
// Customizable Area End