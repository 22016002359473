// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:boolean
  classes?:any
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsLocation: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  seachLocation:string
  isModel:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsLocation: "",
      isSpinnerShowing: false,
      seachLocation:"",
      isModel:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handlelClose =() =>{
    this.setState({isModel:false})
  }

  handleSearchLocation = (event:any)=> {    
    this.setState({seachLocation:event.target.value});
  }

  // Customizable Area End
}
